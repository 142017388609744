import React, { useState } from 'react';
import { Nav, NavLink, TabContent } from 'reactstrap';
import Tab, { TabProps } from './Tab';

export interface TabsProps {
  tabs: TabProps[];
}

export default (props: TabsProps) => {
  const { tabs } = props;
  const [activeTab, setActiveTab] = useState(1);

  const toggle = (tab: number) => () => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className={'tab-wrapper'}>
      <Nav tabs>
        {tabs.length > 0 &&
          tabs.map(tab => {
            const { tabId, tabTitle } = tab;
            return (
              <NavLink
                className={activeTab === tabId ? `active` : ``}
                onClick={toggle(tabId)}>
                {tabTitle}
              </NavLink>
            );
          })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {tabs.length > 0 &&
          tabs.map(tab => {
            const { tabId, tabTitle, content } = tab;
            return <Tab tabId={tabId} tabTitle={tabTitle} content={content} />;
          })}
      </TabContent>
    </div>
  );
};
