import React from 'react';
import { TableColumn } from '../../masterlist/MasterList';
import { MasterListActionButton, IndexableMasterListItem } from '../../masterlist/types';
import {
  useAffiliatedCompaniesForInstance,
  AffiliatedUserCompanyDTO,
  AffiliatedUserCompany,
  deleteCompanyAffiliation
} from '../../../api/affiliations';
import { FilteredMasterList } from '../../masterlist/filter/FilteredMasterList';
import { AllAffiliationData } from './AllAffiliations';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const columns: TableColumn[] = [
  { id: 'companyId', title: 'Company ID', width: '20%' },
  { id: 'displayName', title: 'Name', width: '40%' },
  { id: 'companyName', title: 'Company', width: '40%' }
];

//const actions = (): MasterListActionButton[] => [];
const actions = (refresh?: () => void) => (
  companyId: number,
  axnInstanceId: number,
  userId: number
): MasterListActionButton[] => [
  {
    to: `/admin/affiliate/airport/${userId}`,
    icon: faTrash,
    text: 'Delete',
    actionCallback: (event: React.MouseEvent<any, MouseEvent>) => {
      event.preventDefault();

      deleteCompanyAffiliation(userId, companyId, axnInstanceId)
        .then(response => {
          if (response.kind === 'Success') {
            toast.success('Successfully deleted company affiliation');
          } else if (response.kind === 'Failure') {
            toast.error(`Unable to delete affiliation: ${response.error}`);
          }
        })
        .then(() => {
          if (refresh) {
            refresh();
          }
        });
    }
  }
];

const convert = (data: AffiliatedUserCompanyDTO): AffiliatedUserCompany => ({
  companyId: data.company.companyId,
  displayName: `${data.user.lastName}, ${data.user.firstName}`,
  companyName: data.company.companyName,
  securityRole: data.securityRole.securityRoleName,
  axnInstanceId: data.axnInstanceId,
  userId: data.user.userId
});

export const allCompaniesModel: AllAffiliationData = {
  fetchData: useAffiliatedCompaniesForInstance,
  actions,
  columns,
  convert
};

interface Props {
  args: string;
  axnInstanceId: number;
  refresh: () => void;
}

const AllCompanyAffiliations = ({ args, axnInstanceId, refresh }: Props) => (
  <FilteredMasterList
    listType="Affiliations"
    actions={actions(refresh)}
    columns={columns}
    convert={d => convert(d) as IndexableMasterListItem}
    useData={useAffiliatedCompaniesForInstance}
    args={args}
    axnInstanceId={axnInstanceId}
  />
);

export default AllCompanyAffiliations;
