import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { appHistory } from '../../reducers';

const GoBack = (props: { color: string; className: string }) => {
  const { color, className } = props;
  return (
    <Button
      color={color}
      className={className}
      onClick={e => {
        e.preventDefault();
        appHistory.goBack();
      }}>
      <FontAwesomeIcon icon={faArrowLeft} /> Go Back
    </Button>
  );
};

GoBack.defaultProps = {
  color: 'primary',
  className: ''
};

export default GoBack;
