import { combineReducers } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { usersReducer, UserState } from './usersReducer';
import { terminalsReducer, TerminalsState } from './terminalsReducer';
import { productTypesReducer, ProductTypesState } from './productTypesReducer';
import { companiesReducer, CompaniesState } from './companiesReducer';
import { airportsReducer, AirportsState } from './airportsReducer';
import { wizardReducer, WizardState } from './wizardReducer';
import { initialPageState, pageReducer } from './pageReducer';
import { contactsReducer, ContactsState } from './contactsReducer';

export const appHistory = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(appHistory),
  users: usersReducer,
  airports: airportsReducer,
  terminals: terminalsReducer,
  productTypes: productTypesReducer,
  companies: companiesReducer,
  wizard: wizardReducer,
  page: pageReducer,
  contacts: contactsReducer
});

export interface AppState {
  page: typeof initialPageState;
  users: UserState;
  companies: CompaniesState;
  contacts: ContactsState;
  terminals: TerminalsState;
  airports: AirportsState;
  productTypes: ProductTypesState;
  wizard: WizardState;
}
export default rootReducer;
