import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik, FormikActions } from 'formik';
import moment from 'moment';
import { find, get } from 'lodash';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Col, Form, Row } from 'reactstrap';
import { faRedoAlt, faSave, faStoreAlt } from '@fortawesome/free-solid-svg-icons';
import { StepDefaultProps, WizardTenantsMatch } from '../../../types';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import FormField from '../../../../forms/FormField';
import { appHistory, AppState } from '../../../../../reducers';
import { airportTenantsValidationSchema } from './yupSchema';
import { useAirportTenantStatuses, useAirportTenantTypes } from '../../../../../api/Airports';
import { TerminalData, TerminalsList } from '../../../../../actions/terminalsActions';
import { useAllCompanies } from '../../../../../api/Companies';
import ExistingTenants from './ExistingTenants';
import { AirportInstance, ProductType, TenantProductType } from '../../../../../types';
import { BrandData, useAllBrands } from '../../../../../api/Brands';
import { CompanyData } from '../../../../../actions/companiesActions';
import {
  addTenantFromAPI,
  getTenantFromAPI,
  updateTenantByLocationFromAPI
} from '../../../../../api/tenants';
import { TenantData } from '../../../../masterlist/types';
import { AuthenticatedUser } from '../../../../../actions/usersActions';
import refocusWizard from '../../../../../core/scroll/refocusWizard';
import BrandNameSelectField from '../../../../forms/BrandNameSelectField';
import CompanySelectField from '../../../../forms/CompanySelectField';
import CategorySelectField from '../../../../forms/CategorySelectField';
import TerminalSelectField from '../../../../forms/TerminalSelectField';
import ExpireOptionSelectField from '../../../../forms/ExpireOptionSelectField';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';

interface ITenantsInitialValues {
  brandName: string;
  brandId: number;
  category: string;
  categoryName: string;
  companyId: number;
  companyName: string;
  terminalId: number;
  terminalName: string;
  sqFt: number;
  tenantStatus: string;
  tenantStatusDes: string;
  expires: string | null;
  futureLocation: boolean;
}

const tenantsInitialValues: ITenantsInitialValues = {
  brandName: '',
  brandId: 0,
  category: '',
  categoryName: '',
  companyId: 0,
  companyName: '',
  terminalId: 0,
  terminalName: '',
  sqFt: 0,
  tenantStatus: 'ACTIVE',
  tenantStatusDes: 'ACTIVE',
  expires: moment().format('YYYY-MM-DD'),
  futureLocation: false
};

type tenantsFormProps = typeof tenantsInitialValues;

interface TenantsStepProps extends StepDefaultProps, tenantsFormProps {
  dispatch?: Dispatch;
  terminals: TerminalsList;
  airportInstance: AirportInstance;
  match: WizardTenantsMatch;
  authenticatedUser: AuthenticatedUser;
  terminalsLoading: boolean;
  activeInstance: ActiveInstance;
}

export const TenantsStep = (props: TenantsStepProps) => {
  const {
    match,
    terminals,
    airportInstance,
    authenticatedUser,
    terminalsLoading,
    activeInstance
  } = props;

  const { id } = activeInstance;
  const { userId } = authenticatedUser;

  const {
    contentID,
    contentType,
    tenantID,
    terminalID,
    locationNumber,
    companyId,
    brandId
  } = match.params;
  const { tenantStatus, tenantStatusLoading } = useAirportTenantStatuses();
  const { companies, companiesLoading } = useAllCompanies();

  const { tenantTypes, tenantTypesLoading } = useAirportTenantTypes();
  const separator = ` | `;

  const { brands, brandsLoading } = useAllBrands();
  const [currentTenant, setCurrentTenant] = useState<TenantData | undefined>();
  const [lastUpdated, setLastUpdated] = useState(moment().unix());
  const isUpdatingTenant = tenantID && locationNumber && currentTenant;

  /* If TenantID changes get the tenant */
  const source = axios.CancelToken.source();

  useEffect(() => {
    let mounted = true;
    if (mounted && tenantID && locationNumber) {
      getTenantFromAPI(+tenantID, id, +contentID, +terminalID, +locationNumber).then(
        response => {
          if (mounted) setCurrentTenant(response.data);
        },
        error => {
          if (mounted && !axios.isCancel(error)) {
            const errorMessage = get(error.response, 'data.message', 'Failure');
            toast.error(`${errorMessage}: failed getting tenant data.`);
          }
        }
      );
    }
    return () => {
      mounted = false;
      source.cancel('Cancelling in cleanup');
    };
  }, [tenantID, locationNumber, terminalID, contentID]);

  const trueInit: tenantsFormProps = {
    brandName: '',
    brandId: currentTenant ? currentTenant.brandId : 0,
    category: currentTenant
      ? `${currentTenant.tenantType}${separator}${currentTenant.productType}`
      : '',
    categoryName: currentTenant
      ? `${currentTenant.tenantType}${separator}${currentTenant.productType}`
      : '',
    expires: currentTenant ? currentTenant.leaseExpireDate : null,
    tenantStatus: currentTenant ? currentTenant.tenantStatus : tenantsInitialValues.tenantStatus,
    tenantStatusDes: currentTenant
      ? currentTenant.tenantStatusDes
      : tenantsInitialValues.tenantStatusDes,
    companyId: currentTenant ? currentTenant.companyId : 0,
    companyName: currentTenant ? currentTenant.companyName : '',
    terminalId: currentTenant ? currentTenant.terminalId : 0,
    terminalName: currentTenant ? currentTenant.terminalName : '',
    sqFt: currentTenant ? currentTenant.areaTotal : tenantsInitialValues.sqFt,
    futureLocation: currentTenant ? currentTenant.futureLocation : false
  };

  const resetTenantFormState = (formikActions: FormikActions<tenantsFormProps>) => {
    refocusWizard();
    appHistory.push(`/wizard/airports/${contentID}/steps/tenants`);
    window.location.reload(); // temporarily fix fields not getting reset.
  };

  return (
    <Row>
      <Col>
        <Formik
          initialValues={trueInit}
          enableReinitialize
          onReset={(values, formikActions) => {
            resetTenantFormState(formikActions);
          }}
          onSubmit={(values, formikActions) => {
            /* Unload airport instance values */
            const {
              airportName,
              city,
              isoCountryCode,
              stateProvinceCode,
              airportId
            } = airportInstance.airport;

            const terminal = find(terminals, {
              airportTerminalPK: { terminalId: +values.terminalId }
            }) as TerminalData;

            if (!terminal) toast.error('Terminal selection is required');

            const { terminalName, terminalShortName } = terminal?.terminal || {};

            /* TODO: split category up into tenant type & product type */
            const [tenantTypeId, productTypeId] = values.category.split(separator);

            const tentantType = find(tenantTypes, {
              tenantType: tenantTypeId
            }) as TenantProductType;
            const productType = find(tentantType.productTypes, {
              productType: productTypeId
            }) as ProductType;
            const brand = find(brands, {
              brandId: +values.brandId
            }) as BrandData;
            const company = find(companies, {
              companyId: +values.companyId
            }) as CompanyData;

            const requestBody = {
              airportId,
              axnInstanceId: id,
              airportName,
              city,
              stateProvinceCode,
              isoCountryCode,
              terminalId: +values.terminalId,
              terminalShortName,
              terminalName,
              tenantStatus: values.tenantStatus,
              tenantStatusDes: values.tenantStatusDes,
              tenantType: tentantType.tenantType,
              tenantTypeDes: tentantType.tenantTypeDes,
              productType: productType.productType,
              productTypeDes: productType.productTypeDes,
              companyId: company.companyId,
              companyName: values.companyName,
              brandId: brand.brandId,
              brandName: brand.brandName,
              areaTotal: +values.sqFt,
              unitMeasureCode: 'SQFT', // TODO: make this change?
              leaseExpireDate: values.expires,
              modifiedUserId: userId,
              locationNumber: 1, // TODO: Barb spoke of wanting to potentially reimplement this feature in the future?
              modifiedDate: moment().format('YYYY-MM-DD'),
              oldCompanyId: companyId,
              oldBrandId: brandId,
              oldTerminalId: +terminalID,
              futureLocation: values.futureLocation
            };

            // make it partial
            const withLeaseExpire =
              values.tenantStatus === 'ACTIVE' || values.tenantStatus === 'COVIDCLOSE'
                ? { ...requestBody, leaseExpireDate: values.expires }
                : requestBody;

            return tenantID && locationNumber && currentTenant
              ? updateTenantByLocationFromAPI(
                  +tenantID,
                  id,
                  +contentID,
                  +terminalID,
                  +locationNumber,
                  withLeaseExpire
                )
                  .then(() => {
                    toast.success(`Successfully Updated Tenant!`);
                    resetTenantFormState(formikActions);
                  })
                  .catch(error => toast.error(error))
              : addTenantFromAPI(withLeaseExpire)
                  .then(() => {
                    toast.success(`Successfully Added New Tenant!`);
                    resetTenantFormState(formikActions);
                    //window.location.reload(false); // this is needed to reset the fields until another solution is implemented
                    window.location.reload();
                  })
                  .catch(error => toast.error(error));
          }}
          validationSchema={airportTenantsValidationSchema}
          render={({
            handleBlur,
            handleChange,
            handleReset,
            handleSubmit,
            setFieldValue,
            values
          }) => {
            return (
              <div>
                <Form>
                  <div className={cssClass}>
                    <StepHeader stepTitle="Tenant Information" />
                    <Row>
                      <BrandNameSelectField
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        match={match}
                        setFieldValue={setFieldValue}
                      />
                      <CategorySelectField
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        match={match}
                        setFieldValue={setFieldValue}
                        col={{ xl: 6 }}
                      />
                      <CompanySelectField
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        match={match}
                        setFieldValue={setFieldValue}
                        col={{ xl: 6 }}
                      />
                      <TerminalSelectField
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        match={match}
                        setFieldValue={setFieldValue}
                        col={{ xl: 6 }}
                        terminals={terminals}
                        terminalsLoading={terminalsLoading}
                      />
                      <FormField
                        name="sqFt"
                        label="Square Foot"
                        type="number"
                        match={match}
                        value={values.sqFt}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{ xl: 6 }}
                      />
                      <ExpireOptionSelectField
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        match={match}
                        setFieldValue={setFieldValue}
                        col={{ xl: 6 }}
                      />
                      {(values.tenantStatus === 'ACTIVE' ||
                        values.tenantStatus === 'COVIDCLOSE') && (
                        <FormField
                          name="expires"
                          label="Expires"
                          type="date"
                          match={match}
                          value={values.expires !== null ? moment(values.expires) : null}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          col={{ xl: 6 }}
                          setFieldValue={setFieldValue}
                          helpText="YYYY-MM-DD"
                          displayPastDates={true}
                        />
                      )}
                      <FormField
                        name="futureLocation"
                        label="Future Location (Coming Soon)"
                        type="checkbox"
                        match={match}
                        value={values.futureLocation}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{ xl: 6 }}
                      />
                    </Row>
                    <Row className="pt-5">
                      <Col>
                        <ButtonGroup>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={event => {
                              event.preventDefault();
                              handleSubmit();
                            }}>
                            {isUpdatingTenant ? `Update` : `Add`} Tenant{' '}
                            <FontAwesomeIcon icon={isUpdatingTenant ? faSave : faStoreAlt} />
                          </Button>
                          <Link
                            to={`/wizard/airports/${contentID}/steps/tenants`}
                            className="btn btn-secondary"
                            onClick={() => handleReset}>
                            Reset <FontAwesomeIcon icon={faRedoAlt} />
                          </Link>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
                <ExistingTenants
                  tenantTypes={tenantTypes}
                  contentType={contentType}
                  contentID={contentID}
                  lastUpdated={lastUpdated}
                  setLastUpdated={setLastUpdated}
                  loading={tenantTypesLoading}
                  axnInstanceId={id}
                  bordered
                />
              </div>
            );
          }}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: AppState) => ({
  tenantsFormStore: state.wizard.stepper.airports.tenants,
  terminals: state.terminals.terminals,
  terminalsLoading: state.terminals.loading,
  airportInstance: state.airports.airportInstance,
  authenticatedUser: state.users.authenticatedUser,
  activeInstance: state.wizard.activeInstance
});

export default connect(mapStateToProps, null)(TenantsStep);
