import { AutoCompleteOption } from "../../components/forms/autocomplete/AutoComplete";

export interface Role {
  key: number;
  name: string;
  label: string;
}

/* There is no endpoint to get these values to here it is. */
export const SECURITY_ROLES: Role[] = [
  { key: 4, name: 'ROLE_ADMIN', label: 'Administrator' },
  { key: 2, name: 'ROLE_AIRPORT', label: 'Airport' },
  { key: 5, name: 'ROLE_AIRPORT_SUB', label: 'Airport Rep & Subscriber' },
  { key: 3, name: 'ROLE_COMPANY', label: 'Company' },
  { key: 6, name: 'ROLE_COMPANY_SUB', label: 'Company Rep & Subscriber' },
  { key: 1, name: 'ROLE_SUBSCRIBER', label: 'Subscriber' },
  { key: 0, name: 'ROLE_USER', label: 'User' }
];

export const convertFromKeyLabel = ({ key, label }: Role): AutoCompleteOption => ({
    label,
    value: `${key}`,
    key: `${key}`
  });
  

export const SecurityRoleFromKey = (key:number|undefined) : Role|undefined => {
    const roles : Role[] = undefined === key ? [] : SECURITY_ROLES.filter( (role) => role.key === key );
    return roles != null && roles != undefined && roles.length > 0 ? roles[0] : undefined
}
