import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, Container, Form } from 'reactstrap';
import { appHistory } from '../../../reducers';
import { AuthenticatedUser } from '../../../actions/usersActions';
import FormField from '../../forms/FormField';
import {
  resetPasswordFromAPI,
  validateRestPasswordTokenFromAPI
} from '../../../api/authentication';
import ResetPasswordSchema from './yupSchema';
import { cssClass } from '../../wizard/cssSharedClasses';

interface RouteParams {
  resetPasswordToken: string;
}

interface ResetPasswordFormProps {
  authenticatedUser: AuthenticatedUser;
  isLoggedIn: boolean;
}

const ResetPasswordForm = (props: ResetPasswordFormProps & RouteComponentProps<RouteParams>) => {
  const { match } = props;
  const { params, path } = match;
  const { resetPasswordToken } = params;

  const resetOrChange = path.includes('settings') ? 'Change' : 'Reset';

  const [userDetails, setUserDetails] = useState({
    userId: 0,
    firstName: '',
    lastName: '',
    email: ''
  });

  useEffect(() => {
    if (resetPasswordToken)
      validateRestPasswordTokenFromAPI(resetPasswordToken)
        .then(response => {
          setUserDetails(response.data);
        })
        .catch(() => toast.error('Something went wrong with the reset password request.'));
  }, [resetPasswordToken]);

  return (
    <Container
      tag="section"
      className={cssClass}
      style={{ width: resetOrChange === 'Change' ? '100%' : '600px', maxWidth: '90%' }}>
      <Formik
        initialValues={{
          ...userDetails,
          currenPassword: '',
          password: '',
          retypePassword: ''
        }}
        enableReinitialize
        validationSchema={ResetPasswordSchema}
        onSubmit={values => {
          const accessToken = resetPasswordToken;
          const { userId, password } = values;
          resetPasswordFromAPI(accessToken, userId, password)
            .then(() => {
              toast.success('Password Reset Successfully!');
              appHistory.push('/login');
            })
            .catch(() => toast.error('Failed to Reset Password!'));
        }}
        render={({ values, handleChange, handleBlur, handleSubmit }) => {
          const colSizes = { xl: 12, lg: 12, md: 12, sm: 12, xs: 12 };
          return (
            <Form className={'row'}>
              <Col>
                <h3 className="card-title">{resetOrChange} Password</h3>
              </Col>
              <FormField
                name="userId"
                label=""
                type="text"
                value={values.userId}
                handleChange={handleChange}
                handleBlur={handleBlur}
                match={match}
                col={colSizes}
                readOnly
                disabled
                hidden
              />
              {resetOrChange === 'Reset' && (
                <>
                  <FormField
                    name="firstName"
                    label=""
                    type="text"
                    value={values.firstName}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    match={match}
                    col={colSizes}
                    readOnly
                    disabled
                  />
                  <FormField
                    name="lastName"
                    label=""
                    type="text"
                    value={values.lastName}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    match={match}
                    col={colSizes}
                    readOnly
                    disabled
                  />
                  <FormField
                    name="email"
                    label=""
                    type="text"
                    value={values.email}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    match={match}
                    col={colSizes}
                    readOnly
                    disabled
                  />
                </>
              )}
              {resetOrChange === 'Change' && (
                <FormField
                  name="currenPassword"
                  label="Current Password"
                  type="password"
                  value={values.currenPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  match={match}
                  col={colSizes}
                />
              )}
              <FormField
                name="password"
                label="New Password"
                type="password"
                value={values.password}
                handleChange={handleChange}
                handleBlur={handleBlur}
                match={match}
                col={colSizes}
              />
              <FormField
                name="retypePassword"
                label="Retype Password"
                type="password"
                value={values.retypePassword}
                handleChange={handleChange}
                handleBlur={handleBlur}
                match={match}
                col={colSizes}
              />
              <Col>
                <ButtonGroup tag="section" className="text-center">
                  <Button onClick={() => handleSubmit} color="primary" type="submit">
                    {resetOrChange} Password
                  </Button>
                </ButtonGroup>
              </Col>
            </Form>
          );
        }}
      />
    </Container>
  );
};

export default withRouter(ResetPasswordForm);
