import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import rootReducer, { appHistory } from '../reducers';
import { analyticActions } from '../actions/analyticActions';
import { getWizardInstanceFromKeyValue } from '../core/authentication/authenticationHelper';
import { initialWizardState } from "../reducers/wizardReducer";

const middleware = [analyticActions, thunk, routerMiddleware(appHistory)];
const { instanceId, status, name } = getWizardInstanceFromKeyValue();

export const store = createStore(
  rootReducer,
  {
    wizard: {
      ...initialWizardState,
      activeInstance: {
        id: instanceId,
        status,
        name
      }
    }
  },
  composeWithDevTools(applyMiddleware(...middleware))
);
