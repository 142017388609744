import * as React from 'react';
import { IndexableMasterListItem, MasterListActions } from './types';
import { ListTableHeader } from './ListTableHeader';
import { ListTableBody } from './ListTableBody';
import { TableColumn } from './MasterList';
import ResponsiveTable from '../table/ResponsiveTable';

interface ListTableProps extends ListTableDefaultProps {
  list: IndexableMasterListItem[];
  columns: TableColumn[];
  actions: MasterListActions;
  additionalValues?: string[];
  readonly?: boolean;
}
type ListTableDefaultProps = typeof defaultProps;

const defaultProps = { currentPage: 0 };

const ListTable = (props: ListTableProps) => {
  const { list, columns, actions, additionalValues, currentPage, readonly } = props;
  return (
    <ResponsiveTable>
      <ListTableHeader columns={columns} readonly={readonly} />
      <ListTableBody
        list={list}
        columns={columns}
        currentPage={currentPage}
        actions={actions}
        additionalValues={additionalValues}
        readonly={readonly}
      />
    </ResponsiveTable>
  );
};

export default ListTable;
