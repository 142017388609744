/* infer type from return value of postman to /api/v1/companies */
import { Dispatch } from 'redux';
import { CompanyType, getCompanyInstanceFromAPI } from '../api/Companies';
import { AxnInstanceStatus, YesOrNo } from '../types';

export interface CompanyIDData {
  companyId?: number;
}

/* figure out which of these should never be empty. */
/* infer type from return value of postman to /api/v1/companies */
export interface CompanyData extends CompanyIDData {
  companyName: string;
  address1: string;
  address2: string;
  city: string;
  postalCode: string;
  stateProvinceCode: string;
  isoCountryCode: string;
  modifiedUserId?: number;
  modifiedDate: string;
  axnStatus: string;
  phone: string;
  fax: string;
  url: string;
  companyInfoText: string;
  modifiedUserDisplayName?: string; // just used for view purposes not needed.
  [key: string]: any;
}

export interface CompanyInstanceTypePK {
  companyInstanceTypePK: {
    companyId: number;
    axnInstanceId: number;
    companyType: string;
  };
}

export interface CompanyInstanceType extends CompanyInstanceTypePK {
  associatedCompanyType: CompanyType;
  companyTypeInfoText: string;
}

export type CompanyInstanceTypeList = CompanyInstanceType[];
export type CompanyInstanceTypePKList = CompanyInstanceTypePK[];

export interface CompanyInstance {
  company: CompanyData;
  axnInstanceStatus: AxnInstanceStatus;
  dbeFlag: YesOrNo;
  companyInstanceTypeList: CompanyInstanceTypeList;
  modifiedDate?: string;
  modifiedUserId?: number;
}

export type CompaniesList = CompanyData[];

export type CompaniesAction =
  | { type: 'GET_COMPANY' }
  | { type: 'GET_COMPANIES' }
  | { type: 'UPDATE_ADD_COMPANY'; companyInstance: CompanyInstance }
  | { type: 'DELETE_COMPANY'; companyID: CompanyIDData }
  | { type: 'GET_COMPANY_SUCCESS'; companyInstance: CompanyInstance }
  | { type: 'GET_COMPANIES_SUCCESS'; companies: CompaniesList }
  | { type: 'UPDATE_ADD_COMPANY_SUCCESS'; companyInstance: CompanyInstance }
  | { type: 'DELETE_COMPANY_SUCCESS'; companyID: CompanyIDData }
  | { type: 'GET_COMPANY_FAILURE'; error: string }
  | { type: 'GET_COMPANIES_FAILURE'; error: string }
  | { type: 'UPDATE_ADD_COMPANY_FAILURE'; error: string }
  | { type: 'DELETE_COMPANY_FAILURE'; error: string };

export type companiesActionsCreator = (...props: any) => CompaniesAction;

/* COMPANIES ACTION CREATORS */
/* Get All Companies */
export const getCompanies: companiesActionsCreator = () => ({
  type: 'GET_COMPANIES'
});
export const getCompaniesSuccess: companiesActionsCreator = (companies: CompaniesList) => ({
  type: 'GET_COMPANIES_SUCCESS',
  companies
});

export const getCompaniesFailure: companiesActionsCreator = (error: string) => ({
  type: 'GET_COMPANIES_FAILURE',
  error
});

/* Get Singular Company */
export const getCompany: companiesActionsCreator = () => ({
  type: 'GET_COMPANY'
});
export const getCompanySuccess: companiesActionsCreator = (companyInstance: CompanyInstance) => ({
  type: 'GET_COMPANY_SUCCESS',
  companyInstance
});
export const getCompanyFailure: companiesActionsCreator = (error: string) => ({
  type: 'GET_COMPANY_FAILURE',
  error
});

export type InitGetCompany = (companyID: number, instanceId: number) => void;
export const initGetCompany = (dispatch: Dispatch) => {
  return async (companyID: number, instanceId: number) => {
    dispatch(getCompany());
    try {
      const response = await getCompanyInstanceFromAPI(companyID, instanceId);
      dispatch(getCompanySuccess(response.data));
    } catch (e) {
      dispatch(getCompanyFailure(e));
    }
  };
};

/* Delete Company */
export const deleteCompany: companiesActionsCreator = (companyID: CompanyIDData) => ({
  type: 'DELETE_COMPANY',
  companyID
});
export const deleteCompanySuccess: companiesActionsCreator = (companyID: CompanyIDData) => ({
  type: 'DELETE_COMPANY_SUCCESS',
  companyID
});
export const deleteCompanyFailure: companiesActionsCreator = (error: string) => ({
  type: 'DELETE_COMPANY_FAILURE',
  error
});

/* Update Company */
export const updateAddCompany: companiesActionsCreator = (companyInstance: CompanyInstance) => ({
  type: 'UPDATE_ADD_COMPANY',
  companyInstance
});
export const updateAddCompanySuccess: companiesActionsCreator = (
  companyInstance: CompanyInstance
) => ({
  type: 'UPDATE_ADD_COMPANY_SUCCESS',
  companyInstance
});
export const updateAddCompanyFailure: companiesActionsCreator = (error: string) => ({
  type: 'UPDATE_ADD_COMPANY_FAILURE',
  error
});

/* Umm this seems a little repetitive maybe a better way to do this but for rapid development will leave it like
 this and move on. */
export interface CompanyLocationIDData {
  airportId: number;
}

export interface CompanyLocationData extends CompanyLocationIDData {
  airportName: string;
  city: string;
  stateProvinceCode: string;
  [key: string]: any;
}
