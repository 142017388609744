import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import { AffiliateUserForm } from './AffiliateUserForm';
import { AffiliateObjectType } from './AffiliateToObjectForm';
import CurrentAffiliations from './CurrentAffiliations';
import { UserData } from '../../../actions/usersActions';
import { addAffliation } from '../../../api/affiliations';
import { getUserByIdFromAPI } from '../../../api/Users';
import AccordionItem from '../../accordion/AccordionItem';
import AllAffiliations from './AllAffiliations';
import { useAllInstances } from '../../../api/instances';

interface AffiliateWrapperProps {
  objectType: AffiliateObjectType;
  axnInstanceId: number;
  userID?: string;
}

const AffiliateWrapper = ({ objectType, userID, axnInstanceId }: AffiliateWrapperProps) => {
  const [selectedUser, setSelectedUser] = useState<UserData>();
  const [modifiedTimestamp, setModifiedTimestamp] = useState<number>(Date.now());
  const [instanceId, setInstanceId] = useState(axnInstanceId);
  const { instances } = useAllInstances();
  const selectedInstance = instances.find(i => i.axnInstanceId === instanceId);

  const forceRefresh = useCallback(() => {
    // force refresh
    setSelectedUser(undefined);
    setSelectedUser(selectedUser);
    setModifiedTimestamp(Date.now());
  }, [selectedUser, modifiedTimestamp]);

  const handleSubmit = (contentId: number) => {
    if (selectedUser)
      addAffliation(objectType)(selectedUser, contentId, instanceId)
        .then(response => {
          if (response.kind === 'Success') {
            toast.success('Affiliation created.');
          } else if (response.kind === 'Failure') {
            toast.error(
              `Unable to create affiliation: Please check that the user is not already affiliated.`
            );
          }
        })
        .then(forceRefresh);
  };
  useEffect(() => {
    if (userID !== undefined) {
      getUserByIdFromAPI(+userID).then(setSelectedUser);
    }
  }, [userID]);

  return (
    <Row className={'bg-light border rounded'}>
      <Col xs={12} sm={12} md={12} lg={4} xl={4}>
        <AffiliateUserForm
          handleSubmit={handleSubmit}
          instanceId={instanceId}
          objectType={objectType}
          selectUser={setSelectedUser}
          selectInstance={setInstanceId}
          userID={userID}
        />
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={8}>
        {selectedUser !== undefined && (
          <CurrentAffiliations
            objectType={objectType}
            user={selectedUser}
            instanceId={instanceId}
            handleDelete={forceRefresh}
          />
        )}
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <AccordionItem
          name={`All ${objectType} Affiliations (${selectedInstance?.axnInstanceName})`}
          expandedDefault>
          <AllAffiliations
            args={`${selectedUser}-${modifiedTimestamp}-ALL`}
            axnInstanceId={instanceId}
            objectType={objectType}
            refresh={forceRefresh}
          />
        </AccordionItem>
      </Col>
    </Row>
  );
};

export default AffiliateWrapper;
