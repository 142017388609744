import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  Row
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Feature } from '../../../resources/main/panel/config';

interface FeatureViewProps {
  feature: Feature
}

export const FeatureView = ({ feature }: FeatureViewProps) => {
  const manageBullets = feature.children;
  return (
    <Row>
      <Col>
        <div className={'admin-panel-grid'}>
          {manageBullets.map(item => (
            <div key={item.path} className={'home-panel-card'}>
              <Link
                to={`/admin${feature.path}${item.path}`}
                className={'home-card-link'}>
                <Card
                  color={'light'}
                  className={
                    'd-flex justify-content-center align-items-center py-5'
                  }>
                  <FontAwesomeIcon
                    icon={item.icon}
                    className={'card-img-top'}
                    size={'6x'}
                  />
                  <CardBody
                    className={
                      'w-75 text-center d-flex justify-content-center flex-column'
                    }>
                    <CardTitle className={'text-center'}>
                      {item.title}
                    </CardTitle>
                    <ListGroup color={'secondary'}>
                      {item.bullets.map(val => (
                        <ListGroupItem key={val}>{val}</ListGroupItem>
                      ))}
                    </ListGroup>
                  </CardBody>
                </Card>
              </Link>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};
