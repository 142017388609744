import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useDataModel, {
  convertSpringPageable,
  createPage,
  emptyPage,
  Page,
  PageableData,
  SpringPage
} from '.';
import { TerminalData } from '../actions/terminalsActions';
import { ParkingRate } from '../components/wizard/airport/steps/rental-parking/RentalParkingStep';
import { initialAirportTotals } from '../components/wizard/airport/steps/terminals/TerminalsStepValueInit';
import {
  AirportComment,
  AirportConfigType,
  AirportData,
  AirportExpansion,
  AirportInstance,
  AirportLookup,
  AxnInstanceStatus,
  TenantProductType,
  TenantStatus,
  TenantTotalTypes,
  TerminalTenantTypeTotal
} from '../types';
import { axnAPI } from './config';

export const airportsAPI = `/airports`;
export const airportTotalsAPI = `/airporttotals`;

/* getAirports Will be used to list main airports in future don't delete */
export const getAirports = (size: '10', page: '0') =>
  axnAPI.get(`${airportsAPI}/`, { params: { page, size } });

export const getAllAirports = (size = '10', page = '0') =>
  axnAPI.get(`${airportsAPI}/all`, { params: { page, size } });

export const getAllAirportsForLookup = (size = '10', page = '0') =>
  axnAPI.get(`${airportsAPI}/allforgeographylookup`, { params: { page, size } });

export const getAllInstanceAirportsFromAPI = (
  iid: number,
  size = 10,
  page = 0
): Promise<SpringPage<AirportInstance>> =>
  axnAPI
    .get(`${airportsAPI}/instance/${iid}`, {
      params: {
        size,
        page
      }
    })
    .then(
      resp => resp.data,
      error => {
        toast.error(`Unable to get airport list: ${error}`);
        return emptyPage<AirportInstance>();
      }
    );

export const searchAirports = (instanceId: number, keyword: string) => (
  size = 10,
  page = 0,
  sort: string
) =>
  axnAPI.post(`${airportsAPI}/instance/${instanceId}/paged/search`, null, {
    params: { page, keyword, sort, size }
  });

export const convertSearchAirports = <T>(instanceId: number, keyword: string) => (
  size = 10,
  page = 0,
  sort: string
) => {
  return searchAirports(instanceId, keyword)(size, page, sort).then(response =>
    convertSpringPageable(response.data as SpringPage<T>)
  );
};

export const useSearchAirports = <T>(
  instanceId: number,
  keyword: any,
  sort: string
): PageableData<T> =>
  useDataModel(
    (currentPage, pageSize) =>
      convertSearchAirports<T>(instanceId, keyword)(currentPage, pageSize, sort),
    emptyPage<T>(),
    keyword
  );

/* Get Airport Instance form API */
export const getAirportInstanceFromAPI = (id: number, axnInstanceId: number) =>
  axnAPI.get(`${airportsAPI}/${id}/instance/${axnInstanceId}`);

export const getAirportsByUser = (userId = '0', size = '10', page = '0') =>
  axnAPI.get(`${airportsAPI}/${userId}`, { params: { page, size } });

export const getTerminal = (airportId = 0, terminalId = 0, axnInstanceId: number) =>
  axnAPI.get(`${airportsAPI}/${airportId}/instance/${axnInstanceId}/terminals/${terminalId}`);

export const getMainTerminal = (airportId = 0, terminalId = 0, axnInstanceId: number) =>
  axnAPI.get(`${airportsAPI}/${airportId}/instance/${axnInstanceId}/terminal/${terminalId}/main`);

export const getTerminals = (aid: number, iid: number, page = 0, size = 10) =>
  axnAPI.get(`${airportsAPI}/${aid}/instance/${iid}/terminals`, {
    params: {
      page,
      size
    }
  });

export const getTerminalsPaged = (aid: number, iid: number, page = 0, size = 10) =>
  axnAPI.get(`${airportsAPI}/${aid}/instance/${iid}/terminals/paged`, {
    params: {
      page,
      size
    }
  });

export const createAirportTerminalFromAPI = (
  airportID: number,
  iid: number,
  terminalId: string,
  airportInstance: TerminalData
) =>
  axnAPI.post(`${airportsAPI}/${airportID}/instance/${iid}/terminals`, {
    ...airportInstance
  });

export const updateAirportTerminalFromAPI = (
  airportID: number,
  iid: number,
  terminalId: string,
  airportInstance: TerminalData
) =>
  axnAPI.put(`${airportsAPI}/${airportID}/instance/${iid}/terminals/${terminalId}`, {
    ...airportInstance
  });

export const deleteAirportTerminalFromAPI = (airportID: number, iid: number, terminalId: number) =>
  axnAPI.delete(`${airportsAPI}/${airportID}/instance/${iid}/terminals/${terminalId}`);

export const updateAirportInstanceFromAPI = (
  airportID: number,
  iid: number,
  airportInstance: AirportData
) => {
  var response: Promise<AxiosResponse<any>>;
  if (airportID !== -1) {
    response = axnAPI.put(`${airportsAPI}/${airportID}/instance/${iid}`, {
      ...airportInstance
    });
  } else {
    response = new Promise<AxiosResponse<any>>((resolve, reject) => {
      reject('Unable to update airport instance');
    });
  }

  return response;
};

/* Airport Configuration Types - React Custom Hook */
export const useAirportConfigType = () => {
  const [airportConfigTypes, setAirportConfigTypes] = useState(new Array<AirportConfigType>());
  const [airportConfigTypesLoading, setAirportConfigTypesLoading] = useState(false);

  useEffect(() => {
    setAirportConfigTypesLoading(true);
    axnAPI
      .get(`${airportsAPI}/configurations`)
      .then(response => {
        setAirportConfigTypes(response.data);
        setAirportConfigTypesLoading(false);
      })
      .catch(e => {
        setAirportConfigTypesLoading(false);
        toast.error(`Error fetching data: ${e}`);
      });
  }, [airportConfigTypes.length]);

  return { airportConfigTypes, airportConfigTypesLoading };
};

/* Airport Tenant Types - Custom React Hook */
export const useAirportTenantTypes = () => {
  const [tenantTypes, setTenantTypes] = useState(new Array<TenantProductType>());
  const [tenantTypesLoading, setTenantTypesLoading] = useState(false);
  let mounted = true;

  useEffect(() => {
    const source = axios.CancelToken.source();
    setTenantTypesLoading(true);
    axnAPI
      .get(`/tenanttypeproducttype`, { cancelToken: source.token })
      .then(response => {
        if (mounted) setTenantTypes(response.data);
        if (mounted) setTenantTypesLoading(false);
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          setTenantTypesLoading(false);
          toast.error(`Error fetching data: ${e}`);
        }
      });
    return () => {
      mounted = false;
      source.cancel('Cancelling in cleanup');
    };
  }, [tenantTypes.length]);

  return { tenantTypes, tenantTypesLoading };
};

/* Airport Tenant Statuses - Custom React Hook */
export const useAirportTenantStatuses = () => {
  const [tenantStatus, setTenantStatus] = useState(new Array<TenantStatus>());
  const [tenantStatusLoading, setTenantStatusLoading] = useState(false);
  let mounted = true;
  useEffect(() => {
    const source = axios.CancelToken.source();
    setTenantStatusLoading(true);
    axnAPI
      .get(`/tenants/status`)
      .then(response => {
        if (mounted) setTenantStatus(response.data);
        if (mounted) setTenantStatusLoading(false);
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          setTenantStatusLoading(false);
          toast.error(`Error fetching data: ${e}`);
        }
      });
    return () => {
      mounted = false;
      source.cancel('Cancelling in cleanup');
    };
  }, [setTenantStatus.length]);

  return { tenantStatus, tenantStatusLoading };
};

/* Airport Expansion Plan End Points */
export const getExpansionsFromAPI = (aid: number, iid: number, page = 0, size = 10) =>
  axnAPI.get(`${airportsAPI}/${aid}/instance/${iid}/expansion`, { params: { page, size } });

export const getExpansionFromAPI = (aid: number, iid: number, eid: number) =>
  axnAPI.get(`${airportsAPI}/${aid}/instance/${iid}/expansion/${eid}`);

export const addExpansionFromAPI = (
  aid: number,
  iid: number,
  expansionPlan: Partial<AirportExpansion>
) =>
  axnAPI.post(`${airportsAPI}/${aid}/instance/${iid}/expansion`, {
    ...expansionPlan
  });

export const updateExpansionFromAPI = (
  aid: number,
  iid: number,
  eid: number,
  expansionPlan: Partial<AirportExpansion>
) =>
  axnAPI.put(`${airportsAPI}/${aid}/instance/${iid}/expansion/${eid}`, {
    ...expansionPlan
  });

export const deleteExpansionFromAPI = (aid: number, iid: number, eid: number) =>
  axnAPI.delete(`${airportsAPI}/${aid}/instance/${iid}/expansion/${eid}`);

/* Calculated Terminal Totals - API */
export const getAllTerminalTotalsFromAPI = (airportId: number, axnInstanceId: number) => {
  return axnAPI.get(`${airportsAPI}/${airportId}/instance/${axnInstanceId}/terminaltotals`);
};

export const getTerminalTotalsFromAPI = (
  airportId: number,
  terminalId: number,
  axnInstanceId: number
) => {
  return axnAPI.get(
    `${airportsAPI}/${airportId}/instance/${axnInstanceId}/terminaltotals/terminal/${terminalId}`
  );
};
export const getTerminalTenantTypeTotalsFromAPI = (
  airportId: number,
  terminalId: number,
  axnInstanceId: number
) => {
  return axnAPI.get(
    `${airportsAPI}/${airportId}/instance/${axnInstanceId}/terminaltotals/tenanttype/${terminalId}`
  );
};

export const addTerminalTenantTypeTotalsFromAPI = (
  airportId: number,
  terminalId: number,
  tenantType: string,
  totals: any,
  axnInstanceId: number
) => {
  return axnAPI.post(
    `${airportsAPI}/${airportId}/instance/${axnInstanceId}/terminaltotals/terminal/${terminalId}/tenanttype/${tenantType}`,
    {
      ...totals
    }
  );
};

export const updateTerminalTenantTypeTotalsFromAPI = (
  airportId: number,
  terminalId: number,
  tenantType: string,
  axnInstanceId: number,
  totals?: TerminalTenantTypeTotal
) => {
  return axnAPI.put(
    `${airportsAPI}/${airportId}/instance/${axnInstanceId}/terminaltotals/terminal/${terminalId}/tenanttype/${tenantType}`,
    {
      ...totals
    }
  );
};

export const getCalculatedCombinedTerminalTotalsFromAPI = (
  airportId: number,
  axnInstanceId: number
) => {
  return axnAPI.get(
    `${airportsAPI}/${airportId}/instance/${axnInstanceId}/terminaltotals/terminal/all`
  );
};

/* Calculated Terminal Totals - Util Methods */
/* Post to each of the endpoints for the selected airport terminal, to update totals.
 * There should probably be an endpoint to update all values at one time.  */
export type TenantTypeObj = { type: TenantTotalTypes; fieldName: string };
export const updateAirportTerminalTotals = (
  tenantTypeList: TenantTypeObj[],
  airportId: number,
  terminalId: number,
  values: any,
  axnInstanceId: number
) => {
  return Promise.all(
    tenantTypeList.map((tenantType: TenantTypeObj) => {
      const { terminal } = values;
      const { terminalShortName, terminalName } = terminal;
      const airportTerminalTotal = {
        airportTerminalTotalPK: {
          airportId,
          terminalId,
          axnInstanceId,
          tenantType: tenantType.type
        },
        terminalShortName,
        terminalName,
        tenantTypeDes: '',
        grossSalesTotal: values[`${tenantType.fieldName}GrossSalesTotal`],
        salesEpRatio: values[`${tenantType.fieldName}SalesEp`],
        rentAirportTotal: values[`${tenantType.fieldName}RentAirportTotal`],
        rentEpRatio: values[`${tenantType.fieldName}RentEp`],
        areaTotal: values[`${tenantType.fieldName}AreaTotal`],
        unitMeasureCode: 'SQFT',
        modifiedUserId: values.modifiedUserId,
        modifiedDate: moment().format('YYYY-MM-DD')
      };

      return updateTerminalTenantTypeTotalsFromAPI(
        airportId,
        terminalId,
        tenantType.type,
        axnInstanceId,
        airportTerminalTotal
      );
    })
  );
};

/* Calculated Terminal Totals - Custom Hooks */
export const useTerminalTotals = (airportId: number, terminalId: number, axnInstanceId: number) => {
  const [terminalTotals, setTerminalTotals] = useState(new Array<TerminalTenantTypeTotal>());
  useEffect(() => {
    getTerminalTotalsFromAPI(airportId, terminalId, axnInstanceId)
      .then(response => {
        setTerminalTotals(response.data);
      })
      .catch(error => toast.error(`Error fetching data: ${error}`));
  }, [terminalTotals.length, airportId, terminalId, axnInstanceId]);

  return terminalTotals;
};

export const useAllTerminalTotals = (airportId: number, axnInstanceId: number) => {
  const [terminalTotalsLoading, setTerminalTotalsLoading] = useState(false);
  const [terminalTotals, setTerminalTotals] = useState(new Array<TerminalTenantTypeTotal>());

  useEffect(() => {
    setTerminalTotalsLoading(true);
    getAllTerminalTotalsFromAPI(airportId, axnInstanceId)
      .then(response => {
        setTerminalTotals(response.data);
        setTerminalTotalsLoading(false);
      })
      .catch(error => {
        toast.error(`Error fetching data: ${error}`);
        setTerminalTotalsLoading(false);
      });
  }, [terminalTotals.length, airportId, axnInstanceId]);

  return { terminalTotals, terminalTotalsLoading };
};

/* TODO: move these. */
interface TentantTypeTotal {
  airportId: number;
  axnInstanceId: number;
  tenantType: string;
  tenantTypeDes: string;
  grossSalesTotals: number;
  salesEpRatios: number;
  rentAirportTotals: number;
  rentEpRatios: number;
  areaTotals: number;
  unitMeasureCode: string;
}

type TentantTypeTotalList = TentantTypeTotal[];

interface AirportTotals {
  grossRentals: number;
  areaTotals: number;
  rentEpRatios: number;
  grossSales: number;
  salesEpRatios: number;
}

interface CombinedTerminalTotals {
  tenanttypetotals: TentantTypeTotalList;
  airporttotals: AirportTotals;
}

export const useCalculatedCombinedTerminalTotals = (airportId: number, axnInstanceId: number) => {
  const [totals, setTotals] = useState({
    tenanttypetotals: new Array<TentantTypeTotal>(),
    airporttotals: {
      grossRentals: 0,
      areaTotals: 0,
      rentEpRatios: 0,
      grossSales: 0,
      salesEpRatios: 0
    }
  });
  const [totalsLoading, setTotalsLoading] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    getCalculatedCombinedTerminalTotalsFromAPI(airportId, axnInstanceId)
      .then(response => {
        setTotals(response.data);
        setTotalsLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setTotalsLoading(false);
          toast.error(`Error fetching data: ${error}`);
        }
      });
    return () => {
      source.cancel('Cancelling in cleanup');
    };
  }, [airportId, axnInstanceId]);

  return { totals, totalsLoading };
};
/* Calculated Airport Totals - API */
export const getAirportCombinedTotalsFromAPI = (airportId: number, axnInstanceId: number) =>
  axnAPI.get(`${airportTotalsAPI}/instance/${axnInstanceId}/airport/${airportId}`);

/* Calculated Airport Totals - Custom Hooks */
export const useAirportTotals = (airportId: number, axnInstanceId: number) => {
  const [airportTotals, setAirportTotals] = useState(initialAirportTotals);

  useEffect(() => {
    getAirportCombinedTotalsFromAPI(airportId, axnInstanceId)
      .then(response => {
        const totals = { ...airportTotals, ...response.data };
        setAirportTotals(totals);
      })
      .catch(error => {
        toast.error(`Error Getting Airport Totals: ${error}`);
      });
  }, [airportTotals.airportId, airportId, axnInstanceId]);

  return airportTotals;
};

/* Airport Parking Rates - API */
export const getParkingRatesFromAPI = (airportId: number, axnInstanceId: number) => {
  const url = `${airportsAPI}/${airportId}/instance/${axnInstanceId}/parkingrates`;
  return axnAPI.get(url);
};

export type ParkingRateType = 'Hourly' | 'Daily';
export type ParkingType = 'Economy' | 'EconomyBasic' | 'Long' | 'Premium' | 'Short' | 'Valet';
export const createOrUpdateAirportParkingRateFromAPI = (
  airportId: number,
  instanceId: number,
  parkingType: ParkingType,
  rateType: ParkingRateType,
  parkingRate: ParkingRate
) => {
  return axnAPI.put(
    `${airportsAPI}/${airportId}/instance/${instanceId}/parkingrates/type/${parkingType}/ratetype/${rateType}`,
    { ...parkingRate }
  );
};

export const useGetParkingRates = (
  contentID: number,
  axnInstanceId: number,
  airport: AirportData
) => {
  const [loadingParkingRates, setLoadingParkingRates] = useState(false);
  const [parkingRates, setParkingRates] = useState(new Array<ParkingRate>());

  useEffect(() => {
    setLoadingParkingRates(true);
    getParkingRatesFromAPI(contentID, axnInstanceId)
      .then(response => {
        setParkingRates([...response.data]);
        setLoadingParkingRates(false);
      })
      .catch(error => {
        setLoadingParkingRates(false);
        toast.error(`Error fetching data: ${error}`);
      });
  }, [parkingRates.length, contentID, airport, axnInstanceId]);

  return { parkingRates, loadingParkingRates };
};
/* Airport Comments - API */
export const updateAirportCommentsFromAPI = (
  airportId: number,
  airportComment: AirportComment,
  axnInstanceId: number
) => {
  const { commentType } = airportComment.airportCommentPK;
  return axnAPI.put(
    `${airportsAPI}/${airportId}/instance/${axnInstanceId}/comment/${commentType}`,
    {
      ...airportComment
    }
  );
};

/* Update Airport Instance Status */
export const updateAirportInstanceStatusFromAPI = (
  aid: number,
  iid: number,
  axnInstanceStatus: AxnInstanceStatus
) =>
  axnAPI.put(`${airportsAPI}/${aid}/instance/${iid}/status`, {
    axnInstanceStatus,
    airportId: aid,
    axnInstanceId: iid
  });

/* Submit Airport Instance for Review */
export const sumbitAirportInstanceReviewFromAPI = (aid: number, iid: number) =>
  axnAPI.post(`${airportsAPI}/${aid}/instance/${iid}/submitforreview`);

/* Unlock Airport Instance for Further Editing */
export const unlockAirportInstanceForEditingFromAPI = (aid: number, iid: number) =>
  axios.post(`${airportsAPI}/${aid}/instance/${iid}/settoinprogress`);

/* Submit Airport Instance Approval */
export const submitAirportInstanceApprovalFromAPI = (aid: number, iid: number) =>
  axnAPI.post(`${airportsAPI}/${aid}/instance/${iid}/approve`);

/* Publish Airport Instance */
export const publishAirportInstanceFromAPI = (aid: number, iid: number) =>
  axnAPI.post(`${airportsAPI}/${aid}/instance/${iid}/publish`);

export const getAirportsByStatus = (status: string, keyword: string, instanceID: number) => (
  page: number,
  size: number
): Promise<Page<AirportInstance>> =>
  axnAPI
    .post(`${airportsAPI}/instance/${instanceID}/paged/search`, null, {
      params: {
        page: page - 1,
        size,
        sort: 'airport.airportName',
        status,
        keyword
      }
    })
    .then(resp => resp.data as SpringPage<AirportInstance>)
    .then(convertSpringPageable);

export const useFilteredAirports = (
  status: string,
  keyword: string,
  instanceID: number
): PageableData<AirportInstance> =>
  useDataModel(
    getAirportsByStatus(status, keyword, instanceID),
    emptyPage<AirportInstance>(),
    status,
    keyword,
    instanceID
  );

/*
        export const convertSpringPageable = <T>(data: SpringPage<T>): Page<T> => ({
            totalRecords: data.totalElements,
            numberOfElements: data.numberOfElements,
            totalPages: data.totalPages + 1,
            pageSize: data.size,
            currentPage: data.number + 1, // spring is zero-indexed
            content: data.content
          });
*/

const getAllAirportsForInstance = (instanceID: number) => (
  page: number,
  size: number
): Promise<Page<AirportLookup>> =>
  axnAPI
    .get(`${airportsAPI}/instance/${instanceID}/airport/lookup`)
    .then(resp => createPage(resp.data));

export const useAllAirports = (instanceID: number) =>
  useDataModel(getAllAirportsForInstance(instanceID), emptyPage<AirportLookup>(), instanceID);
