import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Col, Container, Nav, Row } from 'reactstrap';
import { getNavLinks } from '../../core/navigation/NavigationHelper';
import { AxnTitle } from '../../components/shared/title';
import { AppState } from '../../reducers';
import { AuthoritiesList } from "../../actions/usersActions";

interface SiteMapProps {
  isLoggedIn: boolean; // comes from redux
  authorities: AuthoritiesList;
}

const SiteMap = (props: SiteMapProps) => {
  const { isLoggedIn, authorities } = props;
  return (
    <Col
      xs={12}
      sm={6}
      md={4}
      lg={4}
      xl={4}
      id={'footer-sitemap'}
      className={'mb-4'}>
      <AxnTitle>Site Map</AxnTitle>
      <nav role="navigation">
        <Nav className="nav-list">{getNavLinks(isLoggedIn, authorities)}</Nav>
      </nav>
    </Col>
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.users.isLoggedIn
});

const mapDispatchToProps = (_dispatch: Dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteMap);
