import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Row } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faRedo, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import FormField from '../../../../forms/FormField';
import {
  InitAddExpansionPlan,
  InitUpdateExpansionPlan
} from '../../../../../actions/airportActions';
import StepHeader from '../../../StepHeader';
import { cssClass } from '../../../cssSharedClasses';
import MasterList, { TableColumn } from '../../../../masterlist/MasterList';
import { MasterListActionButton } from '../../../../masterlist/types';
import {
  deleteExpansionFromAPI,
  getExpansionFromAPI,
  getExpansionsFromAPI
} from '../../../../../api/Airports';
import { AirportExpansion, AirportInstance } from '../../../../../types';
import { paginationClasses } from '../../../../admin/adminUtils';
import { MasterListPagesize, MasterListPagination } from '../../../../masterlist';
import expansionPlanSchema from './ExpansionPlanSchema';

type ExpansionPlanFormProps = {
  axnInstanceId: number;
  handleAddExpansion: InitAddExpansionPlan;
  handleUpdateExpansion: InitUpdateExpansionPlan;
  airportInstance: AirportInstance;
} & RouteComponentProps<{ contentID: string }>;

const ExpansionPlanForm = (props: ExpansionPlanFormProps) => {
  const {
    match,
    handleAddExpansion,
    handleUpdateExpansion,
    axnInstanceId,
    airportInstance
  } = props;
  const { contentID } = match.params;

  const [selectedExpansionId, setSelectedExpansionId] = useState<number>();
  const [selectedExpansionPlan, setSelectedExpansionPlan] = useState<AirportExpansion>();

  const [expansionPlans, setExpansionPlans] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [expansionPlanCount, setExpansionPlanCount] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);
  const [loading, setLoading] = useState(false);

  /* This will get it from all instance values. */
  const handleAddPlan = (values: Partial<AirportExpansion>) =>
    handleAddExpansion(+contentID, axnInstanceId, {
      ...values,
      airportExpansionPK: { airportId: +contentID, expansionId: undefined, axnInstanceId }
    });

  const handleUpdatePlan = (expansionId: number | undefined) => (
    values: Partial<AirportExpansion>
  ) => {
    if (expansionId) {
      handleUpdateExpansion(+contentID, axnInstanceId, expansionId, {
        ...values,
        airportExpansionPK: { airportId: +contentID, expansionId, axnInstanceId }
      });
    } else {
      toast.error('No Expansion Plan Selected');
    }
  };

  const safeList = airportInstance.airportExpansionList || [];
  const completeDateDep = safeList.reduce(
    (accum, curr) => `${accum}, ${curr.completionDateText}`,
    ''
  );
  const areaTotalDep = safeList.reduce((accum, curr) => `${accum}, ${curr.areaTotal}`, '');
  const expansionPlanTextDep = safeList.reduce(
    (accum, curr) => `${accum}, ${curr.expansionPlannedText}`,
    ''
  );
  /* Get List of Expansion Plans */
  useEffect(() => {
    let mounted = true;
    let hasContent = false;
    setLoading(true);
    getExpansionsFromAPI(+contentID, axnInstanceId, currentPage, paginationSize)
      .then(res => {
        if (mounted) {
          if (res.data.content) hasContent = true;
          const { content, pageable, totalElements } = res.data;
          setExpansionPlans(
            content.map((item: AirportExpansion) => {
              return {
                ...item,
                expansionId: item.airportExpansionPK.expansionId
              };
            })
          );
          setExpansionPlanCount(totalElements);
          setCurrentPage(pageable.pageNumber);
          setPaginationSize(pageable.pageSize);
          setLoading(false);
        }
      })
      .catch(error => {
        if (mounted) {
          setLoading(false);
          const errorMsg = hasContent
            ? get(error.response, 'data.message', 'Failed to Expansion Plans!')
            : 'No Expansion Plans!';
          if (hasContent) toast.error(errorMsg);
          else toast.warn(errorMsg);
        }
      });
    return () => {
      mounted = true;
    };
  }, [
    +contentID,
    axnInstanceId,
    paginationSize,
    safeList.length,
    completeDateDep,
    areaTotalDep,
    expansionPlanTextDep
  ]);

  /* Get Singular Expansion Plan to Edit */
  useEffect(() => {
    if (selectedExpansionId !== undefined)
      getExpansionFromAPI(+contentID, axnInstanceId, selectedExpansionId).then(
        response => setSelectedExpansionPlan(response.data),
        error => {
          const errorMsg = get(
            error.responsone,
            'data.message',
            'Failed to Retrieve Expansion Plan'
          );
          toast.error(errorMsg);
        }
      );
  }, [selectedExpansionId]);

  const rowActions = (expansionId: number): MasterListActionButton[] => {
    return [
      {
        to: `/wizard/airports/${contentID}/steps/expansion`,
        icon: faEdit,
        text: 'Edit',
        actionCallback: () => setSelectedExpansionId(expansionId)
      },
      {
        to: `/wizard/airports/${contentID}/steps/expansion`,
        icon: faTrash,
        text: 'Delete',
        actionCallback: () => {
          deleteExpansionFromAPI(+contentID, axnInstanceId, expansionId).then(
            () => {
              toast.success('Expansion Plan Deleted Successfully!');
            },
            error => {
              const errorMsg = get(
                error.response,
                'data.message',
                'Failed to Delete Expansion Plan'
              );
              toast.error(errorMsg);
            }
          );
          const updatedExpansionList = expansionPlans.filter(
            (expansionPlan: AirportExpansion) =>
              expansionPlan.airportExpansionPK.expansionId !== expansionId
          );
          setExpansionPlans(updatedExpansionList);
          setExpansionPlanCount(expansionPlanCount - 1);
          setCurrentPage(1);
        }
      }
    ];
  };

  const columnsMap: TableColumn[] = [
    { id: 'expansionId', title: 'ID', width: '20%' },
    { id: 'expansionPlannedText', title: 'Text', width: '40%' },
    { id: 'areaTotal', title: 'Area', width: '20%' },
    { id: 'completionDateText', title: 'Complete Date', width: '20%' }
  ];

  const usePaginationSelection = (pageNum: number) => setCurrentPage(pageNum - 1);

  const useSetPaginationSize = (pageSize: number) => {
    setCurrentPage(0);
    setPaginationSize(pageSize);
  };

  const emptyValues = { completionDateText: '', areaTotal: 0, expansionPlannedText: '' };
  return (
    <div className={cssClass}>
      <Formik
        initialValues={{
          ...emptyValues,
          ...selectedExpansionPlan
        }}
        validationSchema={expansionPlanSchema}
        enableReinitialize
        onSubmit={(values, formikActions) => {
          const submitVals: AirportExpansion = {
            ...values,
            airportExpansionPK: {
              airportId: +contentID,
              expansionId: selectedExpansionId,
              axnInstanceId
            },
            unitMeasureCode: 'SQFT',
            axnStatus: 'ACTIVE'
          };

          if (selectedExpansionId !== undefined) handleUpdatePlan(selectedExpansionId)(submitVals);
          else handleAddPlan(submitVals);

          formikActions.resetForm({ ...emptyValues });
          setSelectedExpansionId(undefined);
          setSelectedExpansionPlan(undefined);
        }}
        onReset={(values, formikActions) => {
          formikActions.resetForm({ ...emptyValues });
          setSelectedExpansionId(undefined);
          setSelectedExpansionPlan(undefined);
        }}
        render={({ handleBlur, handleChange, handleReset, handleSubmit, values }) => {
          return (
            <Form>
              <StepHeader stepTitle={'Expansion Plan Information'} Tag={'h3'} />
              <Row>
                <FormField
                  name={`completionDateText`}
                  label="Complete Date"
                  type="text"
                  match={match}
                  value={values.completionDateText}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                />
                <FormField
                  name={`areaTotal`}
                  label="Additional Sq. Ft"
                  type="number"
                  match={match}
                  value={values.areaTotal}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                />
                <FormField
                  name={`expansionPlannedText`}
                  label="Additional Info"
                  type="textarea"
                  match={match}
                  value={values.expansionPlannedText}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                  tooltipText={
                    'A reference as to whether the airport is expanding its concessions and/or other space in any given terminal or zone in its facility, how much additional space is planned and when the expansion is expected to be complete.'
                  }
                />
              </Row>
              <ButtonGroup>
                <Button color="primary" type={'submit'} onClick={() => handleSubmit}>
                  {selectedExpansionId !== undefined ? (
                    <>
                      Save Plan <FontAwesomeIcon icon={faSave} />
                    </>
                  ) : (
                    <>
                      Add Plan <FontAwesomeIcon icon={faPlus} />
                    </>
                  )}
                </Button>
                <Button color="secondary" onClick={() => handleReset}>
                  Refresh <FontAwesomeIcon icon={faRedo} />
                </Button>
              </ButtonGroup>
            </Form>
          );
        }}
      />
      {expansionPlanCount > 0 ? (
        <div>
          <div className={paginationClasses}>
            <MasterListPagesize size={paginationSize} setPagesize={useSetPaginationSize} />
            <MasterListPagination
              pageSize={paginationSize}
              totalItems={expansionPlanCount}
              handlePaginationSelection={usePaginationSelection}
              currentPage={currentPage + 1}
            />
          </div>
          <MasterList
            loading={loading}
            actions={rowActions}
            columns={columnsMap}
            currentPage={currentPage}
            itemCount={expansionPlanCount}
            list={expansionPlans}
            listType="Expansion Plans"
            readonly={false}
            additionalValues={[]}
          />
          <div className={paginationClasses}>
            <MasterListPagesize size={paginationSize} setPagesize={useSetPaginationSize} />
            <MasterListPagination
              pageSize={paginationSize}
              totalItems={expansionPlanCount}
              handlePaginationSelection={usePaginationSelection}
              currentPage={currentPage + 1}
            />
          </div>
        </div>
      ) : (
        <div className={'my-3'}>
          <StepHeader stepTitle={'No Expansion Plans Available!'} Tag={'h4'} />
        </div>
      )}
    </div>
  );
};

export default withRouter(ExpansionPlanForm);
