import React from 'react';
import { get } from 'lodash';
import { Form, Formik } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { airportSearchFormValidation } from './yupSchema';
import FormField from '../../forms/FormField';
import { AllAirportsGeography } from '../../../pages/AirportsView';
import { appHistory } from '../../../reducers';

interface AirportsSearchFormProps {
  airports: AllAirportsGeography[];
  setAirportInstance: any /* this is a use state setter from parent component */;
}
const AirportsSearchForm = (
  props: AirportsSearchFormProps & RouteComponentProps
) => {
  const { match, airports, setAirportInstance } = props;
  const autoCompleteListIata = airports.map(airport => ({
    ...airport,
    label: airport.iataCode,
    key: airport.airportId,
    value: airport.airportId
  }));
  const autoCompleteListLocation = airports.map(airport => ({
    ...airport,
    label: `${airport.city}, ${airport.airportState} (${airport.airportStateProvince}) (${airport.iataCode})`,
    key: airport.airportId,
    value: airport.airportId
  }));

  return (
    <Formik
      onSubmit={values => {
        const { airportId } = values;
        setAirportInstance(airportId);
        appHistory.push(`/airports/${airportId}`);
      }}
      onReset={(values, formikActions) => {
        formikActions.resetForm();
        appHistory.push('/airports');
      }}
      initialValues={{
        locationLabel: '',
        locationId: '',
        iataLabel: '',
        iataCode: '',
        airportId: ''
      }}
      validationSchema={airportSearchFormValidation}
      render={({ values, handleChange, handleBlur, setFieldValue }) => {
        return (
          <Form className="w-100">
            <Row>
              <FormField
                name="locationId"
                labelName="locationLabel"
                labelValue={values.locationLabel || ''}
                value={values.locationId || ''}
                label="Airport Location"
                type="autocomplete"
                helpText="This field autocompletes, start typing a City, State or IATA code to pick an airport."
                handleChange={(e, label, value) => {
                  const autoSelectAirport = autoCompleteListLocation.filter(
                    location =>
                      location.label
                        .toLowerCase()
                        .includes(value!.toLowerCase() || '')
                  );

                  const airportId = get(
                    autoSelectAirport[0],
                    'airportId',
                    value || 0
                  );

                  setFieldValue('airportId', airportId, false);
                  /* formik handle change */
                  handleChange(e);
                }}
                handleBlur={handleBlur}
                match={match}
                col={{ lg: 12 }}
                autoCompleteList={autoCompleteListLocation}
                setFieldValue={setFieldValue}
                placeholder="City, State (IATA Code)"
                onFocus={e => {
                  setFieldValue('iataLabel', '', false);
                  setFieldValue('iataCode', 0, false);
                }}
                browserAutoComplete={"off"}
              />
              <FormField
                label="Airport Iata"
                name="iataCode"
                value={values.iataCode || ''}
                labelName="iataLabel"
                labelValue={values.iataLabel || ''}
                type="autocomplete"
                helpText="This field autocompletes, start typing-- an IATA code to pick an airport."
                /* called by both clicking of select options or pressing enter on an active item. */
                handleChange={(e, label, value) => {
                  const targetVal = get(e.currentTarget, 'value', value || 0);
                  const useVal = targetVal || value;
                  const autoSelectIata = autoCompleteListIata.filter(location =>
                    location.label
                      .toLowerCase()
                      .includes(useVal!.toLowerCase() || '')
                  );

                  const airportId = get(
                    autoSelectIata[0],
                    'airportId',
                    value || 0
                  );

                  setFieldValue('airportId', airportId, false);
                  /* formik handle change */
                  handleChange(e);
                }}
                handleBlur={e => {
                  handleBlur(e);
                }}
                match={match}
                col={{ lg: 12 }}
                autoCompleteList={autoCompleteListIata}
                setFieldValue={setFieldValue}
                placeholder="IATA Code"
                onFocus={(e, setInput) => {
                  setFieldValue('locationLabel', '', false);
                  setFieldValue('locationId', 0, false);
                }}
                browserAutoComplete={"off"}
              />
              <FormField
                name="airportId"
                label=""
                type="text"
                value={values.airportId || ''}
                handleChange={handleChange}
                handleBlur={handleBlur}
                match={match}
                col={{ lg: 12 }}
                hidden
                readOnly
                disabled
              />
            </Row>
            <Row>
              <Col>
                <ButtonGroup>
                  <Button type="submit" color="primary">
                    <FontAwesomeIcon icon={faSearch} /> Search
                  </Button>
                  <Button type="reset" color="secondary">
                    <FontAwesomeIcon icon={faRedoAlt} /> Reset
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Form>
        );
      }}
    />
  );
};

export default withRouter(AirportsSearchForm);
