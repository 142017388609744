import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AxnStatus } from '../types';
import { axnAPI } from './config';
import useDataModel, { PageableData, SpringPage, convertSpringPageable, emptyPage } from '.';

export interface BrandData {
  brandId: number;
  brandName: string;
  axnStatus: AxnStatus;
}
export type BrandList = BrandData[];

export const brandsAPI = `/brands`;

export const getAllBrandsFromAPI = () => axnAPI.get(`${brandsAPI}`, { params: { pageable: 'N' } });

export const useFilteredBrands = (status: string, keyword: string): PageableData<BrandData> =>
  useDataModel(
    (currentPage, pageSize) =>
      getBrandsFromAPIPagedWithSearch(pageSize, currentPage, keyword).then(convertSpringPageable),
    emptyPage<BrandData>(),
    status,
    keyword
  );

export const getBrandsFromAPIPagedWithSearch = (size: number, page: number, keyword: string) =>
  axnAPI
    .post(`${brandsAPI}/paged/search`, null, {
      params: {
        size,
        page: page - 1,
        keyword,
        sort: 'brandName'
      }
    })
    .then(resp => resp.data as SpringPage<BrandData>);

export const getBrandsFromAPIPaged = (page = 0, size = 10) =>
  axnAPI.get(`${brandsAPI}`, {
    params: {
      pageable: 'Y',
      page: page,
      size: size,
      sort: 'brandName'
    }
  });

export const getBrandById = (id: Number) => axnAPI.get(`${brandsAPI}/${id}`, {});

export const createBrandFromAPI = (brandData: BrandData) =>
  axnAPI.post(brandsAPI, {
    ...brandData
  });

export const updateBrandFromAPI = (brandData: BrandData) =>
  axnAPI.put(`${brandsAPI}/${brandData.brandId}`, {
    ...brandData
  });

export const deleteBrandFromAPI = (id: Number) => axnAPI.delete(`${brandsAPI}/${id}`, {});

export const useAllBrands = () => {
  const [brands, setBrands] = useState(new Array<BrandData>());
  const [brandsLoading, setBrandsLoading] = useState(false);
  const source = axios.CancelToken.source();
  let mounted = true;
  useEffect(() => {
    setBrandsLoading(true);
    getAllBrandsFromAPI()
      .then(response => {
        if (mounted) {
          setBrands(response.data);
          setBrandsLoading(false);
        }
      })
      .catch(error => {
        return !axios.isCancel(error) && toast.error(error);
      });
    return () => {
      mounted = false;
      source.cancel('Cancelling in cleanup');
    };
  }, [brands.length]);

  return { brands, brandsLoading };
};
