import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { axnAPI } from './config';
import useDataModel, { emptyPage, convertSpringPageable, Page, PageableData } from '.';

export interface AxnInstance {
  axnInstanceId: number;
  axnInstanceName: string;
  axnInstanceDes: string;
  axnInstanceMasterStatus: string;
}

const getAllInstances = () => axnAPI.get(`/axninstances/all`);

const getInstances = (page: number, size: number): Promise<Page<AxnInstance>> =>
  axnAPI
    .get('/axninstances', {
      params: {
        page,
        size,
        sort: 'axnInstanceId'
      }
    })
    .then(
      response => convertSpringPageable(response.data),
      error => {
        toast.error(`Unable to fetch instance list: ${error}`);
        return emptyPage<AxnInstance>();
      }
    );

// fetch-all with a size of 100
export const useInstances = (): PageableData<AxnInstance> =>
  useDataModel(() => getInstances(0, 100), emptyPage<AxnInstance>());

export const useAllInstances = () => {
  const [instances, setInstances] = useState(new Array<AxnInstance>());
  const [instanceLoading, setInstanceLoading] = useState(false);

  const source = axios.CancelToken.source();
  let mounted = true;
  useEffect(() => {
    setInstanceLoading(true);
    getAllInstances()
      .then(response => {
        if (mounted) setInstances(response.data);
        setInstanceLoading(false);
      })
      .catch(error => {
        return !axios.isCancel(error) && toast.error(`Error retrieving instances: ${error}`);
      });
    return () => {
      mounted = false;
      source.cancel('Cancelling in cleanup');
    };
  }, [instances.length]);

  return { instances, instanceLoading };
};


const axnInstancesEndpoint =  `/axninstances`;
export const createNewYearInstance = () => axnAPI.post(`${axnInstancesEndpoint}/createAxnInstance`);