import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Form, Formik } from 'formik';
import FileDownload from 'js-file-download';
import moment from 'moment';
import { Badge, Button, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFileExcel, faFilePdf, faSearch } from '@fortawesome/free-solid-svg-icons';
import { cssClass } from '../components/wizard/cssSharedClasses';
import InstanceSelectField from '../components/forms/InstanceSelectField';
import ResponsiveTable from '../components/table/ResponsiveTable';
import reports, { ReportMap } from '../resources/reports/reports';
import { downloadReport, InstanceStatus } from '../api/reports';
import { AppState } from '../reducers';
import { useAllInstances } from '../api/instances';

const ReportsView = (
  props: RouteComponentProps & { isAdmin: boolean; activeInstanceStatus: string }
) => {
  const { match, isAdmin, activeInstanceStatus } = props;
  const defaultStatus = isAdmin ? (activeInstanceStatus as InstanceStatus) : 'ACTIVE';
  const [instanceId, setInstanceId] = useState(0); //moment().year() % 100);
  const [instanceYear, setInstanceYear] = useState<string | undefined>();
  const [instanceYears, setInstanceYears] = useState<string[] | undefined>();
  const [instanceStatus, setInstanceStatus] = useState<InstanceStatus>(defaultStatus);
  const [instanceName, setInstanceName] = useState<string | undefined>();

  const { instances } = useAllInstances();

  useEffect(() => {
    if (instances && instances.length > 0) {
      const scrubbedInstances = instances
        .filter(value => (isAdmin ? true : value.axnInstanceMasterStatus !== 'PENDING'))
        .map(axnInstance => {
          const {
            axnInstanceDes,
            axnInstanceId,
            axnInstanceMasterStatus,
            axnInstanceName
          } = axnInstance;
          return {
            label: `${axnInstanceDes} (${axnInstanceMasterStatus})`,
            key: axnInstanceId,
            value: axnInstanceId,
            year: axnInstanceName,
            status: axnInstanceMasterStatus
          };
        })
        .sort((a, b) => {
          return a.label.toLowerCase() === 'none' ? 1 : a.label.localeCompare(b.label);
        })
        .filter(item => item.label !== '');
      setInstanceId(
        (scrubbedInstances &&
          scrubbedInstances[scrubbedInstances.length - 1] &&
          scrubbedInstances[scrubbedInstances.length - 1].value) ||
          0
      );

      setInstanceName(scrubbedInstances[scrubbedInstances.length - 1].label);

      if (!instanceYears || instanceYears.length === 0) {
        setInstanceYears(
          scrubbedInstances &&
            scrubbedInstances.map(instance => instance.label.split(' ')[0].trim())
        );
      }

      if (!instanceYear) {
        setInstanceYear(scrubbedInstances[scrubbedInstances.length - 1].year);
      }

      setInstanceStatus(scrubbedInstances[scrubbedInstances.length - 1].status as InstanceStatus);
    }
  }, [instances, isAdmin]);

  useEffect(() => {
    const newInstance = instances.find(value => value.axnInstanceId === instanceId);
    setInstanceStatus(newInstance?.axnInstanceMasterStatus as InstanceStatus);
  }, [instanceId, isAdmin]);

  const expirationSelect = () => {
    const years = Array(30)
      .fill(2011)
      .map((value, idx) => value + idx);

    return (
      <select
        key={'expirationSelect'}
        multiple={true}
        style={{ width: '100px' }}
        defaultValue={instanceYears}
        onChange={e => {
          const selection = Array.prototype.slice
            .call(e.target.options)
            .filter(o => o.selected)
            .map(o => o.value);
          setInstanceYears(selection as string[] | undefined);
        }}>
        {years.map(year => (
          <option key={`expiration-${year}`} value={year} defaultValue={instanceYears}>
            {year}
          </option>
        ))}
      </select>
    );
  };

  return (
    <Container className={cssClass}>
      <h1>Reports</h1>
      <Badge
        color={'primary'}
        className={'p-2 position-absolute'}
        style={{ top: '10px', right: '10px' }}>
        status: {instanceStatus}
      </Badge>
      <p>
        The reports listed in the table below represent the AXN Fact Book results for a specific
        year. Remember the year references the year the Fact Book was published which represents
        numbers from the previous year.
      </p>
      <p>
        Each report opens as a PDF file in a separate window. You can peruse these reports online or
        once a report is open, you can easily print or save as a PDF on your own computer.
      </p>

      <Formik
        enableReinitialize
        onSubmit={values => {
          setInstanceName(`${values.instanceYear} Factbook (${values.axnInstanceMasterStatus})`);
          setInstanceYear(values.instanceYear);
          setInstanceStatus(values.axnInstanceMasterStatus);
          setInstanceId(values.instanceId);
        }}
        initialValues={{
          instanceId,
          instanceYear,
          axnInstanceName: instanceName,
          axnInstanceMasterStatus: instanceStatus
        }}
        render={({ values, setFieldValue, handleChange, handleBlur }) => {
          return (
            <Col tag={Form} className={'pt-3'}>
              <Row>
                <div className={'col-auto p-0 mb-3'}>
                  <Button color="primary" tag={Link} to={`/reports/dbe`}>
                    <FontAwesomeIcon icon={faArrowRight} /> DBE Companies
                  </Button>
                </div>
              </Row>
              <Row>
                <InstanceSelectField
                  values={values}
                  match={match}
                  setFieldValue={setFieldValue}
                  handleChange={(
                    e: string | React.ChangeEvent<any>,
                    selectedLabel: string,
                    selectedVal: string
                  ): void => {
                    const newInstance = instances.find(
                      value => value.axnInstanceId === +selectedVal
                    );
                    setInstanceId(+selectedVal);
                    setInstanceName(selectedLabel);
                    setInstanceYear(newInstance?.axnInstanceName);
                    setInstanceStatus(newInstance?.axnInstanceMasterStatus as InstanceStatus);

                    handleChange(e);
                  }}
                  handleBlur={handleBlur}
                  isAdmin={isAdmin}
                />
                <div className={'col-auto p-0 m-0'}>
                  <Button color="primary" type="submit">
                    <FontAwesomeIcon icon={faSearch} /> Search
                  </Button>
                </div>
              </Row>
            </Col>
          );
        }}
      />
      <Row>
        <Col>
          <ResponsiveTable>
            <thead className={'thead-light'}>
              <tr>
                <th className={'text-left'}>Report Title</th>
                <th>Year</th>
                <th>Excel</th>
                <th>PDF</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report: ReportMap) => {
                const { name, historical, type, subType } = report;

                if (instanceYears && instanceYears.length > 0) {
                  const { pdf, xlsx } = historical;
                  const xlsfilename =
                    type === 'expiring'
                      ? xlsx(instanceYears as string & string[])
                      : xlsx(instanceYear as string & string[]);
                  const pdffilename =
                    type === 'expiring'
                      ? pdf(instanceYears as string & string[])
                      : pdf(instanceYear as string & string[]);
                  return (
                    <tr key={window.btoa(`${type}${subType}`)}>
                      <td className={'text-left'}>{name}</td>
                      <td> {(type === 'expiring' && expirationSelect()) || instanceName}</td>
                      <td>
                        <Button
                          color={'link'}
                          onClick={() => {
                            if (instanceStatus === 'HISTORICAL') {
                              const reportPath = `/reports/${
                                type !== 'expiring' ? instanceYear : 'expiring'
                              }/${encodeURIComponent(xlsfilename)}`;
                              window.open(reportPath, '_none');
                            } else if (instanceYears && instanceYears.length > 1)
                              downloadReport(
                                instanceId,
                                instanceStatus,
                                name,
                                'xlsx',
                                type,
                                instanceYears.map(year => +year),
                                subType
                              );
                          }}>
                          <FontAwesomeIcon color="primary" icon={faFileExcel} />
                        </Button>
                      </td>
                      <td>
                        <Button
                          color={'link'}
                          onClick={() => {
                            if (instanceStatus === 'HISTORICAL')
                              FileDownload(
                                `/reports/${encodeURIComponent(pdffilename)}`.toLowerCase(),
                                pdffilename
                              );
                            else if (
                              instanceYear &&
                              instanceYear.length > 0 &&
                              instanceYears &&
                              instanceYears.length > 0
                            ) {
                              downloadReport(
                                instanceId,
                                instanceStatus,
                                name,
                                'pdf',
                                type,
                                type !== 'expiring'
                                  ? +instanceYear
                                  : instanceYears.map(year => +year),
                                subType
                              );
                            }
                          }}>
                          <FontAwesomeIcon color="primary" icon={faFilePdf} />
                        </Button>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={window.btoa(`${type}${subType}`)}>
                      <td colSpan={4}>Loading...</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </ResponsiveTable>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  isAdmin: state.users.isAdmin,
  activeInstanceStatus: state.wizard.activeInstance.status
});

export default withRouter(connect(mapStateToProps, null)(ReportsView));
