import React from 'react';
import { TableColumn } from '../../masterlist/MasterList';
import { MasterListActionButton, IndexableMasterListItem } from '../../masterlist/types';
import {
  useAffiliatedAirportsForInstance,
  AffiliatedUserAirport,
  AffiliatedUserAirportDTO,
  deleteAirportAffiliation
} from '../../../api/affiliations';
import { FilteredMasterList } from '../../masterlist/filter/FilteredMasterList';
import { AllAffiliationData } from './AllAffiliations';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const columns: TableColumn[] = [
  { id: 'airportId', title: 'Airport ID', width: '20%' },
  { id: 'displayName', title: 'Name', width: '40%' },
  { id: 'airportName', title: 'Airport', width: '40%' },
  { id: 'iataCode', title: 'IATA Code' }
];

// const actions = (): MasterListActionButton[] => [];
const actions = (refresh?: () => void) => (
  airportId: number,
  axnInstanceId: number,
  userId: number
): MasterListActionButton[] => [
  {
    to: `/admin/affiliate/airport/${userId}`,
    icon: faTrash,
    text: 'Delete',
    actionCallback: (event: React.MouseEvent<any, MouseEvent>) => {
      event.preventDefault();

      deleteAirportAffiliation(userId, airportId, axnInstanceId)
        .then(response => {
          if (response.kind === 'Success') {
            toast.success('Successfully deleted airport affiliation');
          } else if (response.kind === 'Failure') {
            toast.error(`Unable to delete affiliation: ${response.error}`);
          }
        })
        .then(() => {
          if (refresh) {
            refresh();
          }
        });
    }
  }
];

const convert = (data: AffiliatedUserAirportDTO): AffiliatedUserAirport => ({
  airportId: data.airport.airportId,
  displayName: `${data.user.lastName}, ${data.user.firstName}`,
  iataCode: data.airport.iataCode,
  airportName: data.airport.airportName,
  securityRole: data.securityRole.securityRoleName,
  axnInstanceId: data.axnInstanceId,
  userId: data.user.userId
});

export const allAirportsModel: AllAffiliationData = {
  fetchData: useAffiliatedAirportsForInstance,
  actions,
  columns,
  convert
};

interface Props {
  args: string;
  axnInstanceId: number;
  refresh: () => void;
}

const AllAirportAffiliations = ({ args, axnInstanceId, refresh }: Props) => (
  <FilteredMasterList
    listType="Affiliations"
    actions={actions(refresh)}
    columns={columns}
    convert={d => convert(d) as IndexableMasterListItem}
    useData={useAffiliatedAirportsForInstance}
    args={args}
    axnInstanceId={axnInstanceId}
    readonly
  />
);

export default AllAirportAffiliations;
