import React from 'react';
import { Container } from 'reactstrap';

export const ErrorView = () => {
  return (
    <Container>
      <h1>Not Found</h1>
      <p>This page does not exist or an error has occurred!</p>
    </Container>
  );
};


export default ErrorView;