import { Dispatch } from 'redux';
import {
  getTerminals as getTerminalsFromAPI,
  getTerminal as getTerminalFromAPI
} from '../api/Airports';

export interface TerminalIDData {
  airportTerminalPK: {
    airportId: number;
    terminalId: number;
    axnInstanceId: number;
  };
}

/* figure out which of these should never be empty. */
export interface TerminalData extends TerminalIDData {
  enplaneTotal: number;
  deplaneTotal: number;
  modifiedUserId: number;
  modifiedDate?: string; // should be date type '2018-12-31T06:00:00.000+0000' of sorts maybe moment?
  terminal: {
    terminalId: number;
    terminalName: string;
    terminalShortName: string;
  };
  passengerTotal?: number;
  epDomesticTotal?: number;
  epIntlTotal?: number;
  annualPercentChangeAmount?: number;
  [key: number]: string;
}

export type TerminalsList = TerminalData[];

export type TerminalsAction =
  | { type: 'GET_TERMINALS' }
  | { type: 'GET_TERMINALS_SUCCESS'; terminals: TerminalsList }
  | { type: 'GET_TERMINALS_FAILURE'; error: string }
  | { type: 'GET_TERMINAL' }
  | { type: 'GET_TERMINAL_SUCCESS'; terminal: TerminalData }
  | { type: 'GET_TERMINAL_FAILURE'; error: string }
  | { type: 'DELETE_TERMINAL'; terminalID: TerminalIDData }
  | { type: 'DELETE_TERMINAL_SUCCESS'; terminalID: TerminalIDData }
  | { type: 'DELETE_TERMINAL_FAILURE'; error: string }
  | { type: 'UPDATE_ADD_TERMINAL'; terminal: TerminalData }
  | { type: 'UPDATE_ADD_TERMINAL_SUCCESS'; terminal: TerminalData }
  | { type: 'UPDATE_ADD_TERMINAL_FAILURE'; error: string };

export type TerminalsActionsCreator = (...props: any) => TerminalsAction;

/* TERMINAL ACTION CREATORS */
/* Get All Terminals for current airport */
export const getTerminalsRequest: TerminalsActionsCreator = () => ({
  type: 'GET_TERMINALS'
});
export const getTerminalsSuccess: TerminalsActionsCreator = (terminals: TerminalsList) => ({
  type: 'GET_TERMINALS_SUCCESS',
  terminals
});

export const getTerminalsFailure: TerminalsActionsCreator = (error: string) => ({
  type: 'GET_TERMINALS_FAILURE',
  error
});

export type GetTerminals = (airportID: number, axnInstanceId: number) => void;

export const getTerminals = (dispatch: Dispatch) => {
  return async (airportID: number, axnInstanceId: number) => {
    dispatch(getTerminalsRequest());
    try {
      const response = await getTerminalsFromAPI(airportID, axnInstanceId);
      dispatch(getTerminalsSuccess(response.data));
    } catch (e) {
      dispatch(getTerminalsFailure(e));
    }
  };
};

/* Get Singular Terminal */
export const getTerminalRequest: TerminalsActionsCreator = () => ({
  type: 'GET_TERMINAL'
});

export const getTerminalSuccess: TerminalsActionsCreator = (terminal: TerminalData) => ({
  type: 'GET_TERMINAL_SUCCESS',
  terminal
});
export const getTerminalFailure: TerminalsActionsCreator = (error: string) => ({
  type: 'GET_TERMINAL_FAILURE',
  error
});

export type GetTerminal = (airportID: number, terminalId: number, axnInstanceId: number) => void;

/* TODO: capture this repeating pattern to cut down on duplicate code. */
export const getTerminal = (dispatch: Dispatch) => {
  return async (airportID: number, terminalId: number, axnInstanceId: number) => {
    dispatch(getTerminalRequest());
    try {
      const response = await getTerminalFromAPI(airportID, terminalId, axnInstanceId);
      dispatch(getTerminalsSuccess(response.data));
    } catch (e) {
      dispatch(getTerminalFailure(e));
    }
  };
};

/* Delete Terminal */
export const deleteTerminal: TerminalsActionsCreator = (terminalID: TerminalIDData) => ({
  type: 'DELETE_TERMINAL',
  terminalID
});

export const deleteTerminalSuccess: TerminalsActionsCreator = (terminalID: TerminalIDData) => ({
  type: 'DELETE_TERMINAL_SUCCESS',
  terminalID
});

export const deleteTerminalFailure: TerminalsActionsCreator = (error: string) => ({
  type: 'DELETE_TERMINAL_FAILURE',
  error
});

/* Update Terminal */
export const updateAddTerminal: TerminalsActionsCreator = (terminal: TerminalData) => ({
  type: 'UPDATE_ADD_TERMINAL',
  terminal
});

export const updateTerminalSuccess: TerminalsActionsCreator = (terminal: TerminalData) => ({
  type: 'UPDATE_ADD_TERMINAL_SUCCESS',
  terminal
});

export const updateTerminalFailure: TerminalsActionsCreator = (error: string) => ({
  type: 'UPDATE_ADD_TERMINAL_FAILURE',
  error
});
