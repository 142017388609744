import { useEffect, useState } from 'react';

export type InstanceStatus = string;
export type AxnStatus = string;

export interface Page<T> {
  totalRecords: number;
  numberOfElements: number;
  totalPages: number;
  pageSize: number;
  currentPage: number;
  content: T[];
}

export interface PageableData<T> {
  data: Page<T>;
  setPageSize: (size: number) => void;
  setCurrentPage: (page: number) => void;
  fetchData: () => void;
  loading: boolean;
  busy: boolean;
}

// manages the pagination and initial fetching of data from the API
export const useDataModel = <T>(
  fetch: (currentPage: number, pageSize: number) => Promise<Page<T>>,
  initialData: Page<T>,
  ...deps: any[]
): PageableData<T> => {
  const [busy, setBusy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(initialData);
  const [pageSize, setPageSize] = useState(initialData.pageSize);
  const [currentPage, setCurrentPage] = useState(initialData.currentPage);

  useEffect(() => {
    setBusy(true);
    fetch(currentPage, pageSize)
        .then(setContent)
        .then( () => { setBusy(false) })
        .catch( () => { setBusy(false); });
  }, [loading, currentPage, pageSize].concat(deps));
  // Removed content.totalRecords from useEffect array, we don't want to refetch the data when the data changes

  const fetchData = () => {
    if (!loading) {
      setLoading(true);
    }
  };
  return {
    data: content,
    setPageSize,
    setCurrentPage,
    fetchData,
    loading,
    busy
  };
};

export interface SpringPage<T> {
  content: T[];
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

// convert Spring Pageable field names
export const convertSpringPageable = <T>(data: SpringPage<T>): Page<T> => ({
  totalRecords: data.totalElements,
  numberOfElements: data.numberOfElements,
  totalPages: data.totalPages + 1,
  pageSize: data.size,
  currentPage: data.number + 1, // spring is zero-indexed
  content: data.content
});

// creates an empty API response for type `T`
export const emptyPage = <T>(): Page<T> => ({
  totalRecords: 0,
  numberOfElements: 0,
  totalPages: 1,
  pageSize: 10,
  currentPage: 0, // spring Pageable is zero-indexed
  content: []
});

// creates an empty API response for type `T`
export const allDataSinglePage = <T>(): Page<T> => ({
    totalRecords: 0,
    numberOfElements: 0,
    totalPages: 1,
    pageSize: 9999999,
    currentPage: 0, // spring Pageable is zero-indexed
    content: []
  });
  

export const createPage = <T>(content: T[]): Page<T> => ({
  totalRecords: content.length,
  numberOfElements: content.length,
  totalPages: 1,
  pageSize: content.length,
  currentPage: 0, // spring Pageable is zero-indexed
  content
})

export default useDataModel;
