import { object, number, array, string } from 'yup';
import msgs from '../../../../../core/yupSchemas/msgs';
import {
  selectCheck,
  validatePercentages
} from '../../../../../core/yupSchemas/validators';

const airportInfoValidationSchema = object().shape({
  airportExpansionList: array().of(
    object().shape({
      airportExpansionPK: object().shape({
        airportId: number().required(msgs.fiddler),
        axnInstanceId: number().required(msgs.fiddler),
        expansionId: number().required(msgs.fiddler)
      }),
      completionDateText: string(),
      areaTotal: number()
        .max(
          2147483647,
          'Please provide an area size smaller than 2,147,483,647.'
        )
        .min(0, 'Please provide an area size greater than 0.')
        .typeError(msgs.nan),
      expansionPlannedText: string()
    })
  ),
  preSecurityPercentAmount: number()
    .typeError(msgs.nan)
    .test(
      'test-pre-security',
      msgs.percents('Post/Pre Security'),
      validatePercentages('postSecurityPercentAmount')
    ),
  postSecurityPercentAmount: number()
    .typeError(msgs.nan)
    .test(
      'test-post-security',
      msgs.percents('Post/Pre Security'),
      validatePercentages('preSecurityPercentAmount')
    ),
  businessPercentAmount: number()
    .typeError(msgs.nan)
    .test(
      'test-business',
      msgs.percents('Business/Leisure'),
      validatePercentages('leisurePercentAmount')
    ),
  leisurePercentAmount: number()
    .typeError(msgs.nan)
    .test(
      'test-leisure',
      msgs.percents('Business/Leisure'),
      validatePercentages('businessPercentAmount')
    ),
  odPercentAmount: number()
    .typeError(msgs.nan)
    .test(
      'test-od',
      msgs.percents('Origination & Destination to Transfer'),
      validatePercentages('transferPercentAmount')
    ),
  transferPercentAmount: number()
    .typeError(msgs.nan)
    .test(
      'test-transfer',
      msgs.percents('Origination & Destination to Transfer'),
      validatePercentages('odPercentAmount')
    ),
  airportConfiguration: selectCheck.string(msgs.airportConfig, ['']),
  concessionManagementType: selectCheck.string(msgs.airportConfig, [''])
});

export default airportInfoValidationSchema;
