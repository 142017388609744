import { object, number, array, string } from "yup";
import msgs from '../../../../../core/yupSchemas/msgs';

export const terminalSchema = object().shape({
  passengerTotal: number().required(msgs.passengerTotal), // not necessary user can't edit this value.
  enplaneTotal: number().required(msgs.enplaneTotal), // not necessary user can't edit this value.
  deplaneTotal: number().required(msgs.deplaneTotal),
  airportTerminalTotals: array().of(
    object().shape({
      grossSalesTotal: number().required(msgs.grossSalesTotal).typeError(msgs.nan)
    })
  ),
  epDomesticTotal: number().required(msgs.epDomesticTotal),
  epIntlTotal: number().required(msgs.epIntlTotal),
});
