import React from 'react';
import contactsConfig from './contactsConfig';
import Contact from './Contact';
import ResponsiveTable from "../table/ResponsiveTable";

export default () => {
  const contacts = contactsConfig;

  return (
    <ResponsiveTable>
      <thead>
        <tr>
          <th>Name</th>
          <th>Title</th>
          <th>Email</th>
          {/* <th>Phone</th> */}
          {/* <th>Mobile</th> */}
        </tr>
      </thead>
      <tbody>
        {contacts.map(contact => {
          const { name, title, email, phone, mobile } = contact;
          return (
            <Contact
              key={email}
              name={name}
              title={title}
              email={email}
              phone={phone}
              mobile={mobile}
            />
          );
        })}
      </tbody>
    </ResponsiveTable>
  );
};
