import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { connect } from "react-redux";
import { SearchForm } from '../components/company/SearchForm';
import { SearchResult } from '../components/company/SearchResult';
import { AppState } from "../reducers";

interface CompaniesSearchProps {
  searchTerm?: string;
}

const CompaniesSearch = ({ match, axnInstanceId }: RouteComponentProps<CompaniesSearchProps> & {axnInstanceId: number}) => {
  const [searchTerm, setSearchTerm] = useState(match.params.searchTerm || '_all');

  return (
    <Container>
      <SearchForm
        handleSubmit={(v) => { setSearchTerm(v || '_all'); } }
        handleReset={() => { setSearchTerm('_all') } }
        initialValue={searchTerm}
        isSubmitting={false}
      />
      {searchTerm.length > 0 && <SearchResult searchTerm={searchTerm} axnInstanceId={axnInstanceId} />}
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  axnInstanceId: state.users.authenticatedUser.axnInstanceId
});

export default connect(
  mapStateToProps,
  null
)(withRouter(CompaniesSearch));
