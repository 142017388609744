import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { faEdit, faKey, faTrash, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import MasterList, { TableColumn } from '../../masterlist/MasterList';
import { MasterListActionButton, IndexableMasterListItem } from '../../masterlist/types';
import MasterListPagesize from '../../masterlist/MasterListPagesize';
import MasterListPagination from '../../masterlist/MasterListPagination';
import {
  convertSearchUsers,
  deleteUserByIDFromAPI,
  getUsersFromAPI,
  searchUsers
} from '../../../api/Users';
import { UserData } from '../../../actions/usersActions';
import { paginationClasses } from '../adminUtils';
import { requestPasswordReset } from '../../../api/authentication';
import AddEntityButton from '../AddEntityButton';
import emptyUserState from './emptyUser';
import SearchField from '../../search/SearchField';
import { Page } from '../../../api';
import Confirm from './Confirm';
import { SecurityRoleFromKey } from '../../../core/authentication/SecurityRoles';

const columnsMap: TableColumn[] = [
  { id: 'userId', title: 'ID' },
  { id: 'fullName', title: 'Name', width: '30%' },
  { id: 'email', title: 'Email', width: '30%' },
  { id: 'securityRole', title: 'Role' },
  { id: 'modifiedDate', title: 'Last Modified' },
  { id: 'userStatus', title: 'Status' }
];

const UsersManager = () => {
  const [users, setUsers] = useState(new Array<UserData>());
  const [currentPage, setCurrentPage] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [reload, setReload] = useState(false);
  const [selectedUser, setSelectedUser] = useState<number>();

  const endpointToUse = useCallback(
    (keyword: string): ((size: number, page: number) => Promise<Page<UserData>>) =>
      searching ? convertSearchUsers(keyword) : getUsersFromAPI,
    [searching]
  );

  useEffect(() => window.scrollTo(0, 0));
  useEffect(() => {
    setLoading(true);
    endpointToUse(keyword)(paginationSize, currentPage)
      .then(response => {
        const { content, pageSize, totalRecords } = response;
        setUsers(
          content.map((item: UserData) => {
            return {
              ...item,
              fullName: `${item.firstName} ${item.lastName}`,
              securityRole: SecurityRoleFromKey(item.securityRoleId)?.label || 'Unknown'
            };
          })
        );
        setUsersCount(totalRecords);
        setPaginationSize(pageSize);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error('Failed to retrieve users, please try again!');
      });
  }, [paginationSize, currentPage, keyword, endpointToUse, reload]);

  const rowActions = (id: number, email: string): MasterListActionButton[] => {
    return [
      {
        to: `/admin/manage/users/edit/${id}`,
        icon: faEdit,
        text: 'Edit'
      },
      {
        to: `/admin/manage/users`,
        icon: faKey,
        text: 'Reset Password',
        actionCallback: event => {
          event.preventDefault(); // prevent to from working?
          requestPasswordReset(email)
            .then(() => {
              toast.success(
                `Password reset has been submitted for ${email}, please advise the user to check their email for steps to proceed with the forgot password process.`,
                { autoClose: 10000 }
              );
            })
            .catch(() => {
              toast.error(`There is no user associated with that Email Address`);
            });
        }
      },
      {
        to: `/admin/manage/users`,
        icon: faTrash,
        text: 'Delete',
        actionCallback: _event => setSelectedUser(id)
      }
    ];
  };

  const additionalRowParams = ['email'];

  const usePaginationSelection = (pageNum: number) => {
    setCurrentPage(pageNum - 1);
  };

  const useSetPaginationSize = (pageSize: number) => {
    setCurrentPage(0);
    setPaginationSize(pageSize);
  };

  const deleteUser = useCallback(() => {
    if (selectedUser) {
      deleteUserByIDFromAPI(selectedUser)
        .then(response => {
          toast.success(response.msg);
          setReload(!reload);
        })
        .catch(() => {
          toast.error(
            'Failed to delete user, please try again or edit the user and set their status to not active.'
          );
        });
      setSelectedUser(undefined);
    }
  }, [selectedUser, reload]);

  return (
    <>
      <div className={paginationClasses}>
        <AddEntityButton
          addIcon={faUserPlus}
          emptyEntityState={emptyUserState}
          path="/admin/manage/users/add"
        />
        <MasterListPagesize size={paginationSize} setPagesize={useSetPaginationSize} />
        <SearchField
          searchRequest={searchUsers}
          setKeyword={setKeyword}
          setSearching={setSearching}
          size={paginationSize}
          page={currentPage}
        />
        <MasterListPagination
          pageSize={paginationSize}
          totalItems={usersCount}
          handlePaginationSelection={usePaginationSelection}
          currentPage={currentPage + 1}
        />
      </div>
      <MasterList
        loading={loading}
        actions={rowActions}
        columns={columnsMap}
        currentPage={currentPage}
        itemCount={usersCount}
        list={users as IndexableMasterListItem[]}
        listType="Users"
        readonly={false}
        additionalValues={additionalRowParams}
      />
      <div className={paginationClasses}>
        <MasterListPagesize size={paginationSize} setPagesize={useSetPaginationSize} />
        <MasterListPagination
          pageSize={paginationSize}
          totalItems={usersCount}
          handlePaginationSelection={usePaginationSelection}
          currentPage={currentPage + 1}
        />
      </div>
      <Confirm
        isOpen={selectedUser !== undefined}
        cancel={_event => setSelectedUser(undefined)}
        success={deleteUser}
        successTitle="Delete">
        <p>Are you sure you wish to delete this user?</p>
      </Confirm>
    </>
  );
};

export default withRouter(UsersManager);
