import React, { ReactNode } from 'react';
import { TabPane } from 'reactstrap';

export interface TabProps {
  tabId: number;
  tabTitle: ReactNode;
  content: ReactNode;
}

export default (props: TabProps) => {
  const { tabId, tabTitle, content } = props;
  return (
    <TabPane tabId={tabId}>
      <h4 className={'my-3'}>{tabTitle}</h4>
      {content}
    </TabPane>
  );
};
