import { object, string, number } from 'yup';

export const airportSearchFormValidation = object().shape({
  location: string(),
  iata: string(),
  airportId: number()
    .required(
      'In order to find an airport you must select an Airport Location or IATA Code'
    )
    .typeError('Unable to find any airport based on your search criteria.')
});
