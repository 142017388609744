import React, { useCallback } from 'react';
import { Alert, Form, FormGroup } from 'reactstrap';
import { AxnInstance, useAllInstances } from '../../../api/instances';
import { useListAllUsers } from '../../../api/Users';
import { UserData } from '../../../actions/usersActions';
import { appHistory } from '../../../reducers';
import { AutoCompleteOption } from '../../forms/autocomplete/AutoComplete';
import FormField from '../../forms/FormField';
import { AffiliateObjectType, AffiliateToObjectForm } from './AffiliateToObjectForm';

const mkInstanceOption = ({ axnInstanceId, axnInstanceDes }: AxnInstance): AutoCompleteOption => ({
  label: `${axnInstanceDes}`,
  value: `${axnInstanceId}`,
  key: `${axnInstanceId}`
});

const mkUserOption = ({
  userId,
  lastName,
  firstName,
  displayName
}: UserData): AutoCompleteOption => ({
  label: `${lastName}, ${firstName} (${displayName})`,
  value: `${userId}`,
  key: `${userId}`
});

const noOp = () => {};

interface AffiliateUserFormProps {
  userID?: string;
  instanceId: number;
  objectType: AffiliateObjectType;
  selectUser: (user: UserData) => void;
  selectInstance: (id: number) => void;
  handleSubmit: (airportId: number) => void;
}

const AffiliateUserForm = ({
  handleSubmit,
  instanceId,
  objectType,
  selectInstance,
  selectUser,
  userID
}: AffiliateUserFormProps) => {
  const lowerCaseType = objectType.toLowerCase();
  const { instances } = useAllInstances();
  const usersModel = useListAllUsers();
  const users = usersModel.data.content;

  const handleUserChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, _label?: string, value?: string) => {
      if (value !== undefined) {
        const userId = +value;
        const user = users.find(u => u.userId === userId);
        if (user) {
          selectUser(user);
          appHistory.push(`/admin/affiliate/${lowerCaseType}/${user.userId}`);
        }
      }
    },
    [lowerCaseType, selectUser, users]
  );

  const userOptions = users.map(mkUserOption);
  const selectedUser = userOptions.find(u => u.value === userID);
  const userLabel = selectedUser !== undefined ? selectedUser.label : '';

  const handleInstanceChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, _label?: string, value?: string) => {
      if (value !== undefined) {
        selectInstance(+value);
        instanceId = +value;
      }
    },
    [selectInstance]
  );

  const instanceOptions = instances.map(mkInstanceOption);
  const selectedInstance = instanceOptions.find(i => i.value === `${instanceId}`);
  const instanceLabel = selectedInstance !== undefined ? selectedInstance.label : '';

  return (
    <div className={'axn-component'}>
      <Form className={'axn-form'} id={'affiliateUserForm'}>
        <h3 className={'card-title'}>Affiliate User</h3>
        <p className={'card-subtitle'}>{`Select a user to affiliate with an ${lowerCaseType}`}</p>
        <FormGroup tag={'section'}>
          <FormField
            autoCompleteList={instanceOptions}
            name="axnInstance"
            label="Axn Instance:"
            type="autocomplete"
            value={instanceId}
            labelValue={instanceLabel}
            handleChange={handleInstanceChange}
            handleBlur={noOp}
            match={{ params: {} }}
            col={{}}
            className="px-0"
          />
        </FormGroup>
        <FormGroup tag="section">
          <FormField
            autoCompleteList={userOptions}
            name="user"
            label="User:"
            type="autocomplete"
            value={userID || ""}
            labelValue={userLabel}
            handleChange={handleUserChange}
            handleBlur={noOp}
            match={{ params: {} }}
            col={{}}
            className="px-0"
          />
        </FormGroup>
        <AffiliateToObjectForm
          handleSubmit={handleSubmit}
          instanceId={instanceId}
          objectType={objectType}
        />
        <Alert color={'info'}>
          {`If a user needs access to more than one ${objectType}, each
          affiliation must be submitted as a separate request.`}
        </Alert>
      </Form>
    </div>
  );
};

export { AffiliateUserForm };
