import { useEffect, useState } from 'react';
import {toast} from "react-toastify";
import { ProductTypeData } from '../actions/productTypeActions';
import { axnAPI } from "./config";

const ptAPI = `/producttype`;

export const getProductTypes = (size = 10, page = 0) => {
  return axnAPI.get(ptAPI, { params: { page, size } });
};

export const getAllProductTypes = (size = 10, page = 0) => axnAPI.get(`${ptAPI}/all`);
export const setProductTypes = () => axnAPI.post(ptAPI);

/* Singular */
export const getProductType = (id: string) => axnAPI.get(`${ptAPI}/${id}`);
export const setProductType = (id: string) => axnAPI.put(`${ptAPI}/${id}`);
export const deleteProductType = (id: string) => axnAPI.delete(`${ptAPI}/${id}`);

/* Custom Effects */
export const useProductTypes = () => {
  const [productTypes, setProductTypes] = useState(
    new Array<ProductTypeData>()
  );
  useEffect(() => {
    getProductTypes()
      .then(response => {
        setProductTypes(response.data);
      })
      .catch(e => {
        toast.error(e);
      });
  }, [productTypes.length]);
  return productTypes;
};
