import { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { stringify } from 'qs';
import { toast } from 'react-toastify';
import FileDownload from 'js-file-download';
import { axnAPI } from './config';
import { Page, PageableData, useDataModel } from './index';
import { TenantData } from '../components/masterlist/types';
import { TableColumn } from '../components/masterlist/MasterList';
import { TenantType } from '../types';
import { AllowedWizTypes } from '../components/wizard/types';

const reportsAPI = `/reports`;

type ReportType = 'expiring' | 'tenants';

// fetches a report from the API, returning the default value on error.
export const fetchReport = async <T>(
  instanceID: string,
  reportType: ReportType,
  config: AxiosRequestConfig,
  defaultValue: Page<T>
): Promise<Page<T>> => {
  try {
    const url = `${reportsAPI}/instance/${instanceID}/report/${reportType}`;
    const response = await axnAPI.get(url, config);
    return response.data as Page<T>;
  } catch (e) {
    toast.error(`Error fetching report: ${e}`);
    return defaultValue;
  }
};

const fileSafeString = (str: string) => str.replace(/[^a-z0-9]/gi, '_');

export type InstanceStatus = 'HISTORICAL' | 'ACTIVE' | 'ARCHIVED' | 'PENDING';

export const downloadReport = (
  instanceID: number,
  instanceStatus: InstanceStatus,
  name: string,
  format: 'xlsx' | 'pdf' | 'json',
  reportType: string,
  years: number | number[],
  tenanttypes?: Array<TenantType> | undefined
) => {
  const yearsAsArray = Array.isArray(years) ? years : [years];
  const fbYearsAsString = (yearsAsArray as number[]).join(',');

  // We want to also display the years the data was pulled from for the report.
  // It's pretty simple, basically for 2024, it's the 2023 data set.
  // Expiriing doesn't deal with the data set, so we don't need to worry about that.
  const dataYearsAsString = yearsAsArray.map(year => (year - 1).toString()).join(',');
  const dataYearsAsStringModifier = reportType != 'expiring' ? `_${dataYearsAsString}` : '';

  return axnAPI
    .get(`${reportsAPI}/instance/${instanceID}/report/${reportType}`, {
      responseType: 'blob',
      params: {
        format,
        tenanttype: Array.isArray(tenanttypes) ? (tenanttypes as TenantType[]).join(',') : '',
        years: fbYearsAsString
      },
      paramsSerializer: params => stringify(params)
    })
    .then(
      response => {
        FileDownload(
          response.data,
          `${fbYearsAsString}_${fileSafeString(
            name
          )}${dataYearsAsStringModifier}_${instanceID}.${format}`.toLowerCase()
        );
      },
      error => {
        toast.warn(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'Failed to download report'
        );
      }
    );
};

const emptyTenantData: Page<TenantData> = {
  totalRecords: 0,
  numberOfElements: 0,
  totalPages: 0,
  pageSize: 10,
  currentPage: 1,
  content: new Array<TenantData>()
};

export const useExpiringTenants = (
  instanceID: number,
  tenantType: string
): PageableData<TenantData> =>
  useDataModel(
    (currentPage: number, pageSize: number) =>
      fetchReport(
        `${instanceID}`,
        'expiring',
        {
          params: {
            tenanttype: tenantType,
            year: moment().year(),
            page: currentPage,
            size: pageSize
          }
        },
        emptyTenantData
      ),
    emptyTenantData,
    instanceID,
    tenantType
  );

// api endpoint: /api/v1/reports/instance/18/report/tenants
export const useTenantListing = (instanceID: number): PageableData<TenantData> =>
  useDataModel(
    (currentPage: number, pageSize: number) =>
      fetchReport(
        `${instanceID}`,
        'tenants',
        {
          params: {
            page: currentPage,
            size: pageSize
          }
        },
        emptyTenantData
      ).then(response => {
        return {
          ...response,
          content: response.content.map((tenant: TenantData) => ({
            ...tenant,
            expireOrStatus:
              tenant.tenantStatus === 'ACTIVE' ? tenant.leaseExpireDate : tenant.tenantStatus
          }))
        };
      }),
    emptyTenantData
  );

export const tenantListingColumns: TableColumn[] = [
  { id: 'iatacode', title: 'IATA Code' },
  { id: 'terminalShortName', title: 'Terminal Location' },
  { id: 'tenantTypeDes', title: 'Product Category', width: '15%' },
  { id: 'brandName', title: 'Tenant Name', width: '20%' },
  { id: 'productTypeDes', title: 'Product Description', width: '20%' },
  { id: 'companyName', title: 'Company Name', width: '20%' },
  { id: 'locationNumber', title: 'Location' },
  { id: 'areaTotal', title: 'Square Footage' },
  { id: 'expireOrStatus', title: 'Lease Expires', width: '10%' }
];

const getAllReports = (
  instanceID: number,
  type: AllowedWizTypes,
  format: 'xlsx' | 'pdf' | 'json',
  companyType?: string
) =>
  axnAPI.post(
    `${reportsAPI}/instance/${instanceID}/${type}book`,
    { companyType },
    {
      responseType: 'blob',
      params: { format },
      paramsSerializer: params => stringify(params),
      timeout: 120000
    }
  );

const getListOfReports = (
  entityIds: number[], // string of airport IDs
  companyType?: string
) => (instanceID: number, type: AllowedWizTypes, format: 'xlsx' | 'pdf' | 'json') => {
  return axnAPI.post(
    `${reportsAPI}/instance/${instanceID}/${type}book`,
    { entityIds, companyType },
    {
      responseType: 'blob',
      params: { format },
      paramsSerializer: params => stringify(params),
      timeout: 120000
    }
  );
};

/* airports/companies book */
export const getBookReport = (
  instanceID: number,
  type: AllowedWizTypes,
  format: 'xlsx' | 'pdf' | 'json',
  name: string,
  map: number[] = [], // string of airport IDs
  companyType?: string
) => {
  const apiToUse = map.length && map.length > 0 ? getListOfReports(map) : getAllReports;
  return apiToUse(instanceID, type, format, companyType).then(
    response => {
      FileDownload(
        response.data,
        `${fileSafeString(name)}_${type}book_${instanceID}.${format}`.toLowerCase()
      );
    },
    error => {
      toast.warn(error.response.data.message || 'Failed to download report');
    }
  );
};
