import React from 'react';
import { Container, Row } from 'reactstrap';
import SiteMap from '../Footer/SiteMap';
import ContactUs from '../Footer/ContactUs';
import Glossary from '../Footer/Glossary';
import { AuthoritiesList } from "../../actions/usersActions";

interface FrontendFooterProps {
  isLoggedIn: boolean;
  authorities: AuthoritiesList;
}

export const FrontendFooter = (props: FrontendFooterProps) => {
  const { authorities } = props;
  return (
    <Container tag={'footer'} className={'py-5'} fluid>
      <Container id={'footer'}>
        <Row>
          <SiteMap authorities={authorities} />
          <ContactUs />
          <Glossary />
        </Row>
      </Container>
    </Container>
  );
};
