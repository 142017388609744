import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { StepDefaultProps } from '../../../types';
import { AppState } from '../../../../../reducers';
import {
  InitAddExpansionPlan,
  initAddExpansionPlan,
  InitUpdateExpansionPlan,
  initUpdateExpansionPlan
} from '../../../../../actions/airportActions';
import {
  AirportInstance,
  AirportInstanceInfo,
  ConcessionManagementType,
  AirportExpansion
} from '../../../../../types';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';
import ExpansionPlanForm from './ExpansionPlanForm';

export interface InfoValues extends AirportInstanceInfo, ConcessionManagementType {
  airportConfiguration: string;
  airportExpansionList: AirportExpansion[];
}

interface InfoStepProps extends StepDefaultProps, InfoValues {
  airportInstance: AirportInstance;
  addExpansionPlan: InitAddExpansionPlan;
  updateExpansionPlan: InitUpdateExpansionPlan;
  activeInstance: ActiveInstance;
}

const ExpansionStep = (props: InfoStepProps & RouteComponentProps<{ contentID: string }>) => {
  const { airportInstance, addExpansionPlan, updateExpansionPlan, activeInstance } = props;
  const { id } = activeInstance;

  return (
    <ExpansionPlanForm
      axnInstanceId={id}
      handleAddExpansion={addExpansionPlan}
      handleUpdateExpansion={updateExpansionPlan}
      airportInstance={airportInstance}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  airportInstance: state.airports.airportInstance,
  activeInstance: state.wizard.activeInstance
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addExpansionPlan: initAddExpansionPlan(dispatch),
  updateExpansionPlan: initUpdateExpansionPlan(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExpansionStep));
