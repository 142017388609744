import React from 'react';
import { Container } from 'reactstrap';
import { cssClass } from '../components/wizard/cssSharedClasses';
import faqsConfig from '../components/faqs/faqsConfig';
import Faqs from '../components/faqs/Faqs';
import { AdSpeedLeaderboard } from '../components/advertisement/adspeed/leaderboard';

export const FaqsView = () => {
  return (
    <>
      <AdSpeedLeaderboard />
      <Container className={cssClass}>
        <h1 className={'mb-3'}>FAQS</h1>
        <Faqs faqs={faqsConfig} />
      </Container>
    </>
  );
};
