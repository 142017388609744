import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import './toolTip.scss';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

const ToolTipToggle = (props: { visible: boolean; setVisible: (...props: any) => void }) => {
  const { visible, setVisible } = props;
  const [browserDimension, setBrowserDimension] = useState(getWindowDimensions());
  useEffect(() => {
    const handleResize = () => setBrowserDimension(getWindowDimensions);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // cleanup
  }, []);
  return (
    <button
      className={'tooltipToggle'}
      type={'button'}
      style={{
        zIndex: visible ? 1001 : 0
      }}
      onMouseEnter={() => {
        if (browserDimension.width > 1024) setVisible(true);
      }}
      onMouseLeave={() => {
        if (browserDimension.width > 1024) setVisible(false);
      }}
      onClick={() => {
        if (browserDimension.width < 1024) setVisible(!visible);
      }}>
      <FontAwesomeIcon icon={faExclamationCircle} color={'grey'} />
    </button>
  );
};

export default ToolTipToggle;
