import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container } from 'reactstrap';
import { AdminIndexView } from './pages/admin';
import { AdminMatch } from './components/wizard/types';
import { ManageDefault } from './pages/admin/manage';
import { Affiliate, AffiliateAirportView, AffiliateCompaniesView } from './pages/admin/affiliate';
import CompanyManager from './components/admin/companies/CompanyManager';
import UsersManager from './components/admin/users/UsersManager';
import ProductTypesManager from './components/admin/producttypes/ProductTypesManager';
import AirportManager from './components/admin/airports/AirportsManager';
import AddEditUserView from './pages/users/AddEditUserView';
import AddEditProductTypesView from './components/admin/producttypes/AddEditProductTypesView';
import AddEditAirportView from './components/admin/airports/AddEditAirportView';
import AddEditMainCompanyView from './components/admin/companies/AddEditMainCompanyView';
import MainCompanyManager from './components/admin/companies/MainCompanyManager';
import AddCompanyInstanceView from './components/admin/companies/AddCompanyInstanceView';
import TerminalManager from './components/admin/terminals/TerminalManager';
import InstanceManager from './components/admin/instances/InstanceManager';
import ReportsManager from './components/admin/reports/ReportsManager';
import AddEditTerminalView from './components/admin/terminals/AddEditTerminalView';
import BrandManager from './components/admin/brands/BrandManager';
import AddEditBrandView from './components/admin/brands/AddEditBrandView';

const AdminRouter = (props: { match: AdminMatch }) => {
  const { match } = props;
  return (
    <Container>
      <Switch>
        <Route
          exact
          path={`${match.path}/affiliate/airport/:userID?`}
          key="AffiliateAirportView"
          component={AffiliateAirportView}
        />
        <Route
          exact
          path={`${match.path}/affiliate/company/:userID?`}
          key={'AffiliateCompaniesView'}
          component={AffiliateCompaniesView}
        />
        <Route exact path={`${match.path}/affiliate`} key="Affiliate" component={Affiliate} />
        <Route
          exact
          path={`${match.path}/manage/main/companies`}
          key="MainCompanyManager"
          component={MainCompanyManager}
        />
        <Route
          exact
          path={`${match.path}/manage/main/companies/:addEdit(add|edit)/:companyID?`}
          component={AddEditMainCompanyView}
        />
        <Route
          exact
          path={`${match.path}/manage/companies`}
          key="CompanyManager"
          component={CompanyManager}
        />
        <Route
          exact
          path={`${match.path}/manage/companies/add`}
          component={AddCompanyInstanceView}
        />
        <Route exact path={`${match.path}/manage/users`} component={UsersManager} />
        <Route
          exact
          path={`${match.path}/manage/users/:addEdit(add|edit)/:userID?`}
          component={AddEditUserView}
        />
        <Route exact path={`${match.path}/manage/product-types`} component={ProductTypesManager} />
        <Route
          exact
          path={`${match.path}/manage/product-types/:addEdit(add|edit)/:productTypeID?`}
          component={AddEditProductTypesView}
        />
        <Route exact path={`${match.path}/manage/airports`} component={AirportManager} />
        <Route
          exact
          path={`${match.path}/manage/airports/:addEdit(add|edit)/:airportID?`}
          component={AddEditAirportView}
        />
        <Route
          exact
          path={`${match.path}/manage/airports/:aid/terminals`}
          component={TerminalManager}
        />
        <Route
          exact
          path={`${match.path}/manage/airports/:aid/terminals/:addEdit(add|edit)/:terminalID?`}
          component={AddEditTerminalView}
        />
        {/* Manage Brands */}
        <Route exact path={`${match.path}/manage/brands`} component={BrandManager} />
        <Route exact path={`${match.path}/manage/brands/:addEdit(add|edit)/:brandId?`} component={AddEditBrandView} />
        {/* Manage Instances */}
        <Route exact path={`${match.path}/manage/instances`} component={InstanceManager} />
        {/* Manage Reports */}
        <Route exact path={`${match.path}/manage/reports`} component={ReportsManager} />
        <Route exact path={`${match.path}/manage`} component={ManageDefault} />
        <Route component={AdminIndexView} />
      </Switch>
    </Container>
  );
};

export default AdminRouter;
