import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Contact } from './types';

export default (props: Contact) => {
  const { name, title, email /* phone, mobile */ } = props;
  return (
    <tr>
      <td>{name}</td>
      <td>{title}</td>
      <td>
        <a href={`mailto:${email}`}>
          <FontAwesomeIcon icon={faEnvelope} /> {email}
        </a>
      </td>
      {/*
        <td>
          {phone && (
            <>
              <FontAwesomeIcon icon={faPhone} /> {phone}{' '}
            </>
          )}
        </td>
        <td>
          {mobile && (
            <>
              <FontAwesomeIcon icon={faMobile} /> {mobile}{' '}
            </>
          )}
        </td>
      */}
    </tr>
  );
};
