import { UserDataWithPhones } from "../../../actions/usersActions";

const emptyUser: UserDataWithPhones = {
  address1: '',
  address2: '',
  city: '',
  companyName: '',
  createdDate: '',
  displayName: '',
  email: '',
  firstName: '',
  isoCountryCode: '',
  jobCategory: '',
  lastName: '',
  modifiedDate: '',
  modifiedUserId: 0,
  postalCode: '',
  publishedEmail: '',
  securityRoleId: 0,
  stateProvinceCode: '',
  userId: 0,
  userPassword: '',
  userStatus: '',
  userPhoneList: [],
  title: ''
}

export default emptyUser;
