import React from 'react';
import StepHeader from '../../../StepHeader';
import Spinner from '../../../../shared/spinner/Spinner';

interface CovidResponseViewProps {
    questionId: number;
    questionText: string;
    answer?: string;
    loading?: boolean;
    title?: string;
}
  
const CovidResponseView = ({
    questionId,
    questionText,
    answer,
    title,
    loading
}: CovidResponseViewProps) => {    

    return (
        <div>
            <StepHeader Tag="h3" stepTitle={title || 'COVID-19 Response'} />

            <div className={'mt-3 font-weight-bold'}>{questionText}</div>
            {loading && <Spinner />}
            {!loading && <p>{answer || "No data received"}</p>}
        </div>
  
    );
};

CovidResponseView.defaultProps = {
    loading: false,
}

export default CovidResponseView;
