import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AxiosRequestConfig } from 'axios';
import { ConnectedRouter } from 'connected-react-router';
import { toast, ToastContainer } from 'react-toastify';
import AppRouter from './AppRouter';
import { appHistory, AppState } from './reducers';
import { axnAPI } from './api/config';
import Header from './layout/Header/Header';
import Footer from './layout/Footer/Footer';
import { AuthenticatedUser, AuthoritiesList, decodeUserToken } from './actions/usersActions';
import { getAuthTokenFromKeyValue } from './core/authentication/authenticationHelper';
import { AdSpeedLeaderboard } from './components/advertisement/adspeed/leaderboard';

interface AppProps {
  isLoggedIn: boolean;
  authorities: AuthoritiesList;
  decodeToken: (token: string) => void;
  authenticatedUser: AuthenticatedUser;
}

/* Store this and other potential responses in an const object? */
const UNAUTHORIZED = 401;

export const App = (props: AppProps) => {
  const { isLoggedIn, authorities, decodeToken, authenticatedUser } = props; // app will reload when user logs in.

  /* Check User Auth */
  const { userAuthToken, hasUserAuthToken } = getAuthTokenFromKeyValue();

  axnAPI.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const headers = `Bearer ${userAuthToken}`;
      const newConfig = {
        ...config,
        headers: { Authorization: headers }
      };

      return hasUserAuthToken ? newConfig : config;
    },
    error => {
      toast.error(error);
    }
  );

  axnAPI.interceptors.response.use(
    response => response,
    error => {
      const status = error.response !== undefined ? error.response.status : 500;
      if (status === UNAUTHORIZED) {
        toast.warn('Invalid Credentials');
      }

      if (
        error.request !== undefined &&
        error.request.responseType === 'blob' &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf('json') !== -1
      )
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            // eslint-disable-next-line no-param-reassign
            error.response.data = JSON.parse(reader.result ? reader.result.toString() : '');
            resolve(Promise.reject(error));
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });

      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (hasUserAuthToken) decodeToken(userAuthToken);
  }, [authenticatedUser.userId, authenticatedUser.expirationDate]);

  return (
    <div className="airport-factbook-container">
      <ToastContainer />
      <ConnectedRouter history={appHistory}>
        <Header />
        <main>
          <AppRouter />
        </main>
        <Footer isLoggedIn={isLoggedIn} authorities={authorities} />
      </ConnectedRouter>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.users.isLoggedIn,
  authorities: state.users.authenticatedUser.authorities,
  authenticatedUser: state.users.authenticatedUser
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  decodeToken: decodeUserToken(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
