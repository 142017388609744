import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { axnAPI } from './config';

const jobCategoryAPI = '/jobcategory';

const getAllJobCategoriesFromAPI = () => axnAPI.get(`${jobCategoryAPI}`);

const useJobCategories = () => {
  const [jobCategories, setJobCategories] = useState(
    new Array<{ jobCategory: string }>()
  );
  const [jobCategoriesLoading, setJobCategoriesLoading] = useState(false);

  useEffect(() => {
    setJobCategoriesLoading(true);
    getAllJobCategoriesFromAPI()
      .then(response => {
        setJobCategories(response.data);
        setJobCategoriesLoading(false);
      })
      .catch(e => {
        setJobCategoriesLoading(false);
        toast.error(e);
      });
  }, [jobCategories.length]);

  return { jobCategories, jobCategoriesLoading };
};

export default useJobCategories;
