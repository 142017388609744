import { object, number, string } from 'yup';
import moment from 'moment';
import msgs from '../../../../../core/yupSchemas/msgs';

export const airportTenantsValidationSchema = object().shape({
  brandName: string()
    .required(msgs.brandName)
    .notOneOf([''], msgs.brandName),
  categoryName: string()
    .required(msgs.categoryName)
    .notOneOf([''], msgs.categoryName),
  companyName: string()
    .required(msgs.companyName)
    .notOneOf([''], msgs.companyName),
  expires: string()
    .when('tenantStatus', {
        is: tenantStatus => tenantStatus === 'ACTIVE',
        then: string()
            .nullable()
            .test(
                'test-expires',
                'Requires a valid date (YYYY-MM-DD)',
                value => value == null || moment(value, 'YYYY-MM-DD', true).isValid()
            ),
        otherwise: true
    }),
  sqFt: number()
    /* If null, then coerce into a number */
    .nullable(true).transform((value, original) => original === '' || original === null ? 0 : value)
    .required(msgs.sqft),
  tenantStatusDes: string()
    .required(msgs.tenantStatus)
    .notOneOf([''], msgs.tenantStatus),
  terminalName: string()
    .required(msgs.terminalName)
    .notOneOf([''], msgs.terminalName)
});
