import React, { useState } from 'react';
import { Button, ButtonGroup, Col, Collapse, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleDown,
  faChevronCircleUp
} from '@fortawesome/free-solid-svg-icons';

interface AccordionProps {
  name: string;
  children: JSX.Element[] | JSX.Element | string;
  callbackFn?: (...props: any) => void;
  expandedDefault?: boolean;
  className?: string;
}

const AccordionItem = (props: AccordionProps) => {
  const { callbackFn, expandedDefault, className, children, name } = props;
  const [collapse, setCollapse] = useState(expandedDefault || false);
  const toggle = (cb?: (...props: any) => void) => {
    if (cb) cb();
    setCollapse(!collapse);
  };
  return (
    <div className={`mb-4${className ? ` ${className}` : ''}`}>
      <ButtonGroup>
        <Button
          color="primary"
          onClick={() => toggle(callbackFn)}
          style={{
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            borderBottomLeftRadius: collapse ? '0' : '5px',
            borderBottomRightRadius: collapse ? '0' : '5px'
          }}>
          <h3 className="my-1 text-light">
            {name}{' '}
            {!collapse ? (
              <FontAwesomeIcon icon={faChevronCircleDown} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faChevronCircleUp} size="xs" />
            )}
          </h3>
        </Button>
      </ButtonGroup>
      <Container
        tag={Collapse}
        isOpen={collapse}
        className="bg-light p-4 border rounded-bottom bg-white">
        <Row>
          <Col>{children}</Col>
        </Row>
      </Container>
    </div>
  );
};

export default AccordionItem;
