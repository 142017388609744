import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Container, Row, ButtonGroup, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { TableColumn } from '../components/masterlist/MasterList';
import { ContactDataWithPhone, ContactPhone } from '../api/contacts';
import { AppState, appHistory } from '../reducers';
import AirportContacts from '../components/contacts/AirportContacts';
import CompanyContacts from '../components/contacts/CompanyContacts';

export const contactSearchColumns: TableColumn[] = [
  { id: 'fullName', title: 'Contact' },
  { id: 'title', title: 'Title' },
  { id: 'address', title: 'Address', width: '200px' },
  { id: 'phone', title: 'Phone', width: '130px' },
  { id: 'fax', title: 'Fax', width: '130px' },
  { id: 'email', title: 'Email' },
  { id: 'url', title: 'Web site' }
];

export interface ContactsSearchResult {
  fullName: string;
  title: string;
  phone: string;
  fax: string;
  address: JSX.Element;
  email: JSX.Element;
  url: JSX.Element;
}

const emptyPhone = (phoneType: string): ContactPhone => ({
  phoneType,
  phone: '',
  extPhone: ''
});

export const workPhone = (contact: ContactDataWithPhone): string => {
  const phone = contact.contactPhoneList.find(p => p.phoneType !== 'Fax') || emptyPhone('Work');
  return `${phone.phone} ${phone.extPhone !== null ? phone.extPhone : ''}`;
};

export const faxPhone = (contact: ContactDataWithPhone): string => {
  const phone = contact.contactPhoneList.find(p => p.phoneType === 'Fax') || emptyPhone('Fax');
  return `${phone.phone} ${phone.extPhone !== null ? phone.extPhone : ''}`;
};

interface ContactProps {
  contact: ContactDataWithPhone;
}

export const Address = ({ contact }: ContactProps) => {
  const fullAddress = `
    ${contact.address1}
    ${contact.address2}
    ${contact.city},
    ${contact.stateProvinceCode || ''}
    ${contact.postalCode}
    ${contact.isoCountryCode}`;
  const mapLink = `https://maps.google.com/?q=${fullAddress}`;
  return (
    <a target="_blank" href={mapLink} rel="noopener noreferrer">
      {`${contact.address1}
        ${contact.address2}`}<br/>
      {`${contact.city},
        ${contact.stateProvinceCode || ''}
        ${contact.postalCode}`}<br/>
      {`${contact.isoCountryCode}`}
    </a>
  );
};
export const Email = ({ contact }: ContactProps) => (
  <a href={`mailto:${contact.email}`}>
    {contact.email}
  </a>
);
export const Url = ({ contact }: ContactProps) => {
  if (contact.url === undefined || contact.url.length === 0) {
    return <div></div>;
  }
  const url = contact.url.startsWith('http') ? contact.url : `http://${contact.url}`;
  return (
    <a href={url} target="_new">
      <FontAwesomeIcon icon={faGlobeAmericas} />
    </a>
  );
};

interface Props {
  axnInstanceId: number;
}

interface RouteProps {
  contactType: ContactType;
}

type ContactType = 'airport' | 'company'

const show = (contactType: ContactType): string =>
  contactType === 'airport'
    ? 'Airport Contacts'
    : 'Company Contacts'

const viewAirports = () => appHistory.push('/contacts/airport')

const viewCompanies = () => appHistory.push('/contacts/company')

const ContactsView = ({axnInstanceId, match}: Props & RouteComponentProps<RouteProps>) => {
  const contactType = match.params.contactType || 'airport';
  const Contacts = contactType === 'airport'
    ? AirportContacts
    : CompanyContacts
  return (
    <Container>
      <Row className="py-3">
        <Col
          lg={2}>
          <h3 className="text-center py-4">Contacts</h3>
        </Col>
        <Col
          className={'py-3'}
          lg={10}>
          <ButtonGroup>
            <Button
              color={'primary'}
              active={contactType === 'airport'}
              onClick={viewAirports}
              value={'airports'}>
                Airports
            </Button>
            <Button
              color={'primary'}
              value={'companies'}
              onClick={viewCompanies}
              active={contactType === 'company'}>
                Companies
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="py-3">
            Viewing {show(contactType)}
          </h3>
          <Contacts axnInstanceId={axnInstanceId} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  axnInstanceId: state.users.authenticatedUser.axnInstanceId
});

const routed = withRouter(ContactsView)

export default connect(mapStateToProps, null)(routed);
