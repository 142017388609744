import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField from './FormField';
import { useAirportTenantStatuses } from '../../api/Airports';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

/* TODO: Fix the any */
const ExpireOptionSelectField = (props: {
  values: any;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: any;
  handleBlur: any;
  col?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}) => {
  const { values, match, handleChange, handleBlur, setFieldValue, col } = props;
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();
  const { tenantStatus, tenantStatusLoading } = useAirportTenantStatuses();
  const [labelValue, setLabelValue] = useState();
  useEffect(() => {
    setLabelValue(values.tenantStatus);
  }, [values.tenantStatus]);

  const [value, setValue] = useState();
  useEffect(() => {
    setValue(values.tenantStatusDes);
  }, [values.tenantStatusDes]);

  useEffect(() => {
    const acList = tenantStatus.map(tenant => ({
      label: tenant.tenantStatusDes,
      key: tenant.tenantStatus,
      value: tenant.tenantStatus
    }));
    setAutoCompleteList(acList);
  }, [tenantStatus]);

  if (tenantStatusLoading) return <SelectFieldSpinner {...col} />;
  return (
    <FormField
      name="tenantStatus"
      labelName="tenantStatusDes"
      label="Tenant Status"
      type="autocomplete"
      match={match}
      value={value}
      labelValue={labelValue}
      handleChange={handleChange}
      handleBlur={handleBlur}
      autoCompleteList={autoCompleteList}
      col={col || {}}
      setFieldValue={setFieldValue}
      browserAutoComplete={'off'}
      tooltipText={`Selecting an option other than "ACTIVE" will disable the "Expires" date field.`}
    />
  );
};

ExpireOptionSelectField.defaultValues = {
  col: { xl: 12 }
};

export default ExpireOptionSelectField;
