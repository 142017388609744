import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import {
  addExpansionFromAPI,
  getAirportInstanceFromAPI,
  updateAirportInstanceFromAPI,
  updateExpansionFromAPI
} from '../api/Airports';
import { AirportData, AirportExpansion, AirportInstance, AirportsList } from '../types';

export type AirportsAction =
  | { type: 'GET_AIRPORTS' }
  | { type: 'GET_AIRPORTS_SUCCESS'; airports: AirportsList }
  | { type: 'GET_AIRPORTS_FAILURE'; error: string }
  | { type: 'GET_AIRPORT' }
  | { type: 'GET_AIRPORT_SUCCESS'; airportInstance: AirportInstance }
  | { type: 'GET_AIRPORT_FAILURE'; error: string }
  | { type: 'DELETE_AIRPORT'; airportID: number }
  | { type: 'DELETE_AIRPORT_SUCCESS'; airportID: number }
  | { type: 'DELETE_AIRPORT_FAILURE'; error: string }
  | { type: 'UPDATE_ADD_AIRPORT'; airportInstance: AirportInstance }
  | { type: 'UPDATE_ADD_AIRPORT_SUCCESS'; airportInstance: AirportInstance }
  | { type: 'UPDATE_ADD_AIRPORT_FAILURE'; error: string }
  | { type: 'ADD_AIRPORT_EXPANSION' }
  | { type: 'ADD_AIRPORT_EXPANSION_SUCCESS'; expansionPlan: Partial<AirportExpansion> }
  | { type: 'ADD_AIRPORT_EXPANSION_FAILURE'; error: string }
  | { type: 'UPDATE_AIRPORT_EXPANSION' }
  | { type: 'UPDATE_AIRPORT_EXPANSION_SUCCESS'; expansionPlan: Partial<AirportExpansion> }
  | { type: 'UPDATE_AIRPORT_EXPANSION_FAILURE'; error: string }
  | { type: 'UPDATE_AIRPORT_INFO' }
  | { type: 'UPDATE_AIRPORT_INFO_SUCCESS'; airportInfo: AirportInstance }
  | { type: 'UPDATE_AIRPORT_INFO_FAILURE'; error: string };

export type airportsActionsCreator = (...props: any) => AirportsAction;

/* AIRPORT ACTION CREATORS */
/* Get All Airports */
export const getAirports: airportsActionsCreator = () => {
  return {
    type: 'GET_AIRPORTS'
  };
};
export const getAirportsSuccess: airportsActionsCreator = (airports: AirportsList) => ({
  type: 'GET_AIRPORTS_SUCCESS',
  airports
});

export const getAirportsFailure: airportsActionsCreator = (error: string) => ({
  type: 'GET_AIRPORTS_FAILURE',
  error
});

/* Get Singular Airport */
export const getAirportRequest: airportsActionsCreator = () => ({
  type: 'GET_AIRPORT'
});

export const getAirportSuccess: airportsActionsCreator = (airportInstance: AirportInstance) => ({
  type: 'GET_AIRPORT_SUCCESS',
  airportInstance
});
export const getAirportFailure: airportsActionsCreator = (error: string) => ({
  type: 'GET_AIRPORT_FAILURE',
  error
});

export type GetAirport = (airportID: number, axnInstanceId: number) => void;

export const getAirport = (dispatch: Dispatch) => {
  return async (airportID: number, axnInstanceId: number) => {
    dispatch(getAirportRequest());
    try {
      const response = await getAirportInstanceFromAPI(airportID, axnInstanceId);
      dispatch(getAirportSuccess(response.data));
    } catch (e) {
      dispatch(getAirportFailure(e));
    }
  };
};

/* Delete Airport */
export const deleteAirport: airportsActionsCreator = (airportID: number) => ({
  type: 'DELETE_AIRPORT',
  airportID
});
export const deleteAirportSuccess: airportsActionsCreator = (airportID: number) => ({
  type: 'DELETE_AIRPORT_SUCCESS',
  airportID
});
export const deleteAirportFailure: airportsActionsCreator = (error: string) => ({
  type: 'DELETE_AIRPORT_FAILURE',
  error
});

/* Update Airport */
export const updateAddAirport: airportsActionsCreator = (airportInstance: AirportInstance) => ({
  type: 'UPDATE_ADD_AIRPORT',
  airportInstance
});
export const updateAddAirportSuccess: airportsActionsCreator = (
  airportInstance: AirportInstance
) => ({
  type: 'UPDATE_ADD_AIRPORT_SUCCESS',
  airportInstance
});
export const updateAddAirportFailure: airportsActionsCreator = (error: string) => ({
  type: 'UPDATE_ADD_AIRPORT_FAILURE',
  error
});

/* Expansion Plan */
/* Add Expansion Plan */
export const addExpansionPlan: airportsActionsCreator = () => ({
  type: 'ADD_AIRPORT_EXPANSION'
});
export const addExpansionPlanSuccess: airportsActionsCreator = (
  expansionPlan: Partial<AirportExpansion>
) => ({
  type: 'ADD_AIRPORT_EXPANSION_SUCCESS',
  expansionPlan
});
export const addExpansionPlanFailure: airportsActionsCreator = (error: string) => ({
  type: 'ADD_AIRPORT_EXPANSION_FAILURE',
  error
});
export type InitAddExpansionPlan = (
  airportId: number,
  instanceID: number,
  expansionPlan: Partial<AirportExpansion>
) => void;
export const initAddExpansionPlan = (dispatch: Dispatch) => (
  airportId: number,
  instanceID: number,
  expansionPlan: Partial<AirportExpansion>
) => {
  dispatch(addExpansionPlan());
  addExpansionFromAPI(airportId, instanceID, expansionPlan)
    .then(() => {
      dispatch(addExpansionPlanSuccess(expansionPlan));
      toast.success('Expansion Plan Added Successfully!');
    })
    .catch(error => {
      dispatch(addExpansionPlanFailure(error));
      toast.error('Expansion Plan Failed to Save!');
    });
};

/* Update Expansion Plan */
export const updateExpansionPlan: airportsActionsCreator = () => ({
  type: 'UPDATE_AIRPORT_EXPANSION'
});
export const updateExpansionPlanSuccess: airportsActionsCreator = (
  expansionPlan: Partial<AirportExpansion>
) => ({
  type: 'UPDATE_AIRPORT_EXPANSION_SUCCESS',
  expansionPlan
});
export const updateExpansionPlanFailure: airportsActionsCreator = (error: string) => ({
  type: 'UPDATE_AIRPORT_EXPANSION_FAILURE',
  error
});
export type InitUpdateExpansionPlan = (
  airportId: number,
  instanceID: number,
  expansionId: number,
  expansionPlan: Partial<AirportExpansion>
) => void;
export const initUpdateExpansionPlan = (dispatch: Dispatch) => (
  airportId: number,
  instanceID: number,
  expansionId: number,
  expansionPlan: Partial<AirportExpansion>
) => {
  dispatch(updateExpansionPlan());
  updateExpansionFromAPI(airportId, instanceID, expansionId, expansionPlan)
    .then(() => {
      dispatch(updateExpansionPlanSuccess(expansionPlan));
      toast.success('Expansion Plan Saved Successfully!');
    })
    .catch(error => {
      toast.error('Expansion Plan Failed to Save!');
      dispatch(updateExpansionPlanFailure(error));
    });
};

/* Update All Airport Information */
export const updateAirportInfo: airportsActionsCreator = () => ({
  type: 'UPDATE_AIRPORT_INFO'
});
export const updateAirportInfoSuccess: airportsActionsCreator = (airportInfo: AirportInstance) => ({
  type: 'UPDATE_AIRPORT_INFO_SUCCESS',
  airportInfo
});
export const updateAirportInfoFailure: airportsActionsCreator = (error: string) => ({
  type: 'UPDATE_AIRPORT_INFO_FAILURE',
  error
});
export type InitUpdateAirportInfo = (
  airportID: number,
  instanceID: number,
  putValues: AirportInstance,
  withToast: boolean
) => void;
export const initUpdateAirportInfo = (dispatch: Dispatch) => (
  airportID: number,
  instanceID: number,
  putValues: AirportInstance,
  withToast: boolean
) => {
  dispatch(updateAirportInfo());
  updateAirportInstanceFromAPI(airportID, instanceID, putValues)
    .then(() => {
      dispatch(updateAirportInfoSuccess(putValues));
      if (withToast) toast.success('Airport Information Saved Successfully!');
    })
    .catch((error: string) => {
      if (withToast) toast.error('Airport Information Failed to Save!');
      dispatch(updateAirportInfoFailure(error));
    });
};
