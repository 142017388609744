import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField, { ChangeHandler } from './FormField';
import { useAirlines } from '../../api/Airlines';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

const DominantAirlinesSelectField = (props: {
  values: any;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: ChangeHandler;
  handleBlur: any;
}) => {
  const { values, match, handleChange, handleBlur, setFieldValue } = props;
  const { availableAirlines, loadingAvailableAirlines } = useAirlines();
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();

  const [labelValue, setLabelValue] = useState(values.airlineName);
  const [value] = useState(values.airlineId);

  useEffect(() => {
    const aclist = availableAirlines
      .map(airline => ({
        ...airline,
        label: airline.airlineName,
        key: airline.airlineId,
        value: airline.airlineId
      }))
      .sort((a, b) => (a.airlineName > b.airlineName ? 1 : -1));

    setAutoCompleteList(aclist);
  }, [availableAirlines]);

  useEffect(() => {
    const airline = availableAirlines.find(a => a.airlineId === values.airlineId);

    if (airline) {
      setLabelValue(airline.airlineName);
      setFieldValue('airlineName', airline.airlineName, false);
    }
  }, [availableAirlines, setFieldValue, values.airlineId, values.airlineName]);

  if (loadingAvailableAirlines) return <SelectFieldSpinner />;
  return (
    <FormField
      name="airlineId"
      labelName="airlineName"
      label="Available Airlines"
      type="autocomplete"
      match={match}
      value={value}
      labelValue={labelValue}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      handleBlur={handleBlur}
      autoCompleteList={autoCompleteList}
      col={{ lg: 12 }}
    />
  );
};

export default DominantAirlinesSelectField;
