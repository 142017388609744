import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { useContacts } from '../../api/contacts';
import { CompanyInfo } from './CompanyInfo';
import { MediumAd } from '../advertisement/medium';
import StepHeader from '../wizard/StepHeader';
import { AppState } from '../../reducers';
import CompanyLocationsList from '../wizard/company/steps/location/CompanyLocationsList';
import { useCompanyLocations } from '../../api/Companies';
import CompanyContactsList from '../wizard/company/steps/contacts/CompanyContactList';

interface CompanyViewProps {
  contentID: string;
}

const CompanyView = ({
  match,
  axnInstanceId
}: { axnInstanceId: number } & RouteComponentProps<CompanyViewProps>) => {
  const { contentID } = match.params;
  const { data } = useContacts(+contentID, axnInstanceId);
  const contacts = data.content;

  return (
    <Container>
      <Row className="m-1">
        <Col className="col bg-light border rounded p-4 mb-4">
          <CompanyInfo contactID={contentID} />
        </Col>
        {/* Company Advertisements */}
        {(contentID == '703' || contentID == '925') /* Be Relax */ && (
          <Col className="col-medium-ad mb-4 mr-0 pr-0">
            <MediumAd
              imageSrc="https://www.airportxnews.com/wp-content/uploads/2023/11/Online-AD-AX-2024-Be-Relax.jpg"
              targetUrl="https://berelax.com"
            />
          </Col>
        )}
        {contentID == '30' /* ICF */ && (
          <Col className="col-medium-ad mb-4 mr-0 pr-0">
            <MediumAd
              imageSrc="https://www.airportxnews.com/wp-content/uploads/2023/11/ea-aviation-digtial-banner-icf-600px-1.jpg"
              targetUrl="https://www.icf.com/sustainableaviation"
            />
          </Col>
        )}
        {(contentID == '33' ||
          contentID == '184' ||
          contentID == '679') /* Parides Lagardere */ && (
          <Col className="col-medium-ad mb-4 mr-0 pr-0">
            <MediumAd
              imageSrc="https://www.airportxnews.com/wp-content/uploads/2023/11/AXN-Factbook-Paradies-Lagardere-300x60077-1.jpg"
              targetUrl="https://www.paradieslagardere.com/"
            />
          </Col>
        )}
        {/*
        {contentID != '33' && contentID != '44' && (
          <Col className="col-medium-ad">
            <MediumAd
              imageSrc="https://via.placeholder.com/300x250"
              targetUrl="https://www.google.com"
            />
          </Col>
        )}
        */}
      </Row>
      <Row className="m-1">
        <Col className="bg-light border rounded p-4 mb-4">
          <StepHeader Tag="h1" stepTitle="Company Contacts" />
          <CompanyContactsList
            contacts={contacts as any}
            contentID={`${contentID}`}
            contentType={'companies'}
            instanceId={axnInstanceId}
            readonly
          />
        </Col>
      </Row>
      <Row className="m-1">
        <Col className="bg-light border rounded p-4 mb-4">
          {/* TODO extend to support both individual types and entire list. */}
          <CompanyLocationsList
            contentID={parseInt(contentID, 10)}
            axnInstanceId={axnInstanceId}
            useLocations={useCompanyLocations}
            companyType={'ALL'}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  axnInstanceId: state.users.authenticatedUser.axnInstanceId
});

export default connect(mapStateToProps, null)(CompanyView);
