import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import StepHeader from '../../../StepHeader';
import FormField from '../../../../forms/FormField';
import { WizardStepperMatch } from '../../../types';

interface ParkingRatesProps {
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  initVals: any;
  values: any;
  match: WizardStepperMatch;
}

const ParkingRates = (props: ParkingRatesProps) => {
  const { handleBlur, handleChange, values, match } = props;
  return (
    <div>
      <StepHeader stepTitle={'Airport Parking'} />
      <FormGroup>
        <Row>
          <Col xs={2}>
            <h4 className={'wizard-title'}>Parking</h4>
          </Col>
          <FormField
            name={'numOfSpaces'}
            label={'# of Spaces'}
            type={'number'}
            match={match}
            value={values.numOfSpaces}
            handleChange={handleChange}
            handleBlur={handleBlur}
            col={{}}
            readOnly
            disabled
          />
          <FormField
            name={'parkingRevenueTotal'}
            label={'Gross Revenue'}
            type={'number'}
            match={match}
            value={values.parkingRevenueTotal}
            handleChange={handleChange}
            handleBlur={handleBlur}
            col={{}}
          />
          <FormField
            name={'parkingRevenueAirportTotal'}
            label={'Gross Parking'}
            type={'number'}
            match={match}
            value={values.parkingRevenueAirportTotal}
            handleChange={handleChange}
            handleBlur={handleBlur}
            col={{}}
          />
        </Row>
      </FormGroup>
    </div>
  );
};

export default ParkingRates;
