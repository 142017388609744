import React, { useCallback, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { get } from 'lodash';
import { Button, ButtonGroup, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBuilding,
  faEdit,
  faEnvelope,
  faFax,
  faGlobeAmericas,
  faHeading,
  faHome,
  faPhone,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { PhoneNumber } from '../../../types';
import { appHistory } from '../../../reducers';
import { ContactData, DeleteContact } from '../../../actions/contactsActions';
import refocusWizard from '../../../core/scroll/refocusWizard';
import Confirm from '../../admin/users/Confirm';
import { deleteUserByIDFromAPI } from '../../../api/Users';
import { toast } from 'react-toastify';

export interface ContactProps {
  axnInstanceId: number;
  contentID: string;
  editUrl: string;
  contentType: string;
  managementResponsibilityTypeId: number;
  contacts: ContactData[];
  contact: {
    contactId: number;
    firstName: string;
    lastName: string;
    companyName: string;
    title: string;
    address1: string;
    address2: string;
    city: string;
    stateProvinceCode: string;
    isoCountryCode: string;
    email: string;
    postalCode: string; // this should be a number but the api returns a string.
    modifiedDate: string;
    modifiedUserId: number;
    url: string;
    axnStatus: string; // I believe this could be a boolean.
    contactPhoneList: PhoneNumber[];
  };
  companyType?: string;
  handleDelete?: DeleteContact;
  readonly?: boolean;
}

const ContactItem = (props: RouteComponentProps<{}> & ContactProps) => {
  const { contact, axnInstanceId, contentType, location } = props;
  const {
    address1,
    address2,
    city,
    contactId,
    contactPhoneList,
    email,
    firstName,
    lastName,
    postalCode,
    stateProvinceCode,
    title,
    companyName,
    url
  } = contact;

  const {
    contentID,
    editUrl,
    managementResponsibilityTypeId,
    handleDelete,
    readonly,
    companyType
  } = props;
  const leftColSize = 4;
  const rightColSize = 8;
  const [reload, setReload] = useState(false);
  const [selectedContact, setSelectedContact] = useState<number>();

  const deleteContact = useCallback(() => {
    if (selectedContact) {
      if (handleDelete)
        handleDelete(
          contentID,
          selectedContact.toString(),
          managementResponsibilityTypeId,
          `${firstName} ${lastName}`,
          companyType || '',
          axnInstanceId
        );

      appHistory.push(`${editUrl}`);
      setSelectedContact(undefined);
    }
  }, [selectedContact, reload]);

  return (
    <Card className="my-3 border border-dark">
      <CardTitle className="bg-primary text-light text-center py-2">{`${firstName} ${lastName}`}</CardTitle>
      <CardBody>
        {title && (
          <Row className="mb-3">
            <Col sm={leftColSize} className="">
              <FontAwesomeIcon icon={faHeading} /> Title
            </Col>
            <Col sm={rightColSize}>{title}</Col>
          </Row>
        )}
        {companyName && (
            <Row className="mb-3">
                <Col sm={leftColSize} className="">
                    <FontAwesomeIcon icon={faBuilding} /> Company
                </Col>
                <Col sm={rightColSize}>{companyName}</Col>
            </Row>
        )}
        {address1 && (
          <Row className="mb-3">
            <Col sm={leftColSize} className="">
              <FontAwesomeIcon icon={faHome} /> Address
            </Col>
            <Col sm={rightColSize}>{address1}</Col>
          </Row>
        )}
        {address2 && (
          <Row className="mb-3">
            {!address1 ? (
              <Col sm={leftColSize} className="">
                <FontAwesomeIcon icon={faHome} /> Address
              </Col>
            ) : (
              <Col sm={leftColSize} />
            )}
            <Col sm={rightColSize}>{address2}</Col>
          </Row>
        )}
        {(city || stateProvinceCode || postalCode) && (
          <Row className="mb-3">
            {!address1 && !address2 ? (
              <Col sm={leftColSize} className="">
                <FontAwesomeIcon icon={faHome} /> Address
              </Col>
            ) : (
              <Col sm={leftColSize} />
            )}
            <Col sm={rightColSize}>
              {city}
              {stateProvinceCode || postalCode ? ', ' : ' '}
              {stateProvinceCode ? `${stateProvinceCode} ` : ''}
              {postalCode}
            </Col>
          </Row>
        )}
        {contactPhoneList &&
          contactPhoneList
            .filter(value => value.phone)
            .map((phoneNumber: PhoneNumber) => {
              const { extPhone, phone, phoneType } = phoneNumber;
              return (
                <Row
                  className="mb-3"
                  key={window.btoa(`${phoneNumber.contactID}${extPhone}${phone}${phoneType}`)}>
                  <Col sm={leftColSize} className="">
                    <FontAwesomeIcon icon={phoneType === 'Fax' ? faFax : faPhone} /> {phoneType}
                  </Col>
                  <Col sm={rightColSize}>
                    {phone} {extPhone && extPhone !== 'null' && `x ${extPhone}`}
                  </Col>
                </Row>
              );
            })}
        {email && (
          <Row className="mb-3">
            <Col sm={leftColSize} className="">
              <FontAwesomeIcon icon={faEnvelope} /> Email
            </Col>
            <Col sm={rightColSize}>{email}</Col>
          </Row>
        )}
        {url && (
          <Row className="mb-3">
            <Col sm={leftColSize} className="">
              <FontAwesomeIcon icon={faGlobeAmericas} /> Website
            </Col>
            <Col sm={rightColSize}>{url}</Col>
          </Row>
        )}
        {!readonly && (
          <Row>
            <Col>
              <ButtonGroup>
                <Button color="primary" onClick={() => setSelectedContact(contactId)}>
                  Delete <FontAwesomeIcon icon={faTrash} />
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    const urlRegex = /(?<pathWithoutContact>\/wizard\/(?<contentType>companies|airports)\/(?<contentId>[0-9]*)\/steps\/contacts)/;

                    let groups: any = {
                      pathWithoutContact: '',
                      contentType: '',
                      contentId: ''
                    };

                    if (location.pathname.match(urlRegex))
                      groups = get(location.pathname.match(urlRegex), 'groups', {});

                    const baseUrl = `${groups.pathWithoutContact}/${contactId}`;
                    const actualUrl =
                      contentType === 'companies' ? `${baseUrl}/${companyType}` : baseUrl;

                    appHistory.push(`${actualUrl}`);
                    refocusWizard();
                  }}>
                  Edit <FontAwesomeIcon icon={faEdit} />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        )}
      </CardBody>
      <Confirm
        isOpen={selectedContact !== undefined}
        cancel={_event => setSelectedContact(undefined)}
        success={deleteContact}
        successTitle="Delete">
        <p>Are you sure you wish to delete this user?</p>
      </Confirm>
    </Card>
  );
};

export default withRouter(ContactItem);
