import React from 'react';
import { Formik } from 'formik';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faSave } from '@fortawesome/free-solid-svg-icons';
import { terminalsTotalSchema } from './yupSchemaTerminalsTotal';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import FormField from '../../../../forms/FormField';
import {
  AirportCombinedTotals,
  AirportCommentType,
  AirportInstance,
  AirportComment
} from '../../../../../types';
import { AirportTotalColFields, TerminalMatchProps, tooltipMsgs } from './TerminalSpecificForm';
import {
  updateAirportCommentsFromAPI,
  updateAirportInstanceFromAPI
} from '../../../../../api/Airports';
import { AuthenticatedUser } from '../../../../../actions/usersActions';
import { extractAirportComment } from '../../../../../core/comments/AirportComments';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';
import { restrictInputToAnyInteger } from '../../../../forms/EventUtils';

/* TODO: replace any with TerminalTotal Shape when implemented */
export const TerminalTotalsForm = (props: {
  airport: AirportInstance;
  match: TerminalMatchProps;
  totals: AirportCombinedTotals;
  authenticatedUser: AuthenticatedUser;
  activeInstance: ActiveInstance;
}) => {
  const { match, airport, totals, authenticatedUser, activeInstance } = props;
  const { contentID } = match.params;
  const initVals = {
    ...airport,
    ...totals,
    passengerTotal: get(airport, 'passengerTotal', 0),
    deplaneTotal: get(airport, 'deplaneTotal', 0),
    enplaneTotal: get(airport, 'enplaneTotal', 0),
    passengerEpDomesticTotal: get(airport, 'passengerEpDomesticTotal', 0),
    passengerEpIntlTotal: get(airport, 'passengerEpIntlTotal', 0),
    acdbePercentageAmount: get(airport, 'acdbePercentageAmount', 0),
    annualPercentageChangeAmount: get(airport, 'annualPercentageChangeAmount', 0),
    passengerTrafficComment: extractAirportComment(airport, 'PASSTRAFFIC'),
    grossSalesComment: extractAirportComment(airport, 'GROSSSALES')
  };

  const { userId } = authenticatedUser;

  return (
    <Formik
      validationSchema={terminalsTotalSchema}
      enableReinitialize
      onSubmit={values => {
        const ptComment = ({
          airportComment: values.passengerTrafficComment,
          modifiedUserId: userId,
          airportCommentPK: {
            airportId: +contentID,
            axnInstanceId: activeInstance.id,
            commentType: 'PASSTRAFFIC' as AirportCommentType // TODO: Move this to a const object/enum?
          }
        } as unknown) as AirportComment;
        const grossSalesComment = ({
          airportComment: values.grossSalesComment,
          modifiedUserId: userId,
          airportCommentPK: {
            airportId: +contentID,
            axnInstanceId: activeInstance.id,
            commentType: 'GROSSSALES' as AirportCommentType // TODO: Move this to a const object/enum?
          }
        } as unknown) as AirportComment;
        const airportValues = {
          ...values, // not sure if I need to send all these values or not.
          airportInstancePK: {
            airportId: values.airportId,
            axnInstanceId: activeInstance.id
          },
          acdbePercentageAmount: +values.acdbePercentageAmount
        };

        if (+contentID !== -1) {
          Promise.all([
            updateAirportCommentsFromAPI(+contentID, ptComment, activeInstance.id),
            updateAirportCommentsFromAPI(+contentID, grossSalesComment, activeInstance.id),
            updateAirportInstanceFromAPI(+contentID, activeInstance.id, airportValues)
          ]).then(
            () => toast.success(`Airport Terminal Totals Successfully Updated!`),
            error => {
              const errorMsg = get(
                error.response,
                'data.message',
                'Airport Terminal Totals Failed to Update!'
              );
              toast.error(errorMsg);
            }
          );
        } else {
          toast.error('Airport Terminal Totals Failed to Update!');
        }
      }}
      initialValues={initVals}
      render={({ values, handleBlur, handleChange, handleSubmit, handleReset }) => {
        return (
          <form className={cssClass} onSubmit={handleSubmit} onReset={handleReset}>
            <StepHeader stepTitle="Airport Totals" />
            <h3 className="wizard-instruction">5.) Airport Passenger Traffic Totals:</h3>
            <FormGroup>
              <Row>
                <Col xl>
                  <Row>
                    <FormField
                      name="airport.iataCode"
                      label="IATA"
                      type="text"
                      value={values.airport.iataCode}
                      col={{}}
                      match={match}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      disabled
                      readOnly
                    />
                    <FormField
                      name="passengerTotal"
                      label="Passenger Traffic"
                      type="number"
                      value={values.passengerTotal}
                      col={{}}
                      match={match}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      disabled
                      readOnly
                    />
                    {/* TODO: connect to airport totals annualPercentChangeAmount once created */}
                    <FormField
                      name="annualPercentageChangeAmount"
                      label="+/-%"
                      type="text"
                      value={values.annualPercentageChangeAmount || 0}
                      col={{}}
                      match={match}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      keyDownHandler={restrictInputToAnyInteger}
                      tooltipText={
                        'A comparison of numbers from the current Fact Book year to the previous. Currently 2016 numbers compared to 2015. All symbols should be typed included. i.g. +2.4 % or -3.1 % .'
                      }
                    />
                  </Row>
                  <Row>
                    <FormField
                      name="deplaneTotal"
                      label="Deplaning"
                      type="number"
                      value={values.deplaneTotal || 0}
                      col={{}}
                      match={match}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      disabled
                      readOnly
                      tooltipText={
                        'The number of people disembarking an airline and arriving through the airport to baggage claim or ground transportation.'
                      }
                    />
                    <FormField
                      name="enplaneTotal"
                      label="Enplaning"
                      type="number"
                      value={values.enplaneTotal || 0}
                      col={{}}
                      match={match}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      disabled
                      readOnly
                      tooltipText={
                        'Passengers departing the airport on airlines leaving the airport to their predetermined destinations.'
                      }
                    />
                    <FormField
                      name="passengerEpDomesticTotal"
                      label="EP Domestic"
                      type="number"
                      value={values.passengerEpDomesticTotal || 0}
                      col={{}}
                      match={match}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      disabled
                      readOnly
                    />
                    <FormField
                      name="passengerEpIntlTotal"
                      label={`EP Int'l`}
                      type="number"
                      value={values.passengerEpIntlTotal || 0}
                      col={{}}
                      match={match}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      disabled
                      readOnly
                    />
                  </Row>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <FormField
                  name="passengerTrafficComment"
                  label="Passenger Traffic Comment"
                  type="textarea"
                  value={values.passengerTrafficComment || ''}
                  col={{ xs: 12 }}
                  match={match}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Row>
            </FormGroup>
            <h3 className="wizard-instruction">6.) Airport Totals from all Terminals Combined:</h3>
            {/* TODO: do a loop similar to the terminals fields. */}
            {/* Food & Beverage */}
            <FormGroup>
              <Row>
                <Col>
                  <h4 className="wizard-title">Food & Beverage</h4>
                </Col>
              </Row>
              <Row>
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.foodbeverageAreaTotal}
                  name={'foodbeverageAreaTotal'}
                  col={{}}
                  label={AirportTotalColFields.areaTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.foodbeverageGrossSalesTotal}
                  name={'foodbeverageGrossSalesTotal'}
                  col={{}}
                  label={AirportTotalColFields.grossSalesTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.foodbeverageSalesEp || 0).toFixed(2)}
                  name={'foodbeverageSalesEp'}
                  col={{}}
                  label={AirportTotalColFields.salesEpRatio}
                  tooltipText={tooltipMsgs.salesEpRatio}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.foodbeverageRentAirportTotal}
                  name={'foodbeverageRentAirportTotal'}
                  col={{}}
                  label={AirportTotalColFields.rentAirportTotal}
                  tooltipText={tooltipMsgs.rentAirportTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.foodbeverageRentEp || 0).toFixed(2)}
                  name={'foodbeverageRentEp'}
                  col={{}}
                  label={'Rent/EP'}
                  readOnly
                  disabled
                />
              </Row>
            </FormGroup>
            {/* Speciality Retail */}
            <FormGroup>
              <Row>
                <Col>
                  <h4 className="wizard-title">Speciality Retail</h4>
                </Col>
              </Row>
              <Row>
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.specialtyAreaTotal}
                  name={'specialtyAreaTotal'}
                  col={{}}
                  label={AirportTotalColFields.areaTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.specialtyGrossSalesTotal}
                  name={'specialtyGrossSalesTotal'}
                  col={{}}
                  label={AirportTotalColFields.grossSalesTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.specialtySalesEp || 0).toFixed(2)}
                  name={'specialtySalesEp'}
                  col={{}}
                  label={AirportTotalColFields.salesEpRatio}
                  tooltipText={tooltipMsgs.salesEpRatio}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.specialtyRentAirportTotal}
                  name={'specialtyRentAirportTotal'}
                  col={{}}
                  label={AirportTotalColFields.rentAirportTotal}
                  tooltipText={tooltipMsgs.rentAirportTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.specialtyRentEp || 0).toFixed(2)}
                  name={'specialtyRentEp'}
                  col={{}}
                  label={'Rent/EP'}
                  readOnly
                  disabled
                />
              </Row>
            </FormGroup>
            {/* News & Gifts */}
            <FormGroup>
              <Row>
                <Col>
                  <h4 className="wizard-title">News & Gifts</h4>
                </Col>
              </Row>
              <Row>
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.newsStandAreaTotal}
                  name={'newsStandAreaTotal'}
                  col={{}}
                  label={AirportTotalColFields.areaTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.newsStandGrossSalesTotal}
                  name={'newsStandGrossSalesTotal'}
                  col={{}}
                  label={AirportTotalColFields.grossSalesTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.newsStandSalesEp || 0).toFixed(2)}
                  name={'newsStandSalesEp'}
                  col={{}}
                  label={AirportTotalColFields.salesEpRatio}
                  tooltipText={tooltipMsgs.salesEpRatio}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.newsStandRentAirportTotal}
                  name={'newsStandRentAirportTotal'}
                  col={{}}
                  label={AirportTotalColFields.rentAirportTotal}
                  tooltipText={tooltipMsgs.rentAirportTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.newsStandRentEp || 0).toFixed(2)}
                  name={'newsStandRentEp'}
                  col={{}}
                  label={'Rent/EP'}
                  readOnly
                  disabled
                />
              </Row>
            </FormGroup>
            {/* Duty Free */}
            <FormGroup>
              <Row>
                <Col>
                  <h4 className="wizard-title">Duty Free</h4>
                </Col>
              </Row>
              <Row>
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.dutyfreeAreaTotal}
                  name={'dutyfreeAreaTotal'}
                  col={{}}
                  label={AirportTotalColFields.areaTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.dutyfreeGrossSalesTotal}
                  name={'dutyfreeGrossSalesTotal'}
                  col={{}}
                  label={AirportTotalColFields.grossSalesTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.dutyfreeSalesEp || 0).toFixed(2)}
                  name={'dutyfreeSalesEp'}
                  col={{}}
                  label={AirportTotalColFields.salesEpRatio}
                  tooltipText={tooltipMsgs.salesEpRatio}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.dutyfreeRentAirportTotal}
                  name={'dutyfreeRentAirportTotal'}
                  col={{}}
                  label={AirportTotalColFields.rentAirportTotal}
                  tooltipText={tooltipMsgs.rentAirportTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.dutyfreeRentEp || 0).toFixed(2)}
                  name={'dutyfreeRentEp'}
                  col={{}}
                  label={'Rent/EP'}
                  readOnly
                  disabled
                />
              </Row>
            </FormGroup>
            {/* Lounges / Suites */}
            <FormGroup>
              <Row>
                <Col>
                  <h4 className="wizard-title">Lounges / Suites</h4>
                </Col>
              </Row>
              <Row>
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.loungessuitesAreaTotal}
                  name={'loungesSuitesAreaTotal'}
                  col={{}}
                  label={AirportTotalColFields.areaTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.loungessuitesGrossSalesTotal}
                  name={'loungesSuitesGrossSalesTotal'}
                  col={{}}
                  label={AirportTotalColFields.grossSalesTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.loungessuitesSalesEp || 0).toFixed(2)}
                  name={'loungesSuitesSalesEp'}
                  col={{}}
                  label={AirportTotalColFields.salesEpRatio}
                  tooltipText={tooltipMsgs.salesEpRatio}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.loungessuitesRentAirportTotal}
                  name={'loungesSuitesRentAirportTotal'}
                  col={{}}
                  label={AirportTotalColFields.rentAirportTotal}
                  tooltipText={tooltipMsgs.rentAirportTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.loungessuitesRentEp || 0).toFixed(2)}
                  name={'loungesSuitesRentEp'}
                  col={{}}
                  label={'Rent/EP'}
                  readOnly
                  disabled
                />
              </Row>
            </FormGroup>
            {/* Passenger Services */}
            <FormGroup>
              <Row>
                <Col>
                  <h4 className="wizard-title">Passenger Services</h4>
                </Col>
              </Row>
              <Row>
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.passengerservicesAreaTotal}
                  name={'passengerServicesAreaTotal'}
                  col={{}}
                  label={AirportTotalColFields.areaTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.passengerservicesGrossSalesTotal}
                  name={'passengerServicesGrossSalesTotal'}
                  col={{}}
                  label={AirportTotalColFields.grossSalesTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.passengerservicesSalesEp || 0).toFixed(2)}
                  name={'passengerServicesSalesEp'}
                  col={{}}
                  label={AirportTotalColFields.salesEpRatio}
                  tooltipText={tooltipMsgs.salesEpRatio}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.passengerservicesRentAirportTotal}
                  name={'passengerServicesRentAirportTotal'}
                  col={{}}
                  label={AirportTotalColFields.rentAirportTotal}
                  tooltipText={tooltipMsgs.rentAirportTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.passengerservicesRentEp || 0).toFixed(2)}
                  name={'passengerServicesRentEp'}
                  col={{}}
                  label={'Rent/EP'}
                  readOnly
                  disabled
                />
              </Row>
            </FormGroup>

            {/* Excluding Duty Free */}
            <FormGroup>
              <Row>
                <Col>
                  <h4 className="wizard-title">Totals Excluding Duty Free</h4>
                </Col>
              </Row>
              <Row>
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.airportTotalAreaExcludeDf}
                  name={'airportTotalAreaExcludeDf'}
                  col={{}}
                  label={AirportTotalColFields.areaTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.airportSalesTotalExcludeDf}
                  name={'airportSalesTotalExcludeDf'}
                  col={{}}
                  label={AirportTotalColFields.grossSalesTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.airportSalesEpExcludeDf || 0).toFixed(2)}
                  name={'airportSalesEpExcludeDf'}
                  col={{}}
                  label={AirportTotalColFields.salesEpRatio}
                  tooltipText={tooltipMsgs.salesEpRatio}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.airportGrossRentalsTotalExcludeDf}
                  name={'airportGrossRentalsTotalExcludeDf'}
                  col={{}}
                  label={AirportTotalColFields.rentAirportTotal}
                  tooltipText={tooltipMsgs.rentAirportTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.airportRentEpExcludeDf || 0).toFixed(2)}
                  name={'airportRentEpExcludeDf'}
                  col={{}}
                  label={'Rent/EP'}
                  readOnly
                  disabled
                />
              </Row>
            </FormGroup>
            {/* Including Duty Free */}
            <FormGroup>
              <Row>
                <Col>
                  <h4 className="wizard-title">Totals Including Duty Free</h4>
                </Col>
              </Row>
              <Row>
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.airportTotalArea}
                  name={'airportTotalArea'}
                  col={{}}
                  label={AirportTotalColFields.areaTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.airportSalesTotal}
                  name={'airportSalesTotal'}
                  col={{}}
                  label={AirportTotalColFields.grossSalesTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.airportSalesEp || 0).toFixed(2)}
                  name={'airportSalesEp'}
                  col={{}}
                  label={AirportTotalColFields.salesEpRatio}
                  tooltipText={tooltipMsgs.salesEpRatio}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={values.airportGrossRentalsTotal}
                  name={'airportGrossRentalsTotal'}
                  col={{}}
                  label={AirportTotalColFields.rentAirportTotal}
                  tooltipText={tooltipMsgs.rentAirportTotal}
                  readOnly
                  disabled
                />
                <FormField
                  match={match}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  type={'number'}
                  value={(values.airportRentEp || 0).toFixed(2)}
                  name={'airportRentEp'}
                  col={{}}
                  label={'Rent/EP'}
                  readOnly
                  disabled
                />
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <FormField
                  name="grossSalesComment"
                  label="Gross Sales Comment"
                  type="textarea"
                  value={values.grossSalesComment}
                  col={{}}
                  match={match}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <FormField
                  name="acdbePercentageAmount"
                  label={`Airport Concessions Disadvantage Business Enterprise Participation Percentage`}
                  type="number"
                  value={values.acdbePercentageAmount}
                  col={{}}
                  match={match}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Row>
            </FormGroup>
            <Row className="pt-5">
              <Col>
                <ButtonGroup>
                  <Button color="primary" type="submit">
                    Save Totals <FontAwesomeIcon icon={faSave} />
                  </Button>
                  <Button color="secondary" type={'reset'}>
                    Reset <FontAwesomeIcon icon={faRedo} />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </form>
        );
      }}
    />
  );
};
