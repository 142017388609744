import { ContactData } from '../actions/contactsActions';
import { companiesAPI, CompanyType } from './Companies';
import { airportsAPI } from './Airports';
import { Page, PageableData, useDataModel, emptyPage, SpringPage, convertSpringPageable, createPage } from './index';
import { axnAPI } from './config';
import { CompanyData } from '../actions/companiesActions';
import { ManagementResponsibility } from '../types';

/* Airport Contacts - CRUD operations */
/* Create - Airport Contact */
export const addAirportContactFromAPI = (aid: string, contact: ContactData, instanceId: number) =>
  axnAPI.post(`/airports/${aid}/instance/${instanceId}/contacts/`, {
    ...contact
  });

/* Read - Airport Contacts (Plural) */
export const getAirportContactsFromAPI = (aid: string, iid: number) =>
  axnAPI.get(`${airportsAPI}/${aid}/instance/${iid}/contacts/all`);

/* Read - Airport Contact (Singular) */
export const getAirportContactFromAPI = (
  airportID: string,
  contactID: string,
  type: string, // strictly for companies
  axnInstanceId: number
) => axnAPI.get(`${airportsAPI}/${airportID}/instance/${axnInstanceId}/contacts/${contactID}`);

/* Update - Airport Contact */
export const updateAirportContactFromAPI = (
  contact: ContactData,
  aid: string,
  cid: string,
  mrt: number,
  companyType: string,
  prevCompanyType: string,
  instanceId: number
) =>
  axnAPI.put(`/airports/${aid}/instance/${instanceId}/contacts/${cid}/responsibility/${mrt}`, {
    ...contact
  });

/* Delete - Airport Contact */
export const deleteAirportContactFromAPI = (cid: string, uid: string, mrt: number, instanceId: number) =>
  axnAPI.delete(`/airports/${cid}/instance/${instanceId}/contacts/${uid}/responsibility/${mrt}`);

/* Company Contacts - CRUD operations */
/* Create - Company Contact */
export const addCompanyContactFromAPI = (
  companyID: string,
  contactValues: ContactData,
  instanceId: number
) =>
  axnAPI.post(
    `${companiesAPI}/${companyID}/instance/${instanceId}/type/${contactValues.companyType}/contacts/`,
    {
      ...contactValues
    }
  );

/* Read - Company Contact (Singular) */
export const getCompanyContactFromAPI = (companyID: string, contactID: string, type: string, instanceID: number) =>
  axnAPI.get(`${companiesAPI}/${companyID}/instance/${instanceID}/type/${type}/contacts/${contactID}`);

/* Read - Company Contacts (Plural) */
export const getCompanyContactsFromAPI = (companyID: string, instanceId: number) =>
  axnAPI.get(`${companiesAPI}/${companyID}/instance/${instanceId}/contacts`);

/* Update - Company Contact */
export const updateCompanyContactFromAPI = (
  contactValues: ContactData,
  companyID: string,
  contactID: string,
  mrt: number, // this can be removed if we change the initUpdateContact method of contactActions.ts
  companyType: string,
  prevCompanyType: string,
  instanceId: number
) =>
  axnAPI.put(`${companiesAPI}/${companyID}/instance/${instanceId}/type/${companyType}/contacts/${contactID}`, {
    ...contactValues,
    prevCompanyType,
  });

/* Delete - Company Contact */
export const deleteCompanyContactFromAPI = (contentID: string, contactID: string,companyType: string, instanceID: number) =>
  axnAPI.delete(`${companiesAPI}/${contentID}/instance/${instanceID}/type/${companyType}/contacts/${contactID}`);

/* Contacts Helper Functions */
export const convertContactPhoneList = (values: ContactData) => {
  return [
    {
      phone: values.faxPhone ? values.faxPhone : '',
      extPhone: values.faxExt ? values.faxExt : '',
      phoneType: 'Fax'
    },
    {
      phone: values.workPhone ? values.workPhone : '',
      extPhone: values.workExt ? values.workExt : '',
      phoneType: 'Work'
    }
  ];
};

export interface ContactPhone {
  extPhone?: string;
  phone: string;
  phoneType: string;
}

export interface ContactDataWithPhone extends ContactData {
  contactPhoneList: ContactPhone[];
}

export interface CompanyContact {
  contact: ContactDataWithPhone
  company: CompanyData
  associatedCompanyType: CompanyType
  orderSequenceNumber: number
  factbookIncludeFlag: string
}

export const useContacts = (contentID: number, instanceId: number): PageableData<ContactDataWithPhone> =>
  useDataModel(
    _ => getCompanyContactsFromAPI(`${contentID}`, instanceId).then(resp => createPage(resp.data)),
    emptyPage<ContactDataWithPhone>()
  );

export interface Airport {
  airportId: number;
  iataCode: string;
  airportName: string;
  city: string;
  websiteUrl: string;
  latitudeText: string;
  longitudeText: string;
  stateProvinceCode: string;
  isoCountryCode: string;
  county: string;
  modifiedDate: string;
  modifiedUserId: number;
  axnStatus: string;
}


export interface AirportContact {
  airport: Airport;
  contact: ContactDataWithPhone;
  managementResponsibilityType: ManagementResponsibility;
}

const fetchAirportContacts = (instanceId: number, keyword: string, managementTypeId: string) => (currentPage: number, pageSize: number): Promise<Page<AirportContact>> => {
  const term = keyword === '' ? 'ALL' : keyword
  const url = `/contacts/instance/${instanceId}/contactType/airport/managementTypeId/${managementTypeId}/search/${term}`
  return axnAPI
    .get(url, {
      params: {
        size: pageSize,
        page: currentPage - 1,
        sort: 'contact_lastName'
      }
    })
    .then(resp => resp.data as SpringPage<AirportContact>)
    .then(convertSpringPageable);
}

export const useAirportContactSearch = (
  args: string,
  keyword: string,
  instanceId: number
): PageableData<AirportContact> =>
  useDataModel(
    fetchAirportContacts(instanceId, keyword, args),
    emptyPage<AirportContact>(),
    args, keyword, instanceId
  )

const fetchCompanyContacts = (instanceId: number, keyword: string) => (currentPage: number, pageSize: number): Promise<Page<CompanyContact>> => {
  const term = keyword === '' ? 'ALL' : keyword
  const url = `/contacts/instance/${instanceId}/contactType/company/search/${term}`
  return axnAPI
    .get(url, {
      params: {
        size: pageSize,
        page: currentPage - 1,
        sort: 'contact_lastName'
      }
    })
    .then(resp => resp.data as SpringPage<CompanyContact>)
    .then(convertSpringPageable);
}

export const useCompanyContactSearch = (
  args: string,
  keyword: string,
  instanceId: number
): PageableData<CompanyContact> =>
  useDataModel(
    fetchCompanyContacts(instanceId, keyword),
    emptyPage<CompanyContact>(),
    args, keyword, instanceId
  )
