import { useEffect, useState } from 'react';
import {toast} from "react-toastify";
import { ConcessionManagementType } from '../types';
import { axnAPI } from "./config";

export const useConcessionManagementTypes = () => {
  const [concessionManagementTypes, setConcessionManagementTypes] = useState(
    new Array<ConcessionManagementType>()
  );
  const [concessionManagementTypesLoading, setConcessionManagementTypesLoading] = useState(false);

  useEffect(() => {
    setConcessionManagementTypesLoading(true);
    axnAPI
      .get(`/concessionmanagementtype`)
      .then(response => {
        setConcessionManagementTypes(response.data);
        setConcessionManagementTypesLoading(false);
      })
      .catch(e => {
        setConcessionManagementTypesLoading(false);
        toast.error(`${e}`);
      });
  }, [concessionManagementTypes.length]);

  return {concessionManagementTypes, concessionManagementTypesLoading};
};
