import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Alert, Col, Row } from 'reactstrap';
import StepHeader from '../../../StepHeader';
import FormField from '../../../../forms/FormField';
import { infoInitialValues } from './InfoStep';
import { AirportInfoChildForm } from './types';

const AirportRatiosForm = (props: AirportInfoChildForm & RouteComponentProps) => {
  const { match, values, handleBlur, handleChange } = props;
  return (
    <Col xl={4} lg={4} md={12} className="mb-3">
      <div>
        <StepHeader stepTitle="Airport Ratios" />
        <div className="container">
          <Row>
            <Alert color={'info'}>
              If any of the ratios listed are not applicable, please enter zero for both ratio
              values.
            </Alert>
            <Col tag="fieldset" className="my-3">
              <legend>Pre/Post Security Percentages</legend>
              <Row className="py-3">
                <FormField
                  name="preSecurityPercentAmount"
                  label="Percentage of Space Pre Security"
                  type="number"
                  placeholder={infoInitialValues.preSecurityPercentAmount.toString()}
                  match={match}
                  value={values.preSecurityPercentAmount}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                  tooltipText={
                    'The percentage of space that is located pre-security. The value of pre-security plus post-security should equal 100, or 0 if the values are not applicable.'
                  }
                />
                <FormField
                  name="postSecurityPercentAmount"
                  label="Percentage of Space Post Security"
                  type="number"
                  placeholder={infoInitialValues.postSecurityPercentAmount.toString()}
                  match={match}
                  value={values.postSecurityPercentAmount}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                  tooltipText={
                    'The percentage of space that is located post-security. The value of pre-security plus post-security should equal 100, or 0 if the values are not applicable.'
                  }
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col tag="fieldset" className="my-3">
              <legend>Business to Leisure Ratio</legend>
              <Row className="py-3">
                <FormField
                  name="businessPercentAmount"
                  label="Percentage of Space Used for Business"
                  type="number"
                  placeholder={infoInitialValues.businessPercentAmount.toString()}
                  match={match}
                  value={values.businessPercentAmount}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                  tooltipText={
                    'The percentage of total enplaning passengers that are traveling on business versus the percentage traveling for leisure/pleasure. The value of Business plus Leisure should equal 100, or 0 if the values are not applicable.'
                  }
                />
                <FormField
                  name="leisurePercentAmount"
                  label="Percentage of Space Used for Leisure"
                  type="number"
                  placeholder={infoInitialValues.leisurePercentAmount.toString()}
                  match={match}
                  value={values.leisurePercentAmount}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                  tooltipText={
                    'The percentage of total enplaning passengers that are traveling on business versus the percentage traveling for leisure/pleasure. The value of Business plus Leisure should equal 100, or 0 if the values are not applicable.'
                  }
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col tag="fieldset" className="my-3">
              <legend>Origination & Destination to Transfer</legend>
              <Row className="py-3">
                <FormField
                  name="odPercentAmount"
                  label="Origination & Destination"
                  type="number"
                  placeholder={infoInitialValues.odPercentAmount.toString()}
                  match={match}
                  value={values.odPercentAmount}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                  tooltipText={
                    'This is percent denoting the percentage of origination passengers, not including transferring from one flight to another in the airport. The value of Origination plus Transferring should equal 100, or 0 if the values are not applicable.'
                  }
                />
                <FormField
                  name="transferPercentAmount"
                  label="Transfer"
                  type="number"
                  placeholder={infoInitialValues.transferPercentAmount.toString()}
                  match={match}
                  value={values.transferPercentAmount}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                  tooltipText={
                    'This is percentage of passengers transferring from one flight to another in the airport, not including origination passengers. The value of Origination plus Transferring should equal 100, or 0 if the values are not applicable.'
                  }
                />
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

export default withRouter(AirportRatiosForm);
