import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { chunk, get } from 'lodash';
import moment from 'moment';
import MasterListPagesize from '../../../../masterlist/MasterListPagesize';
import MasterListPagination from '../../../../masterlist/MasterListPagination';
import AccordionItem from '../../../../accordion/AccordionItem';
import MasterList, { TableColumn } from '../../../../masterlist/MasterList';
import { deleteTenantFromAPI, fetchAirportTenants } from '../../../../../api/tenants';
import {
  MasterListActionButton,
  MasterListItem,
  MasterListType,
  TenantData
} from '../../../../masterlist/types';
import { TenantProductType } from '../../../../../types';
import refocusWizard from '../../../../../core/scroll/refocusWizard';
import { Alert } from 'reactstrap';

import axios from 'axios';
import { axnAPI } from '../../../../../api/config';

interface TenantsAccordionItemWrapperProps {
  axnInstanceId: number;
  contentID: string;
  contentType: string;
  iid: string;
  aid: string;
  tenantType: TenantProductType;
  setLastUpdated: (...props: any) => void;
  readonly?: boolean;
}

const TenantAccordionItemWrapper = ({
  tenantType: { tenantType, tenantTypeDes },
  contentID,
  contentType,
  iid,
  aid,
  setLastUpdated,
  readonly,
  axnInstanceId
}: TenantsAccordionItemWrapperProps) => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [tenants, setTenants] = useState(new Array<TenantData>());

  /* Config for Master List Components */
  const tenantsColumnsMap: TableColumn[] = [
    { id: 'tenantId', title: 'ID' }, // NOTE: this is necessary as it's used to build the edit value.
    { id: 'brandName', title: 'Brand', width: '20%' },
    { id: 'companyName', title: 'Company', width: '20%' },
    { id: 'productTypeDes', title: 'Description', width: '15%' },
    /*{ id: 'locationNumber', title: 'Location' },*/
    { id: 'areaTotal', title: 'SQFT' },
    { id: 'terminalName', title: 'Terminal', width: '15%' },
    { id: 'tenantStatus', title: 'Status' },
    { id: 'leaseExpireDate', title: 'Expires', width: '15%' },
    { id: 'futureLocation', title: 'Future Location' }
  ];

  const rowActions = (
    tenantID: number,
    terminalID: number,
    locationNumber: number,
    companyId: number,
    brandId: number
  ): MasterListActionButton[] => {
    return [
      {
        to: `/wizard/${contentType}/${contentID}/steps/tenants/${tenantID}/${terminalID}/${locationNumber}/${companyId}/${brandId}`, // start at contacts
        icon: faEdit,
        text: 'Edit',
        actionCallback: () => refocusWizard()
      },
      {
        to: `/wizard/${contentType}/${contentID}/steps/tenants/`,
        icon: faTrash,
        text: 'Delete',
        actionCallback: () => {
          refocusWizard();
          deleteTenantFromAPI(+tenantID, axnInstanceId, +contentID, +terminalID, +locationNumber, +companyId, +brandId)
            .then(() => {
              toast.success(`Removed tenant: ${tenantID}-${companyId}-${brandId}`);
              setLastUpdated(moment().unix()); // used to reset tenant
              setTenants([]);
            })
            .catch(error => {
              const errorMsg = get(error.response, 'data.message', 'Failure');
              toast.error(`${errorMsg}: couldn't delete Tenant.`);
            });
        }
      }
    ];
  };

  const additionalRowParams = ['terminalId', 'locationNumber', 'companyId', 'brandId']; // these values are used with the edit action

  useEffect(
    () => { fetchAirportTenants(iid, aid, tenantType, setTenants); }, 
    [tenants.length, aid, iid, tenantType]
  );

  /* Maintain the state of the master list component and pagination. */
  const [tenantPages, setTenantPages] = useState(chunk(tenants, pageSize));
  const [tenantsPage, setTenantsPage] = useState<MasterListItem[]>(tenantPages[currentPage]);

  let mounted = true;
  useEffect(() => {
    if (mounted) {
      setTenantPages(chunk(tenants, pageSize));
      setTenantsPage(tenantPages[currentPage - 1]);
    }

    return () => {
      mounted = false;
    };
  }, [currentPage, tenants.length, pageSize, tenantPages.length]);

  return (
    <>
      {tenants.length > 0 ? (
        <AccordionItem name={tenantTypeDes}>
          <div className="d-flex justify-content-between">
            <MasterListPagesize size={pageSize} setPagesize={setPageSize} />
            <MasterListPagination
              totalItems={tenants.length}
              currentPage={currentPage}
              handlePaginationSelection={setCurrentPage}
              pageSize={pageSize}
            />
          </div>
          <MasterList
            actions={rowActions}
            additionalValues={additionalRowParams}
            columns={tenantsColumnsMap}
            currentPage={currentPage}
            itemCount={tenants.length}
            list={(tenantsPage as MasterListType) || []}
            listType={tenantTypeDes}
            loading={false} // TODO: implement real loading boolean, whenever true a spinner will show up instead of components.
            readonly={readonly}
          />
          <div className="py-3 d-flex justify-content-between">
            <MasterListPagesize size={pageSize} setPagesize={setPageSize} />
            <MasterListPagination
              totalItems={tenants.length}
              currentPage={currentPage}
              handlePaginationSelection={setCurrentPage}
              pageSize={pageSize}
            />
          </div>
        </AccordionItem>
      ) : (
        <Alert color={'warning'}>{`${tenantTypeDes} has no Tenants Available.`}</Alert>
      )}
    </>
  );
};

export default TenantAccordionItemWrapper;
