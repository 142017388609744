import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import moment from 'moment';
import { find, get, isEqual, remove } from 'lodash';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Col, Form, FormGroup, Row } from 'reactstrap';
import { StepDefaultProps, WizardStepperMatch } from '../../../types';
import { AppState } from '../../../../../reducers';
import { airportRentalParkingValidationSchema } from './yupSchema';
import FormField from '../../../../forms/FormField';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import { extractAirportComment } from '../../../../../core/comments/AirportComments';
import { AirportCommentType, AirportInstance } from '../../../../../types';
import {
  createOrUpdateAirportParkingRateFromAPI,
  getParkingRatesFromAPI,
  ParkingRateType,
  ParkingType,
  updateAirportCommentsFromAPI
} from '../../../../../api/Airports';
import ParkingRates from './ParkingRates';
import {
  InitUpdateAirportInfo,
  initUpdateAirportInfo
} from '../../../../../actions/airportActions';
import { AuthenticatedUser } from '../../../../../actions/usersActions';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';

/* TODO: move this to the type file */
export interface ParkingRatePK {
  airportId: number;
  axnInstanceId: number;
  parkingType: string;
  parkingRateType: string;
}

export interface ParkingRate {
  parkingRatePK: ParkingRatePK;
  parkingSpaceQuantity: number;
  parkingRateAmount: number;
  parkingRevenueTotal: number;
  parkingRevenueAirportTotal: number;
  modifiedUserId: number;
  modifiedDate: string;
  parkingRateDes: string;
}

const initialCarRentalValues = {
  carRentalTotal: 0, // (Airport Instance) Total number of cars rented.
  carRentalAreaTotal: 0, // (Airport Instance) Total Area of space consumed. (multiple units of measurement)
  carRentalOnsiteAgenciesTotal: 0, // (Airport Instance) Total number of rental agencies on site of airport.
  carRentalOffsiteAgenciesTotal: 0, // (Airport Instance) Total number of rental agencies off site of airport.
  carRentalOnsiteRevenueTotal: 0, // (Airport Instance) Car rental total gross revenue on site of airport.
  carRentalOffsiteRevenueTotal: 0, // (Airport Instance) Car rental total gross revenue off site of airport.
  carRentalOnsiteRevenueAirportTotal: 0, // (Airport Instance) Car rental airport gross on site of airport.
  carRentalOffsiteRevenueAirportTotal: 0 // (Airport Instance) Car rental airport gross off site of airport.
};

const initialParkingValues = {
  parkingRevenueTotal: 0, // (Airport Instance) Gross revenue for parking.
  parkingRevenueAirportTotal: 0, // (Airport Instance) Airport gross revenue for Parking.
  numOfSpaces: 0, // Total Number of Parking Spaces Combined.
  /* These List Below are what th parking rate values are converted to. */
  parking: {
    spaces: 0,
    revenue: 0,
    parking: 0
  },
  hourly: {
    premium: 0,
    economybasic: 0,
    valet: 0
  },
  daily: {
    premium: 0,
    economybasic: 0,
    valet: 0
  }
};

const initAirportWideComment = {
  airportWideComment: ''
};

const rentalParkingInitialValues = {
  ...initialCarRentalValues,
  ...initialParkingValues,
  ...initAirportWideComment
};

type RentalParkingFormProps = typeof rentalParkingInitialValues;

interface RentalParkingStepProps extends StepDefaultProps, RentalParkingFormProps {
  match: WizardStepperMatch;
  airportInstance: AirportInstance;
  authenticatedUser: AuthenticatedUser;
  dispatch?: Dispatch;
  updateAirportInfo: InitUpdateAirportInfo;
  activeInstance: ActiveInstance;
}

export const findRate = (
  prop: string,
  parkingRates: ParkingRate[],
  contentID: string,
  axnInstanceId: number
) => (parkingType: string, parkingRateType: string, defaultValue: any) => {
  const rateObject = find(parkingRates, {
    parkingRatePK: {
      airportId: +contentID,
      axnInstanceId,
      parkingType,
      parkingRateType
    }
  }) as ParkingRate;

  return get(rateObject, prop, defaultValue);
};

const InfoStep = (props: RentalParkingStepProps) => {
  const { airportInstance, authenticatedUser, match, updateAirportInfo, activeInstance } = props;
  const { id } = activeInstance;
  const { userId } = authenticatedUser;
  const { contentID } = match.params;
  const [parkingRates, setParkingRates] = useState(new Array<ParkingRate>());

  useEffect(() => {
    getParkingRatesFromAPI(+contentID, id)
      .then(response => {
        setParkingRates([...response.data]);
      })
      .catch(error => toast.error(error));
  }, [parkingRates.length, contentID, airportInstance, activeInstance]);

  const updateAirportRateType = (
    values: typeof initVals,
    parkingType: ParkingType,
    parkingRateType: ParkingRateType
  ) => {
    const lowerCaseType = parkingType.toLowerCase();
    const lowerCaseRate = parkingRateType.toLowerCase();
    const quantity = values[`${lowerCaseRate}`][`${lowerCaseType}Spaces`];

    const rateObject = {
      parkingRatePK: {
        airportId: +contentID,
        axnInstanceId: id,
        parkingType,
        parkingRateType
      },
      parkingSpaceQuantity: quantity,
      parkingRateAmount: values[`${lowerCaseRate}`][`${lowerCaseType}`], // get this value from the form.
      parkingRevenueTotal: findRevenueTotal(parkingType, parkingRateType, 0),
      parkingRevenueAirportTotal: findRevenueAirportTotal(parkingType, parkingRateType, 0),
      modifiedUserId: userId,
      modifiedDate: moment().format('YYYY-MM-DD'), // use today.
      parkingRateDes: findRateDes(parkingType, parkingRateType, '')
    };

    const updatedRates = remove(parkingRates, item =>
      isEqual(item.parkingRatePK, rateObject.parkingRatePK)
    );

    setParkingRates([...updatedRates, rateObject]);
    return createOrUpdateAirportParkingRateFromAPI(
      +contentID,
      id,
      parkingType,
      parkingRateType,
      rateObject
    );
  };

  /* Putting these in a const object literal might help with auto complete. */
  const findRateAmount = findRate('parkingRateAmount', parkingRates, contentID, id);
  const findSpaceQuantity = findRate('parkingSpaceQuantity', parkingRates, contentID, id);
  const findRevenueTotal = findRate('parkingRevenueTotal', parkingRates, contentID, id);
  const findRevenueAirportTotal = findRate(
    'parkingRevenueAirportTotal',
    parkingRates,
    contentID,
    id
  );
  const findRateDes = findRate('parkingRateDes', parkingRates, contentID, id);

  const hourly: { [key: string]: any } = {
    premium: findRateAmount('Premium', 'Hourly', 0),
    economybasic: findRateAmount('EconomyBasic', 'Hourly', 0),
    valet: findRateAmount('Valet', 'Hourly', 0),
    premiumSpaces: findSpaceQuantity('Premium', 'Hourly', 0),
    economybasicSpaces: findSpaceQuantity('EconomyBasic', 'Hourly', 0),
    valetSpaces: findSpaceQuantity('Valet', 'Hourly', 0)
  };
  const hourlyTotalSpaces = hourly.premiumSpaces + hourly.economybasicSpaces + hourly.valetSpaces;

  const daily: { [key: string]: any } = {
    premium: findRateAmount('Premium', 'Daily', 0),
    economybasic: findRateAmount('EconomyBasic', 'Daily', 0),
    valet: findRateAmount('Valet', 'Daily', 0),
    premiumSpaces: findSpaceQuantity('Premium', 'Daily', 0),
    economybasicSpaces: findSpaceQuantity('EconomyBasic', 'Daily', 0),
    valetSpaces: findSpaceQuantity('Valet', 'Daily', 0)
  };
  const dailyTotalSpaces = daily.premiumSpaces + daily.economybasicSpaces + daily.valetSpaces;
  /* Find the total number of spaces of all ParkingTypes and ParkingRateTypes combined. */

  const combinedTotalSpaces = hourlyTotalSpaces + dailyTotalSpaces;

  const comment = extractAirportComment(airportInstance, 'AIRPORTWIDE');
  const [updatedComment, setUpdatedComment] = useState();

  const initVals: { [key: string]: any } = {
    ...rentalParkingInitialValues,
    ...airportInstance,
    airportWideComment: updatedComment || comment,
    hourly,
    daily,
    numOfSpaces: combinedTotalSpaces
  };

  const rateAmountText = 'Rate Amount';
  const numOfSpacesText = 'Number of Spaces';

  return (
    <Row>
      <Col>
        <Formik
          initialValues={initVals}
          enableReinitialize
          validationSchema={airportRentalParkingValidationSchema}
          onSubmit={values => {
            /* Send Airport Instance - we have to send the entire instance so values don't get wiped out :( */
            const putValues = {
              airport: airportInstance.airport,
              modifiedUserId: userId,
              airportConfigurationId: values.airportConfigurationId,
              configurationAdditionalInfoText: values.configurationAdditionalInfoText,
              additionalInfoFlag: values.additionalInfoFlag,
              concessionManagementTypeId: values.concessionManagementTypeId,
              dwellTimeAmount: values.dwellTimeAmount,
              /* Passenger Services */
              passengerSvcRevenueTotal: values.passengerSvcRevenueTotal,
              passengerSvcRevenueAirportTotal: values.passengerSvcRevenueAirportTotal,
              /* Advertising */
              advertisingRevenueTotal: values.advertisingRevenueTotal,
              advertisingRevenueAirportTotal: values.advertisingRevenueAirportTotal,
              /* Currency Exchange */
              currencyExchangeRevenueTotal: values.currencyExchangeRevenueTotal,
              currencyExchangeRevenueAirportTotal: values.currencyExchangeRevenueAirportTotal,
              /* Pre/Post Security Percentages */
              preSecurityPercentAmount: values.preSecurityPercentAmount,
              postSecurityPercentAmount: values.postSecurityPercentAmount,
              /* Business/Leisure Percentages */
              businessPercentAmount: values.businessPercentAmount,
              leisurePercentAmount: values.leisurePercentAmount,
              /* Origination & Destination/Transfer Percentages */
              odPercentAmount: values.odPercentAmount,
              transferPercentAmount: values.transferPercentAmount,

              /* Terminal Data Related to Instance, get values from state since they aren't part of this form?  */
              passengerTotal: airportInstance.passengerTotal,
              passengerEpDomesticTotal: airportInstance.passengerEpDomesticTotal,
              passengerEpIntlTotal: airportInstance.passengerEpIntlTotal,
              enplaneTotal: airportInstance.enplaneTotal,
              deplaneTotal: airportInstance.deplaneTotal,
              acdbePercentageAmount: airportInstance.acdbePercentageAmount,

              /* Car Rental and Parking Data, get values from state since they aren't part of this form? */
              parkingRevenueTotal: values.parkingRevenueTotal,
              parkingRevenueAirportTotal: values.parkingRevenueAirportTotal,
              carRentalTotal: values.carRentalTotal,
              carRentalAreaTotal: values.carRentalAreaTotal,
              carRentalOnsiteAgenciesTotal: values.carRentalOnsiteAgenciesTotal,
              carRentalOnsiteRevenueTotal: values.carRentalOnsiteRevenueTotal,
              carRentalOnsiteRevenueAirportTotal: values.carRentalOnsiteRevenueAirportTotal,
              carRentalOffsiteAgenciesTotal: values.carRentalOffsiteAgenciesTotal,
              carRentalOffsiteRevenueTotal: values.carRentalOffsiteRevenueTotal,
              carRentalOffsiteRevenueAirportTotal: values.carRentalOffsiteRevenueAirportTotal,
              airportWideComment: values.airportWideComment,
              axnInstanceStatus: airportInstance.axnInstanceStatus,
              annualPercentageChangeAmount: airportInstance.annualPercentageChangeAmount
            };

            /* Send Parking Rates */
            const airportWideComment = {
              airportComment: values.airportWideComment,
              modifiedUserId: userId,
              airportCommentPK: {
                airportId: +contentID,
                axnInstanceId: id,
                commentType: 'AIRPORTWIDE' as AirportCommentType // TODO: Move this to a const object/enum?
              }
            };
            Promise.all([
              updateAirportInfo(values.airport.airportId, id, putValues, false),
              /* Send Parking Rates */
              updateAirportRateType(values, 'Valet', 'Hourly'),
              updateAirportRateType(values, 'EconomyBasic', 'Hourly'),
              updateAirportRateType(values, 'Premium', 'Hourly'),
              updateAirportRateType(values, 'Valet', 'Daily'),
              updateAirportRateType(values, 'EconomyBasic', 'Daily'),
              updateAirportRateType(values, 'Premium', 'Daily'),
              /* Send Airport Wide Comment */
              updateAirportCommentsFromAPI(+contentID, airportWideComment, id)
            ])
              .then(() => {
                toast.success(`Successfully Updated Airport Rental & Parking!`);
                setUpdatedComment(values.airportWideComment);
                setTimeout(() => window.location.reload(), 1000); // make sure correct values, temporary fix.
              })
              .catch(error => toast.error(error));
          }}
          render={formProps => {
            const { values, handleChange, handleBlur, handleSubmit, handleReset } = formProps;
            return (
              <Form>
                <div className={cssClass}>
                  <StepHeader stepTitle="Airport Car Rental" />
                  <Row>
                    <Col>
                      <h4 className="wizard-title">Car Rental On Site</h4>
                    </Col>
                  </Row>
                  <Row>
                    <FormField
                      name="carRentalOnsiteAgenciesTotal"
                      label="Agencies"
                      type="number"
                      match={match}
                      value={values.carRentalOnsiteAgenciesTotal}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      col={{}}
                    />
                    <FormField
                      name="carRentalOnsiteRevenueTotal"
                      label="Gross Revenue"
                      type="number"
                      match={match}
                      value={values.carRentalOnsiteRevenueTotal}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      col={{}}
                    />
                    <FormField
                      name="carRentalOnsiteRevenueAirportTotal"
                      label="Gross Rentals"
                      type="number"
                      match={match}
                      value={values.carRentalOnsiteRevenueAirportTotal}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      col={{}}
                    />
                  </Row>
                  <Row>
                    <Col>
                      <h4 className="wizard-title">Car Rental Off Site</h4>
                    </Col>
                  </Row>
                  <Row>
                    <FormField
                      name="carRentalOffsiteAgenciesTotal"
                      label="Agencies"
                      type="number"
                      match={match}
                      value={values.carRentalOffsiteAgenciesTotal}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      col={{}}
                    />
                    <FormField
                      name="carRentalOffsiteRevenueTotal"
                      label="Gross Revenue"
                      type="number"
                      match={match}
                      value={values.carRentalOffsiteRevenueTotal}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      col={{}}
                    />
                    <FormField
                      name="carRentalOffsiteRevenueAirportTotal"
                      label="Gross Rentals"
                      type="number"
                      match={match}
                      value={values.carRentalOffsiteRevenueAirportTotal}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      col={{}}
                    />
                  </Row>
                  <Row>
                    <Col>
                      <h4 className="wizard-title">Total Cars Rented</h4>
                    </Col>
                  </Row>
                  <Row>
                    <FormField
                      name="carRentalTotal"
                      label=""
                      type="number"
                      match={match}
                      value={values.carRentalTotal}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      col={{}}
                    />
                  </Row>
                  <Row>
                    <Col>
                      <h4 className="wizard-title">Car Rental Sq Footage</h4>
                    </Col>
                  </Row>
                  <Row>
                    <FormField
                      name="carRentalAreaTotal"
                      label=""
                      type="number"
                      match={match}
                      value={values.carRentalAreaTotal}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      col={{}}
                    />
                  </Row>
                  <ParkingRates
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    match={match}
                    values={values}
                    initVals={initVals}
                  />
                  <FormGroup>
                    <hr />
                    <StepHeader stepTitle="Hourly" Tag="h3" />
                    <Row>
                      <Col xs={2}>
                        <h4 className="wizard-title">{rateAmountText}</h4>
                      </Col>
                      <FormField
                        name="hourly.premium"
                        label="Premium"
                        type="number"
                        match={match}
                        value={values.hourly.premium}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{}}
                      />
                      <FormField
                        name="hourly.economybasic"
                        label="Economy/Basic"
                        type="number"
                        match={match}
                        value={values.hourly.economybasic}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{}}
                      />
                      <FormField
                        name="hourly.valet"
                        label="Valet"
                        type="number"
                        match={match}
                        value={values.hourly.valet}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{}}
                      />
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <hr />
                    <Row>
                      <Col xs={2}>
                        <h4 className="wizard-title">{numOfSpacesText}</h4>
                      </Col>
                      <FormField
                        name="hourly.premiumSpaces"
                        label="Premium"
                        type="number"
                        match={match}
                        value={values.hourly.premiumSpaces}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{}}
                      />
                      <FormField
                        name="hourly.economybasicSpaces"
                        label="Economy/Basic"
                        type="number"
                        match={match}
                        value={values.hourly.economybasicSpaces}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{}}
                      />
                      <FormField
                        name="hourly.valetSpaces"
                        label="Valet"
                        type="number"
                        match={match}
                        value={values.hourly.valetSpaces}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{}}
                      />
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <hr />
                    <StepHeader stepTitle="Daily" Tag="h3" />
                    <Row>
                      <Col xs={2}>
                        <h4 className="wizard-title">{rateAmountText}</h4>
                      </Col>
                      <FormField
                        name="daily.premium"
                        label="Premium"
                        type="number"
                        match={match}
                        value={values.daily.premium}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{}}
                      />
                      <FormField
                        name="daily.economybasic"
                        label="Economy/Basic"
                        type="number"
                        match={match}
                        value={values.daily.economybasic}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{}}
                      />
                      <FormField
                        name="daily.valet"
                        label="Valet"
                        type="number"
                        match={match}
                        value={values.daily.valet}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{}}
                      />
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col xs={2}>
                        <h4 className="wizard-title">{numOfSpacesText}</h4>
                      </Col>
                      <FormField
                        name="daily.premiumSpaces"
                        label="Premium"
                        type="number"
                        match={match}
                        value={values.daily.premiumSpaces}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{}}
                      />
                      <FormField
                        name="daily.economybasicSpaces"
                        label="Economy/Basic"
                        type="number"
                        match={match}
                        value={values.daily.economybasicSpaces}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{}}
                      />
                      <FormField
                        name="daily.valetSpaces"
                        label="Valet"
                        type="number"
                        match={match}
                        value={values.daily.valetSpaces}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        col={{}}
                      />
                    </Row>
                  </FormGroup>
                  <StepHeader stepTitle="Airport Wide Comment" />
                  <FormGroup>
                    <Row>
                      <FormField
                        name="airportWideComment"
                        label=""
                        type="textarea"
                        value={values.airportWideComment}
                        col={{}}
                        match={match}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    </Row>
                  </FormGroup>
                  <Row>
                    <Col>
                      <ButtonGroup>
                        <Button
                          color="primary"
                          type="submit"
                          onClick={event => {
                            event.preventDefault();
                            handleSubmit();
                          }}>
                          Save Change <FontAwesomeIcon icon={faSave} />
                        </Button>
                        <Button color="secondary" type="reset" onClick={() => handleReset}>
                          Cancel <FontAwesomeIcon icon={faRedoAlt} />
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            );
          }}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: AppState) => ({
  rentalParkingFormStore: state.wizard.stepper.airports.rentalParking,
  airportInstance: state.airports.airportInstance,
  authenticatedUser: state.users.authenticatedUser,
  activeInstance: state.wizard.activeInstance
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateAirportInfo: initUpdateAirportInfo(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoStep);
