import React from 'react';
import { NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ToggleNavbar } from './NavigationHelper';

const LoginItem = (props: { toggleNavbar?: ToggleNavbar }) => (
  <NavItem key={'login'}>
    <Link
      to={'/login'}
      onClick={() => {
        if (props.toggleNavbar) props.toggleNavbar(false);
      }}>
      Login
    </Link>
  </NavItem>
);

export default LoginItem;
