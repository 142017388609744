import React, { FunctionComponent } from 'react';
import { Table } from 'reactstrap';

interface TableProps {
  className?: string;
}

const ResponsiveTable: FunctionComponent<TableProps> = props => {
  const { children } = props;
  return (
    <Table responsive bordered striped className={'w-100 rounded py-3'}  >
      {children}
    </Table>
  );
};

export default ResponsiveTable;
