import { get } from 'lodash';
import moment from 'moment';
import { AuthenticatedUser } from '../../actions/usersActions';
import { AvailableInstanceMasterStatus } from '../../actions/wizardActions';

export const getCookieKeyValue = (name: string) =>
  document.cookie.split('; ').filter(item => item.split('=')[0] === name)[0];

export const getAuthTokenFromKeyValue = () => {
  const tokenKeyValue = getCookieKeyValue('userAuthToken')
    ? getCookieKeyValue('userAuthToken').split('=')[1]
    : '';
  return { userAuthToken: tokenKeyValue, hasUserAuthToken: !!tokenKeyValue };
};

export const getWizardInstanceFromKeyValue = () => {
  const tokenKeyValue = getCookieKeyValue('wizardInstance')
    ? getCookieKeyValue('wizardInstance').split('=')[1]
    : '';
  const cleanedUpString = JSON.stringify(
    tokenKeyValue
      .replace(/%22/g, '"')
      .replace(/%2C/g, ',')
      .replace(/%5C/g, '"')
  );
  const wizardInstance =
    cleanedUpString.length > 2 // there will always be at least two quote marks in the string ""
      ? JSON.parse(JSON.parse(cleanedUpString))
      : JSON.parse(cleanedUpString); // why is double parse fixing issues?

  const instanceId = get(wizardInstance, 'instanceId', +moment().format('YY'));
  const status = get(wizardInstance, 'instanceStatus', 'PENDING' as AvailableInstanceMasterStatus);
  const name = get(wizardInstance, 'instanceName', moment().format('YYYY'));
  return { instanceId, status, name, hasWizardInstance: !!tokenKeyValue };
};

export const decodeJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64)) as AuthenticatedUser;
};

export const getAuthUserID = () => {
  const { userAuthToken, hasUserAuthToken } = getAuthTokenFromKeyValue();
  return hasUserAuthToken ? decodeJwt(userAuthToken).userId : 0;
};
