import React, { useEffect, useState } from 'react';
import { upperFirst, get } from 'lodash';
import { Form, Formik } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { cssClass } from '../../wizard/cssSharedClasses';
import FormField from '../../forms/FormField';
import StateProvinceSelectField from '../../forms/StateProvincesSelectField';
import IsoCountrySelectField from '../../forms/IsoCountrySelectField';
import { appHistory } from '../../../reducers';
import emptyCompanyState from './emptyCompany';
import {
  addMainCompanyFromAPI,
  editMainCompanyFromAPI,
  getAxnStatusTypes,
  getMainCompanyFromAPI
} from '../../../api/Companies';
import AddEditMainCompanySchema from './AddEditMainCompanySchema';
import { CompanyData } from '../../../actions/companiesActions';
import AddEntityButton from '../AddEntityButton';
import { AxnStatus } from '../../../api';
import GoBack from '../../buttons/GoBack';
import AxnStatusSelectField from '../../forms/AxnStatusSelectField';

interface AddEditMainCompanyViewUrlParams {
  addEdit: 'add' | 'edit';
  companyID: string;
}
const AddEditMainCompanyView = (props: RouteComponentProps<AddEditMainCompanyViewUrlParams>) => {
  const { match } = props;
  const { params } = match;
  const { addEdit, companyID } = params;
  const [company, setCompany] = useState(emptyCompanyState);

  /* Handle Add/Edit functionality */
  const addEditIcon = addEdit === 'add' ? faPlus : faSave;
  const addEditText = addEdit === 'add' ? 'Add' : 'Save';
  const addEditEndpoint =
    addEdit === 'add' ? addMainCompanyFromAPI : editMainCompanyFromAPI(+companyID);

  /* Remove/Add additional values as needed for different endpoints */
  const addEditSendValues = (values: CompanyData) => {
    const sendVals = { ...values };
    if (addEdit === 'add') delete sendVals.companyId;
    delete sendVals.modifiedUserId; // this will be tracked by JWT token and backend...
    return { ...sendVals };
  };

  useEffect(() => {
    if (companyID) {
      getMainCompanyFromAPI(+companyID).then(
        response => {
          const { data } = response;
          setCompany({ ...company, ...data });
        },
        () => {
          toast.warn(`Failed to ${addEdit} Company!`);
        }
      );
    }
  }, [companyID]);

  /* Status Select Field */
  const [statuses, setStatuses] = useState(new Array<AxnStatus>());
  useEffect(() => {
    getAxnStatusTypes().then(
      response => setStatuses(response),
      () => toast.warn(`Failed to get AXN Statuses!`)
    );
  }, []);

  return (
    <Container className={cssClass}>
      <AddEntityButton
        addIcon={faPlus}
        emptyEntityState={emptyCompanyState}
        path="/admin/manage/main/companies/add"
        setEntity={setCompany}
        // bottom="80px"
      />
      {/*
        <AddEntityButton
          addIcon={faTrash}
          emptyEntityState={emptyCompanyState}
          path={`/admin/manage/main/companies/delete/${companyID}`}
        />
      */}
      <h2>{`${upperFirst(addEdit)}`} Main Company</h2>
      <Formik
        enableReinitialize
        initialValues={{
          ...company,
          companyId: +companyID || emptyCompanyState.companyId,
          modifiedUserDisplayName: get(company, 'modifiedUser.displayName', ''),
          axnStatus: company.axnStatus
        }}
        validationSchema={AddEditMainCompanySchema}
        onSubmit={values => {
          const sendVals = addEditSendValues(values);
          addEditEndpoint(sendVals).then(
            response => {
              const { data } = response;
              const { companyName } = data;
              toast.success(`${companyName} Successfully ${upperFirst(addEdit)}ed`);
              appHistory.push(`/admin/manage/main/companies`);
            },
            error => toast.error(error)
          );
        }}
        render={({
          values,
          handleBlur,
          handleChange,
          setFieldValue,
          validateForm,
          handleSubmit
        }) => {
          return (
            <Form>
              <Row>
                {companyID && (
                  <FormField
                    name={'companyId'}
                    label={'Company ID'}
                    type={'number'}
                    value={values.companyId}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    match={match}
                    col={{ xl: 12, lg: 12 }}
                    hidden={!!companyID}
                    readOnly
                    disabled
                  />
                )}
                <FormField
                  name={'companyName'}
                  label={'Company Name (Required)'}
                  type={'text'}
                  value={values.companyName}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  match={match}
                  col={{ xl: 12, lg: 12 }}
                />
                <FormField
                  name={'address1'}
                  label={'Address 1:'}
                  type={'text'}
                  value={values.address1}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  match={match}
                  col={{ xl: 12, lg: 12 }}
                />
                <FormField
                  name={'address2'}
                  label={'Address 2:'}
                  type={'text'}
                  value={values.address2}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  match={match}
                  col={{ xl: 12, lg: 12 }}
                />
                <FormField
                  name={'city'}
                  label={'City'}
                  type={'text'}
                  value={values.city}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  match={match}
                  col={{ xl: 12, lg: 12 }}
                />
                <FormField
                  name="postalCode"
                  label="Postal Code"
                  type="text"
                  value={values.postalCode}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  match={match}
                  col={{ xl: 12, lg: 12 }}
                />
                <StateProvinceSelectField
                  values={values}
                  match={match}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleChange}
                />
                <IsoCountrySelectField
                  values={values}
                  match={match}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleChange}
                  displayRequired={false}
                />
                <FormField
                  name={'modifiedDate'}
                  label={'Modified Date'}
                  type={'text'}
                  value={values.modifiedDate}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  match={match}
                  col={{ xl: 12, lg: 12 }}
                  readOnly
                  disabled
                />
                <FormField
                  name={'modifiedUserId'}
                  label={'Modified User ID'}
                  type={'text'}
                  match={match}
                  value={values.modifiedUserId}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ xl: 12, lg: 12 }}
                  disabled
                  readOnly
                />
                <FormField
                  name={'modifiedUserDisplayName'}
                  label={'Lasted Modified User'}
                  type={'text'}
                  match={match}
                  value={values.modifiedUserDisplayName}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ xl: 12, lg: 12 }}
                  disabled
                  readOnly
                />
                <AxnStatusSelectField
                  axnStatusValue={values.axnStatus}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                <FormField
                  name={'phone'}
                  label={'Phone'}
                  type={'text'}
                  match={match}
                  value={values.phone}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ xl: 12, lg: 12 }}
                />
                <FormField
                  name={'fax'}
                  label={'Fax'}
                  type={'text'}
                  match={match}
                  value={values.fax}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ xl: 12, lg: 12 }}
                />
                <FormField
                  name={'url'}
                  label={'Website URL'}
                  type={'text'}
                  match={match}
                  value={values.url}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ xl: 12, lg: 12 }}
                />
                <FormField
                  name={'companyInfoText'}
                  label={'Company Info Text'}
                  type={'textarea'}
                  match={match}
                  value={values.companyInfoText}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ xl: 12, lg: 12 }}
                />
                <Col xl={12} lg={12}>
                  <ButtonGroup>
                    <GoBack />
                    <Button
                      type={'submit'}
                      color={'primary'}
                      onClick={e => {
                        e.preventDefault();
                        validateForm().then(() => handleSubmit());
                      }}>
                      <FontAwesomeIcon icon={addEditIcon} /> {addEditText} Company
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Form>
          );
        }}
      />
    </Container>
  );
};

export default withRouter(AddEditMainCompanyView);
