import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Alert, Col, Row } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import { StepDefaultProps, WizardStepperMatch } from '../../../types';
import { AppState } from '../../../../../reducers';
import TerminalList from './TerminalList';
import TerminalNotFound from './TerminalNotFound';
import TerminalSpecificForm from './TerminalSpecificForm';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import { getTerminals, TerminalsList } from '../../../../../actions/terminalsActions';
import { TerminalTotalsForm } from './TerminalTotalsForm';
import { AirportCombinedTotals, AirportInstance } from '../../../../../types';
import { initialAirportTotals } from './TerminalsStepValueInit';
import { useAirportTotals } from '../../../../../api/Airports';
import { AuthenticatedUser } from '../../../../../actions/usersActions';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';

interface ITerminalsStepProps extends StepDefaultProps {
  dispatch?: Dispatch;
  terminals: TerminalsList;
  loading: boolean;
  airportInstance: AirportInstance;
  match: WizardStepperMatch;
  authenticatedUser: AuthenticatedUser;
  activeInstance: ActiveInstance;
  getTerminals: any;
}

const TerminalsStep = (props: ITerminalsStepProps) => {
  const {
    airportInstance,
    authenticatedUser,
    terminals,
    loading,
    match,
    activeInstance,
    getTerminals
  } = props;
  const { id } = activeInstance;
  const { contentID } = match.params;
  const [airportTotals, setAirportTotals] = useState<AirportCombinedTotals>(initialAirportTotals);
  const totalFromAPI = useAirportTotals(+contentID, id);

  useEffect(() => {
    setAirportTotals({ ...airportTotals, ...totalFromAPI });
  }, [totalFromAPI, terminals, airportInstance]);

  return (
    <Row>
      <Col xs={12}>
        <div className={cssClass}>
          <StepHeader stepTitle={'Airport Info'} />
          <div>
            <small className={'form-text text-muted'}>
              {'Please follow steps 1, 2, 3, & 4 for each terminal.'}
            </small>
            <Row>
              <Col>
                <h3 className={'wizard-instruction'}>1.) Select a Terminal to modify data:</h3>
              </Col>
            </Row>
            {terminals.length > 0 ? (
              <TerminalList terminals={terminals} loading={loading} url={match.url} />
            ) : (
              <Alert color={'warning'}>
                There are currently no terminals available for the current airport and AXN Instance.
              </Alert>
            )}
          </div>
          <Switch>
            <Route
              path={`${match.path}/:terminalID`}
              render={_props => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TerminalSpecificForm
                  airport={airportInstance}
                  {..._props}
                  axnInstanceId={id}
                  getTerminals={getTerminals}
                />
              )}
            />
            <Route component={TerminalNotFound} />
          </Switch>
        </div>
        <TerminalTotalsForm
          airport={airportInstance}
          match={match}
          totals={airportTotals}
          authenticatedUser={authenticatedUser}
          activeInstance={activeInstance}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: AppState) => ({
  terminalsFormStore: state.wizard.stepper.airports.terminals,
  terminals: state.terminals.terminals,
  loading: state.terminals.loading,
  airportInstance: state.airports.airportInstance,
  authenticatedUser: state.users.authenticatedUser,
  activeInstance: state.wizard.activeInstance
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getTerminals: getTerminals(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TerminalsStep);
