import moment from 'moment';
import {
  CompanyData,
  CompanyIDData,
  CompaniesAction,
  CompaniesList,
  CompanyInstance,
  CompanyInstanceType
} from '../actions/companiesActions';
import { AxnInstanceStatus, YesOrNo } from '../types';
import { getAuthUserID } from '../core/authentication/authenticationHelper';

/* This Reducer setup is kind of wordy but it does over type safe actions with a ton of IDE auto complete
 capabilities. */
export interface CompanyState {
  companies: CompaniesList;
  companyInstance: CompanyInstance;
  loading: boolean;
  error: string;
}

export const initialCompanyInstanceTypeForList = {
  companyInstanceTypePK: {
    companyId: 0,
    axnInstanceId: 0,
    companyType: 'None'
  },
  associatedCompanyType: {
    companyType: '',
    companyTypeDes: '',
    sequenceNumber: 0
  }
};

/* Set Initial Company State - Entire possible shape of object initially. */
const initialCompanyState = {
  companies: Array<CompanyData>(),
  companyInstance: {
    company: {
      companyId: 0, // this should be a number and not a string as a number :'|
      companyName: '',
      address1: '',
      address2: '',
      city: '',
      postalCode: '',
      stateProvinceCode: '',
      isoCountryCode: '',
      modifiedUserId: getAuthUserID(),
      modifiedDate: '', //  "2019-06-14"
      axnStatus: 'ACTIVE', // ACTIVE|INACTIVE
      phone: '',
      fax: '',
      url: '',
      companyInfoText: ''
    },
    axnInstanceStatus: 'PUBLISHED' as AxnInstanceStatus,
    modifiedDate: moment().format('YYYY-MM-DD'),
    modifiedUserId: getAuthUserID(),
    dbeFlag: 'N' as YesOrNo, // default to disabled
    companyInstanceTypeList: new Array<CompanyInstanceType>()
  },
  loading: false,
  error: ''
};

export type CompaniesState = typeof initialCompanyState;

export const companiesReducer = (
  state: CompanyState = initialCompanyState,
  action: CompaniesAction
) => {
  switch (action.type) {
    case 'GET_COMPANY':
    case 'GET_COMPANIES':
    case 'UPDATE_ADD_COMPANY':
    case 'DELETE_COMPANY':
      return { ...state, isLoading: true };
    case 'GET_COMPANY_SUCCESS':
    case 'UPDATE_ADD_COMPANY_SUCCESS':
      return {
        ...state,
        isLoading: false,
        companyInstance: action.companyInstance
      };
    case 'GET_COMPANIES_SUCCESS':
      return { ...state, isLoading: false, companies: action.companies };
    case 'DELETE_COMPANY_SUCCESS':
      return {
        ...state,
        companies: state.companies.filter(data => (data as CompanyIDData) !== action.companyID),
        isLoading: false
      };
    case 'GET_COMPANY_FAILURE':
    case 'GET_COMPANIES_FAILURE':
    case 'DELETE_COMPANY_FAILURE':
    case 'UPDATE_ADD_COMPANY_FAILURE':
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
