import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Alert, Col, Table } from 'reactstrap';
import { get } from 'lodash';
import { AirportData } from '../../../../../types';
import { extractAirportComment } from '../../../../../core/comments/AirportComments';
import { useGetParkingRates } from '../../../../../api/Airports';
import { findRate } from '../rental-parking/RentalParkingStep';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import Spinner from '../../../../shared/spinner/Spinner';
import { vnfMsg } from './ReviewStep';
import { AppState } from '../../../../../reducers';

const ReviewRevenue = (props: {
  airport: AirportData;
  airportLoading: boolean;
  contentID: string;
  axnInstanceId: number;
}) => {
  const { airport, airportLoading, contentID, axnInstanceId } = props;

  const airportWideComment = extractAirportComment(airport, 'AIRPORTWIDE');

  /* Rental and Parking Values */
  const { parkingRates, loadingParkingRates } = useGetParkingRates(
    +contentID,
    axnInstanceId,
    airport
  );
  /* Putting these in a const object literal might help with auto complete. */
  const findRateAmount = findRate('parkingRateAmount', parkingRates, contentID, axnInstanceId);
  const findSpaceQuantity = findRate(
    'parkingSpaceQuantity',
    parkingRates,
    contentID,
    axnInstanceId
  );

  const hourly: { [key: string]: any } = {
    premium: findRateAmount('Premium', 'Hourly', 0),
    economybasic: findRateAmount('EconomyBasic', 'Hourly', 0),
    valet: findRateAmount('Valet', 'Hourly', 0),
    premiumSpaces: findSpaceQuantity('Premium', 'Hourly', 0),
    economybasicSpaces: findSpaceQuantity('EconomyBasic', 'Hourly', 0),
    valetSpaces: findSpaceQuantity('Valet', 'Hourly', 0)
  };

  const daily: { [key: string]: any } = {
    premium: findRateAmount('Premium', 'Daily', 0),
    economybasic: findRateAmount('EconomyBasic', 'Daily', 0),
    valet: findRateAmount('Valet', 'Daily', 0),
    premiumSpaces: findSpaceQuantity('Premium', 'Daily', 0),
    economybasicSpaces: findSpaceQuantity('EconomyBasic', 'Daily', 0),
    valetSpaces: findSpaceQuantity('Valet', 'Daily', 0)
  };

  const hourlyTotalSpaces = hourly.premiumSpaces + hourly.economybasicSpaces + hourly.valetSpaces;
  const dailyTotalSpaces = daily.premiumSpaces + daily.economybasicSpaces + daily.valetSpaces;
  const combinedTotalSpaces = hourlyTotalSpaces + dailyTotalSpaces;
  return (
    <Col className={cssClass}>
      <StepHeader stepTitle="Airport Revenue" Tag="h4" />
      {/* Airport Revenue - Main */}
      {airportLoading ? (
        <Spinner />
      ) : (
        <Table bordered striped responsive className="py-3">
          <thead>
            <tr>
              <th> </th>
              <th scope="col">Gross Revenue</th>
              <th scope="col">Revenue to Airport</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Passenger Services</th>
              <td>${get(airport, 'passengerSvcRevenueTotal', 0)}</td>
              <td>${get(airport, 'passengerSvcRevenueAirportTotal', 0)}</td>
            </tr>
            <tr>
              <th scope="row">Advertising</th>
              <td>${get(airport, 'advertisingRevenueTotal', 0)}</td>
              <td>${get(airport, 'advertisingRevenueAirportTotal', 0)}</td>
            </tr>
            <tr>
              <th scope="row">Currency Exchange</th>
              <td>${get(airport, 'currencyExchangeRevenueTotal', 0)}</td>
              <td>${get(airport, 'currencyExchangeRevenueAirportTotal', 0)}</td>
            </tr>
          </tbody>
        </Table>
      )}
      {/* Airport Revenue - Car Rentals */}
      <StepHeader stepTitle="Car Rentals" Tag="h4" />
      {airportLoading ? (
        <Spinner />
      ) : (
        <Table bordered striped responsive className="py-3">
          <thead>
            <tr>
              <th> </th>
              <th>Agencies</th>
              <th>Gross Revenue</th>
              <th>Gross Rentals</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="col">Car Rental On Site</th>
              <td>{get(airport, 'carRentalOnsiteAgenciesTotal', 0)}</td>
              <td>${get(airport, 'carRentalOnsiteRevenueTotal', 0)}</td>
              <td>${get(airport, 'carRentalOnsiteRevenueAirportTotal', 0)}</td>
            </tr>
            <tr>
              <th scope="col">Car Rental Off Site</th>
              <td>{get(airport, 'carRentalOffsiteAgenciesTotal', 0)}</td>
              <td>${get(airport, 'carRentalOffsiteRevenueTotal', 0)}</td>
              <td>${get(airport, 'carRentalOffsiteRevenueAirportTotal', 0)}</td>
            </tr>
            <tr>
              <th scope="col">Total Cars Rented</th>
              <td colSpan={3}>{get(airport, 'carRentalTotal', 0)}</td>
            </tr>
            <tr>
              <th scope="col">Car Rental Square Footage</th>
              <td colSpan={3}>{get(airport, 'carRentalAreaTotal', 0)}</td>
            </tr>
          </tbody>
        </Table>
      )}
      {/* Airport Revenue - Parking */}
      <StepHeader stepTitle="Parking" Tag="h4" />
      {airportLoading ? (
        <Spinner />
      ) : (
        <Table bordered striped responsive className="py-3">
          <thead>
            <tr>
              <th scope="row">Total # of Spaces</th>
              <th scope="row">Gross Revenue</th>
              <th scope="row">Revenue to Airport</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{combinedTotalSpaces}</td>
              <td>${get(airport, 'parkingRevenueTotal', 0)}</td>
              <td>${get(airport, 'parkingRevenueAirportTotal', 0)}</td>
            </tr>
          </tbody>
        </Table>
      )}
      {/* Airport Revenue - Parking Rates */}
      <StepHeader stepTitle="Parking Rates" Tag="h4" />
      {loadingParkingRates ? (
        <Spinner />
      ) : (
        <Table bordered striped responsive className="py-3">
          <thead>
            <tr>
              <th scope="col"> </th>
              <th scope="col">Premium</th>
              <th scope="col">Economy/Basic</th>
              <th scope="col">Valet</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Hourly</th>
              <td>${hourly.premium}</td>
              <td>${hourly.economybasic}</td>
              <td>${hourly.valet}</td>
            </tr>
            <tr>
              <th scope="row">Daily</th>
              <td>${daily.premium}</td>
              <td>${daily.economybasic}</td>
              <td>${daily.valet}</td>
            </tr>
            <tr>
              <th scope="row"># of Spaces</th>
              <td>{daily.premiumSpaces + hourly.premiumSpaces}</td>
              <td>{daily.economybasicSpaces + hourly.economybasicSpaces}</td>
              <td>{daily.valetSpaces + hourly.valetSpaces}</td>
            </tr>
          </tbody>
        </Table>
      )}
      <StepHeader stepTitle="Airport Wide Comment" Tag="h4" className="pb-3" />
      {airportLoading ? (
        <Spinner />
      ) : (
        <Alert color={airportWideComment ? 'success' : 'danger'}>
          {airportWideComment || vnfMsg}
        </Alert>
      )}
    </Col>
  );
};

const mapStateToProps = (state: AppState) => ({
  //axnInstanceId: state.users.authenticatedUser.axnInstanceId
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewRevenue);
