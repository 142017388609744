import React, { useState } from 'react';
import { faArrowLeft, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { useFilteredMainCompanies } from '../../../api/Companies';
import { TableColumn } from '../../masterlist/MasterList';
import { MasterListActionButton, IndexableMasterListItem } from '../../masterlist/types';
import { CompanyData } from '../../../actions/companiesActions';
import { FilteredMasterList } from '../../masterlist/filter/FilteredMasterList';
import { StatusFilter } from '../../masterlist/filter';
import emptyCompanyState from './emptyCompany';
import { appHistory, AppState } from '../../../reducers';
import AddEntityButton from '../AddEntityButton';

export interface MainCompanySearchResult {
  companyId?: number;
  companyName: string;
  isoCountryCode: string;
  modifiedDate: string;
  axnStatus: string;
}

const companyColumnsMap: TableColumn[] = [
  { id: 'companyId', title: 'ID' },
  { id: 'companyName', title: 'Name', width: '40%' },
  { id: 'isoCountryCode', title: 'Country' },
  { id: 'modifiedDate', title: 'Last Modified' },
  { id: 'axnStatus', title: 'Status' }
];

const rowActions = (id: number): MasterListActionButton[] => {
  return [
    {
      to: `/admin/manage/main/companies/edit/${id}`,
      icon: faEdit,
      text: 'Edit'
    }
    /* need to remove foreign key associations to do this */
    // {
    //   to: `/admin/manage/main/companies`,
    //   icon: faTrash,
    //   text: 'Delete',
    //   actionCallback: () => {
    //     deleteMainCompanyFromAPI(id).then(
    //       () => toast.success(`Company Deleted Successfully!`),
    //       error => toast.error(error)
    //     );
    //   }
    // }
  ];
};

const convert = (data: CompanyData): MainCompanySearchResult => ({
  companyId: data.companyId,
  companyName: data.companyName,
  isoCountryCode: data.isoCountryCode,
  modifiedDate: data.modifiedDate || '',
  axnStatus: data.axnStatus
});

export const MainCompanyManager = (props: { axnInstanceId: number }) => {
  const { axnInstanceId } = props;
  const [status, setStatus] = useState('ALL');
  return (
    <>
      <div className={'d-flex align-items-center justify-content-between'}>
        <div>
          <StatusFilter handleChange={setStatus} selected={status} type={'main'} />
        </div>
        <div>
          <Button color={'primary'} onClick={() => appHistory.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} /> Go Back
          </Button>
        </div>
      </div>
      <AddEntityButton
        addIcon={faPlus}
        emptyEntityState={emptyCompanyState}
        path={'/admin/manage/main/companies/add'}
      />
      <FilteredMasterList
        listType="Companies"
        actions={rowActions}
        columns={companyColumnsMap}
        convert={d => convert(d) as IndexableMasterListItem}
        useData={useFilteredMainCompanies}
        args={status}
        axnInstanceId={axnInstanceId}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  axnInstanceId: state.users.authenticatedUser.axnInstanceId
});

export default connect(mapStateToProps, null)(MainCompanyManager);
