import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, ButtonGroup, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Form, Formik } from 'formik';
import FormField from '../forms/FormField';

interface SearchField {
  searchRequest: any; // fix type signature, currently used by userManager
  setKeyword: (keyword: string) => void;
  setSearching: (searching: boolean) => void;
  size: number;
  page: number;
}

const SearchField = (props: SearchField & RouteComponentProps<{}>) => {
  const { match, page, size, searchRequest, setKeyword, setSearching } = props;
  return (
    <Container>
      <Formik
        onSubmit={values => {
          setKeyword(values.keyword);
        }}
        initialValues={{ keyword: '' }}
        render={({ handleChange, handleBlur, values }) => {
          return (
            <Form>
              <Row>
                <FormField
                  name="keyword"
                  label=""
                  type="text"
                  value={values.keyword}
                  handleChange={e => {
                    const { value } = e.target;
                    setSearching( !!value );
                    handleChange(e);
                  }}
                  handleBlur={handleBlur}
                  match={match}
                  col={{ xl: 9, lg: 9, md: 9, sm: 9, xs: 8 }}
                  className="mr-0 pr-0"
                />
                <Col xl={3} lg={3} md={3} sm={3} xs={4} className="ml-0 pl-0">
                  <ButtonGroup>
                    <Button type="submit" color="primary">
                      <FontAwesomeIcon icon={faSearch} /> Search
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Form>
          );
        }}
      />
    </Container>
  );
};

export default withRouter(SearchField);
