import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { MasterListPagesize, MasterListPagination } from '../components/masterlist';
import { MasterListActionButton, MasterListType } from '../components/masterlist/types';
import { useTenantListing, tenantListingColumns } from '../api/reports';
import { cssClass } from '../components/wizard/cssSharedClasses';
import MasterList from '../components/masterlist/MasterList';
import { AppState } from '../reducers';

const actions: MasterListActionButton[] = [];

const TenantsView = (props: { axnInstanceId: number }) => {
  const { axnInstanceId } = props;
  const model = useTenantListing(axnInstanceId);
  const { data, loading, setCurrentPage, setPageSize } = model;
  const { content, pageSize, totalRecords, currentPage } = data;
  return (
    <Container className={cssClass}>
      <Row>
        <Col>
          <h1>{'Tenant Listing'}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="pt-5">
            <div className={'d-flex justify-content-between'}>
              <MasterListPagesize size={pageSize} setPagesize={setPageSize} />
              <MasterListPagination
                totalItems={totalRecords}
                currentPage={currentPage}
                handlePaginationSelection={setCurrentPage}
                pageSize={pageSize}
              />
            </div>
            <MasterList
              columns={tenantListingColumns}
              list={(content as MasterListType) || []}
              actions={() => actions}
              currentPage={currentPage}
              itemCount={totalRecords}
              loading={loading}
              listType="tenants"
              readonly
            />
            <div className={'py-3 d-flex justify-content-between'}>
              <MasterListPagesize size={pageSize} setPagesize={setPageSize} />
              <MasterListPagination
                totalItems={totalRecords}
                currentPage={currentPage}
                handlePaginationSelection={setCurrentPage}
                pageSize={pageSize}
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  axnInstanceId: state.users.authenticatedUser.axnInstanceId
});

export default connect(mapStateToProps, null)(TenantsView);
