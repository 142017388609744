import React, { useState, useEffect } from 'react';
import { getAxnStatusTypes, getInstanceStatusTypes } from '../../../api/Companies';

interface StatusFilterProps {
  selected: string;
  handleChange: (newValue: string) => void;
  type: 'main' | 'instance';
}

export const StatusFilter = ({ handleChange, selected, type }: StatusFilterProps) => {
  const [statusTypes, setStatusTypes] = useState(new Array<string>());
  const getStatusTypesRequest = type === 'main' ? getAxnStatusTypes : getInstanceStatusTypes;
  useEffect(() => {
    getStatusTypesRequest().then(setStatusTypes);
  }, []);

  return (
    <form>
      <div className="form-group">
        <label htmlFor="instanceStatus">
          Status Filter:
          <select
            className="form-control"
            defaultValue={selected}
            id="instanceStatus"
            name="instanceStatus"
            onChange={e => handleChange(e.target.value)}>
            <option value="ALL">ALL</option>
            {statusTypes.map(status => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </label>
      </div>
    </form>
  );
};
