import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { TableColumn } from '../components/masterlist/MasterList';
import { IndexableMasterListItem, MasterListActionButton } from '../components/masterlist/types';
import { CompanyInstance } from '../actions/companiesActions';
import { StatusFilter } from '../components/masterlist/filter';
import { AppState } from '../reducers';
import { FilteredMasterList } from '../components/masterlist/filter/FilteredMasterList';
import { useDBEFilteredCompanies } from '../api/Companies';
import { cssClass } from '../components/wizard/cssSharedClasses';
import { CompanySearchResult } from '../components/admin/companies/CompanyManager';
import GoBack from '../components/buttons/GoBack';

const companyColumnsMap: TableColumn[] = [
  { id: 'companyId', title: 'ID' },
  { id: 'companyName', title: 'Name', width: '40%' },
  { id: 'isoCountryCode', title: 'Country', width: '10%' },
  { id: 'modifiedDate', title: 'Last Modified', width: '20%' },
  { id: 'axnInstanceStatus', title: 'Status' }
];

const rowActions = (id: number): MasterListActionButton[] => {
  return [
    {
      to: `/wizard/companies/${id}/steps/contacts`,
      icon: faEdit,
      text: 'Edit'
    }
  ];
};

const convert = (data: CompanyInstance): CompanySearchResult => ({
  companyId: data.company.companyId,
  companyName: data.company.companyName,
  isoCountryCode: data.company.isoCountryCode,
  modifiedDate: data.modifiedDate || '',
  axnInstanceStatus: data.axnInstanceStatus
});

export const ReportsDBEView = (props: { axnInstanceId: number }) => {
  const { axnInstanceId } = props;
  const [status, setStatus] = useState('ALL');
  return (
    <Container class={cssClass}>
      <div className={'d-flex align-items-center justify-content-between'}>
        <div>
          <StatusFilter handleChange={setStatus} selected={status} type={'instance'} />
        </div>
        <div>
          <GoBack />
        </div>
      </div>
      <FilteredMasterList
        listType="DBE Companies"
        actions={rowActions}
        columns={companyColumnsMap}
        convert={d => convert(d) as IndexableMasterListItem}
        useData={useDBEFilteredCompanies}
        args={status}
        axnInstanceId={axnInstanceId}
      />
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  axnInstanceId: state.users.authenticatedUser.axnInstanceId
});

export default connect(mapStateToProps, null)(ReportsDBEView);
