import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField from './FormField';
import { CompanyType, useCompanyTypes } from '../../api/Companies';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

/* TODO: Fix the any */
const CompanyTypeSelectField = (props: {
  values: any;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: any;
  handleBlur: any;
  companyId: number;
  axnInstanceId: number;
  helpText?: string;
  allHelpText?: string;
  all?: boolean;
  label?: string;
  browserAutoComplete?: string;
  col?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}) => {
  const {
    values,
    match,
    handleChange,
    handleBlur,
    setFieldValue,
    col,
    companyId,
    label,
    all,
    helpText,
    allHelpText,
    axnInstanceId,
    browserAutoComplete
  } = props;
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();
  const { companyTypes, companyTypesLoading } = useCompanyTypes(axnInstanceId, companyId, all);
  useEffect(() => {
    const acList = companyTypes
      .map((companyType: CompanyType) => ({
        label: companyType.companyTypeDes,
        key: companyType.companyType,
        value: companyType.companyType
      }))
      .sort((a, b) => {
        return a.label.toLowerCase() === 'other' ? 1 : a.label.localeCompare(b.label);
      })
      .filter(item => item.label !== ''); // no blank labels
    setAutoCompleteList(acList);
  }, [companyTypes]);

  const [labelValue, setLabelValue] = useState();
  useEffect(() => {
    setLabelValue(values.companyTypeDes);
  }, [values.companyTypeDes]);

  const [value, setValue] = useState();
  useEffect(() => {
    setValue(values.companyType);
  }, [values.companyType]);

  if (companyTypesLoading) return <SelectFieldSpinner {...col} />;
  return (
    <FormField
      name="companyType"
      labelName="companyTypeDes"
      label={label || ''}
      type="autocomplete"
      match={match}
      value={value || ''}
      labelValue={labelValue || ''}
      handleChange={handleChange}
      handleBlur={handleBlur}
      autoCompleteList={autoCompleteList}
      col={col || {}}
      setFieldValue={setFieldValue}
      helpText={all ? allHelpText : helpText}
      browserAutoComplete={browserAutoComplete}
    />
  );
};

CompanyTypeSelectField.defaultProps = {
  helpText:
    'Available options are determined from the "Associated Company Types" section of Company Information step.',
  allHelpText: '',
  all: true,
  label: 'Select a Company Type to Add to the Current Company',
  col: { xl: 12 }
};

export default CompanyTypeSelectField;
