import React, { useCallback } from 'react';
import { SelectModelData } from '../SelectModelData';
import { useAllCompaniesForInstance } from '../../../api/Companies';
import { AirportLookup, CompanyLookup } from '../../../types';
import { useAllAirports } from '../../../api/Airports';
import { AutoCompleteOption } from '../../forms/autocomplete/AutoComplete';

export type AffiliateObjectType = 'Airport' | 'Company';

interface AffiliateToObjectFormProps {
  objectType: AffiliateObjectType;
  instanceId: number;
  handleSubmit: (airportId: number) => void;
}

const mkCompanyOption = (data: CompanyLookup): AutoCompleteOption => ({
  label: data.companyName,
  key: `${data.companyId}`,
  value: `${data.companyId}`
});

const SelectCompany = ({ handleSubmit, instanceId }: AffiliateToObjectFormProps) => (
  <SelectModelData
    handleSubmit={(key: string) => handleSubmit(+key)}
    model={useAllCompaniesForInstance(instanceId)}
    mkOption={mkCompanyOption}
    objectType={'Company'}
  />
);

const mkAirportOption = (data: AirportLookup): AutoCompleteOption => ({
  label: `${data.airportName} - ${data.iataCode}`,
  key: data.iataCode,
  value: `${data.airportId}`
});

const SelectAirport = ({ handleSubmit, instanceId }: AffiliateToObjectFormProps) =>  {
  const handler = useCallback(key => handleSubmit(+key), [handleSubmit])
  return (
    <SelectModelData
        handleSubmit={handler}
        mkOption={mkAirportOption}
        model={useAllAirports(instanceId)}
        objectType="Airport"
    />
  )
}

const AffiliateToObjectForm = ({
  handleSubmit,
  instanceId,
  objectType
}: AffiliateToObjectFormProps) =>
  objectType === 'Airport' ? (
    <SelectAirport instanceId={instanceId} handleSubmit={handleSubmit} objectType={objectType} />
  ) : (
    <SelectCompany instanceId={instanceId} handleSubmit={handleSubmit} objectType={objectType} />
  );

export { AffiliateToObjectForm };
