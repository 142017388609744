import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import AffiliateWrapper from '../../../../components/admin/affiliations/AffiliateWrapper';
import { AppState } from '../../../../reducers';

interface Params {
  userID?: string;
}

const AffiliateAirportView = ({
  match,
  axnInstanceId
}: RouteComponentProps<Params> & { axnInstanceId: number }) => (
  <AffiliateWrapper
    objectType="Airport"
    userID={match.params.userID}
    axnInstanceId={axnInstanceId}
  />
);

const mapStateToProps = (state: AppState) => ({
  axnInstanceId: state.users.authenticatedUser.axnInstanceId
});

export default connect(mapStateToProps, null)(withRouter(AffiliateAirportView));
