import React from 'react';
import { Container } from 'reactstrap';
import { cssClass } from '../components/wizard/cssSharedClasses';
import Tabs from '../components/tabs/Tabs';
import glossaryTabConfig from './configs/glossaryTabConfig';
import { AdSpeedLeaderboard } from '../components/advertisement/adspeed/leaderboard';

const GlossaryView = () => {
  return (
    <>
      <AdSpeedLeaderboard />
      <Container className={cssClass}>
        <h1 className="mb-3">Glossary View</h1>
        <p>
          The following information is provided in this year’s AXN Fact Book, unless otherwise
          unavailable.
        </p>
        <Tabs tabs={glossaryTabConfig} />
      </Container>
    </>
  );
};

export default GlossaryView;
