import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { get, chunk, groupBy, find } from 'lodash';
import { Col, Row } from 'reactstrap';
import { ContactData, DeleteContact } from '../../../../../actions/contactsActions';
import { CompanyType, useCompanyTypes } from '../../../../../api/Companies';
import ContactItem from '../../../shared/ContactItem';
import StepHeader from '../../../StepHeader';

interface ContactsListProps {
  instanceId: number;
  contentID: string;
  contentType: string;
  contacts: ContactData[];
  handleDelete?: DeleteContact;
  readonly?: boolean;
}

export interface ContactsListUrlParams {
  terminalID: string;
  contentID: string;
  contentType: string;
}
const emptyCompanyType: CompanyType = { companyType: '', companyTypeDes: '', sequenceNumber: 0 };
const CompanyContactsList = (
  props: ContactsListProps & RouteComponentProps<ContactsListUrlParams>
) => {
  const { contentID, match, contentType, contacts, handleDelete, readonly, instanceId } = props;
  const { url } = match;
  const { companyTypes } = useCompanyTypes(instanceId, +contentID, true); // todo user instance ID assigned in JWT token.

  const groupedContacts =
    contentType === 'companies'
      ? groupBy(contacts, (contact: ContactData) =>
          get(contact.associatedCompanyType, 'companyType', '')
        )
      : contacts;

  const contactRows = (contacts: ContactData[]) =>
    chunk(
      contacts.sort((a, b) => a.orderSequenceNumber - b.orderSequenceNumber),
      2
    );

  return (
    <div>
      {Object.keys(groupedContacts).map(groupKey => {
        const isCompanies = contentType === 'companies';
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const contactGroup = isCompanies ? groupedContacts[groupKey] : groupedContacts;
        const companyTypeObj: CompanyType =
          find(companyTypes, (companyType: CompanyType) => companyType.companyType === groupKey) ||
          emptyCompanyType;

        const { companyTypeDes, companyType } = companyTypeObj;

        return (
          <div key={groupKey}>
            <StepHeader stepTitle={companyTypeDes} Tag={'h3'} />
            {contactRows(contactGroup).map(items => (
              <Row key={window.btoa(items.map(item => item.contactId).toString())}>
                {items.map(contact => {
                  return (
                    <Col lg={6} key={contact.contactId}>
                      {contact.contact && (
                        <ContactItem
                          contacts={contacts}
                          contact={contact.contact}
                          contentID={contentID}
                          editUrl={url.replace(/\/[0-9]*$/, '')} // remove  the current contact number if existent
                          contentType={contentType}
                          managementResponsibilityTypeId={
                            contact.managementResponsibilityTypeId || 0
                          }
                          handleDelete={handleDelete}
                          readonly={readonly}
                          axnInstanceId={instanceId}
                          companyType={companyType}
                        />
                      )}
                    </Col>
                  );
                })}
              </Row>
            ))}
          </div>
        );
      })}
    </div>
  );
};
export default withRouter(CompanyContactsList);
