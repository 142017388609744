import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'reactstrap';
import { AuthoritiesList } from "../../actions/usersActions";
import './scss/footer.scss';

interface AdminFooterProps {
  isLoggedIn: boolean;
  authorities: AuthoritiesList;
}

export const AdminFooter = (props: AdminFooterProps) => {
  const year = new Date().getFullYear();
  return (
    <Container tag={'footer'} className={'py-5'} fluid>
      <Container id={'footer'}>
        <FontAwesomeIcon icon={faCopyright} size={'xs'} />
        {` Clarion ${year}`}
      </Container>
    </Container>
  );
};
