import React from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import { IndexableMasterListItem, MasterListActions } from './types';
import './style.scss';
import ListTable from './ListTable';

export interface TableColumn {
  id: string;
  title: string;
  width?: string;
}

/* initial handle of props */
export interface ListProps {
  columns: TableColumn[];
  list: IndexableMasterListItem[];
  actions: MasterListActions;
  currentPage: number;
  itemCount: number;
  listType: string;
  loading: boolean;
  additionalValues?: string[];
  readonly?: boolean;
}

/* Must be a Class Component to work with current print functionality. */
class MasterList extends React.PureComponent<ListProps> {
  render() {
    const {
      listType,
      itemCount,
      loading,
      list,
      columns,
      currentPage,
      actions,
      additionalValues,
      readonly
    } = this.props;
    return (
      <Container className="bg-light border rounded masterlist">
        <div className="py-3">
          Total {listType}: {itemCount}
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner size="lg" className="my-5" color="primary" />
          </div>
        ) : (
          <Row>
            <Col>
              <ListTable
                list={list}
                columns={columns}
                currentPage={currentPage}
                actions={actions}
                additionalValues={additionalValues}
                readonly={readonly}
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default MasterList;
