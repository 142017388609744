const pleaseInput = (fieldName: string) => `Please input a valid ${fieldName}.`;

const msgs = {
  address: pleaseInput('Address'),
  airportName: pleaseInput("Airport Name"),
  airportConfig: pleaseInput('Airport Configuration'),
  brandName: pleaseInput('Brand Name'),
  categoryName: pleaseInput('Category Name'),
  city: pleaseInput('City'),
  companyInfoText: pleaseInput('Company Info Text'),
  companyName: pleaseInput('Company Name'),
  companyType: pleaseInput('Company Type'),
  completeDate: pleaseInput('Completion Date'),
  concessionManagementType: pleaseInput('Concession Management'),
  country: pleaseInput('Country'),
  deplaneTotal: pleaseInput('Deplane Total'),
  displayName: pleaseInput('Display Name'),
  email: pleaseInput('Email'),
  enplaneTotal: pleaseInput('Enplane Total'),
  epDomesticTotal: pleaseInput('EP Domestic'),
  epIntlTotal: pleaseInput('EP International'),
  expansionPlannedText: pleaseInput('Expansion Plan Text'),
  expires: pleaseInput('Expiration Date'),
  fax: pleaseInput('Fax Number'),
  fiddler: 'Something went terribly wrong or someone is attempting to do bad things...',
  firstName: pleaseInput('First Name'),
  grossSalesTotal: pleaseInput('Gross Sales Total'),
  homePhone: pleaseInput("Home Phone Number"),
  jobCategory: pleaseInput('Job Catergory'),
  lastName: pleaseInput('Last Name'),
  mgmtRes: pleaseInput('Management Responsibility'),
  mobilePhone: pleaseInput('Mobile Phone Number'),
  nan: 'Please provide a valid number.',
  passengerTotal: pleaseInput('Passenger Total'),
  percents: (percentType: string) =>
    `Please ensure that ${percentType} percentages are equal to 100%`,
  phone: pleaseInput('Phone Number'),
  pleaseSelectValid: pleaseInput,
  postalCode: pleaseInput('Postal Code'),
  securityRole: pleaseInput('Security Role'),
  sqft: pleaseInput('Square Footage'),
  stateProvince: pleaseInput('State Province'),
  status: pleaseInput('Status'),
  tenantStatus: pleaseInput('Tenant Status'),
  terminalName: pleaseInput('Terminal Name'),
  website: pleaseInput('Web URL'),
  workPhone: pleaseInput("Work Phone Number"),
  zip: 'Must be 5 or 9 digits'
};

export default msgs;
