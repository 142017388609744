import { SpecialQuestion } from "../types";
import { axnAPI } from "./config";

export const getSpecialQuestionFromAPI = (id: number, aid: number, iid: number) =>
  axnAPI.get(`/airports/${aid}/instance/${iid}/specialquestion/${id}`);

export const addSpecialQuestionFromAPI = (
    aid: number,
    iid: number,
    specialQuestion: Partial<SpecialQuestion>
) =>
    axnAPI.post(`/airports/${aid}/instance/${iid}/specialquestion`, {
        ...specialQuestion
    });

export const updateSpecialQuestionFromAPI = (
    aid: number,
    iid: number,
    questionId: number,
    specialQuestion: Partial<SpecialQuestion>
    ) =>
    axnAPI.put(`/airports/${aid}/instance/${iid}/specialquestion/${questionId}`, {
        ...specialQuestion
    });
      