import * as React from 'react';
import { toast } from 'react-toastify';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { UserData } from '../../../actions/usersActions';
import { useAffiliatedAirports, deleteAirportAffiliation } from '../../../api/affiliations';
import { TableColumn } from '../../masterlist/MasterList';
import { MasterListActionButton } from '../../masterlist/types';
import { AffiliationData } from './CurrentAffiliations';
import { AirportData } from '../../../types';

const actions = (user: UserData, instanceId: number, refresh: () => void) => (
  airportId: number
): MasterListActionButton[] => [
  {
    to: `/admin/affiliate/airport/${user.userId}`,
    icon: faTrash,
    text: 'Delete',
    actionCallback: (event: React.MouseEvent<any, MouseEvent>) => {
      event.preventDefault();
      deleteAirportAffiliation(user.userId, airportId, instanceId).then(response => {
        if (response.kind === 'Success') {
          toast.success('Successfully deleted airport affiliation');
        } else if (response.kind === 'Failure') {
          toast.error(`Unable to delete affiliation: ${response.error}`);
        }
      }).then(refresh);
    }
  }
];

const columns: TableColumn[] = [
  { id: 'airportId', title: 'ID' },
  { id: 'airportName', title: 'Name' },
  { id: 'iataCode', title: 'IATA Code' },
  { id: 'isoCountryCode', title: 'Country' }
];

const airportModel: AffiliationData<AirportData> = {
  fetchData: useAffiliatedAirports,
  actions,
  columns
}

export default airportModel;
