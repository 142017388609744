import React from 'react';

const AdSpeedLeaderboard = () => {
  return (
    <div id={'adspeed-leaderboard'}>
      <iframe src="/load-ad.html" scrolling="no"></iframe>
    </div>
  );
};

export { AdSpeedLeaderboard };

/*
<!-- AdSpeed.com Tag 8.1 for [Ad] 728x90 - Placeholder 728x90 -->
<script type="text/javascript" src="https://g.adspeed.net/ad.php?do=js&aid=874415&oid=27889&wd=728&ht=90&target=_blank"></script>
<noscript><iframe width="728" height="90" src="https://g.adspeed.net/ad.php?do=html&aid=874415&oid=27889&wd=728&ht=90&target=_blank" frameborder="0" scrolling="no" allowtransparency="true" hspace="0" vspace="0"><a href="https://g.adspeed.net/ad.php?do=clk&aid=874415&oid=27889&wd=728&ht=90&pair=as" target="_blank"><img style="border:0;max-width:100%;height:auto;" src="https://g.adspeed.net/ad.php?do=img&aid=874415&oid=27889&wd=728&ht=90&pair=as" alt="i" width="728" height="90"/></a></iframe>
</noscript><!-- AdSpeed.com End -->
*/
