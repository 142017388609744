import { toast } from 'react-toastify';
import { axnAPI } from './config';
import useDataModel, { emptyPage, Page, convertSpringPageable } from '.';
import { UserData, UserDataWithPhones } from '../actions/usersActions';

// for the currently authenticated user
export const getCurrentUser = (): Promise<UserDataWithPhones> =>
  axnAPI.get('/users/settings').then(
    resp => resp.data,
    error => {
      toast.error(`Unable to fetch profile: ${error}`);
    }
  );

export const updateCurrentUser = (user: UserData): Promise<UserDataWithPhones> =>
  axnAPI.put(`/users/settings`, { ...user });

// for admins only
export const getUser = (userId: number): Promise<UserDataWithPhones> =>
  axnAPI.get(`/users/${userId}`).then(
    resp => resp.data,
    _error => {
      toast.error(
        `User doesn't exist, or another issue has occurred. Please try another user and if the issue persist reach out to support`
      );
    }
  );

export const getUsersFromAPI = (size = 10, page = 0): Promise<Page<UserData>> =>
  axnAPI.get(`/users`, { params: { size, page } }).then(
    resp => convertSpringPageable(resp.data),
    error => {
      toast.error(`Unable to fetch users: ${error}`);
      return emptyPage<UserData>();
    }
  );

const getAllUsersFromAPI = (): Promise<Page<UserData>> =>
  axnAPI.get(`/users/all`, { timeout: 30000, params: { sort: `lastName` } }).then(
    resp => {
      const { data } = resp;
      const totalElements = data.length !== undefined ? +data.length : 0;
      return {
        totalRecords: totalElements,
        numberOfElements: totalElements,
        totalPages: 1,
        pageSize: totalElements,
        currentPage: 1, // spring is zero-indexed
        content: data
      };
    },
    error => {
      toast.error(`Unable to fetch users: ${error}`);
      return emptyPage<UserData>();
    }
  );

export const createUserFromAPI = (user: UserData): Promise<UserData> =>
  axnAPI.post(`/users`, { ...user }).then(resp => resp.data);

export const updateUserByIDFromAPI = (id: number) => (user: UserData): Promise<UserData> =>
  axnAPI.put(`/users/${id}`, { ...user }).then(resp => resp.data);

interface DeleteResponse {
  msg: string;
}

export const deleteUserByIDFromAPI = (id: number): Promise<DeleteResponse> =>
  axnAPI.delete(`/users/${id}`).then(resp => resp.data);

export const getUserByIdFromAPI = (id: number): Promise<UserData> =>
  axnAPI.get(`/users/${id}`).then(resp => resp.data);

export const useListAllUsers = () =>
  useDataModel(() => getAllUsersFromAPI(), emptyPage<UserData>());

export const searchUsers = (keyword?: string) => (size = 10, page = 0) =>
  axnAPI.post(`/users/search?page=${page}&size=${size}&keyword=${keyword}`);

export const convertSearchUsers = (keyword?: string) => (
  size = 10,
  page = 0
): Promise<Page<UserData>> => {
  return searchUsers(keyword)(size, page).then(
    response => convertSpringPageable(response.data),
    _error => {
      toast.info(`Search returned zero results.`);
      return emptyPage<UserData>();
    }
  );
};
