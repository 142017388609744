import React, { CSSProperties, PropsWithChildren, useRef, useState } from 'react';
import moment from 'moment';
import { Alert, Col, FormGroup, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ErrorMessage } from 'formik';
import SingleDatePickerWrapper from './DatePickerWrapper';
import AutoComplete, { AutoCompleteOption } from './autocomplete/AutoComplete';
import ToolTip from '../tooltip/ToolTip';
import ToolTipToggle from '../tooltip/ToolTipToggle';

export type FormFieldTypes =
  | 'text'
  | 'autocomplete'
  | 'select'
  | 'textarea'
  | 'url'
  | 'email'
  | 'number'
  | 'checkbox'
  | 'date'
  | 'password'
  | 'hidden';

export type ChangeHandler = (
  e: React.ChangeEvent<HTMLInputElement>,
  selectedLabel?: string,
  selectedVal?: string
) => void;

export interface FormFieldsProp {
  name: string;
  label: JSX.Element | string;
  type: FormFieldTypes;
  placeholder?: string;
  value: any;
  labelValue?: any; // used for auto complete field
  labelName?: string; // used for auto complete field
  readOnly?: boolean;
  handleChange: ChangeHandler;
  handleBlur: (e: React.FocusEvent) => void;
  keyDownHandler?: React.KeyboardEventHandler;
  match: {
    params: any;
  };
  info?: string;
  helpText?: string;
  hidden?: boolean;
  disabled?: boolean;
  setFieldValue?: (...props: any) => void;
  col: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  className?: string;
  autoCompleteList?: AutoCompleteOption[];
  onFocus?: (e: React.FocusEvent, setInput: (...props: any) => void) => void;
  style?: CSSProperties;
  browserAutoComplete?: string;
  tooltipText?: string;
  displayPastDates?: boolean;
}

/* TODO: Change to default export currently used in a lot of spots so this will be a some what large change. */
const FormField = (props: PropsWithChildren<FormFieldsProp>) => {
  const {
    name,
    label,
    type,
    handleBlur,
    handleChange,
    keyDownHandler,
    onFocus,
    match,
    placeholder,
    value,
    labelValue,
    info,
    readOnly,
    col: { xs, sm, md, lg, xl },
    children,
    helpText,
    hidden,
    disabled,
    setFieldValue,
    autoCompleteList,
    labelName,
    className,
    style,
    browserAutoComplete,
    tooltipText,
    displayPastDates
  } = props;
  const { contentID } = match.params;
  const [toolTipState, setToolTipState] = useState(false);
  return (
    <Col
      xl={xl}
      lg={lg || true}
      md={md}
      sm={sm}
      xs={xs}
      className={hidden ? `py-0 my-0 ${className}` : className}
      style={style}>
      <FormGroup
        check={type === 'checkbox'}
        className={hidden ? 'py-0 my-0 position-relative' : 'position-relative'}>
        {tooltipText !== '' && (
          <ToolTipToggle visible={toolTipState} setVisible={setToolTipState} />
        )}
        <ToolTip text={tooltipText} visible={toolTipState} />
        {type !== 'date' && type !== 'autocomplete' && (
          <Label
            for={name}
            id={name}
            check={type === 'checkbox'}
            className={hidden ? 'd-none' : 'd-block'}>
            {type !== 'checkbox' && label}
            {info && <FontAwesomeIcon icon={faInfoCircle} className="float-right" />}
            {children ? (
              <Input
                type={type}
                className={type !== 'checkbox' ? 'form-control' : ''}
                name={name}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={keyDownHandler}
                value={value}
                readOnly={readOnly || false}
                disabled={disabled}
                autoComplete={browserAutoComplete}>
                {type === 'select' && <option value="">Please Select</option>}
                {children}
              </Input>
            ) : (
              <Input
                type={type}
                className={type !== 'checkbox' ? 'form-control' : ''}
                name={name}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={keyDownHandler}
                value={value}
                readOnly={readOnly || false}
                disabled={disabled}
                checked={value}
                autoComplete={browserAutoComplete}
              />
            )}
            {type === 'checkbox' && label}
            <small id={`${name}Help`} className="form-text text-muted">
              {helpText}
            </small>
          </Label>
        )}
        {type === 'date' && (
          <>
            <Label for={name} id={`${name}-${contentID}`} className={hidden ? 'd-none' : 'd-block'}>
              {label}
            </Label>
            {setFieldValue && (
              <SingleDatePickerWrapper
                initDate={value !== null ? moment(value) : null}
                name={name}
                setFieldValue={setFieldValue}
                key={value}
                displayPastDates={displayPastDates}
              />
            )}
            <small id={`${name}Help`} className="form-text text-muted">
              {helpText}
            </small>
          </>
        )}
        {type === 'autocomplete' && autoCompleteList && (
          <Label for={name} id={`${name}-${contentID}`} className={hidden ? 'd-none' : 'd-block'}>
            {label}
            {info && <FontAwesomeIcon icon={faInfoCircle} className="float-right" />}

            <AutoComplete
              value={value}
              labelValue={labelValue}
              onChange={(e, selectedLabel, selectedVal) => {
                // set's airport id and called formik handle change
                handleChange(e, selectedLabel, selectedVal);
              }}
              name={name}
              labelName={labelName || ''}
              options={autoCompleteList}
              onSelect={(e, selectedLabel, selectedVal) => {
                // set's airport id and called formik handle change
                handleChange({} as React.ChangeEvent<HTMLInputElement>, selectedLabel, selectedVal);
              }}
              setFieldValue={setFieldValue}
              onFocus={onFocus}
              onBlur={handleBlur}
              browserAutoComplete={browserAutoComplete}
            />
            <small className="form-text text-muted"></small>
            <small id={`${name}Help`} className="form-text text-muted">
              {helpText ||
                `This field will attempt to autocomplete, if you dont see options erase the current text and the options should repopulate.`}
            </small>
          </Label>
        )}

        <ErrorMessage component={Alert} className="alert-danger my-1" name={labelName || name} />
      </FormGroup>
    </Col>
  );
};

FormField.defaultProps = {
  tooltipText: '',
  disabled: false,
  hidden: false
};

export default FormField;
