import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { TerminalData } from '../../../../../actions/terminalsActions';
import { appHistory } from '../../../../../reducers';
import Spinner from '../../../../shared/spinner/Spinner';
import ResponsiveTable from "../../../../table/ResponsiveTable";

const TerminalList = (props: {
  terminals: TerminalData[];
  loading: boolean;
  url: string;
  readonly?: boolean;
}) => {
  const { terminals, loading, url, readonly } = props;
  return (
    <div className={'table-responsive'}>
      {loading ? (
        <Spinner />
      ) : (
        terminals && (
          <ResponsiveTable>
            <thead className={'thead-light'}>
              <tr>
                <th>Terminal</th>
                <th>Total</th>
                <th>+/-</th>
                <th>Deplaning</th>
                <th>Enplaning</th>
                <th>EP Domestic</th>
                <th>{`EP Int'l`}</th>
                {!readonly && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {terminals.map((item: TerminalData) => {
                const {
                  annualPercentChangeAmount,
                  terminal,
                  passengerTotal,
                  deplaneTotal,
                  enplaneTotal,
                  epDomesticTotal,
                  epIntlTotal,
                } = item;
                const { terminalShortName, terminalId } = terminal;
                return (
                  <tr key={terminalId}>
                    <td>{terminalShortName}</td>
                    <td>{passengerTotal || 0}</td>
                    <td>{annualPercentChangeAmount || 0}</td>
                    <td>{deplaneTotal || 0}</td>
                    <td>{enplaneTotal || 0}</td>
                    <td>{epDomesticTotal || 0}</td>
                    <td>{epIntlTotal || 0}</td>
                    {!readonly && (
                      <td>
                        <Button
                          color={'primary'}
                          onClick={() => {
                            appHistory.push(`${url}/${terminalId}`);
                          }}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </ResponsiveTable>
        )
      )}
    </div>
  );
};

export default TerminalList;
