import React from 'react';

const faqsConfig = [
    {
        type: 'Airports',
        qaList: [
            {
                question: 'How can I search for a specific airport?',
                answer: (
                    <>
                        <p>
                            In the main menu, click on the option <strong>AIRPORTS</strong>. On this page, you
              will have two options to find a specific airport:{' '}
                            <strong>Airport Location or Airport IATA</strong>. Once you click on your choice of
              search, you can either scroll to the airport you want or begin typing in the letters
              to the city or the IATA code. As you begin typing, the field will automatically
              provide you with a list of options.
            </p>
                        <p>
                            Once you have chosen the airport you want, click on <strong>SEARCH</strong>. Click on{' '}
                            <strong>RESET</strong> if you would like to begin your search again.
            </p>
                    </>
                )
            }
        ]
    },
    {
        type: 'Lease',
        qaList: [
            {
                question: 'What kind of information does the Lease Expiration report provide?',
                answer:
                    'The report displays all leases due to expire within the next two years in the following categories: Food/Beverage, Specialty Retail, Newsstand/Travel Essentials, Duty Free, Passenger Services, Lounges/Suites or Advertising.  Select category you would like to view simply by clicking on the category heading and collapsing the list of expiring leases in that category. The report lists the name of the concept, the operator(s), airport location, the number of locations in that airport, the square footage of the unit, and the date its lease expires.'
            }
        ]
    },
    {
        type: 'Contacts',
        qaList: [
            {
                question:
                    'Is there an option to search only contacts from airports? Or only contacts from companies?',
                answer: (
                    <>
                        <p>
                            Yes! When you choose <strong>CONTACT</strong> from the main menu, you first have the
              option to choose which type of contact you would like to search for: AIRPORTS or
              COMPANIES.
            </p>
                        <p>
                            When you choose <strong>AIRPORTS</strong>, you have the following contact types to
              choose from: <strong>AIRPORT CONCESSIONS</strong>, <strong>FOOD AND BEVERAGE</strong>,{' '}
                            <strong>NEWSSTAND/TRAVEL ESSENTIALS</strong>, <strong>LEASING</strong>,{' '}
                            <strong>PASSENGER SERVICES</strong>,<strong>ADVERTISING</strong>, and{' '}
                            <strong>OTHER</strong>. Choose the appropriate contact category you would like to
              search and in the search field enter in either the name of the person, the airport
              they work in, the city or the company name. When completed, either hit RETURN/ENTER on
              your keyboard or choose SEARCH. The results of that search will be displayed. Note
              that the broader the search term, the more likely effective a search it will be. Ex.
              Search for Paradies instead of Paradies Lagardere. Also note that if the contact name
              you are searching for does not appear, it is possible that they are listed in a
              different category and not the one you are searching in.
            </p>
                        <p>When you choose COMPANIES, you can simply search by name, company or city.</p>
                    </>
                )
            }
        ]
    },
    {
        type: 'Reports',
        qaList: [
            {
                question: 'Can I get a PDF or Excel report with the airports’ information?',
                answer:
                    'Yes! You have the ability to choose either a PDF or Excel format to download.'
            },
            {
                question: 'How do I search in the Reports section?',
                answer:
                    'Simply click in the SEARCH field and you will be given a list of REPORT options to choose from.  When you choose the type of report, you can then choose either a PDF or Excel file to download.'
            }
        ]
    },
    {
        type: 'Glossary',
        qaList: [
            {
                question:
                    'How can I access instructions for airport representatives to add or change listings?',
                answer:
                    'In the ‘Glossary’ section (located within the ‘About Us’ tab on the main menu) there is an option to download instructions. Just click on the link under the ‘Airport Instructions’ tab and a PDF of the instructions will begin downloading.'
            },
            {
                question: 'How can I contact AXN team members regarding the Fact Book?',
                answer:
                    'In ‘Contact Us’ section (located within the ‘About Us’ tab on the main menu), the office mailing address, phone and fax numbers, and emails and phone numbers of all AXN team members are provided. Feel free to get in touch however you find convenient. '
            }
        ]
    }
    /* {
      type: 'Others',
      qaList: [
        {
          question: 'How to login to the AX Fact Book Online?',
          answer:
            'Go to the URL http://axnfactbook.com/ and enter the username and password you’ve been provided to log into your account.'
        }
      ]
    } */
];

export default faqsConfig;
