import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { cssClass } from '../components/wizard/cssSharedClasses';

interface RouteParams {
  isLoggedIn: boolean;
}

const IndexView = (props: RouteParams) => {
  const { isLoggedIn } = props;

  const getLoginButton = () => {
    return (
      <button className="btn btn-primary mt-3 p-3 btn-block" type="submit">
        <a href="/login">Login</a>
      </button>
    );
  };

  const getActionButton = () => {
    return isLoggedIn ? <div></div> : getLoginButton();
  };

  return (
    <Container className={`${cssClass} p-5`}>
      <h1 className={'mb-3'}>
        Welcome to the AXN Online Fact Book (OFB), the premier resource for revenue data on airports
        in North America.
      </h1>
      <p>
        This online service, like its hard copy version, contains comprehensive data from major
        airports on:
      </p>
      <div className={'my-3'}>
        <Row>
          <Col xl={6}>Food and beverage</Col>
          <Col xl={6}>Specialty retail</Col>
        </Row>
        <Row>
          <Col xl={6}>Newsstand and Travel Essentials</Col>
          <Col xl={6}>Duty-free</Col>
        </Row>
        <Row>
          <Col xl={6}>Advertising</Col>
          <Col xl={6}>Parking</Col>
        </Row>
        <Row>
          <Col xl={12}>Car rental concessions</Col>
        </Row>
      </div>
      <p>
        Additionally, the OFB provides detailed information on all of the key companies doing
        business in airports. All data is based on previous calendar year information.
      </p>
      <p>
        For those unfamiliar with the figures contained within the OFB, please review the Glossary
        page to understand precisely the meaning of the various data points. Also, please navigate
        all the menu options for a variety of valuable and useful reports, such as the
        top-performing airports by sales per enplanement; leases due to expire within the next 18
        months; as well as a snapshot of important aspects of every airport.
      </p>
      <p>
        Each of the participating airports included in the OFB have provided their information by
        manually entering it into the AXN customized OFB program. Each participant has the ability
        to review the data before submitting it for publication. Likewise, each concessions company
        has directly entered its own information. All participants in the OFB have been informed of
        the high priority placed on the accuracy of this information and AXN is not responsible for
        any discrepancies that may occur.
      </p>
      <p>
        AXN is grateful to all of the participants who took the time to enter their information,
        making this service the most valuable, one-of-a-kind resource in the airport revenue
        industry.
      </p>
      <p>
        As always, AXN is interested to know what you think of the new OFB. Please give us a call at
        561-257-1024 or contact us at info@airportxnews.com.
      </p>
      <p>
        The AXN Fact Book and Online Fact Book is published by Airport Experience News, which also
        publishes the Airport Experience News magazine and hosts the annual Airport Experience
        Conference & Exhibition.
      </p>
      <p className={'font-weight-bold'}>
        For more information, please call 561-257-1024 or fax your questions to 561.228.0882. Or,
        visit our Web site at{' '}
        <a href={'https://www.airportxnews.com'}>https://www.airportxnews.com</a>
      </p>
      <p>
        If you are not already a subscriber and are interested in gaining access, you can view
        subscription pricing and purchase access at{' '}
        <a href={'https://airportxnews.com/plans/'}>https://airportxnews.com/plans/</a>
      </p>

      <Container className={'action-buttons'}>
        <Row>
          <Col sm={2} className={'text-center'}>
            <button className="btn btn-light mt-3 p-3 btn-block" type="button">
              <a
                href="https://airportxnews.com/email-signup/"
                target="_blank"
                rel="noopener noreferrer">
                Join Our Email List
              </a>
            </button>
          </Col>
          <Col sm={2} className={'text-center'}>
            {getActionButton()}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default IndexView;
