import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { getNavLinks } from '../../core/navigation/NavigationHelper';
import { AuthoritiesList, AuthorityRoles } from '../../actions/usersActions';

interface FrontendHeaderProps {
  isLoggedIn: boolean;
  authorities: AuthoritiesList;
}
const sharedStyle = { top: '0', zIndex: 9998 };

export const adminCogPosition = {
  ...sharedStyle,
  right: '10%'
};

const cogButtonSize = '36px';
const cogGap = '4px';
export const userCogPosition = {
  ...sharedStyle,
  right: `calc(10% - ${cogButtonSize} - ${cogGap})`
};

export const FrontendHeader = (props: FrontendHeaderProps) => {
  const { isLoggedIn, authorities } = props;
  const isAdmin = !!authorities.find(
    roleObj => roleObj.authority === ('ROLE_ADMIN' as AuthorityRoles)
  );
  const PUB_PATH = process.env.PUBLIC_URL;

  const toggler = useState(false);
  const [isOpen, toggleNavbar] = toggler;
  return (
    <header className="container-fluid">
      <Navbar id="nav" className={`nav navbar-expand-xl p-0${isOpen ? ' active' : ''}`} dark>
        <NavbarBrand href="/">
          <img alt="AXN Factbook" src={`${PUB_PATH}/axn-logo.png`} />
        </NavbarBrand>
        <NavbarToggler onClick={() => toggleNavbar(!isOpen)} />
        <Collapse isOpen={isOpen} navbar>
          <Nav role="navigation" className="ml-auto navbar-nav">
            {getNavLinks(isLoggedIn, authorities, toggleNavbar)}
          </Nav>
        </Collapse>
      </Navbar>
      {isLoggedIn && (
        <>
          {isAdmin && (
            <div
              className="position-fixed d-inline p-2 bg-dark text-white rounded-bottom"
              style={adminCogPosition}>
              <Link
                to="/admin"
                onClick={() => {
                  toggleNavbar(false);
                }}>
                <FontAwesomeIcon icon={faCogs} />
              </Link>
            </div>
          )}
          <div
            className="position-fixed d-inline p-2 bg-dark text-white rounded-bottom"
            style={userCogPosition}>
            <Link
              to="/settings"
              onClick={() => {
                toggleNavbar(false);
              }}>
              <FontAwesomeIcon icon={faUserCog} />
            </Link>
          </div>
        </>
      )}
    </header>
  );
};
