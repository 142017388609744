import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { cssClass } from '../../wizard/cssSharedClasses';
import CompanySelectField from '../../forms/CompanySelectField';
import InstanceSelectField from '../../forms/InstanceSelectField';
import {
  createCompanyInstance,
  getCompanyFromAPI,
  getInstanceStatusTypes
} from '../../../api/Companies';
import FormField from '../../forms/FormField';
import { appHistory, AppState } from '../../../reducers';
import { CompanyInstance } from '../../../actions/companiesActions';
import { AxnInstanceStatus, YesOrNo } from '../../../types';
import GoBack from '../../buttons/GoBack';

const AddCompanyInstanceView = (props: RouteComponentProps<{}> & { isAdmin: boolean }) => {
  const { match, isAdmin } = props;
  const [statusTypes, setStatusTypes] = useState(new Array<string>());

  useEffect(() => {
    getInstanceStatusTypes().then(setStatusTypes);
  }, []);

  return (
    <Container className={cssClass}>
      <h1>Add Company Instance</h1>
      <Formik
        onSubmit={values => {
          getCompanyFromAPI(values.companyId).then(
            response => {
              const companyInstance: CompanyInstance = {
                company: response,
                axnInstanceStatus: values.instanceStatus as AxnInstanceStatus,
                dbeFlag: (values.dbeFlag ? 'Y' : 'N') as YesOrNo,
                modifiedDate: moment().format('YYYY-MM-DD'),
                companyInstanceTypeList: values.companyInstanceTypeList
              };

              createCompanyInstance(values.instanceId, companyInstance).then(
                () => {
                  toast.success('Created New Company Instance');
                  appHistory.goBack();
                },
                () => toast.error('failed to create Company Instance')
              );
            },
            () => toast.warn('Failed to get Company Data!')
          );
        }}
        initialValues={{
          instanceStatus: 'INPROGRESS',
          companyId: '',
          companyName: '',
          axnInstanceMasterStatus: 'ACTIVE',
          axnInstanceName: '',
          instanceId: moment().year(),
          instanceYear: moment().year(),
          dbeFlag: false,
          companyInstanceTypeList: []
        }}
        render={({ values, handleChange, handleBlur, setFieldValue }) => {
          return (
            <Form>
              <Row>
                <CompanySelectField
                  values={values}
                  match={match}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ xl: 12, lg: 12 }}
                />
                <InstanceSelectField
                  values={values}
                  match={match}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  showLabel
                  col={{ xl: 12, lg: 12 }}
                  isAdmin={isAdmin}
                />
                <Col>
                  <label htmlFor="instanceStatus">
                    Instance Status:
                    <select
                      className="form-control"
                      defaultValue="INPROGRESS"
                      id="instanceStatus"
                      name="instanceStatus"
                      onChange={e => handleChange(e.target.value)}>
                      {statusTypes.map(status => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </label>
                </Col>
                <Col xl={12} lg={12}>
                  <FormField
                    className={'px-0 py-3'}
                    name="dbeFlag"
                    label="Is this Company Instance a certified Disadvantaged Business Enterprise?"
                    // info={'?'}
                    type="checkbox"
                    match={match}
                    value={values.dbeFlag}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ xl: 12, lg: 12 }}
                  />
                </Col>
                <Col xl={12} lg={12}>
                  <ButtonGroup>
                    <GoBack />
                    <Button type={'submit'} color={'primary'}>
                      <FontAwesomeIcon icon={faPlus} /> Add Company to Instance
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Form>
          );
        }}
      />
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  isAdmin: state.users.isAdmin
});

export default withRouter(connect(mapStateToProps, null)(AddCompanyInstanceView));
