import './scss/header.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown
} from 'reactstrap';
import {
  faLongArrowAltLeft,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { panelFeatures } from '../../resources/main/panel/config';
import { AuthenticatedUser, AuthoritiesList } from "../../actions/usersActions";

const PUB_PATH = process.env.PUBLIC_URL;

interface NavLinkItem {
  path: string;
  bullets: string[];
  title: string;
  icon: IconDefinition;
  children?: Array<NavLinkItem>;
}

const NavTextSpan = (title: string, icon: IconDefinition) => (
  <>
    <FontAwesomeIcon className={'nav-icon'} icon={icon} />
    <span className={'nav-item-text'}>{title}</span>
  </>
);

const getNavDropdownItem = (item: NavLinkItem, key: number) => {
  const { path, title, icon } = item;
  return (
    <UncontrolledDropdown setActiveFromChild key={key} tag={'li'} className={'nav-item'}>
      <DropdownToggle
        tag={'a'}
        to={`/admin${path}`}
        className={'nav-link '}
        caret>
        {NavTextSpan(title, icon)}
      </DropdownToggle>
      <DropdownMenu>
        {item.children &&
          item.children.map((child, key) => (
            <DropdownItem key={key}>
              {getNavItem(child, key, path)}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const getNavItem = (item: NavLinkItem, key: number, parent = '') => {
  const isChild = parent.length > 0;
  const { title, icon, path } = item;
  const finalPath = isChild ? `${parent}${path}` : path;

  return (
    <Link to={`/admin${finalPath}`} key={key}>
      {NavTextSpan(title, icon)}
    </Link>
  );
};

interface AdminHeaderProps {
  isLoggedIn: boolean;
  authorities: AuthoritiesList;
}

export const AdminHeader = (props: AdminHeaderProps) => {
  const {isLoggedIn} = props;
  const toggler = useState(false);
  const [isOpen, toggleNavbar] = toggler;
  const logo = {
    alt: `AXN Factbook`,
    src: `${PUB_PATH}/images/axn-logo.png`
  };

  return (
    <Navbar
      tag={'header'}
      sticky={'top'}
      id={'admin-nav'}
      className={'nav navbar-expand-xl navbar-light'}
      dark>
      <Container>
        <NavbarBrand href="/" tag={Link} to={'/admin'}>
          <img alt={logo.alt} src={logo.src} />
        </NavbarBrand>
        <NavbarToggler onClick={() => toggleNavbar(!isOpen)} />
        <Collapse isOpen={isOpen} navbar>
          <Nav role="navigation" className={'ml-auto navbar-nav'}>
            {panelFeatures.map((item, key) =>
              item.children
                ? getNavDropdownItem(item, key)
                : getNavItem(item, key)
            )}
            <NavItem>
              {/* TODO: this path needs to be dynamic based on environment */}
              <a className="nav-link" href={'/'}>
                {NavTextSpan('Back to Site', faLongArrowAltLeft)}
              </a>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};