import React from 'react';

const MediumAd = (props: { imageSrc: string; targetUrl: string }) => {
  const { imageSrc, targetUrl } = props;
  return (
    <div className="advertisement-medium">
      <a href={targetUrl}>
        <img src={imageSrc} />
      </a>
    </div>
  );
};

export { MediumAd };
