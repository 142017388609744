import { Table } from 'reactstrap';
import React from 'react';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import Spinner from '../../../../shared/spinner/Spinner';
import { TerminalData, TerminalsList } from '../../../../../actions/terminalsActions';
import ResponsiveTable from '../../../../table/ResponsiveTable';

type ExistingTerminalsProps = {
  airportName: string;
  terminalsLoading: boolean;
  terminals: TerminalsList;
  bordered?: boolean;
};
const ExistingTerminals = (props: ExistingTerminalsProps) => {
  const { airportName, terminalsLoading, terminals, bordered } = props;
  return (
    <div className={bordered ? cssClass : ''}>
      <StepHeader Tag={'h3'} stepTitle={`Existing Terminals for ${airportName}`} />
      {terminalsLoading && <Spinner />}
      {!terminalsLoading && terminals.length > 0 ? (
        <ResponsiveTable>
          <thead>
            <tr>
              <th>ID</th>
              <th>Terminal</th>
              <th>Abbreviation</th>
            </tr>
          </thead>
          <tbody>
            {terminals.map((terminal: TerminalData, key: number) => {
              const { terminalId, terminalName, terminalShortName } = terminal.terminal;

              return (
                <tr key={key}>
                  <th scope={'row'}>{terminalId}</th>
                  <td>{terminalName}</td>
                  <td>{terminalShortName}</td>
                </tr>
              );
            })}
          </tbody>
        </ResponsiveTable>
      ) : (
        <StepHeader stepTitle={'No Terminals Available'} Tag={'h4'} />
      )}
    </div>
  );
};

ExistingTerminals.defaultProps = {
  bordered: false
};

export default ExistingTerminals;
