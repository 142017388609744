import { Col, Table } from 'reactstrap';
import * as React from 'react';
import { AirportData } from '../../../../../types';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import Spinner from '../../../../shared/spinner/Spinner';

export const ReviewSpecifics = (props: { airportLoading: boolean; airport: AirportData }) => {
  const { airportLoading, airport } = props;
  return (
    <Col className={cssClass}>
      <StepHeader Tag={'h3'} stepTitle={'Airport Specifics'} />
      {airportLoading ? (
        <Spinner />
      ) : (
        <Table striped bordered responsive>
          <tbody>
            <tr>
              <th scope={'row'} style={{ width : "40%" }}>Pre/Post Security % of SqFt</th>
              <td>Pre: {airport.preSecurityPercentAmount}%</td>
              <td>Post: {airport.postSecurityPercentAmount}%</td>
            </tr>
            <tr>
              <th scope={'row'}>Business to Leisure Ratio</th>
              <td colSpan={2}>
                {airport.businessPercentAmount}/{airport.leisurePercentAmount}
              </td>
            </tr>
            <tr>
              <th scope={'row'}>Origination & Destination to Transfer Ratio</th>
              <td colSpan={2}>
                {airport.odPercentAmount}/{airport.transferPercentAmount}
              </td>
            </tr>
            <tr>
              <th scope={'row'}>Average Dwell Time</th>
              <td colSpan={2}>{airport.dwellTimeAmount} min.</td>
            </tr>
          </tbody>
        </Table>
      )}
    </Col>
  );
};