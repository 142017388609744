import axios, { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Airline } from '../types';
import { airportsAPI } from './Airports';
import { axnAPI } from './config';

export const airlinesAPI = `/airlines`;

export const getAllAirlinesFromAPI = (config: AxiosRequestConfig) =>
  axnAPI.get(`${airlinesAPI}`, config);

export const useAirlines = () => {
  const [availableAirlines, setAvailableAirlines] = useState(
    new Array<Airline>()
  );
  const [loadingAvailableAirlines, setLoadingAvailableAirlines] = useState(
    false
  );

  useEffect(() => {
    const source = axios.CancelToken.source();
    setLoadingAvailableAirlines(true);
    getAllAirlinesFromAPI({ cancelToken: source.token })
      .then(response => {
        setLoadingAvailableAirlines(false);
        setAvailableAirlines(response.data);
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          setLoadingAvailableAirlines(false);
          toast.error(`Error fetching data: ${e}`)
        }
      });
    return () => {
      source.cancel('Cancelling in cleanup');
    };
  }, [availableAirlines.length]);
  return { availableAirlines, loadingAvailableAirlines };
};

/* Terminal Airlines - Endpoints */
export const getTerminalAirlinesFromAPI = (
  airportId: number,
  instanceId: number,
  terminalId: number
) =>
  axnAPI.get(
    `${airportsAPI}/${airportId}/instance/${instanceId}/terminals/${terminalId}/airlines`
  );

export const getTerminalAirlineFromAPI = (
  airportId: number,
  instanceId: number,
  terminalId: number,
  airlineId: string
) =>
  axnAPI.get(
    `${airportsAPI}/${airportId}/instance/${instanceId}/terminals/${terminalId}/airlines/${airlineId}`
  );

export const addTerminalAirlineFromAPI = (
  airportId: number,
  instanceId: number,
  terminalId: number,
  airlineId: string,
  airline: Airline
) =>
  axnAPI.post(
    `${airportsAPI}/${airportId}/instance/${instanceId}/terminals/${terminalId}/airlines/${airlineId}`,
    { ...airline }
  );

export const deleteTerminalAirlineFromAPI = (
  airportId: number,
  instanceId: number,
  terminalId: number,
  airlineId: string
) =>
  axnAPI.delete(
    `${airportsAPI}/${airportId}/instance/${instanceId}/terminals/${terminalId}/airlines/${airlineId}`
  );
