import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { TerminalAirlineList } from '../../../../../types';
import './_dominantAirline.scss';
import { TerminalMatchProps } from './TerminalSpecificForm';
import {
  addTerminalAirlineFromAPI,
  deleteTerminalAirlineFromAPI,
  useAirlines
} from '../../../../../api/Airlines';
import DominantAirlinesSelectField from '../../../../forms/DominantAirlinesSelectField';

interface DominantAirlinesProps {
  airlineTerminals: TerminalAirlineList;
  match: TerminalMatchProps;
  axnInstanceId: number;
}

const DominantAirlines = (props: DominantAirlinesProps) => {
  const { airlineTerminals, match, axnInstanceId } = props;
  const { terminalID, contentID } = match.params;
  const [airlines, setAirlines] = useState(airlineTerminals);
  useEffect(() => setAirlines(airlineTerminals), [airlineTerminals, airlineTerminals.length]);

  const handleDelete = (airlineId: string) => () => {
    /* TODO: Move this to redux maybe? */
    deleteTerminalAirlineFromAPI(+contentID, axnInstanceId, +terminalID, airlineId)
      .then(() => {
        /* TODO: better response handling. */
        toast.success('Deleted Successfully');
        const filteredAirlines = airlines.filter(
          airline => airline.airline.airlineId !== airlineId
        );
        setAirlines(filteredAirlines);
      })
      /* TODO: better error handling. */
      .catch(e => toast.error(e));
  };

  const { availableAirlines } = useAirlines(); /* TODO: move to redux to gain tracking? */

  return (
    <div className={'dominantAirlineWrapper'}>
      <h3 className={'wizard-instruction'}>4.) Set Terminal Dominant Airline/s:</h3>
      <div className={'dominantAirlinesFields'}>
        <div className={'existing-dominant-airlines container-fluid'}>
          <Row className={'dominant-airline-col-names'}>
            <Col sm>ID</Col>
            <Col sm>Name</Col>
            <Col sm>Status</Col>
            <Col sm />
          </Row>
          {airlines.map(({ airline: { airlineId, airlineName, axnStatus } }) => {
            return (
              <Row key={airlineId}>
                <Col sm>{airlineId}</Col>
                <Col sm>{airlineName}</Col>
                <Col sm>{axnStatus}</Col>
                <Col sm>
                  <ButtonGroup>
                    <Button color={'primary'} onClick={handleDelete(airlineId)}>
                      Delete <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            );
          })}
        </div>
        <div className={'my-3'}>
          <Formik
            onSubmit={(values, formikActions) => {
              const airlineDoesntExist = !find(
                airlines,
                airline => airline.airline.airlineId === values.airlineId
              );
              const selectedAirline = find(availableAirlines, {
                airlineId: values.airlineId
              });
              if (airlineDoesntExist)
                if (selectedAirline)
                  addTerminalAirlineFromAPI(
                    +contentID,
                    axnInstanceId,
                    +terminalID,
                    values.airlineId,
                    selectedAirline
                  )
                    .then(() => setAirlines([...airlines, { airline: selectedAirline }]))
                    .catch(e => toast.error(e));

              formikActions.resetForm();
            }}
            initialValues={{ airlineId: '000', airlineName: '' }}
            render={({ handleBlur, handleChange, values, setFieldValue }) => (
              <Form>
                <Row>
                  <DominantAirlinesSelectField
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    match={match}
                    setFieldValue={setFieldValue}
                  />
                </Row>
                <ButtonGroup>
                  <Button color={'primary'} type={'submit'}>
                    Add Dominant Airline <FontAwesomeIcon icon={faPlane} />
                  </Button>
                </ButtonGroup>
              </Form>
            )}
          />
        </div>
      </div>
    </div>
  );
};
export default DominantAirlines;
