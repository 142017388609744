import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField, { ChangeHandler } from './FormField';
import { useConcessionManagementTypes } from '../../api/ConcessionManagementTypes';
import { ConcessionManagementType } from '../../types';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

/* TODO: Fix the any */
const ConcessionManagementSelectField = (props: {
  values: ConcessionManagementType;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: ChangeHandler;
  handleBlur: (e: React.FocusEvent<Element>) => void;
}) => {
  const { values, match, handleChange, handleBlur, setFieldValue } = props;
  const {
    concessionManagementTypes,
    concessionManagementTypesLoading
  } = useConcessionManagementTypes();
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();

  const [labelValue, setLabelValue] = useState(values.concessionManagementType);
  const [value] = useState(values.concessionManagementTypeId);

  useEffect(() => {
    const aclist = concessionManagementTypes.map(mgmtType => ({
      ...mgmtType,
      label: mgmtType.concessionManagementType,
      key: `${mgmtType.concessionManagementTypeId}`,
      value: `${mgmtType.concessionManagementTypeId}`
    }));
    setAutoCompleteList(aclist);
  }, [concessionManagementTypes]);

  useEffect(() => {
    const mgmtType = concessionManagementTypes.find(
      type => type.concessionManagementType === values.concessionManagementType
    );

    if (mgmtType) {
      setLabelValue(mgmtType.concessionManagementType);
      setFieldValue('concessionManagementType', mgmtType.concessionManagementType, false);
    }
  }, [
    concessionManagementTypes,
    setFieldValue,
    values.concessionManagementType,
    values.concessionManagementTypeId
  ]);

  if (concessionManagementTypesLoading) return <SelectFieldSpinner />;
  return (
    <FormField
      name="concessionManagementTypeId"
      labelName="concessionManagementType"
      label="Concession Management Type"
      type="autocomplete"
      match={match}
      value={value}
      labelValue={labelValue}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      handleBlur={handleBlur}
      autoCompleteList={autoCompleteList}
      col={{ lg: 12 }}
    />
  );
};

export default ConcessionManagementSelectField;
