import axios from 'axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CompanyData, CompanyInstance, CompanyInstanceType } from '../actions/companiesActions';
import { AirportData, AxnInstanceStatus, CompanyLocation, CompanyLookup } from '../types';
import { initialInfoFormValuesForAPI } from '../components/wizard/company/steps/info/InfoStep';
import { axnAPI } from './config';
import { PageableData, SpringPage, convertSpringPageable, useDataModel, emptyPage, createPage, Page } from '.';
import {
  getCompanyAirportLocationByTypeFromAPI,
  getCompanyAirportLocationsFromAPI
} from './CompanyAirportLocation';

/* Main Path for most Company related endpoint calls. */
export const companiesAPI = `/companies`;

/* Get all companies, without pagination. Good for use with drop down selects. */
/* Returns a response in a promise containing list of company names and identifiers. */
export const getAllCompaniesFromAPI = () => axnAPI.get(`${companiesAPI}/all`);

export const getCompaniesForLookup = (instanceID: number) => (
    page: number,
    size: number
  ): Promise<Page<CompanyLookup>> => 
    axnAPI.get(`${companiesAPI}/instance/${instanceID}/company/lookup`)
        .then(resp => createPage(resp.data) );

export const getAllInstanceCompaniesFromAPI = (
  iid: number,
  size: number,
  page: number,
  keyword: string,
  status = 'ALL',
  dbeOnly = false
): Promise<SpringPage<CompanyInstance>> =>
  axnAPI
    .post(`${companiesAPI}/instance/${iid}/paged/search`, null, {
      params: {
        size,
        page: page - 1,
        status,
        keyword,
        dbeOnly,
        sort: 'company_companyName'
      }
    })
    .then(resp => resp.data as SpringPage<CompanyInstance>);

/* Get singular company from API using the Company ID. */
/* Returns a response in a promise containing a single company object. */
export const getCompanyFromAPI = (id: string): Promise<CompanyData> =>
  axnAPI.get(`${companiesAPI}/${id}`).then(resp => resp.data as CompanyData);

/* Get singular company instance from API. */
/* Returns a response in a promise containing a single company instance object. */
export const getCompanyInstanceFromAPI = (id = 0, instanceId: number) =>
  axnAPI.get(`${companiesAPI}/${id}/instance/${instanceId}`);

type CompanyInfoFormValues = typeof initialInfoFormValuesForAPI;
export const updateCompanyInfoFromAPI = (
  companyId: number,
  companyInfo: CompanyInstance,
  instanceId: number
) =>
  axnAPI.put(`${companiesAPI}/${companyId}/instance/${instanceId}`, {
    ...companyInfo
  });

export const createCompanyInstance = (iid: number, companyInstance: CompanyInstance) =>
  axnAPI.post(`${companiesAPI}/instance/${iid}/`, companyInstance);

/*
 * Company Custom React Hooks
 */
export const useAllCompanies = () => {
  const [companies, setCompanies] = useState(new Array<CompanyData>());
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const source = axios.CancelToken.source();
  useEffect(() => {
    let mounted = true;
    setCompaniesLoading(true);
    getAllCompaniesFromAPI()
      .then(response => {
        if (mounted) setCompanies(response.data);
        if (mounted) setCompaniesLoading(false);
      })
      .catch(e => {
        setCompaniesLoading(false);
        return !axios.isCancel(e) && toast.error(e);
      });
    return () => {
      mounted = false;
      source.cancel('Cancelling in cleanup');
    };
  }, [companies.length]);
  return { companies, companiesLoading };
};

export const useAllCompaniesForInstance = (instanceId: number): PageableData<CompanyLookup> =>
  useDataModel(
    getCompaniesForLookup(instanceId),
    emptyPage<CompanyLookup>(),
    instanceId
  );

/*
 * Company Types - Custom React Hooks
 */

/* prevent nulls with initializer */
const initCompanyType = {
  companyType: '',
  companyTypeDes: '',
  sequenceNumber: 20
};
export type CompanyType = typeof initCompanyType;

export const useCompanyTypes = (instanceId: number, companyId: number, all: boolean = true) => {
  const [companyTypes, setCompanyTypes] = useState(new Array<CompanyType>());
  const [companyTypesLoading, setCompanyTypesLoading] = useState(false);
  const apiString = all
    ? `${companiesAPI}/types`
    : `${companiesAPI}/${companyId}/instance/${instanceId}/types`;

  useEffect(() => {
    setCompanyTypesLoading(true);
    axnAPI
      .get(apiString)
      .then(response => {
        if (response.data[0].companyInstanceTypePK) {
          setCompanyTypes(
            response.data.map(
              (companyInstanceType: CompanyInstanceType) =>
                companyInstanceType.associatedCompanyType
            )
          );
        } else {
          setCompanyTypes(response.data);
        }
        setCompanyTypesLoading(false);
      })
      .catch(() => {
        setCompanyTypesLoading(false);
        toast.error('Failed Getting Company Types');
      });
  }, [companyTypes.length]);

  return { companyTypes, companyTypesLoading };
};

/* Handle company types for current company and instance. */

/* This is the state of company type as of 09/20/2019 */
export type CompanyTypes =
  | 'AirportAd'
  | 'Architects'
  | 'Aviation'
  | 'Concessions'
  | 'ConcessionsSuppliers'
  | 'DutyFree'
  | 'Food'
  | 'Kiosk'
  | 'NewsandGift'
  | 'None'
  | 'Passenger'
  | 'PrivateDev'
  | 'Specialty'
  | 'Technology'
  | 'TEST'
  | 'TradePubs';

export const addCompanyTypesFromAPI = (
  companyId = 0,
  instanceId: number,
  companyType: CompanyTypes,
  companyInstanceType: CompanyInstanceType
) => {
  return axnAPI.post(`${companiesAPI}/${companyId}/instance/${instanceId}/types/${companyType}`, {
    ...companyInstanceType
  });
};
export const updateCompanyTypesFromAPI = (
  id = 0,
  companyType: CompanyTypes,
  instanceId: number
) => {
  return axnAPI.put(`${companiesAPI}/${id}/instance/${instanceId}/types/${companyType}`);
};
export const deleteCompanyTypeFromAPI = (id = 0, companyType: CompanyTypes, instanceId: number) => {
  return axnAPI.delete(`${companiesAPI}/${id}/instance/${instanceId}/types/${companyType}`);
};

export const getCompanyLocationsFromAPI = (
  companyId: number,
  instanceId: number,
  page: number,
  size: number
) => {
  return axnAPI.get(`${companiesAPI}/${companyId}/instance/${instanceId}/locations`, {
    params: { page, size }
  });
};

interface PagedObj {
  currentPage: number;
  numberOfElements: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export type UseLocations = (
  companyId: number,
  instanceId: number,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  setPageSize: Dispatch<SetStateAction<number>>,
  page: number,
  size: number,
  type: string
) => { locations: CompanyLocation[]; loading: boolean; pageObj: PagedObj };

export type AirportCompanyDTO = AirportData & CompanyData & { companyType: CompanyType };

export const useCompanyLocations: UseLocations = (
  companyId: number,
  instanceId: number,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  setPageSize: Dispatch<SetStateAction<number>>,
  page: number,
  size: number,
  type: string = 'ALL'
) => {
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState(new Array<CompanyLocation>());
  const [pageObj, setPageObj] = useState({
    currentPage: 0,
    numberOfElements: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0
  });

  useEffect(() => {
    setLoading(true);
    getCompanyAirportLocationsFromAPI(companyId, instanceId, page - 1, size)
      .then(response => {
        const { data } = response;
        const { pageable, numberOfElements, totalElements, totalPages } = data;
        const { pageNumber, pageSize } = pageable;
        setLocations(
          data.content.map((airportCompanyDTO: AirportCompanyDTO) => ({
            ...airportCompanyDTO.company,
            ...airportCompanyDTO.airport,
            ...airportCompanyDTO.companyType
          }))
        );
        setPageObj({
          currentPage: pageNumber + 1, // supplement for pagination component being 1 indexed and spring boot being zero indexed.
          pageSize,
          numberOfElements,
          totalPages,
          totalRecords: totalElements
        });
        setCurrentPage(pageNumber + 1); // supplement for pagination component being 1 indexed and spring boot being zero indexed.
        setPageSize(pageSize);
        setLoading(false);
      })
      .catch(error => {
        toast.error(`Error fetching data: ${error}`);
        setLoading(false);
      });
  }, [companyId, instanceId, size, page]);

  return { locations, loading, pageObj };
};

export const useCompanyAirportLocations: UseLocations = (
  companyId: number,
  instanceId: number,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  setPageSize: Dispatch<SetStateAction<number>>,
  page: number,
  size: number,
  type: string = 'ALL'
) => {
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState(new Array<CompanyLocation>());
  const [pageObj, setPageObj] = useState({
    currentPage: 0,
    numberOfElements: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0
  });

  useEffect(() => {
    setLoading(true);
    getCompanyAirportLocationByTypeFromAPI(companyId, instanceId, type, page - 1, size)
      .then(response => {
        const {
          airportList,
          companyName,
          companyTypeDes,
          companyType,
          numberOfElements,
          pageable,
          totalElements,
          totalPages
        } = response.data;
        const { pageNumber, pageSize } = pageable;
        setLocations(
          airportList.map((airport: any) => ({
            ...airport,
            companyName,
            companyTypeDes,
            companyType
          }))
        );
        setPageObj({
          currentPage: pageNumber + 1, // supplement for pagination component being 1 indexed and spring boot being zero indexed.
          pageSize,
          numberOfElements,
          totalPages,
          totalRecords: totalElements
        });
        setCurrentPage(pageNumber + 1); // supplement for pagination component being 1 indexed and spring boot being zero indexed.
        setPageSize(pageSize);
        setLoading(false);
      })
      .catch(error => {
        toast.error(`Error fetching data: ${error}`);
      });
  }, [companyId, instanceId, size, page]);
  return { locations, loading, pageObj };
};

/* Update Company Instance Status */
export const updateCompanyInstanceStatusFromAPI = (
  cid: number,
  iid: number,
  axnInstanceStatus: AxnInstanceStatus
) =>
  axnAPI.put(`${companiesAPI}/${cid}/instance/${iid}/status`, {
    axnInstanceStatus,
    companyId: cid,
    axnInstanceId: iid
  });

/* Submit Company Instance for Review */
export const submitCompanyInstanceReviewFromAPI = (cid: number, iid: number) =>
  axnAPI.post(`${companiesAPI}/${cid}/instance/${iid}/submitforreview`);

/* Unlock Company Instance for Further Editing */
export const unlockCompanyInstanceForEditingFromAPI = (cid: number, iid: number) =>
  axnAPI.post(`${companiesAPI}/${cid}/instance/${iid}/settoinprogress`);

/* Submit Company Instance Approval */
export const submitCompanyInstanceApprovalFromAPI = (cid: number, iid: number) =>
  axnAPI.post(`${companiesAPI}/${cid}/instance/${iid}/approve`);

/* Publish Company Instance */
export const publishCompanyInstanceFromAPI = (cid: number, iid: number) =>
  axnAPI.post(`${companiesAPI}/${cid}/instance/${iid}/publish`);

export const searchCompanies = (instanceId: number, keyword: string) => (
  size: number,
  page: number,
  sort: string
) =>
  axnAPI.post(`${companiesAPI}/instance/${instanceId}/paged/search`, null, {
    params: { page, size, keyword, sort }
  });

export const convertSearchCompanies = <T>(instanceId: number, keyword: string) => (
  size = 10,
  page = 0,
  sort: string
) =>
  searchCompanies(instanceId, keyword)(size, page, sort).then(response =>
    convertSpringPageable(response.data as SpringPage<T>)
  );

export const useCompanySearch = (
    searchTerm: string,
    instanceId: number,
) : PageableData<CompanyData> => 
    useDataModel(
        (currentPage, pageSize) => {
            if ( searchTerm == '_all' )
            {
                return axnAPI
                        .get(
                            `${companiesAPI}/instance/${instanceId}/all`,
                            {
                                params: {
                                    page: currentPage <= 0 ? 0 : currentPage - 1, // spring is zero-indexed
                                    size: pageSize
                                }
                            }
                        )
                        .then(response => response.data as SpringPage<CompanyData>)
                        .then(convertSpringPageable)
            }
            else {
                return axnAPI
                    .post(
                        `${companiesAPI}/instance/${instanceId}/search`,
                        { companyName: searchTerm },
                        {
                            params: {
                                page: currentPage <= 0 ? 0 : currentPage - 1, // spring is zero-indexed
                                size: pageSize
                            }
                        }
                    )
                    .then(response => response.data as SpringPage<CompanyData>)
                    .then(convertSpringPageable)
            }
        },
        emptyPage<CompanyData>(),
        searchTerm
    );

export const getInstanceStatusTypes = (): Promise<string[]> =>
  axnAPI.get('/instanceStatus/all').then(
    resp => {
      return resp.data;
    },
    error => {
      toast.error(`Error fetching instance status types: ${error}`);
      return [];
    }
  );

export const getAxnStatusTypes = (): Promise<string[]> =>
  axnAPI.get('/axnstatuses/all').then(
    resp => resp.data.map((item: { axnStatus: string }) => item.axnStatus), // convert to string[]
    error => {
      toast.error(`Error fetching instance status types: ${error}`);
      return [];
    }
  );

export const useDBEFilteredCompanies = (
  status: string,
  keyword: string,
  instanceId: number
): PageableData<CompanyInstance> =>
  useDataModel(
    (currentPage, pageSize) =>
      getAllInstanceCompaniesFromAPI(instanceId, pageSize, currentPage, keyword, status, true).then(
        convertSpringPageable
      ),
    emptyPage<CompanyInstance>(),
    status,
    keyword
  );

export const useFilteredCompanies = (
  status: string,
  keyword: string,
  instanceID: number
): PageableData<CompanyInstance> =>
  useDataModel(
    (currentPage, pageSize) =>
      getAllInstanceCompaniesFromAPI(
        instanceID,
        pageSize,
        currentPage,
        keyword,
        status,
        false
      ).then(convertSpringPageable),
    emptyPage<CompanyInstance>(),
    status,
    keyword,
    instanceID
  );

/* Main Companies */

export const getAllMainCompaniesFromAPI = (page: number, size: number) =>
  axnAPI.get(`${companiesAPI}`, { params: { page, size } });

export const addMainCompanyFromAPI = (company: CompanyData) =>
  axnAPI.post(`${companiesAPI}`, company);

export const editMainCompanyFromAPI = (cid: number) => (company: CompanyData) =>
  axnAPI.put(`${companiesAPI}/${cid}`, company);

export const deleteMainCompanyFromAPI = (cid: number) => axnAPI.delete(`${companiesAPI}/${cid}`);

export const getMainCompanyFromAPI = (cid: number) => axnAPI.get(`${companiesAPI}/${cid}`);

export const searchAllMainCompaniesFromAPI = (
  size: number,
  page: number,
  keyword: string,
  status = 'ALL'
): Promise<SpringPage<CompanyData>> =>
  axnAPI
    .post(`${companiesAPI}/paged/search`, null, {
      params: {
        size,
        page: page - 1,
        status,
        keyword
      }
    })
    .then(resp => resp.data as SpringPage<CompanyData>);

export const useFilteredMainCompanies = (
  status: string,
  keyword: string
): PageableData<CompanyData> =>
  useDataModel(
    (currentPage, pageSize) =>
      searchAllMainCompaniesFromAPI(pageSize, currentPage, keyword, status).then(
        convertSpringPageable
      ),
    emptyPage<CompanyData>(),
    status,
    keyword
  );
