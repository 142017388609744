import React from 'react';
import { Button, Col, Spinner as ReactStrapSpinner } from 'reactstrap';

const Spinner = (props: { size?: string; spinnerClassName?: string }) => {
  const { size, spinnerClassName } = props;
  return (
    <div className="d-flex justify-content-center">
      <ReactStrapSpinner
        size={size}
        className={spinnerClassName}
        color="primary"
      />
    </div>
  );
};

Spinner.defaultProps = {
  size: 'lg',
  spinnerClassName: 'my-5'
};

export const SelectFieldSpinner = (props: {
  xl?: number | boolean;
  lg?: number | boolean;
  md?: number | boolean;
  sm?: number | boolean;
  xs?: number | boolean;
}) => {
  const { xl, lg, md, sm, xs } = props;
  return (
    <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
      <Button block disabled className="my-3">
        <Spinner size="sm" spinnerClassName="p-2" />
        <span className="sr-only">Loading...</span>
      </Button>
    </Col>
  );
};

SelectFieldSpinner.defaultProps = {
  xl: 12,
  lg: false,
  md: false,
  sm: false,
  xs: false
};

export default Spinner;
