import React, { ChangeEvent, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, FormGroup, Row } from 'reactstrap';
import { find, get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { terminalsInitialValues } from './TerminalsStepValueInit';
import {
  getTerminal,
  TenantTypeObj,
  updateAirportTerminalFromAPI,
  updateAirportTerminalTotals
} from '../../../../../api/Airports';
import FormField, { ChangeHandler } from '../../../../forms/FormField';
import { terminalSchema } from './yupSchemaTerminal';
import { extractAirportComment } from '../../../../../core/comments/AirportComments';
import { AirportCombinedTotals, AirportData, TerminalTenantTypeTotal } from '../../../../../types';
import DominantAirlines from './DominantAirlines';
import Spinner from '../../../../shared/spinner/Spinner';
import { TerminalsActionsCreator } from '../../../../../actions/terminalsActions';
import { appHistory } from '../../../../../reducers';
import { getAuthUserID } from '../../../../../core/authentication/authenticationHelper';

export interface TerminalMatchProps {
  url: string;
  path: string;
  params: {
    terminalID: string;
    contentID: string;
    contentType: string;
  };
}

interface ITerminalSpecificForm {
  airport: AirportData;
  axnInstanceId: number;
  match: TerminalMatchProps;
  getTerminals: TerminalsActionsCreator;
}

const findAndGet = (
  airportTerminalTotals: Partial<TerminalTenantTypeTotal>[],
  tenantType: string,
  valueType: string
) => {
  const airportTerminalTotal = find(
    airportTerminalTotals,
    (airportTerminalTotal: TerminalTenantTypeTotal) =>
      airportTerminalTotal.airportTerminalTotalPK.tenantType === tenantType.toUpperCase()
  );

  return get(airportTerminalTotal, `${valueType}`, 0);
};

const commonColFields: { [key: string]: string } = {
  areaTotal: 'Square Footage',
  grossSalesTotal: 'Gross Sales',
  salesEpRatio: 'Sales/EP',
  rentAirportTotal: 'Gross Rentals'
};

export const AirportTotalColFields: {
  [key: string]: any;
} = {
  ...commonColFields,
  rentEpRatio: 'Rent/EP'
};

export const tooltipMsgs = {
  salesEpRatio:
    'The estimated amount of money each departing passenger is spending before boarding their flight. This figure is a standard measure of performance in the industry and is derived by dividing the total gross sales figure by the number of enplaning passengers.',
  rentAirportTotal:
    'The amount of revenue received by the airport in the form of rent paid by concessionaires.'
};

const TerminalSpecificForm = (props: ITerminalSpecificForm) => {
  const { match, airport, axnInstanceId } = props;
  const { terminalID, contentID } = match.params;
  const [loading, setLoading] = useState(false); // not used yet, just set to be used.
  const [terminal, setTerminal] = useState(terminalsInitialValues);
  const { terminalName, terminalShortName } = terminal.terminal;
  useEffect(() => {
    setLoading(true);
    if (terminalID)
      getTerminal(+contentID, +terminalID, axnInstanceId)
        .then(response => {
          const { data } = response;
          const { airportTerminalTotals } = data;
          delete data.airportTerminalTotals;
          setTerminal({
            ...data,
            /* Duty Free */
            dutyfreeRentAirportTotal: findAndGet(
              airportTerminalTotals,
              'DUTYFREE',
              'rentAirportTotal'
            ),
            dutyfreeGrossSalesTotal: findAndGet(
              airportTerminalTotals,
              'DUTYFREE',
              'grossSalesTotal'
            ),
            dutyfreeAreaTotal: findAndGet(airportTerminalTotals, 'DUTYFREE', 'areaTotal'),
            dutyfreeSalesEp: findAndGet(airportTerminalTotals, 'DUTYFREE', 'salesEpRatio'),
            dutyfreeRentEp: findAndGet(airportTerminalTotals, 'DUTYFREE', 'rentEpRatio'),
            /* Food Beverage */
            foodbeverageRentAirportTotal: findAndGet(
              airportTerminalTotals,
              'FOODBEVERAGE',
              'rentAirportTotal'
            ),
            foodbeverageGrossSalesTotal: findAndGet(
              airportTerminalTotals,
              'FOODBEVERAGE',
              'grossSalesTotal'
            ),
            foodbeverageAreaTotal: findAndGet(airportTerminalTotals, 'FOODBEVERAGE', 'areaTotal'),
            foodbeverageSalesEp: findAndGet(airportTerminalTotals, 'FOODBEVERAGE', 'salesEpRatio'),
            foodbeverageRentEp: findAndGet(airportTerminalTotals, 'FOODBEVERAGE', 'rentEpRatio'),

            /* Lounges / Suites */
            loungessuitesRentAirportTotal: findAndGet(
              airportTerminalTotals,
              'LOUNGESSUITES',
              'rentAirportTotal'
            ),
            loungessuitesGrossSalesTotal: findAndGet(
              airportTerminalTotals,
              'LOUNGESSUITES',
              'grossSalesTotal'
            ),
            loungessuitesAreaTotal: findAndGet(airportTerminalTotals, 'LOUNGESSUITES', 'areaTotal'),
            loungessuitesSalesEp: findAndGet(
              airportTerminalTotals,
              'LOUNGESSUITES',
              'salesEpRatio'
            ),
            loungessuitesRentEp: findAndGet(airportTerminalTotals, 'LOUNGESSUITES', 'rentEpRatio'),

            /* Passenger Services */
            passengerservicesRentAirportTotal: findAndGet(
              airportTerminalTotals,
              'PASSENGER',
              'rentAirportTotal'
            ),
            passengerservicesGrossSalesTotal: findAndGet(
              airportTerminalTotals,
              'PASSENGER',
              'grossSalesTotal'
            ),
            passengerservicesAreaTotal: findAndGet(airportTerminalTotals, 'PASSENGER', 'areaTotal'),
            passengerservicesSalesEp: findAndGet(
              airportTerminalTotals,
              'PASSENGER',
              'salesEpRatio'
            ),
            passengerservicesRentEp: findAndGet(airportTerminalTotals, 'PASSENGER', 'rentEpRatio'),

            /* News Stand */
            newsStandRentAirportTotal: findAndGet(
              airportTerminalTotals,
              'NEWSSTAND',
              'rentAirportTotal'
            ),
            newsStandGrossSalesTotal: findAndGet(
              airportTerminalTotals,
              'NEWSSTAND',
              'grossSalesTotal'
            ),
            newsStandAreaTotal: findAndGet(airportTerminalTotals, 'NEWSSTAND', 'areaTotal'),
            newsStandSalesEp: findAndGet(airportTerminalTotals, 'NEWSSTAND', 'salesEpRatio'),
            newsStandRentEp: findAndGet(airportTerminalTotals, 'NEWSSTAND', 'rentEpRatio'),
            /* Specialty */
            specialtyRentAirportTotal: findAndGet(
              airportTerminalTotals,
              'SPECIALTY',
              'rentAirportTotal'
            ),
            specialtyGrossSalesTotal: findAndGet(
              airportTerminalTotals,
              'SPECIALTY',
              'grossSalesTotal'
            ),
            specialtyAreaTotal: findAndGet(airportTerminalTotals, 'SPECIALTY', 'areaTotal'),
            specialtySalesEp: findAndGet(airportTerminalTotals, 'SPECIALTY', 'salesEpRatio'),
            specialtyRentEp: findAndGet(airportTerminalTotals, 'SPECIALTY', 'rentEpRatio')
          });
          setLoading(false);
        })
        .catch(e => {
          toast.error(e);
          setLoading(false);
        });
  }, [terminalID, contentID, axnInstanceId]);

  const initVals = {
    ...terminalsInitialValues,
    ...terminal,
    passengerTrafficComment: extractAirportComment(airport, 'PASSTRAFFIC'),
    grossSalesComment: extractAirportComment(airport, 'GROSSSALES')
  };

  const foodbeverage = (
    handleBlur: (e: React.FocusEvent) => void,
    handleChange: ChangeHandler,
    values: AirportCombinedTotals
  ) => (
    <FormGroup>
      <Row>
        <Col>
          <h4 className={'wizard-title'}>Food & Beverage</h4>
        </Col>
      </Row>
      <Row>
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.foodbeverageAreaTotal || 0}
          name={'foodbeverageAreaTotal'}
          col={{}}
          label={commonColFields.areaTotal}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.foodbeverageGrossSalesTotal || 0}
          name={'foodbeverageGrossSalesTotal'}
          col={{}}
          label={commonColFields.grossSalesTotal}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.foodbeverageSalesEp || 0}
          name={'foodbeverageSalesEp'}
          col={{}}
          label={commonColFields.salesEpRatio}
          readOnly
          disabled
          tooltipText={tooltipMsgs.salesEpRatio}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.foodbeverageRentAirportTotal || 0}
          name={'foodbeverageRentAirportTotal'}
          col={{}}
          label={commonColFields.rentAirportTotal}
          tooltipText={tooltipMsgs.rentAirportTotal}
        />
      </Row>
    </FormGroup>
  );

  const specialtyRetail = (
    handleBlur: (e: React.FocusEvent) => void,
    handleChange: ChangeHandler,
    values: AirportCombinedTotals
  ) => (
    <FormGroup>
      <Row>
        <Col>
          <h4 className={'wizard-title'}>Speciality Retail</h4>
        </Col>
      </Row>
      <Row>
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.specialtyAreaTotal || 0}
          name={'specialtyAreaTotal'}
          col={{}}
          label={commonColFields.areaTotal}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.specialtyGrossSalesTotal || 0}
          name={'specialtyGrossSalesTotal'}
          col={{}}
          label={commonColFields.grossSalesTotal}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.specialtySalesEp || 0}
          name={'specialtySalesEp'}
          col={{}}
          label={commonColFields.salesEpRatio}
          readOnly
          disabled
          tooltipText={tooltipMsgs.salesEpRatio}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.specialtyRentAirportTotal || 0}
          name={'specialtyRentAirportTotal'}
          col={{}}
          label={commonColFields.rentAirportTotal}
          tooltipText={tooltipMsgs.rentAirportTotal}
        />
      </Row>
    </FormGroup>
  );

  const newsAndGifts = (
    handleBlur: (e: React.FocusEvent) => void,
    handleChange: ChangeHandler,
    values: AirportCombinedTotals
  ) => (
    <FormGroup>
      <Row>
        <Col>
          <h4 className={'wizard-title'}>News & Gifts</h4>
        </Col>
      </Row>
      <Row>
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.newsStandAreaTotal || 0}
          name={'newsStandAreaTotal'}
          col={{}}
          label={commonColFields.areaTotal}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.newsStandGrossSalesTotal || 0}
          name={'newsStandGrossSalesTotal'}
          col={{}}
          label={commonColFields.grossSalesTotal}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.newsStandSalesEp || 0}
          name={'newsStandSalesEp'}
          col={{}}
          label={commonColFields.salesEpRatio}
          readOnly
          disabled
          tooltipText={tooltipMsgs.salesEpRatio}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.newsStandRentAirportTotal || 0}
          name={'newsStandRentAirportTotal'}
          col={{}}
          label={commonColFields.rentAirportTotal}
          tooltipText={tooltipMsgs.rentAirportTotal}
        />
      </Row>
    </FormGroup>
  );

  const dutyFree = (
    handleBlur: (e: React.FocusEvent) => void,
    handleChange: ChangeHandler,
    values: AirportCombinedTotals
  ) => (
    <FormGroup>
      <Row>
        <Col>
          <h4 className={'wizard-title'}>Duty Free</h4>
        </Col>
      </Row>
      <Row>
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.dutyfreeAreaTotal || 0}
          name={'dutyfreeAreaTotal'}
          col={{}}
          label={commonColFields.areaTotal}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.dutyfreeGrossSalesTotal || 0}
          name={'dutyfreeGrossSalesTotal'}
          col={{}}
          label={commonColFields.grossSalesTotal}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.dutyfreeSalesEp || 0}
          name={'dutyfreeSalesEp'}
          col={{}}
          label={commonColFields.salesEpRatio}
          readOnly
          disabled
          tooltipText={tooltipMsgs.salesEpRatio}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.dutyfreeRentAirportTotal || 0}
          name={'dutyfreeRentAirportTotal'}
          col={{}}
          label={commonColFields.rentAirportTotal}
          tooltipText={tooltipMsgs.rentAirportTotal}
        />
      </Row>
    </FormGroup>
  );

  const loungesSuites = (
    handleBlur: (e: React.FocusEvent) => void,
    handleChange: ChangeHandler,
    values: AirportCombinedTotals
  ) => (
    <FormGroup>
      <Row>
        <Col>
          <h4 className={'wizard-title'}>Lounges / Suites</h4>
        </Col>
      </Row>
      <Row>
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.loungessuitesAreaTotal || 0}
          name={'loungessuitesAreaTotal'}
          col={{}}
          label={commonColFields.areaTotal}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.loungessuitesGrossSalesTotal || 0}
          name={'loungessuitesGrossSalesTotal'}
          col={{}}
          label={commonColFields.grossSalesTotal}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.loungessuitesSalesEp || 0}
          name={'loungessuitesSalesEp'}
          col={{}}
          label={commonColFields.salesEpRatio}
          readOnly
          disabled
          tooltipText={tooltipMsgs.salesEpRatio}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.loungessuitesRentAirportTotal || 0}
          name={'loungessuitesRentAirportTotal'}
          col={{}}
          label={commonColFields.rentAirportTotal}
          tooltipText={tooltipMsgs.rentAirportTotal}
        />
      </Row>
    </FormGroup>
  );

  const passengerServices = (
    handleBlur: (e: React.FocusEvent) => void,
    handleChange: ChangeHandler,
    values: AirportCombinedTotals
  ) => (
    <FormGroup>
      <Row>
        <Col>
          <h4 className={'wizard-title'}>Passenger Services</h4>
        </Col>
      </Row>
      <Row>
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.passengerservicesAreaTotal || 0}
          name={'passengerservicesAreaTotal'}
          col={{}}
          label={commonColFields.areaTotal}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.passengerservicesGrossSalesTotal || 0}
          name={'passengerservicesGrossSalesTotal'}
          col={{}}
          label={commonColFields.grossSalesTotal}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.passengerservicesSalesEp || 0}
          name={'passengerservicesSalesEp'}
          col={{}}
          label={commonColFields.salesEpRatio}
          readOnly
          disabled
          tooltipText={tooltipMsgs.salesEpRatio}
        />
        <FormField
          match={match}
          handleChange={handleChange}
          handleBlur={handleBlur}
          type={'number'}
          value={values.passengerservicesRentAirportTotal || 0}
          name={'passengerservicesRentAirportTotal'}
          col={{}}
          label={commonColFields.rentAirportTotal}
          tooltipText={tooltipMsgs.rentAirportTotal}
        />
      </Row>
    </FormGroup>
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initVals} /* TODO: Shape this to only accept singular terminal values. */
      onSubmit={values => {
        const mappedValues = { ...values, modifiedUserId: getAuthUserID() };

        /* List of Tenant Types to be using when updating form, can be expanded to other types. */
        const tenantTypes = [
          { type: 'FOODBEVERAGE', fieldName: 'foodbeverage' },
          { type: 'SPECIALTY', fieldName: 'specialty' },
          { type: 'NEWSSTAND', fieldName: 'newsStand' },
          { type: 'DUTYFREE', fieldName: 'dutyfree' },
          { type: 'LOUNGESSUITES', fieldName: 'loungessuites' },
          { type: 'PASSENGER', fieldName: 'passengerservices' }
        ] as TenantTypeObj[];

        Promise.all([
          /* Update the Terminal Tenant Type Information, using the list above. */
          updateAirportTerminalTotals(
            tenantTypes,
            +contentID,
            +terminalID,
            mappedValues,
            axnInstanceId
          ),

          /* Update Totals for Terminal */
          updateAirportTerminalFromAPI(+contentID, axnInstanceId, terminalID, {
            airportTerminalPK: mappedValues.airportTerminalPK,
            annualPercentChangeAmount: mappedValues.annualPercentChangeAmount,
            epDomesticTotal: mappedValues.epDomesticTotal,
            epIntlTotal: mappedValues.epIntlTotal,
            enplaneTotal: mappedValues.enplaneTotal,
            deplaneTotal: mappedValues.deplaneTotal,
            passengerTotal: mappedValues.passengerTotal, // terminal passenger traffic, should be read only?
            modifiedUserId: mappedValues.modifiedUserId,
            terminal: mappedValues.terminal
          })
        ]).then(
          () => {
            window.scroll(0, 0);
            toast.success('Successfully Updated Terminal Specific Data.');
            appHistory.push(`/wizard/airports/${contentID}/steps/terminals`); // unselect the current terminal
            setTimeout(() => window.location.reload(), 500);
          },
          () => toast.warn('Failed to Update Terminal Specific Data.')
        );
      }}
      validationSchema={terminalSchema}
      render={({ handleBlur, handleChange, values }) => (
        <div>
          {loading ? (
            <Spinner />
          ) : (
            <Form>
              <h3 className={'wizard-instruction'}>
                2.) You are Modifying data for ({terminalShortName}) {terminalName}:
              </h3>
              {/* TODO: create in a loop of each type starting here. */}

              {foodbeverage(handleBlur, handleChange, values)}
              {specialtyRetail(handleBlur, handleChange, values)}
              {newsAndGifts(handleBlur, handleChange, values)}
              {dutyFree(handleBlur, handleChange, values)}
              {loungesSuites(handleBlur, handleChange, values)}
              {passengerServices(handleBlur, handleChange, values)}

              {/* Totals of Terminals Airport Wide */}
              <h3 className={'wizard-instruction'}>
                3.) Totals for Terminal ({terminalShortName}) {terminalName}:
              </h3>
              <FormGroup>
                <Row>
                  <FormField
                    name={'terminalTotals.terminal.terminalName'}
                    label={'Name'}
                    type={'text'}
                    value={values.terminal.terminalName || ''}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{}}
                    match={match}
                    disabled
                    readOnly
                  />
                  <FormField
                    name={'passengerTotal'}
                    label={'Passenger Traffic'}
                    info={'Terminal Passenger Traffic'}
                    type={'number'}
                    value={values.passengerTotal || 0}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{}}
                    match={match}
                    disabled
                    readOnly
                  />
                  <FormField
                    name={'annualPercentChangeAmount'}
                    label={'+/-%'}
                    type={'number'}
                    value={values.annualPercentChangeAmount || 0}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{}}
                    match={match}
                  />
                </Row>
                <Row>
                  <FormField
                    name={'deplaneTotal'}
                    label={'Deplaning'}
                    type={'number'}
                    value={values.deplaneTotal || 0}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{}}
                    match={match}
                  />
                  <FormField
                    name={'enplaneTotal'}
                    label={'Enplaning'}
                    type={'number'}
                    value={values.enplaneTotal || 0}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{}}
                    match={match}
                    disabled
                    readOnly
                  />
                  <FormField
                    name={'epDomesticTotal'}
                    label={'EP Domestic'}
                    type={'number'}
                    value={values.epDomesticTotal || 0}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{}}
                    match={match}
                  />
                  <FormField
                    name={'epIntlTotal'}
                    label={`EP Int'l`}
                    type={'number'}
                    value={values.epIntlTotal || 0}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{}}
                    match={match}
                  />
                </Row>
              </FormGroup>
              <Row className={'pt-3'}>
                <Col>
                  <ButtonGroup>
                    <Button color={'primary'} type={'submit'}>
                      Save Terminal Totals <FontAwesomeIcon icon={faSave} />
                    </Button>
                    {/* This cancel should close the current terminal. */}
                    {/* TODO: implement reset/cancel button */}
                    {/* <Button color={'secondary'}> */}
                    {/*  Cancel <FontAwesomeIcon icon={faTimesCircle} /> */}
                    {/* </Button> */}
                  </ButtonGroup>
                </Col>
              </Row>
            </Form>
          )}
          <DominantAirlines
            airlineTerminals={values.airlineTerminals}
            match={match}
            axnInstanceId={axnInstanceId}
          />
        </div>
      )}
    />
  );
};

export default TerminalSpecificForm;
