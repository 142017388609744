import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import { AppState } from '../../../../../reducers';
import { StepDefaultProps } from '../../../types';
import { getAirport } from '../../../../../actions/airportActions';
import { AirportInstance } from '../../../../../types';

interface LocationStepProps extends StepDefaultProps {
  airportInstance: AirportInstance;
}

/* TODO: move this to a more reusable location, not currently sure where to move it to. */
export const pinStyle = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  /* TODO: below is ridiculous find a way to fix this */
  position: 'absolute' as
    | 'absolute'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | '-webkit-sticky'
    | 'fixed'
    | 'relative'
    | 'static'
    | 'sticky'
    | undefined
};

const LocationStep = (props: LocationStepProps) => {
  const { airportInstance } = props;
  const { longitudeText, latitudeText } = airportInstance.airport;
  return (
    <Container>
      <Row>
        <Col className={cssClass}>
          <StepHeader stepTitle={'Airport Location'} />
          <div style={{ height: '75vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_KEY_PRIMARY!
              }} // get api from env variables
              center={{ lat: +latitudeText, lng: +longitudeText }}
              defaultZoom={13}
            />
            <FontAwesomeIcon
              className={'text-primary'}
              icon={faMapMarkerAlt}
              size={'4x'}
              style={pinStyle}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  airportInstance: state.airports.airportInstance
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAirport: getAirport(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationStep);
