import React from 'react';
import { TableColumn } from '../masterlist/MasterList';
import { FilteredMasterList } from '../masterlist/filter/FilteredMasterList';
import { useAirportContactSearch, AirportContact } from '../../api/contacts';
import { workPhone, faxPhone, Address, Email, Url } from '../../pages/ContactsView';
import { IndexableMasterListItem } from '../masterlist/types';
import { useManagementResponsibilities } from '../../api/ManagementResponsibilites';
import AccordionItem from '../accordion/AccordionItem';
import { ManagementResponsibility } from '../../types';

const columns: TableColumn[] = [
  { id: 'fullName', title: 'Contact' },
  { id: 'title', title: 'Title' },
  { id: 'companyName', title: 'Company Name' },
  { id: 'address', title: 'Address', width: '200px' },
  { id: 'phone', title: 'Phone', width: '130px' },
  { id: 'fax', title: 'Fax', width: '130px' },
  { id: 'email', title: 'Email' },
  { id: 'url', title: 'Web site' }
];

export interface AirportContactsSearchResult {
  fullName: string;
  title: string;
  companyName: string;
  airportName: string;
  airportIata: string;
  phone: string;
  fax: string;
  airport: string;
  address: JSX.Element;
  email: JSX.Element;
  url: JSX.Element;
}

const convert = ({ airport, contact }: AirportContact): AirportContactsSearchResult => ({
  fullName: `${contact.firstName} ${contact.lastName}`,
  title: contact.title,
  companyName: contact.companyName,
  airportName: airport.airportName,
  airportIata: airport.iataCode,
  phone: workPhone(contact),
  fax: faxPhone(contact),
  airport: airport.iataCode,
  address: <Address contact={contact} />,
  email: <Email contact={contact} />,
  url: <Url contact={contact} />
});

const convertAsIndexable = (data: AirportContact): IndexableMasterListItem =>
  convert(data) as IndexableMasterListItem;

interface Props {
  axnInstanceId: number;
  managementType: ManagementResponsibility;
}

const ContactsForManagementType = ({ axnInstanceId, managementType }: Props) => (
  <AccordionItem name={managementType.managementResponsibilityType}>
    <FilteredMasterList
      args={`${managementType.managementResponsibilityTypeId}`}
      axnInstanceId={axnInstanceId}
      columns={columns}
      convert={convertAsIndexable}
      listType="Airport Contacts"
      useData={useAirportContactSearch}
    />
  </AccordionItem>
);

interface AirportContactsProps {
  axnInstanceId: number;
}

const AirportContacts = ({ axnInstanceId }: AirportContactsProps) => {
  const { managementResponsibilities } = useManagementResponsibilities();
  return (
    <div>
      {managementResponsibilities.map(managementType => (
        <ContactsForManagementType
          axnInstanceId={axnInstanceId}
          key={managementType.managementResponsibilityTypeId}
          managementType={managementType} />
      ))}
    </div>
  );
};

export default AirportContacts;
