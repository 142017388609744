import React from 'react';
import { get } from 'lodash';
import { Button, Container } from 'reactstrap';
import { toast } from 'react-toastify';
import { cssClass } from '../../wizard/cssSharedClasses';
import { createNewYearInstance } from '../../../api/instances';

const InstanceManager = () => {
  const handleCreateNewInstance = () =>
    createNewYearInstance().then(
      () => {
        toast.success('Successfully Created a New Pending Instance');
      },
      error => {
        const errorMsg = get(error, 'response.data.message', 'Failed to create new instance.');
        toast.error(errorMsg);
      }
    );
  return (
    <Container className={cssClass}>
      <h1>Instance Manager</h1>
      <Button
        className={'my-3'}
        type={'button'}
        color={'primary'}
        onClick={handleCreateNewInstance}>
        Create New Instance
      </Button>
    </Container>
  );
};

export default InstanceManager;
