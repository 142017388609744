import React from 'react';
import { Alert } from 'reactstrap';

const TerminalNotFound = () => (
  <Alert color={'warning'}>
    You have not selected a terminal or the selected terminal does not exist.
  </Alert>
);

export default TerminalNotFound;
