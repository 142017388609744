import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Country, StateProvince } from '../types';
import { axnAPI } from './config';

export const useStateProvinces = () => {
  const [stateProvinceLoading, setStateProvinceLoading] = useState(false);
  const [states, setStates] = useState(new Array<StateProvince>());

  useEffect(() => {
    let unmounted = false;
    setStateProvinceLoading(true);
    axnAPI
      .get(`/statesprovinces`)
      .then(response => {
        if (!unmounted) {
          setStates(response.data);
          setStateProvinceLoading(false);
        }
      })
      .catch(e => {
        if (!unmounted) {
          setStateProvinceLoading(false);
        }
      });
    return () => {
      unmounted = true;
    };
  }, [states.length]);

  return { states, stateProvinceLoading };
};

export const useCountries = () => {
  const [countries, setCountries] = useState(new Array<Country>());
  const [countriesLoading, setCountriesLoading] = useState(false);

  useEffect(() => {
    let unmounted = false;
    setCountriesLoading(true);
    axnAPI
      .get(`/countries`)
      .then(response => {
        if (!unmounted) {
          setCountries(response.data);
          setCountriesLoading(false);
        }
      })
      .catch(e => {
        if (!unmounted) {
          setCountriesLoading(false);
          toast.error(e);
        }
      });
    return () => {
      unmounted = true;
    };
  }, [countries.length]);

  return { countries, countriesLoading };
};
