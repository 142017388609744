import React, { useEffect, useState } from 'react';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import MasterList, { TableColumn } from '../../masterlist/MasterList';
import { MasterListActionButton } from '../../masterlist/types';
import MasterListPagesize from '../../masterlist/MasterListPagesize';
import MasterListPagination from '../../masterlist/MasterListPagination';
import { ProductTypeData } from '../../../actions/productTypeActions';
import { deleteProductType, getProductTypes } from '../../../api/ProductTypes';
import { paginationClasses } from '../adminUtils';
import Confirm from '../users/Confirm';

const ProductTypesManager = () => {
  const [productTypes, setProductTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [productTypesCount, setProductTypesCount] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState<string>();

  useEffect(() => {
    /* TODO: change instance id to reflect a variable set according to user or set by admin. */
    setLoading(true);
    getProductTypes(paginationSize, currentPage)
      .then(res => {
        const { content, pageable, totalElements } = res.data;

        setProductTypes(
          content.map((item: ProductTypeData) => {
            return {
              ...item
            };
          })
        );
        setProductTypesCount(totalElements);
        setCurrentPage(pageable.pageNumber);
        setPaginationSize(pageable.pageSize);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        toast.error(error);
      });
  }, [paginationSize, currentPage]);

  const columnsMap: TableColumn[] = [{ id: 'productTypeDescription', title: 'Product Type' }];

  const rowActions = (id: string): MasterListActionButton[] => {
    return [
      {
        to: `/admin/manage/product-types/edit/${id}`,
        icon: faEdit,
        text: 'Edit'
      },
      {
        to: `/admin/manage/product-types`,
        icon: faTrash,
        text: 'Delete',
        actionCallback: () => setSelectedProductType(id)
      }
    ];
  };

  const handleDelete = () => {
    if (selectedProductType)
      deleteProductType(selectedProductType).then(
        resp => {
          toast.success(`Successfully Deleted Product Type!`);
          setSelectedProductType(undefined);

          const updatedProductTypes = productTypes.filter(
            (productType: ProductTypeData) => productType.productType !== selectedProductType
          );
          setProductTypes(updatedProductTypes);
          setProductTypesCount(updatedProductTypes.length);
          setCurrentPage(0);
        },
        error => {
          toast.error(`Failed to Delete Product Type`);
          setSelectedProductType(undefined);
        }
      );
  };

  const usePaginationSelection = (pageNum: number) => {
    setCurrentPage(pageNum - 1);
  };

  const useSetPaginationSize = (pageSize: number) => {
    setCurrentPage(0);
    setPaginationSize(pageSize);
  };

  return (
    <>
      <div className={paginationClasses}>
        <MasterListPagesize size={paginationSize} setPagesize={useSetPaginationSize} />
        <MasterListPagination
          pageSize={paginationSize}
          totalItems={productTypesCount}
          handlePaginationSelection={usePaginationSelection}
          currentPage={currentPage + 1}
        />
      </div>
      <MasterList
        loading={loading}
        actions={rowActions}
        columns={columnsMap}
        currentPage={currentPage}
        itemCount={productTypesCount}
        list={productTypes}
        listType="Product Types"
        readonly={false}
        additionalValues={[]}
      />
      <div className={paginationClasses}>
        <MasterListPagesize size={paginationSize} setPagesize={useSetPaginationSize} />
        <MasterListPagination
          pageSize={paginationSize}
          totalItems={productTypesCount}
          handlePaginationSelection={usePaginationSelection}
          currentPage={currentPage + 1}
        />
      </div>
      <Confirm
        isOpen={selectedProductType !== undefined}
        cancel={_event => setSelectedProductType(undefined)}
        success={handleDelete}
        successTitle="Delete">
        <p>Are you sure you wish to delete this terminal?</p>
      </Confirm>
    </>
  );
};

export default ProductTypesManager;
