import * as React from 'react';
import { IndexableMasterListItem, MasterListActions } from './types';
import { ListRow } from './ListRow';
import { TableColumn } from './MasterList';

export const ListTableBody = (props: {
  list: IndexableMasterListItem[];
  columns: TableColumn[];
  currentPage: number;
  actions: MasterListActions;
  additionalValues?: string[];
  readonly?: boolean;
}) => {
  const { actions, additionalValues, columns, list, readonly } = props;
  return (
    <tbody>
      {list.map((item, id) => (
        <ListRow
          key={id}
          values={item}
          actions={actions}
          columns={columns}
          additionalValues={additionalValues}
          readonly={readonly}
        />
      ))}
    </tbody>
  );
};
