import React from 'react';
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './header.scss';
import { FrontendHeader } from '../frontend/Header';
import { AdminHeader } from '../admin/Header';
import { AuthoritiesList } from "../../actions/usersActions";
import { AppState } from "../../reducers";


interface HeaderProps {
  isLoggedIn: boolean;
  authorities: AuthoritiesList;
}

const Header = (props: HeaderProps & RouteComponentProps) => {
  const { location, isLoggedIn, authorities } = props;
  const isAdmin = location.pathname.indexOf('/admin') === 0;
  return isAdmin ? (
    <AdminHeader
      isLoggedIn={isLoggedIn}
      authorities={authorities}
    />
  ) : (
    <FrontendHeader
      isLoggedIn={isLoggedIn}
      authorities={authorities}
    />
  );
};


const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.users.isLoggedIn,
  authorities: state.users.authenticatedUser.authorities
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header));
