import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStoreAlt } from '@fortawesome/free-solid-svg-icons';
import { ChildReportComponent } from './ReportsManager';
import CompanyTypeSelectField from '../../forms/CompanyTypeSelectField';
import { cssClass } from '../../wizard/cssSharedClasses';
import { getBookReport } from '../../../api/reports';
import AxnInstanceFilter from '../../forms/AxnInstanceFilter';
import { AvailableInstanceMasterStatus } from '../../../actions/wizardActions';

const CompanyTypeReports = (props: ChildReportComponent & RouteComponentProps) => {
  const {
    activeInstance,
    activeInstanceStatus,
    axnInstanceId,
    axnPendingInstanceId,
    axnInstanceName,
    axnPendingInstanceName,
    switchWizardInstance,
    authenticatedUser,
    match
  } = props;
  const [axnStatus, setAxnStatus] = useState(activeInstanceStatus);

  useEffect(() => {
    const instanceIdToUse = axnStatus === 'PENDING' ? axnPendingInstanceId : axnInstanceId;
    const instanceNameToUse = axnStatus === 'PENDING' ? axnPendingInstanceName : axnInstanceName;
    switchWizardInstance(
      instanceIdToUse,
      axnStatus as AvailableInstanceMasterStatus,
      instanceNameToUse
    );
  }, [axnStatus]);

  const initVals = { companyType: '', companyTypeDes: '' };
  return (
    <Formik
      onSubmit={values => {
        getBookReport(
          activeInstance,
          'companies',
          'pdf',
          `${values.companyTypeDes}`,
          [],
          values.companyType
        );
      }}
      initialValues={initVals}
      render={({ setFieldValue, handleBlur, values, handleChange }) => {
        return (
          <Form>
            <div className={cssClass}>
              <Row>
                <Col>
                  <h1 className={'mb-3'}>Company Report by Company Type</h1>
                </Col>
              </Row>
              <Row>
                <CompanyTypeSelectField
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  axnInstanceId={axnInstanceId}
                  match={match}
                  values={values}
                  handleChange={handleChange}
                  companyId={0} // not relevant when calling all
                  label={'Select a Company Type you wish your Company Report to be based upon.'}
                  col={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}
                  all
                  browserAutoComplete={'off'}
                />
                <AxnInstanceFilter
                  axnStatusFilterColSize={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}
                  setStatus={setAxnStatus}
                  status={axnStatus}
                  authenticatedUser={authenticatedUser}
                />
              </Row>
              <ButtonGroup className={'py-3'}>
                <Button color={'primary'} type={'submit'}>
                  <FontAwesomeIcon icon={faStoreAlt} /> Generate Company Type Report
                </Button>
              </ButtonGroup>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default withRouter(CompanyTypeReports);
