import React, { useEffect, useState } from 'react';
import { useStateProvinces } from '../../api/StateProvince';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import { AutoCompleteOption } from './autocomplete/AutoComplete';
import FormField from './FormField';

/* TODO: Fix the any */
const StateProvinceSelectField = (props: {
  values: any;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: any;
  handleBlur: any;
}) => {
  const { values, match, handleChange, handleBlur, setFieldValue } = props;
  const { states, stateProvinceLoading } = useStateProvinces();
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();
  const [value] = useState(values.stateProvinceCode);

  useEffect(() => {
    const aclist = states.map(state => ({
      ...state,
      label: state.stateProvinceName,
      key: state.stateProvinceCode,
      value: state.stateProvinceCode
    }));
    setAutoCompleteList(aclist);
  }, [states]);

  useEffect(() => {
    const stateProvince = states.find(
      state => state.stateProvinceCode === values.stateProvinceCode
    );
    if (stateProvince) setFieldValue('stateProvince', stateProvince.stateProvinceName, false);
  }, [states, setFieldValue, values.stateProvinceName, values.stateProvinceCode]);

  return (
    <>
      {stateProvinceLoading ? (
        <SelectFieldSpinner />
      ) : (
        <FormField
          name="stateProvinceCode"
          labelName="stateProvince"
          label="State"
          type="autocomplete"
          match={match}
          value={value}
          labelValue={values.stateProvince || ''}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleBlur={handleBlur}
          autoCompleteList={autoCompleteList}
          col={{ lg: 12 }}
        />
      )}
    </>
  );
};

export default StateProvinceSelectField;
