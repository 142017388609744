import React from 'react';
import { NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { submitLogout } from '../../actions/usersActions';
import { AppState } from '../../reducers';
import { ToggleNavbar } from "./NavigationHelper";

interface LogoutItemProps {
  logout: () => void;
  toggleNavbar?: ToggleNavbar;
}

const LogoutItem = (props: LogoutItemProps) => {
  const { logout } = props;
  return (
    <NavItem key={'logout'}>
      <Link to={'/'} onClick={() => logout()}>
        Logout
      </Link>
    </NavItem>
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.users.isLoggedIn
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: submitLogout(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutItem);
