import React from 'react';
import { Button, Container, Form, Row } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { ForgotPasswordValidation } from './yupSchema';
import FormField from '../../forms/FormField';
import { requestPasswordReset } from '../../../api/authentication';
import { appHistory } from '../../../reducers';
import { cssClass } from '../../wizard/cssSharedClasses';

const handleSubmitForgotPassword = (username: string) =>
  requestPasswordReset(username)
    .then(() => {
      toast.success('Check your email for password recovery steps!');
      appHistory.push('/login');
    })
    .catch(() => {
      toast.error(`There is no user associated with that Username`);
    });

export default (props: RouteComponentProps) => {
  const { match } = props;
  return (
    <Container tag="section" className={cssClass} style={{ width: '600px', maxWidth: '90%' }}>
      <div className="axn-component">
        <Formik
          onSubmit={values => handleSubmitForgotPassword(values.username)}
          initialValues={{ username: '' }}
          validationSchema={ForgotPasswordValidation}
          render={({ values, handleBlur, handleChange, handleSubmit }) => {
            return (
              <Form
                id="forgotPasswordForm"
                className="axn-form"
                method="post"
                action="#"
                onSubmit={handleSubmit}>
                <h3 className="card-title">Forgot Password</h3>
                <Row>
                  <FormField
                    name="username"
                    label="Username"
                    type="email"
                    match={match}
                    value={values.username}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ xl: 12 }}
                  />
                </Row>
                <Button color="dark">Submit</Button>
              </Form>
            );
          }}
        />
      </div>
    </Container>
  );
};
