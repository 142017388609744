import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { AppState } from '../../../../../reducers';
import { SetWizardFormState } from '../../../../../actions/wizardActions';
import AddCompanyAirportLocation from './AddCompanyAirportLocation';
import CompanyAirportLocationList from './CompanyAirportLocationList';
import { useCompanyAirportLocations } from '../../../../../api/Companies';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';

interface LocationStepProps {
  dispatch?: Dispatch;
  setFormState: SetWizardFormState;
  activeInstance: ActiveInstance;
  match: {
    url: string;
    path: string;
    params: {
      contentID: string;
      contentType: string;
      wizType: string;
    };
  };
}

const LocationStep = (props: LocationStepProps) => {
  const { match, activeInstance } = props;
  const { id } = activeInstance;
  const { contentID } = match.params;

  return (
    <Row>
      <Col>
        <AddCompanyAirportLocation axnInstanceId={id} />
        <CompanyAirportLocationList
          companyId={+contentID}
          axnInstanceId={id}
          useLocations={useCompanyAirportLocations}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: AppState) => ({
  locationFormStore: state.wizard.stepper.companies.location,
  activeInstance: state.wizard.activeInstance
});

export default connect(mapStateToProps, () => ({}))(LocationStep);
