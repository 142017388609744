import { useEffect, useState } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { TenantData } from '../components/masterlist/types';
import { axnAPI } from './config';

const tenantsAPI = `/tenants`;

export const useAirportTenants = (iid: string, aid: string, tid: string) => {
  const [tenants, setTenants] = useState(new Array<TenantData>());
  const source = axios.CancelToken.source();
  let mounted = true;
  useEffect(() => {
    axnAPI.get(`${tenantsAPI}/instance/${iid}/airport/${aid}/type/${tid}`).then(
      response => {
        if (mounted) setTenants(response.data.content);
      },
      e => {
        if (mounted && !axios.isCancel(e)) {
          const errorMsg = get(e.response, 'data.error', 'Failure');
          toast.error(`${errorMsg} when requesting tenants for type ${tid}`);
        }
      }
    );

    return () => {
      mounted = false;
      source.cancel('Cancelling in cleanup');
    };
  }, [tenants.length, aid, iid, tid]);

  return tenants;
};

export const fetchAirportTenants = (
  iid: string,
  aid: string,
  tid: string,
  callback: (data: TenantData[]) => void
) => {
  const source = axios.CancelToken.source();

  axnAPI.get(`${tenantsAPI}/instance/${iid}/airport/${aid}/type/${tid}`).then(
    response => {
      callback(response.data.content);
    },
    e => {
      if (!axios.isCancel(e)) {
        const errorMsg = get(e.response, 'data.error', 'Failure');
        toast.error(`${errorMsg} when requesting tenants for type ${tid}`);
      }
    }
  );
};

export const addTenantFromAPI = (tenant: Partial<TenantData>) => {
  return axnAPI.post(`${tenantsAPI}`, {
    ...tenant
  });
};

/* Get Singular Tenant from Endpoint */
export const getTenantFromAPI = (
  tenantID: number,
  instanceID: number,
  airportID: number,
  terminalID: number,
  locationNumber: number
) => {
  return axnAPI.get(
    `${tenantsAPI}/${tenantID}/instance/${instanceID}/airport/${airportID}/terminal/${terminalID}/location/${locationNumber}`
  );
};

export const updateTenantByLocationFromAPI = (
  tnid: number,
  iid: number,
  aid: number,
  tid: number,
  lnum: number,
  tenantDetails: Partial<TenantData>
) =>
  axnAPI.put(
    `${tenantsAPI}/${tnid}/instance/${iid}/airport/${aid}/terminal/${tid}/location/${lnum}`,
    {
      ...tenantDetails
    }
  );

export const deleteTenantFromAPI = (
  tnid: number,
  iid: number,
  aid: number,
  tid: number,
  lnum: number,
  companyId: number,
  brandId: number
) => {
  return axnAPI.delete(
    `${tenantsAPI}/${tnid}/instance/${iid}/airport/${aid}/terminal/${tid}/location/${lnum}/company/${companyId}/brand/${brandId}`
  );
};
