import { PageActions } from '../actions/pageActions';

export const initialPageState = {
  title: 'AXN'
};

export const pageReducer = (
  state: typeof initialPageState = initialPageState,
  action: PageActions
) => {
  switch (action.type) {
    case 'VIEW_PAGE':
      return { ...state, page: { title: action.payload.title } };
    default:
      return state;
  }
};
