import React from 'react';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import TenantAccordionItemWrapper from './TenantsAccordionItemWrapper';
import { TenantProductType } from '../../../../../types';
import Spinner from '../../../../shared/spinner/Spinner';

interface ExistingTenantsProps {
  tenantTypes: TenantProductType[];
  contentID: string;
  contentType: string;
  setLastUpdated: (...props: any) => void;
  lastUpdated: number;
  axnInstanceId: number;
  readonly?: boolean;
  bordered?: boolean;
  title?: string;
  loading?: boolean;
}

const ExistingTenants = ({
  tenantTypes,
  contentID,
  contentType,
  setLastUpdated,
  bordered,
  readonly,
  title,
  loading,
  axnInstanceId
}: ExistingTenantsProps) => {
  return (
    <div className={bordered ? cssClass : ''}>
      <StepHeader Tag="h3" stepTitle={title || 'Existing Tenants'} />
      {loading && <Spinner />}
      {!loading &&
        tenantTypes.map(tenantType => {
          const iid = axnInstanceId;
          const aid = contentID;
          return (
            <TenantAccordionItemWrapper
              iid={`${iid}`}
              aid={aid}
              contentType={contentType}
              contentID={contentID}
              tenantType={tenantType}
              setLastUpdated={setLastUpdated}
              key={`${tenantType.tenantType}`}
              axnInstanceId={axnInstanceId}
              readonly={readonly}
            />
          );
        })}
    </div>
  );
};
ExistingTenants.defaultProps = {
  bordered: false,
  readonly: false,
  loading: false
};

export default ExistingTenants;
