import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField from './FormField';
import { TerminalsList } from '../../actions/terminalsActions';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

/* TODO: Fix the any */
const TerminalSelectField = (props: {
  terminals: TerminalsList;
  terminalsLoading: boolean;
  values: any;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: any;
  handleBlur: any;
  col?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}) => {
  const {
    values,
    match,
    handleChange,
    handleBlur,
    setFieldValue,
    col,
    terminals,
    terminalsLoading
  } = props;
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();
  useEffect(() => {
    const acList = terminals
      .map(terminal => ({
        label: terminal.terminal.terminalName,
        key: `${terminal.terminal.terminalId}`,
        value: `${terminal.terminal.terminalId}`
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setAutoCompleteList(acList);
  }, [terminals]);

  const [labelValue, setLabelValue] = useState();
  useEffect(() => {
    setLabelValue(values.terminalName);
  }, [values.terminalName]);

  const [value, setValue] = useState();
  useEffect(() => {
    setValue(values.terminalId);
  }, [values.terminalId]);

  if (terminalsLoading) return <SelectFieldSpinner {...col} />;
  return (
    <FormField
      name="terminalId"
      labelName="terminalName"
      label="Terminal"
      type="autocomplete"
      match={match}
      value={value}
      labelValue={labelValue}
      handleChange={handleChange}
      handleBlur={handleBlur}
      autoCompleteList={autoCompleteList}
      col={col || {}}
      setFieldValue={setFieldValue}
      browserAutoComplete={'off'}
    />
  );
};

TerminalSelectField.defaultValues = {
  col: { xl: 12 }
};

export default TerminalSelectField;
