import moment from 'moment';

const emptyCompanyState = {
  companyId: 0,
  companyName: '',
  address1: '',
  address2: '',
  city: '',
  postalCode: '',
  stateProvinceCode: '',
  stateProvince: '',
  isoCountryCode: '',
  countryName: '',
  modifiedUserId: 0,
  modifiedDate: moment().format(),
  axnStatus: 'ACTIVE',
  phone: '',
  fax: '',
  url: '',
  companyInfoText: '',
  modifiedUserDisplayName: '',
};

export default emptyCompanyState;
