import React from 'react';
import FormField, { ChangeHandler } from './FormField';
import { AutoCompleteOption } from './autocomplete/AutoComplete';
import { SECURITY_ROLES, convertFromKeyLabel, SecurityRoleFromKey } from '../../core/authentication/SecurityRoles';

const autoCompleteList: AutoCompleteOption[] = SECURITY_ROLES.map(convertFromKeyLabel);

interface SecurityRoleSelect {
  securityRoleId: number;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: ChangeHandler;
  handleBlur: (e: React.FocusEvent) => void;
  col?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}

const SecurityRoleSelect = (props: SecurityRoleSelect) => {
  const { securityRoleId, match, handleChange, handleBlur, setFieldValue, col } = props;
  const role = SecurityRoleFromKey(securityRoleId);
  const labelValue = role !== undefined ? role.label : '';
  return (
    <FormField
      name="securityRoleId"
      labelName="securityRoleName"
      label="Security Role"
      type="autocomplete"
      match={match}
      value={securityRoleId}
      labelValue={labelValue}
      handleChange={handleChange}
      handleBlur={handleBlur}
      autoCompleteList={autoCompleteList}
      col={col || {}}
      setFieldValue={setFieldValue}
    />
  );
};

export default SecurityRoleSelect;
