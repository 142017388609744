import * as React from 'react';
import { IndexableMasterListItem, MasterListActionButton, MasterListActions } from './types';
import { ListActionButton } from './ListActionButton';
import { TableColumn } from './MasterList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const getButtonGroup = (userManagementActionButtons: MasterListActionButton[]) => {
  return (
    <>
      {userManagementActionButtons.map(val => {
        const { text, to, actionCallback, icon } = val;
        return (
          <ListActionButton
            key={window.btoa(text + to)}
            text={text}
            to={to}
            actionCallback={actionCallback}
            icon={icon}
          />
        );
      })}
    </>
  );
};

interface ListRowProps {
  actions: MasterListActions;
  columns: TableColumn[];
  values: IndexableMasterListItem;
  additionalValues?: string[];
  readonly?: boolean;
}

export const ListRow = (props: ListRowProps) => {
  const { columns, values, actions, additionalValues, readonly } = props;
  const itemIDKey = columns[0].id;
  const itemID = values[itemIDKey];
  const additionalParams = additionalValues
    ? additionalValues.map(paramKey => values[paramKey])
    : [];
  return (
    <tr className={'data-slice'}>
      {columns.map((col: TableColumn) => {
        return (
          <td key={col.id} style={{ width: col.width ? col.width : '100px', verticalAlign: 'middle', alignContent: 'center' }}>
            {typeof(values[col.id.toString()]) === 'boolean' ? values[col.id.toString()] === true ? <FontAwesomeIcon icon={faCheck}/> : "" : values[col.id.toString()] }
          </td>
        );
      })}
      {!readonly && (
        <td style={{ width: '100px' }}>
          <div className={'d-flex justify-content-center'}>
            {getButtonGroup(actions(itemID, ...additionalParams))}
          </div>
        </td>
      )}
    </tr>
  );
};
