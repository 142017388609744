import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField from './FormField';
import { useCountries } from '../../api/StateProvince';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

/* TODO: Fix the any */
const IsoCountrySelectField = (props: {
  values: any;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: any;
  handleBlur: any;
  displayRequired: boolean;
}) => {
  const { values, match, handleChange, handleBlur, setFieldValue } = props;
  const { countries, countriesLoading } = useCountries();
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();
  useEffect(() => {
    const aclist = countries.map(country => ({
      ...country,
      label: country.countryName,
      key: country.isoCountryCode,
      value: country.isoCountryCode
    }));
    setAutoCompleteList(aclist);
  }, [countries]);

  useEffect(() => {
    const country = countries.find(c => c.isoCountryCode === values.isoCountryCode);
    if (country) setFieldValue('countryName', country.countryName, false);
  }, [countries, setFieldValue, values.countryName, values.isoCountryCode]);

  if (countriesLoading) return <SelectFieldSpinner />;
  return (
    <FormField
      name="isoCountryCode"
      labelName="countryName"
      label={(<span>Country {props.displayRequired ? <i>(Required)</i> : ''}</span>) as JSX.Element}
      type="autocomplete"
      match={match}
      value={values.isoCountryCode || ''}
      labelValue={values.countryName || ''}
      handleChange={handleChange}
      handleBlur={handleBlur}
      autoCompleteList={autoCompleteList}
      col={{ lg: 12 }}
      setFieldValue={setFieldValue}
    />
  );
};

export default IsoCountrySelectField;
