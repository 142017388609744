import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Container } from "reactstrap";
import { cssClass } from "../../wizard/cssSharedClasses";

const AddEditProductTypeView = (props: RouteComponentProps<{}>) => {
  return <Container className={cssClass}>Add Edit View</Container>;
};

export default withRouter(AddEditProductTypeView);
