import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField from './FormField';
import { useAllInstances } from '../../api/instances';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

/* TODO: Fix the any */
const InstanceSelectField = (props: {
  values: any;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: any;
  handleBlur: any;
  isAdmin: boolean;
  showLabel?: boolean;
  col?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}) => {
  const { values, match, handleChange, handleBlur, setFieldValue, col, showLabel, isAdmin } = props;
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();
  const { instances, instanceLoading } = useAllInstances();
  useEffect(() => {
    const acList = instances
      .filter(value => (isAdmin ? true : value.axnInstanceMasterStatus !== 'PENDING'))
      .map(axnInstance => {
        const { axnInstanceDes, axnInstanceId, axnInstanceMasterStatus } = axnInstance;
        return {
          label: `${axnInstanceDes} (${axnInstanceMasterStatus})`,
          key: `${axnInstanceId}`,
          value: `${axnInstanceId}`
        };
      })
      .sort((a, b) => {
        return a.label.toLowerCase() === 'none' ? 1 : a.label.localeCompare(b.label);
      })
      .filter(item => item.label !== ''); // no blank labels
    setAutoCompleteList(acList);
  }, [instances, isAdmin]);

  useEffect(() => {
    const selectedInstance = instances.find(
      instance => instance.axnInstanceId === +values.instanceId
    );

    if (selectedInstance) {
      setFieldValue('axnInstanceMasterStatus', selectedInstance.axnInstanceMasterStatus);
      setFieldValue('instanceYear', selectedInstance.axnInstanceName);
    }
  }, [values.instanceId, values.axnInstanceName, instances, setFieldValue]);

  if (instanceLoading) return <SelectFieldSpinner {...col} />;
  return (
    <FormField
      name="instanceId"
      labelName="axnInstanceName"
      label={showLabel ? `Axn Instance` : ``}
      type="autocomplete"
      match={match}
      value={values.instanceId}
      labelValue={values.axnInstanceName}
      handleChange={handleChange}
      handleBlur={handleBlur}
      autoCompleteList={autoCompleteList}
      col={col || {}}
      setFieldValue={setFieldValue}
      style={{
        margin: '0px',
        padding: '0px'
      }}
    />
  );
};

InstanceSelectField.defaultValues = {
  showLabel: false,
  col: { xl: 12 }
};

export default InstanceSelectField;
