import moment from 'moment';
import emptyCompanyState from "./emptyCompany";
import { AxnInstanceStatus } from "../../../types";
import { CompanyInstanceType } from "../../../actions/companiesActions";

const emptyCompanyInstanceState = {
  companyInstancePK: {
    companyId: +'', // empty number?
    axnInstanceId: moment().year(),
  },
  company: emptyCompanyState,
  axnInstanceStatus: 'INPROGRESS' as AxnInstanceStatus,
  modifiedDate: moment().format('YYYY-MM-DD'),
  modifiedUserId: +'',
  dbeFlag: 'N',
  companyInstanceTypeList: new Array<CompanyInstanceType>()
};

export default emptyCompanyInstanceState;
