import {
  TerminalData,
  TerminalIDData,
  TerminalsAction,
  TerminalsList
} from '../actions/terminalsActions';
import { getAuthUserID } from "../core/authentication/authenticationHelper";

/* This Reducer setup is kind of wordy but it does over type safe actions with a ton of IDE auto complete
 capabilities. */
interface TerminalState {
  terminals: TerminalsList;
  terminal: TerminalData;
  loading: boolean;
  error: string;
}

/* Set Initial Terminal State - Entire possible shape of object initially. */
const initialTerminalState = {
  terminals: Array<TerminalData>(),
  terminal: {
    airportTerminalPK: {
      airportId: 0,
      terminalId: 0,
      axnInstanceId: 0
    },
    revenueTotal: 0,
    salesEpDomesticTotal: 0,
    salesEpIntlTotal: 0,
    enplaneTotal: 0,
    deplaneTotal: 0,
    modifiedUserId: getAuthUserID(),
    modifiedDate: '', // 2018-12-31T06:00:00.000+0000
    terminal: {
      terminalId: 0,
      terminalName: '',
      terminalShortName: ''
    }
  },
  loading: false,
  error: ''
};

export type TerminalsState = typeof initialTerminalState;

export const terminalsReducer = (
  state: TerminalState = initialTerminalState,
  action: TerminalsAction
) => {
  switch (action.type) {
    case 'GET_TERMINAL':
    case 'GET_TERMINALS':
    case 'UPDATE_ADD_TERMINAL':
    case 'DELETE_TERMINAL':
      return { ...state, loading: true };
    case 'GET_TERMINAL_SUCCESS':
    case 'UPDATE_ADD_TERMINAL_SUCCESS':
      return { ...state, loading: false, terminal: action.terminal };
    case 'GET_TERMINALS_SUCCESS':
      return { ...state, loading: false, terminals: action.terminals };
    case 'DELETE_TERMINAL_SUCCESS':
      return {
        ...state,
        terminals: state.terminals.filter(
          data => (data as TerminalIDData) !== action.terminalID
        ),
        loading: false
      };
    case 'GET_TERMINAL_FAILURE':
    case 'GET_TERMINALS_FAILURE':
    case 'DELETE_TERMINAL_FAILURE':
    case 'UPDATE_ADD_TERMINAL_FAILURE':
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
