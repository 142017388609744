import React, { useState, useEffect } from 'react';
import { AxnStatus } from '../../../types';
import { AppState, appHistory } from '../../../reducers';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, ButtonGroup, Button } from 'reactstrap';
import AddEntityButton from '../AddEntityButton';
import { faPlus, faUserEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form } from 'formik';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { cssClass } from '../../wizard/cssSharedClasses';
import FormField from '../../forms/FormField';
import AxnStatusSelectField from '../../forms/AxnStatusSelectField';
import GoBack from '../../buttons/GoBack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    BrandData,
    createBrandFromAPI,
    updateBrandFromAPI,
    getBrandById,
    deleteBrandFromAPI
} from '../../../api/Brands';
import Confirm from '../users/Confirm';

export const initBrandVals = {
    brandName: '',
    axnStatus: 'ACTIVE' as AxnStatus
};

type AddEditBrandFormUrlParams = {
    addEdit: string;
    brandId: string;
};

type AddEditBrandFormProps = {
    authenticatedUserId: number;
};

const AddEditBrandView = (
    props: AddEditBrandFormProps & RouteComponentProps<AddEditBrandFormUrlParams>
) => {
    const { match, authenticatedUserId } = props;
    const { addEdit, brandId } = match.params;

    const [initBrandFormValues, setInitBrandFormValues] = useState(initBrandVals);
    const [brandData, setBrandData] = useState<BrandData>({} as BrandData);

    const ucFirstAddEdit = addEdit[0].toUpperCase() + addEdit.slice(1);

    useEffect(() => {
        if (brandId && addEdit.toLowerCase() === 'edit') {
            getBrandById(+brandId).then(
                response => {
                    setBrandData(response.data);
                    setInitBrandFormValues({ ...response.data });
                },
                () => toast.warn('Failed to get Brand data!')
            );
        }
    }, [brandId, addEdit]);

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <AddEntityButton
                            addIcon={faPlus}
                            emptyEntityState={initBrandVals}
                            path={`/admin/manage/brands/add`}
                            setEntity={setInitBrandFormValues}
                        />
                        <Formik
                            enableReinitialize
                            onSubmit={values => {
                                const isAdd = addEdit.toLowerCase() === 'add';
                                const { brandName, axnStatus } = values;

                                const endpoint = isAdd ? createBrandFromAPI : updateBrandFromAPI;

                                const sendVals = {
                                    brandId: brandData?.brandId || null,
                                    brandName: brandName,
                                    axnStatus: axnStatus
                                } as BrandData;

                                endpoint(sendVals).then(
                                    () => {
                                        toast.success(`Successfully ${addEdit}ed Brand ${brandData.brandName}!`);
                                        appHistory.push(`/admin/manage/brands`);
                                    },
                                    error => {
                                        const errorMsg = get(
                                            error.response,
                                            'data.message',
                                            `Failed to ${addEdit} Brand!`
                                        );
                                        toast.error(errorMsg);
                                    }
                                );
                            }}
                            initialValues={initBrandFormValues}
                            render={({ handleBlur, handleChange, values }) => {
                                return (
                                    <Form className={cssClass}>
                                        <h1 className={'mb-3'}>{ucFirstAddEdit}ing Brand</h1>
                                        <Row>
                                            {addEdit === 'edit' ? (
                                                <div className={'ml-3 mb-3'}>Brand ID: 
                                                    <div className={'mt-2 ml-3 mb-2'}>{brandId}</div>
                                                </div>
                                            ) : (<div></div>)}

                                            <FormField
                                                name="brandName"
                                                label="Brand Name"
                                                type="text"
                                                value={values.brandName}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                match={match}
                                                col={{ xl: 12, lg: 12 }}
                                            />
                                            <AxnStatusSelectField
                                                axnStatusValue={values.axnStatus || 'ACTIVE'}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                            />
                                        </Row>
                                        <ButtonGroup className="mt-5">
                                            <GoBack color={'secondary'} />
                                            <Button color="primary" type="submit">
                                                {addEdit === 'edit' ? (
                                                    <>
                                                        <FontAwesomeIcon icon={faUserEdit} /> Save
                                                    </>
                                                ) : (
                                                        <>
                                                            <FontAwesomeIcon icon={faUserPlus} /> Add
                                                        </>
                                                    )}
                                            </Button>
                                        </ButtonGroup>
                                    </Form>
                                );
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

const mapStateToProps = (state: AppState) => ({
    activeInstance: state.wizard.activeInstance,
    authenticatedUserId: state.users.authenticatedUser.userId
});

export default connect(mapStateToProps)(withRouter(AddEditBrandView));
