import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Col, Container, Row } from 'reactstrap';
import { cssClass } from '../../wizard/cssSharedClasses';
import ReportsView from '../../../pages/ReportsView';
import { AppState } from '../../../reducers';
import {
  AvailableInstanceMasterStatus,
  dispatchSwitchWizardInstance,
  WizardActionsCreator
} from '../../../actions/wizardActions';
import { AuthenticatedUser } from '../../../actions/usersActions';
import AllReports from './AllReports';
import CompanyTypeReports from './CompanyTypeReports';

export interface ChildReportComponent {
  activeInstance: number;
  activeInstanceStatus: AvailableInstanceMasterStatus;
  axnInstanceId: number;
  axnPendingInstanceId: number;
  axnInstanceName: string;
  axnPendingInstanceName: string;
  switchWizardInstance: WizardActionsCreator;
  authenticatedUser: AuthenticatedUser;
}

interface ReportsManagerProps {
  authenticatedUser: AuthenticatedUser;
  activeInstance: number;
  activeInstanceStatus: string;
  switchWizardInstance: WizardActionsCreator;
}
const ReportsManager = (props: ReportsManagerProps) => {
  const { authenticatedUser, activeInstance, switchWizardInstance, activeInstanceStatus } = props;
  const {
    axnInstanceId,
    axnPendingInstanceId,
    axnInstanceName,
    axnPendingInstanceName
  } = authenticatedUser;

  return (
    <Container>
      <AllReports
        activeInstanceStatus={activeInstanceStatus as AvailableInstanceMasterStatus}
        activeInstance={activeInstance}
        axnInstanceId={axnInstanceId}
        axnPendingInstanceId={axnPendingInstanceId}
        axnInstanceName={axnInstanceName}
        axnPendingInstanceName={axnPendingInstanceName}
        switchWizardInstance={switchWizardInstance}
        authenticatedUser={authenticatedUser}
      />
      <CompanyTypeReports
        activeInstanceStatus={activeInstanceStatus as AvailableInstanceMasterStatus}
        activeInstance={activeInstance}
        axnInstanceId={axnInstanceId}
        axnPendingInstanceId={axnPendingInstanceId}
        axnInstanceName={axnInstanceName}
        axnPendingInstanceName={axnPendingInstanceName}
        switchWizardInstance={switchWizardInstance}
        authenticatedUser={authenticatedUser}
      />
      <Row>
        <Col>
          <ReportsView isAdmin activeInstanceStatus={activeInstanceStatus} />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  authenticatedUser: state.users.authenticatedUser,
  activeInstance: state.wizard.activeInstance.id,
  activeInstanceStatus: state.wizard.activeInstance.status
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  switchWizardInstance: dispatchSwitchWizardInstance(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsManager);
