import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Col, Row } from 'reactstrap';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { StepDefaultProps, WizardContactsMatch } from '../../../types';
import { SetWizardFormState, setWizardFormState } from '../../../../../actions/wizardActions';
import { appHistory, AppState } from '../../../../../reducers';
import ContactsForm from './ContactsForm';
import {
  initialContactFormValuesWithFlag,
  ContactData,
  deleteAirportContact,
  DeleteContact,
  DispatchResetContact,
  dispatchResetContact,
  InitAddContact,
  initAddContact,
  InitGetContact,
  initGetContact,
  InitGetContacts,
  initGetContacts,
  initialContactFormValues,
  InitUpdateContact,
  initUpdateContact
} from '../../../../../actions/contactsActions';
import { convertContactPhoneList } from '../../../../../api/contacts';
import { airportContactSchema } from '../../../shared/yupSchemas/yupSchema';
import { YesOrNo } from '../../../../../types';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import Spinner from '../../../../shared/spinner/Spinner';
import { AuthenticatedUser } from '../../../../../actions/usersActions';
import AirportContactsList, { ContactsListUrlParams } from './AirportContactList';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';

export interface ContactsStepProps extends StepDefaultProps {
  initGetContact: InitGetContact;
  initGetContacts: InitGetContacts;
  dispatchResetContact: DispatchResetContact;
  deleteContact: DeleteContact;
  initUpdateContact: InitUpdateContact;
  initAddContact: InitAddContact;
  contact: ContactData;
  contacts: ContactData[];
  contactsLoading: boolean;
  match: WizardContactsMatch;
  authenticatedUser: AuthenticatedUser;
  activeInstance: ActiveInstance;
}

const ContactsStep = (props: ContactsStepProps & RouteComponentProps<ContactsListUrlParams>) => {
  const {
    match,
    initGetContact,
    initGetContacts,
    dispatchResetContact,
    deleteContact,
    initUpdateContact,
    initAddContact,
    contact,
    contacts,
    contactsLoading,
    authenticatedUser,
    activeInstance
  } = props;
  const { userId } = authenticatedUser;
  const { id } = activeInstance;
  const [contactToUse, setContactToUse] = useState(contact);

  useEffect(() => {
    setContactToUse(contact);
  }, [
    contact.managementResponsibilityType,
    contact.companyName,
    contact.stateProvince,
    contact.countryName
  ]);

  /* First Render - Reset Contact in Redux state in case it was set previously in another wizard */
  useEffect(() => dispatchResetContact(), [dispatchResetContact]);

  const { contentID, contactID, contentType } = match.params;

  /* This is here to for the reload from the child, used as dep of use effect */
  // const [selectedContact, setSelectedContact] = useState(contactID);
  const [initVals, setInitVals] = useState(initialContactFormValuesWithFlag);

  /* Set Form Initial Value and Track with Functional Component State */
  useEffect(() => {
    setInitVals({
      ...initialContactFormValues,
      ...contactToUse,
      factbookIncludeFlag: contactToUse.factbookIncludeFlag === 'Y',
      workExt: contactToUse.workExt || '',
      workPhone: contactToUse.workPhone || '',
      faxPhone: contactToUse.faxPhone || '',
      faxExt: contactToUse.faxExt || '',
      modifiedUserId: userId,
      managementResponsibilityType: contactToUse.managementResponsibilityType,
      stateProvince: contactToUse.stateProvince,
      countryName: contactToUse.countryName,
      companyName: contactToUse.companyName
    });
  }, [initVals.contactId, contactToUse, userId]);

  /* get the current contact based on contact id url param if existent */
  useEffect(() => {
    if (contactID) initGetContact(contentID, contactID, 'airports', '', '', id);
  }, [contactToUse.firstName, contactID, contentID, initGetContact, id]);

  /* get all the contacts associated with the current airport  */
  useEffect(() => {
    initGetContacts(contentID, 'airports', id);
  }, [contacts.length, contentID, initGetContacts, id]);

  /* change the location to default form location and reset contact so form resets. */
  const resetContact = () => {
    appHistory.push(match.url.replace(/\/[0-9]*$/, ''));
    dispatchResetContact();
  };

  return (
    <>
      <Row>
        <Col>
          <Formik
            validationSchema={airportContactSchema}
            enableReinitialize
            onSubmit={(values: ContactData, formikActions) => {
              const sendVals = {
                ...values,
                factbookIncludeFlag: (values.factbookIncludeFlag ? 'Y' : 'N') as YesOrNo, // API requires it this
                // way :(
                contactPhoneList: convertContactPhoneList(values),
                managementResponsibilityTypeId: +values.managementResponsibilityTypeId // parse to number
              };

              delete sendVals.workPhone;
              delete sendVals.workExt;
              delete sendVals.faxPhone;
              delete sendVals.faxExt;

              if (contactID)
                initUpdateContact(
                  contentID,
                  contactID,
                  values.managementResponsibilityTypeId,
                  '',
                  '',
                  sendVals,
                  'airports',
                  id
                );
              else initAddContact(contentID, sendVals, 'airports', id);

              resetContact();
              formikActions.resetForm();
            }}
            onReset={() => resetContact()}
            initialValues={initVals}
            render={({
              handleBlur,
              handleChange,
              handleReset,
              handleSubmit,
              values,
              setFieldValue
            }) => (
              <ContactsForm
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleReset={handleReset}
                handleSubmit={handleSubmit}
                isUpdatingContact={!!contactID}
                values={values}
                setFieldValue={setFieldValue}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={cssClass}>
            <StepHeader stepTitle={'Existing Contacts'} />
            {contactsLoading && <Spinner />}
            {!contactsLoading && contacts.length > 0 ? (
              <AirportContactsList
                contacts={contacts}
                contentID={contentID}
                contentType={contentType}
                handleDelete={deleteContact}
                instanceId={id}
              />
            ) : (
              <StepHeader Tag={'h4'} stepTitle={'No Airport Contacts Available!'} />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  contactFormStore: state.wizard.stepper.airports.contacts,
  contact: state.contacts.contact,
  contacts: state.contacts.contacts,
  contactsLoading: state.contacts.loading,
  authenticatedUser: state.users.authenticatedUser,
  activeInstance: state.wizard.activeInstance
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initGetContact: initGetContact(dispatch),
  initGetContacts: initGetContacts(dispatch),
  dispatchResetContact: dispatchResetContact(dispatch),
  deleteContact: deleteAirportContact(dispatch),
  initUpdateContact: initUpdateContact(dispatch),
  initAddContact: initAddContact(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactsStep));
