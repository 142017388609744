import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField from './FormField';
import { useAllBrands } from '../../api/Brands';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

/* TODO: Fix the any */
const BrandNameSelectField = (props: {
  values: any;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: any;
  handleBlur: any;
}) => {
  const { values, match, handleChange, handleBlur, setFieldValue } = props;
  const { brands, brandsLoading } = useAllBrands();
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();

  const [labelValue, setLabelValue] = useState(values.brandName);
  const [value] = useState(values.brandId);

  useEffect(() => {
    const aclist: AutoCompleteOption[] = brands
      .map(brand => ({
        ...brand,
        label: brand.brandName,
        key: `${brand.brandId}`,
        value: `${brand.brandId}`
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setAutoCompleteList(aclist);
  }, [brands]);

  useEffect(() => {
    const brand = brands.find(b => b.brandId === values.brandId);

    if (brand) {
      setLabelValue(brand.brandName);
      setFieldValue('brandName', brand.brandName, false);
    }
  }, [brands, setFieldValue, values.brandName, values.brandId]);

  if (brandsLoading) return <SelectFieldSpinner xl={6} />;

  return (
    <FormField
      name="brandId"
      labelName="brandName"
      label="Brand Name"
      type="autocomplete"
      match={match}
      value={value}
      labelValue={labelValue}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      handleBlur={handleBlur}
      autoCompleteList={autoCompleteList}
      col={{ lg: 6 }}
      browserAutoComplete={'off'}
    />
  );
};

export default BrandNameSelectField;
