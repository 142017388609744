import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { useAirportTenantTypes } from '../api/Airports';
import { cssClass } from '../components/wizard/cssSharedClasses';
import LeaseExpiration from '../components/lease/LeaseExpiration';
import { AppState } from '../reducers';

const LeaseExpirationView = ({ axnInstanceId }: { axnInstanceId: number }) => {
  const { tenantTypes } = useAirportTenantTypes();
  return (
    <Container className={cssClass}>
      <Row>
        <Col>
          <h1>Lease Expirations</h1>
        </Col>
      </Row>
      <Row className="pt-5">
        <Col>
          {tenantTypes.map(tenantType => (
            <LeaseExpiration
              key={tenantType.tenantType}
              tenantProductType={tenantType}
              axnInstanceId={axnInstanceId}
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  axnInstanceId: state.users.authenticatedUser.axnInstanceId
});

export default connect(mapStateToProps, null)(LeaseExpirationView);
