import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ManagementResponsibility } from '../types';
import { axnAPI } from './config';

export const useManagementResponsibilities = () => {
  const [managementResponsibilities, setManagementResponsibilities] = useState(
    new Array<ManagementResponsibility>()
  );
  const [managementResponsibilitiesLoading, setManagementResponsibilitiesLoading] = useState(false);

  useEffect(() => {
    let unmounted = false;
    const source = axios.CancelToken.source();
    setManagementResponsibilitiesLoading(true);

    axnAPI.get(`/managementresponsibilitytype`, { cancelToken: source.token }).then(
      response => {
        if (!unmounted) {
          setManagementResponsibilities(response.data);
          setManagementResponsibilitiesLoading(false);
        }
      },
      e => {
        if (!unmounted) {
          if (!axios.isCancel(e)) {
            setManagementResponsibilitiesLoading(false);
            toast.error(`Error fetching data: ${e}`);
          }
        }
      }
    );
    return () => {
      unmounted = true;
      source.cancel('Operation canceled by the user.');
    };
  }, [
    setManagementResponsibilities,
    setManagementResponsibilitiesLoading,
    managementResponsibilities.length
  ]);

  return { managementResponsibilities, managementResponsibilitiesLoading };
};
