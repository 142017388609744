import React, { useState } from 'react';
import { PageableData } from '../../../api';
import { MasterListPagesize, MasterListPagination } from '../index';
import { MasterListActionButton, IndexableMasterListItem, MasterListActions } from '../types';
import MasterList, { TableColumn } from '../MasterList';
import SearchField from '../../search/SearchField';

interface FilteredMasterListProps<T> {
  actions?: MasterListActions;
  columns: TableColumn[];
  convert: (rowData: T) => IndexableMasterListItem;
  listType: string;
  useData: (args: string, keyword: string, instanceId: number) => PageableData<T>;
  args: string;
  axnInstanceId: number;
  additionalValues?: string[];
  readonly?: boolean;
}

export const FilteredMasterList = <T extends unknown>({
  actions,
  args,
  columns,
  convert,
  listType,
  useData,
  axnInstanceId,
  additionalValues,
  readonly
}: FilteredMasterListProps<T>) => {
  const searchState = useState(false);
  const setSearching = searchState[1];
  const [keyword, setKeyword] = useState('');
  const model = useData(args, keyword, axnInstanceId);

  const { loading, setCurrentPage, setPageSize } = model;
  const { content, currentPage, pageSize, totalRecords } = model.data;

  return (
    <div>
      <div className={'py-3 d-flex search-list-tools justify-content-between'}>
        <MasterListPagesize size={pageSize} setPagesize={setPageSize} />
        <SearchField
          searchRequest={model}
          setKeyword={setKeyword}
          setSearching={setSearching}
          size={pageSize}
          page={currentPage}
        />
        <MasterListPagination
          pageSize={pageSize}
          totalItems={totalRecords}
          handlePaginationSelection={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
      <MasterList
        loading={loading}
        actions={actions !== undefined ? actions : () => []}
        columns={columns}
        currentPage={currentPage}
        itemCount={totalRecords}
        list={content.map(convert)}
        listType={listType}
        readonly={actions === undefined || actions.length === 0 || readonly}
        additionalValues={additionalValues || []}
      />
      <div className={'py-3 d-flex search-list-tools justify-content-between'}>
        <MasterListPagesize size={pageSize} setPagesize={setPageSize} />
        <MasterListPagination
          pageSize={pageSize}
          totalItems={totalRecords}
          handlePaginationSelection={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

FilteredMasterList.defaultProps = {
  readonly: false
};
