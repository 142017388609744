import React from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Button, Col, Row, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import FormField from '../forms/FormField';
import { appHistory } from '../../reducers';
import AccordionItem from '../accordion/AccordionItem';

interface SearchFormProps {
  initialValue: string;
  isSubmitting: boolean;
  handleReset: () => void;
  handleSubmit: (searchTerm: string) => void;
}

const schema = object().shape({ /*
  searchTerm: string().required(
    'In order to search, you must enter the full or partial name of a company.'
  )
*/ });

export const SearchForm = ({
  handleReset,
  handleSubmit,
  initialValue,
  isSubmitting
}: SearchFormProps) => (
  <AccordionItem name="Company Search" expandedDefault>
    <Formik
      enableReinitialize
      onSubmit={values => {
        const { searchTerm } = values;
        handleSubmit(searchTerm);
        appHistory.push(`/companies/${searchTerm||"_all"}`);
      }}
      onReset={(_values, formikActions) => {
        appHistory.push('/companies');
        formikActions.resetForm();
        handleReset();
      }}
      initialValues={{
        searchTerm: initialValue
      }}
      validationSchema={schema}
      render={({ values, handleBlur, handleChange }) => (
        <Form className="w-100">
          <Row>
            <FormField
              name="searchTerm"
              label="Company Name"
              handleBlur={handleBlur}
              handleChange={handleChange}
              match={{ params: { contentID: values.searchTerm } }}
              value={values.searchTerm == '_all' ? '' : values.searchTerm}
              type="text"
              helpText="Enter the full or partial name of a company."
              col={{ lg: 12 }}
            />
          </Row>
          <Row>
            <Col>
              <ButtonGroup>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  <FontAwesomeIcon icon={faSearch} /> Search
                </Button>
                <Button type="reset" color="secondary">
                  <FontAwesomeIcon icon={faRedoAlt} /> Reset
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Form>
      )}
    />
  </AccordionItem>
);
