import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faStoreAlt } from '@fortawesome/free-solid-svg-icons/faStoreAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form } from 'formik';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import { appHistory, AppState } from '../../../../../reducers';
import { SetWizardFormState, setWizardFormState } from '../../../../../actions/wizardActions';
import { companyInfoValidationSchema } from './YupSchema';
import FormField from '../../../../forms/FormField';
import { StepDefaultProps, WizardStepperMatch } from '../../../types';
import {
  CompanyInstance,
  CompanyInstanceType,
  getCompany,
  InitGetCompany,
  initGetCompany
} from '../../../../../actions/companiesActions';
import {
  addCompanyTypesFromAPI,
  CompanyTypes,
  deleteCompanyTypeFromAPI,
  updateCompanyInfoFromAPI,
  useCompanyTypes
} from '../../../../../api/Companies';
import { AxnInstanceStatus, YesOrNo } from '../../../../../types';
import ListManager from '../../../shared/ListManager';
import { AuthenticatedUser } from '../../../../../actions/usersActions';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';

export const initialInfoFormValuesForAPI = {
  companyInstanceTypeList: new Array<CompanyInstanceType>(),
  dbeFlag: 'N',
  companyInfoText: '',
  companyType: 'None'
};

export const initialInfoFormValues = {
  companyInstanceTypeList: new Array<CompanyInstanceType>(),
  dbeFlag: false,
  companyInfoText: '',
  companyType: '',
  companyTypeDes: '',
  companyTypeInfoText: null as null | any
};

/* Reuse this */
interface InfoStepProps extends StepDefaultProps {
  dispatch?: Dispatch;
  setFormState: SetWizardFormState;
  getCompany: InitGetCompany;
  companyInstance: CompanyInstance;
  match: WizardStepperMatch;
  authenticatedUser: AuthenticatedUser;
  activeInstance: ActiveInstance;
}
interface CompanyTypeWithDescription {
  id: number;
  name: string;
  description: string;
}

const InfoStep = (props: InfoStepProps) => {
  const { match, companyInstance, authenticatedUser, activeInstance, getCompany } = props;
  const { id } = activeInstance;
  const { userId } = authenticatedUser;
  const { contentID } = match.params;
  const { companyTypes } = useCompanyTypes(id, +contentID);
  const [initVals, setInitVals] = useState({ ...initialInfoFormValues });
  const [selectedTypes, setSelectedTypes] = useState(new Array<CompanyTypeWithDescription>());

  useEffect(() => {
    setSelectedTypes(
      initVals.companyInstanceTypeList.map(companyType => ({
        id: companyType.companyInstanceTypePK.companyId,
        name: companyType.companyInstanceTypePK.companyType,
        description: companyType.associatedCompanyType.companyTypeDes
      }))
    );
  }, [initVals]);

  useEffect(() => {
    const textKV = new Array<{ [key: string]: string }>();
    companyInstance.companyInstanceTypeList.forEach(companyInstanceType => {
      // @ts-ignore
      textKV[`${companyInstanceType.associatedCompanyType.companyType}`] =
        companyInstanceType.companyTypeInfoText;
    });

    setInitVals({
      ...initialInfoFormValues,
      companyInfoText: companyInstance.company.companyInfoText,
      dbeFlag: companyInstance.dbeFlag === ('Y' as YesOrNo), // convert to boolean for checkbox
      companyInstanceTypeList: companyInstance.companyInstanceTypeList,
      companyType: 'None',
      companyTypeInfoText: { ...textKV }
    });
  }, [companyInstance, companyTypes]);

  const handleDelete = (itemId: number, name: string, axnInstanceId: number) => {
    deleteCompanyTypeFromAPI(itemId, name as CompanyTypes, axnInstanceId)
      .then(() => {
        var newInitVals = { ...initVals };
        newInitVals.companyInstanceTypeList = initVals.companyInstanceTypeList.filter(item => {
          return item.associatedCompanyType.companyType != name;
        });

        setInitVals(newInitVals);

        toast.success(`Successfully Deleted ${name}`);
      })
      .catch(error => {
        const { message } = error.response.data;
        if (message) {
          toast.error(message, { autoClose: 10000 });
          appHistory.push(`/wizard/companies/${contentID}/steps/contacts`);
        } else {
          toast.error(`Oops, an error occurred while deleting a list item: - ${error}`);
        }
      });
  };

  const handleAdd = (
    companyId = 0,
    instanceId: number,
    _companyType: CompanyTypes,
    companyTypeDes: string,
    companyInstanceType: CompanyInstanceType
  ) => {
    addCompanyTypesFromAPI(companyId, instanceId, _companyType, companyInstanceType)
      .then(response => {
        const { companyType } = response.data.companyInstanceTypePK;

        var newCompanyInstanceType: CompanyInstanceType = {
          companyInstanceTypePK: response.data.companyInstanceTypePK,
          associatedCompanyType: {
            companyType: companyType,
            companyTypeDes: companyTypeDes,
            sequenceNumber: initVals.companyInstanceTypeList.length
          },
          companyTypeInfoText: ''
        };

        var newInitVals = { ...initVals };
        newInitVals.companyInstanceTypeList = [
          ...newInitVals.companyInstanceTypeList,
          newCompanyInstanceType
        ];

        setInitVals(newInitVals);
        toast.success(`Successfully Added ${companyTypeDes}`);
      })
      .catch(error => {
        const status = get(error.response, 'status', 9999);
        if (status === 500) toast.warn(`Potentially Duplicate Item.`);
        else toast.error(`${error}`);
      });
  };

  console.log(initVals);

  return (
    <Row>
      <Col>
        <div className={cssClass}>
          <Formik
            initialValues={initVals}
            validationSchema={companyInfoValidationSchema}
            enableReinitialize
            onSubmit={values => {
              const putValues = {
                company: {
                  ...companyInstance.company,
                  companyInfoText: values.companyInfoText
                },
                axnInstanceStatus: 'PUBLISHED' as AxnInstanceStatus,
                modifiedUserId: userId,
                dbeFlag: (values.dbeFlag ? 'Y' : 'N') as YesOrNo,
                companyInstanceTypeList: values.companyInstanceTypeList.map(
                  (companyInstanceType: any) => {
                    const companyType = get(
                      companyInstanceType,
                      'associatedCompanyType.companyType',
                      ''
                    );
                    const companyTypeText = get(values.companyTypeInfoText, companyType, '');
                    return {
                      ...companyInstanceType,
                      companyInstanceTypePK: {
                        ...companyInstanceType.companyInstanceTypePK,
                        axnInstanceId: id // set to current active axn instance?
                      },
                      companyTypeInfoText: companyTypeText
                    };
                  }
                )
              };

              updateCompanyInfoFromAPI(+contentID, putValues, id)
                .then(() => {
                  toast.success('Successfully Updated Company Info');
                  getCompany(+contentID, id); // update the redux state
                })
                .catch(error => {
                  toast.error(`Error with Submitting Company Info: ${error}`);
                });
            }}
            render={({ handleSubmit, handleReset, handleChange, handleBlur, values }) => {
              return (
                <Form>
                  <div>
                    <StepHeader stepTitle="Company Information" />
                    <Row>
                      <Col>
                        <Row>
                          <Col xl lg>
                            <Row>
                              <FormField
                                name="dbeFlag"
                                label="Are you a certified Disadvantaged Business Enterprise?"
                                // info={'?'}
                                type="checkbox"
                                match={match}
                                value={values.dbeFlag}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                col={{ lg: 12 }}
                              />
                              <FormField
                                name="companyInfoText"
                                label="Enter a detailed description of your company as you wished published in the Online Fact Book."
                                // info={'?'}
                                type="textarea"
                                match={match}
                                value={values.companyInfoText}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                col={{ lg: 12 }}
                              />
                              {selectedTypes.map(selectedType => {
                                const { description, name, id } = selectedType;
                                return (
                                  <FormField
                                    key={`${name}-${id}`}
                                    name={`companyTypeInfoText.${name}`}
                                    label={`Enter a detailed description for the ${description} company type.`}
                                    // info={'?'}
                                    type="textarea"
                                    match={match}
                                    value={values.companyTypeInfoText[`${name}`] || ''}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    col={{ lg: 12 }}
                                  />
                                );
                              })}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="pt-5">
                      <Col>
                        <ButtonGroup>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={event => {
                              event.preventDefault();
                              handleSubmit();
                            }}>
                            Save Changes <FontAwesomeIcon icon={faSave} />
                          </Button>
                          <Button color="secondary" type="reset" onClick={() => handleReset}>
                            Reset <FontAwesomeIcon icon={faRedoAlt} />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              );
            }}
          />
          <ListManager
            initValues={initVals}
            selectedItems={selectedTypes}
            setSelectedItems={setSelectedTypes}
            deleteListItem={handleDelete}
            addListItem={handleAdd}
            headerText="Associated Company Types"
            fieldName="companyType"
            itemType="Company Type"
            match={match}
            addIcon={faStoreAlt}
            deleteIcon={faTrash}
            axnInstanceId={id}
          />
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: AppState) => ({
  companyInstance: state.companies.companyInstance,
  authenticatedUser: state.users.authenticatedUser,
  activeInstance: state.wizard.activeInstance
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setFormState: setWizardFormState(dispatch),
  getCompany: initGetCompany(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoStep);
