import axios from 'axios';

export const axnAPI = axios.create({
  baseURL: `/api/v1`,
  timeout: 20000,
  responseType: 'json',
  params: {}
});

export const axnUnauthAPI = axios.create({
  baseURL: `/api/v1`,
  timeout: 5000,
  responseType: 'json',
  params: {},
  auth: {
    username: process.env.REACT_APP_BASIC_AUTH_USER!,
    password: process.env.REACT_APP_BASIC_AUTH_PASS!
  }
});
