import moment from 'moment';
import { TerminalAirline } from '../../../../../types';
import { getAuthUserID } from '../../../../../core/authentication/authenticationHelper';

export type CommonRowFieldNames =
  | 'foodbeverage'
  | 'specialty'
  | 'newsStand'
  | 'dutyfree'
  | 'excludingDutyFree'
  | 'includingDutyFree';

const authUserId = getAuthUserID();

const initialTerminalData = {
  airportTerminalPK: {
    airportId: 0,
    terminalId: 0,
    axnInstanceId: +moment().format('YY')
  },
  annualPercentChangeAmount: 0,
  epDomesticTotal: 0,
  epIntlTotal: 0,
  enplaneTotal: 0,
  deplaneTotal: 0,
  passengerTotal: 0,
  modifiedUserId: authUserId,
  modifiedDate: moment().format('MM-DD-YYYY'),
  terminal: {
    terminalId: 0,
    terminalName: '',
    terminalShortName: ''
  },
  /* New List of the Terminals Dominant Airlines */
  airlineTerminals: new Array<TerminalAirline>()
};

const initialTerminalTotals = {
  airportTerminalTotals: [
    {
      airportTerminalTotalPK: {
        airportId: 0,
        axnInstanceId: +moment().format('YY'),
        tenantType: 'FOODBEVERAGE',
        terminalId: 0
      },
      annualPercentChangeAmount: 0,
      areaTotal: 0,
      grossSalesTotal: 0,
      modifiedDate: '',
      modifiedUserId: authUserId,
      rentAirportTotal: 0,
      rentEpRatio: 0,
      salesEpRatio: 0,
      tenantTypeDes: 'Food/Beverage',
      terminalName: '',
      terminalShortName: '',
      unitMeasureCode: 'SQFT'
    },
    {
      airportTerminalTotalPK: {
        airportId: 0,
        axnInstanceId: +moment().format('YY'),
        tenantType: 'SPECIALTY',
        terminalId: 0
      },
      annualPercentChangeAmount: 0,
      areaTotal: 0,
      grossSalesTotal: 0,
      modifiedDate: moment().format('MM-DD-YYYY'),
      modifiedUserId: authUserId,
      rentAirportTotal: 0,
      rentEpRatio: 0,
      salesEpRatio: 0,
      tenantTypeDes: 'Specialty Retail',
      terminalName: '',
      terminalShortName: '',
      unitMeasureCode: 'SQFT'
    },
    {
      airportTerminalTotalPK: {
        airportId: 0,
        axnInstanceId: +moment().format('YY'),
        tenantType: 'NEWSSTAND',
        terminalId: 0
      },
      annualPercentChangeAmount: 0,
      areaTotal: 0,
      grossSalesTotal: 0,
      modifiedDate: moment().format('MM-DD-YYYY'),
      modifiedUserId: authUserId,
      rentAirportTotal: 0,
      rentEpRatio: 0,
      salesEpRatio: 0,
      tenantTypeDes: 'Newsstand/Travel Essentials',
      terminalName: '',
      terminalShortName: '',
      unitMeasureCode: 'SQFT'
    },
    {
      airportTerminalTotalPK: {
        airportId: 0,
        axnInstanceId: +moment().format('YY'),
        tenantType: 'DUTYFREE',
        terminalId: 0
      },
      annualPercentChangeAmount: 0,
      areaTotal: 0,
      grossSalesTotal: 0,
      modifiedDate: moment().format('MM-DD-YYYY'),
      modifiedUserId: authUserId,
      rentAirportTotal: 0,
      rentEpRatio: 0,
      salesEpRatio: 0,
      tenantTypeDes: 'Duty Free',
      terminalName: '',
      terminalShortName: '',
      unitMeasureCode: 'SQFT'
    }
  ]
};

export const initialAirportTotals = {
  airportId: 0,
  axnInstanceId: +moment().format('YY'),
  airportName: '',
  iata: '',
  city: '',
  dutyfreeGrossSalesTotal: 0,
  dutyfreeAreaTotal: 0,
  dutyfreeSalesEp: 0,
  dutyfreeRentAirportTotal: 0,
  dutyfreeRentEp: 0,
  foodbeverageGrossSalesTotal: 0,
  foodbeverageAreaTotal: 0,
  foodbeverageSalesEp: 0,
  foodbeverageRentAirportTotal: 0,
  foodbeverageRentEp: 0,
  loungessuitesGrossSalesTotal: 0,
  loungessuitesAreaTotal: 0,
  loungessuitesSalesEp: 0,
  loungessuitesRentAirportTotal: 0,
  loungessuitesRentEp: 0,
  passengerservicesGrossSalesTotal: 0,
  passengerservicesAreaTotal: 0,
  passengerservicesSalesEp: 0,
  passengerservicesRentAirportTotal: 0,
  passengerservicesRentEp: 0,
  specialtyGrossSalesTotal: 0,
  specialtyAreaTotal: 0,
  specialtySalesEp: 0,
  specialtyRentAirportTotal: 0,
  specialtyRentEp: 0,
  newsStandGrossSalesTotal: 0,
  newsStandAreaTotal: 0,
  newsStandSalesEp: 0,
  newsStandRentAirportTotal: 0,
  newsStandRentEp: 0,
  airportSalesTotalExcludeDf: 0,
  airportSalesEpExcludeDf: 0,
  airportTotalAreaExcludeDf: 0,
  airportGrossRentalsTotalExcludeDf: 0,
  airportRentEpExcludeDf: 0,
  airportSalesTotal: 0,
  airportSalesEp: 0,
  airportTotalArea: 0,
  airportGrossRentalsTotal: 0,
  airportRentEp: 0
};

export type PartialAirportTotals = Partial<typeof initialAirportTotals>;

const passengerTrafficComment = {
  passengerTrafficComment: ''
};
const grossSalesComment = {
  grossSalesComment: ''
};

/* Airport Concession Disadvantaged Business Enterprise Participation Percentage */
const acdbe = {
  acdbePercentageAmount: 0
};

export const terminalsInitialValues = {
  ...initialTerminalData,
  ...initialTerminalTotals,
  ...initialAirportTotals,
  ...passengerTrafficComment,
  ...grossSalesComment,
  ...acdbe
};
