import * as React from 'react';
import { TableColumn } from './MasterList';

interface ListTableHeader {
  columns: TableColumn[];
  readonly?: boolean;
}

/* TODO: generate this based on tables columns */
export const ListTableHeader = (props: ListTableHeader) => {
  const { columns, readonly } = props;
  return (
    <thead className={'thead-light'}>
      <tr>
        {columns.map((val, key) => (
          <th key={key}>{val.title}</th>
        ))}
        {!readonly && <th>Actions</th>}
      </tr>
    </thead>
  );
};
