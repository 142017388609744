import * as React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MasterListActionButton } from './types';

export const ListActionButton = (props: MasterListActionButton) => {
  const { to, icon, text, actionCallback } = props;
  return (
    <Link to={to}>
      <Button
        className={'mx-1 text-nowrap'}
        color={'primary'}
        onClick={actionCallback}>
        <FontAwesomeIcon icon={icon} className={'mr-2'} />
        {text}
      </Button>
    </Link>
  );
};
