import React from 'react';
import { PageableData } from '../../../api';
import { TableColumn } from '../../masterlist/MasterList';
import { AffiliateObjectType } from './AffiliateToObjectForm';
import { FilteredMasterList } from '../../masterlist/filter/FilteredMasterList';
import { IndexableMasterListItem, MasterListActionButton } from '../../masterlist/types';
import { allCompaniesModel } from './AllCompanyAffiliations';
import { allAirportsModel } from './AllAirportAffiliations';

export type AffiliationAction = (
  refresh: () => void
) => (itemId: number, axnInstanceId: number, userId: number) => MasterListActionButton[];

export interface AllAffiliationData {
  actions: AffiliationAction;
  columns: TableColumn[];
  convert: (a: any) => any;
  fetchData: (args: string, keyword: string, instanceId: number) => PageableData<any>;
}

const modelFor = (objectType: AffiliateObjectType) => {
  switch (objectType) {
    case 'Airport':
      return allAirportsModel;
    default:
      return allCompaniesModel;
  }
};

interface Props {
  args: string;
  objectType: AffiliateObjectType;
  axnInstanceId: number;
  refresh: () => void;
}

const AllAffiliations = ({ args, axnInstanceId, objectType, refresh }: Props) => {
  const { fetchData, actions, columns, convert } = modelFor(objectType);
  return (
    <FilteredMasterList
      listType="Affiliations"
      actions={actions(refresh)}
      columns={columns}
      convert={d => convert(d) as IndexableMasterListItem}
      useData={fetchData}
      args={args}
      axnInstanceId={axnInstanceId}
      additionalValues={['axnInstanceId', 'userId']}
    />
  );
};

export default AllAffiliations;
