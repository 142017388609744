import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import StepHeader from '../../../StepHeader';
import FormField from '../../../../forms/FormField';
import { AirportInfoChildForm } from './types';
import AirportConfigurationSelectField from '../../../../forms/AirportConfigurationSelectField';
import ConcessionManagementSelectField from '../../../../forms/ConcessionManagementSelectField';
import { YOrN } from '../../../../../types';

interface AirportInfoForm extends AirportInfoChildForm {
  values: any;
  axnInstanceId: number;
  setFieldValue: (name: string, value: any, validate: boolean) => void;
}

const AirportInfoForm = (props: AirportInfoForm & RouteComponentProps) => {
  const { match, values, handleBlur, handleChange, setFieldValue } = props;
  return (
    <Col xl={4} lg={4} md={12} className="mb-3">
      <StepHeader stepTitle="Airport Information" />
      <Container>
        <Row>
          <AirportConfigurationSelectField
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            match={match}
            setFieldValue={setFieldValue}
          />
          <ConcessionManagementSelectField
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            match={match}
            setFieldValue={setFieldValue}
          />
          <FormField
            name="dwellTimeAmount"
            label="Avg. Dwell"
            type="number"
            match={match}
            value={values.dwellTimeAmount}
            handleChange={handleChange}
            handleBlur={handleBlur}
            col={{ lg: 12 }}
            tooltipText={"The average amount of time a departing passenger spends within the airport."}
          />
          {values.additionalInfoFlag === ('Y' as YOrN) && (
            <FormField
              name="configurationAdditionalInfoText"
              label="Additional Info"
              type="textarea"
              match={match}
              value={values.configurationAdditionalInfoText}
              handleChange={handleChange}
              handleBlur={handleBlur}
              col={{ lg: 12 }}
            />
          )}
        </Row>
      </Container>
    </Col>
  );
};

export default withRouter(AirportInfoForm);
