import { ContactData, ContactsActions, initialContactFormValues } from '../actions/contactsActions';

const initialContactState = {
  contacts: new Array<ContactData>(),
  contact: { ...initialContactFormValues },
  loading: false,
  error: ''
};

export type ContactsState = typeof initialContactState;

export const contactsReducer = (
  state: ContactsState = initialContactState,
  action: ContactsActions
) => {
  switch (action.type) {
    /* Handle Initial Action before HTTP Request */
    case 'GET_CONTACT':
    case 'UPDATE_CONTACT':
      return { ...state, loading: true };
    case 'GET_CONTACTS':
      return { ...state, loading: true };
    case 'DELETE_CONTACT':
    case 'ADD_CONTACT':
      return { ...state, loading: true };
    /* Handle Initial Action on Success of HTTP Request */
    case 'GET_CONTACT_SUCCESS':
      return { ...state, loading: false, contact: action.contact };
    case 'GET_CONTACTS_SUCCESS':
      return { ...state, loading: false, contacts: action.contacts };
    case 'UPDATE_CONTACT_SUCCESS':
      return {
        ...state,
        contacts: [
          ...state.contacts.filter(contact => contact.contactId !== action.contact.contactId)
        ],
        loading: false
      };
    case 'DELETE_CONTACT_SUCCESS':
      return {
        ...state,
        contacts: state.contacts.filter(contact => contact.contactId !== action.contactID),
        loading: false
      };
    case 'ADD_CONTACT_SUCCESS':
      return {
        ...state,
        contacts: [...state.contacts, action.contactsContact]
      };
    /* Handle Initial Action on Failure of HTTP Request */
    case 'GET_CONTACT_FAILURE':
    case 'GET_CONTACTS_FAILURE':
    case 'UPDATE_CONTACT_FAILURE':
    case 'DELETE_CONTACT_FAILURE':
    case 'ADD_CONTACT_FAILURE':
      return { ...state, loading: false, error: action.error };
    /* Handle Misc Actions */
    case 'RESET_CONTACT':
      return {
        ...state,
        contact: {
          ...initialContactFormValues,
          managementResponsibilityType: '',
          stateProvince: '',
          companyName: '',
          countryName: ''
        },
        contacts: [],
        loading: false
      };
    /* Handle Default Actions */
    default:
      return state;
  }
};
