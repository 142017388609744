import React from 'react';
import { AutoCompleteOption } from './AutoComplete';
import ListItemButton from './ListItemButton';

interface OptionsListProps {
  activeOption: number;
  filteredOptions: AutoCompleteOption[];
  showOptions: boolean;
  setFilteredOptions: (obj: any) => void;
  getFilteredOpts: (value: string) => void;
  setShowOptions: (obj: any) => void;
  setInput: (obj: any) => void;
  userInput: string;
  onClick: (e: React.ChangeEvent<any>, label: string, value: string) => void;
}

export default (props: OptionsListProps) => {
  const {
    activeOption,
    filteredOptions,
    setInput,
    setFilteredOptions,
    getFilteredOpts,
    setShowOptions,
    onClick
  } = props;
  const handleClick = (e: React.MouseEvent) => {
    const { currentTarget } = e;
    const { textContent } = currentTarget;
    const label = `${textContent}`;
    const value = e.currentTarget.getAttribute('value') || '';
    setShowOptions(false);
    setInput(textContent);
    setFilteredOptions(getFilteredOpts(label));
    onClick(e, label, value);
  };

  return (
    <ul
      className="list-group options rounded-bottom border"
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        zIndex: 1000,
        background: '#ffffff',
        maxHeight: '50vh',
        overflowX: 'hidden',
        overflowY: 'scroll'
      }}>
      {filteredOptions.map((option, index) => {
        const isActive = index === activeOption;
        const className = `${isActive ? 'active-autocomplete-option ' : ''}`;
        const bgColorAlpha = isActive ? 0.15 : 0.0;
        const { label, value } = option;
        return (
          <li
            className="list-group-item p-1 m-0 rounded-0"
            style={{ backgroundColor: `rgba(0,0,0,${bgColorAlpha})` }}
            key={value}>
              <ListItemButton
                className={`${className} btn btn-link`}
                handleClick={handleClick}
                label={label}
                value={value}
                isActive={isActive}
              />
          </li>
        );
      })}
    </ul>
  );
};
