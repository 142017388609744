import { Dispatch } from 'redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import fetchToken, { LoginResponse } from '../api/authentication';
import { appHistory } from '../reducers';
import { decodeJwt } from '../core/authentication/authenticationHelper';
import { PhoneNumberFromAPI } from '../types';

export type AuthorityRoles =
  | 'ROLE_USER'
  | 'ROLE_SUBSCRIBER'
  | 'ROLE_AIRPORT'
  | 'ROLE_AIRPORT_SUB'
  | 'ROLE_COMPANY'
  | 'ROLE_COMPANY_SUB'
  | 'ROLE_ADMIN';

export type AuthorityRolesObject = {
  authority: AuthorityRoles;
};

export type AuthoritiesList = AuthorityRolesObject[];

export interface AuthenticatedUser {
  firstName: string;
  lastName: string;
  sub: string;
  userName: string;
  exp: number; // JWT default expiration
  userId: number;
  iat: number; // JWT Issued At
  email: string;
  axnInstanceId: number;
  axnPendingInstanceId: number;
  axnInstanceName: string;
  axnPendingInstanceName: string;
  authorities: AuthoritiesList;
  expirationDate: number; // AXN custom expiration date, this should probably just modify JWT exp...
}

/*
import { getAirportAffiliationWithAirportAndInstance } from '../../api/affiliations';
if ( content === airportInstance )
{
    // Need to lookup permissions for instance
    const affiliation = getAirportAffiliationWithAirportAndInstance( authenticatedUser.userId, axnInstanceId, airportInstance.airport.airportId);
    const pendingAffiliation = getAirportAffiliationWithAirportAndInstance( authenticatedUser.userId, axnPendingInstanceId, airportInstance.airport.airportId);
    console.log("Affiliation");
    console.log(affiliation);
    console.log("Pending Affiliation");
    console.log(pendingAffiliation.);
  // /user/{userId}/instance/{axnInstanceId}/airport/{airportId}
}
*/


export interface UserIDData {
  userId: number;
}

/* figure out which of these should never be empty. */
export interface UserData extends UserIDData {
  displayName?: string;
  email?: string;
  userPassword?: string;
  firstName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  stateProvinceCode?: string;
  isoCountryCode?: string;
  postalCode?: string;
  jobCategory?: string;
  userStatus?: string;
  modifiedUserId?: number;
  modifiedDate?: string;
  securityRoleId?: number;
  companyName?: string;
  createdDate?: string;
  publishedEmail?: string;
  [key: number]: string;
}

export interface UserDataWithPhones extends UserData {
  userPhoneList: PhoneNumberFromAPI[];
  securityRoleId: number;
  title: string;
}

export type UsersList = UserData[];

export type UsersAction =
  | { type: 'GET_USERS' }
  | { type: 'GET_USERS_SUCCESS'; users: UsersList }
  | { type: 'GET_USERS_FAILURE'; error: string }
  | { type: 'GET_USER'; userID: UserIDData }
  | { type: 'GET_USER_SUCCESS'; user: UserData }
  | { type: 'GET_USER_FAILURE'; error: string }
  | { type: 'DELETE_USER'; userID: UserIDData }
  | { type: 'DELETE_USER_SUCCESS'; userID: UserIDData }
  | { type: 'DELETE_USER_FAILURE'; error: string }
  | { type: 'UPDATE_ADD_USER'; user: UserData }
  | { type: 'UPDATE_ADD_USER_SUCCESS'; user: UserData }
  | { type: 'UPDATE_ADD_USER_FAILURE'; error: string }
  | { type: 'USER_LOGIN' }
  | { type: 'USER_LOGOUT' }
  | { type: 'USER_LOGIN_SUCCESS'; authenticatedUser: AuthenticatedUser }
  | { type: 'DECODE_USER_TOKEN'; authenticatedUser: AuthenticatedUser }
  | { type: 'USER_LOGIN_FAILED'; error: string };

export type UsersActionsCreator = (...props: any) => UsersAction;

/* USER ACTION CREATORS */
/* Get All Users */
export const getUsers: UsersActionsCreator = () => ({
  type: 'GET_USERS'
});
export const getUsersSuccess: UsersActionsCreator = (users: UsersList) => ({
  type: 'GET_USERS_SUCCESS',
  users
});

export const getUsersFailure: UsersActionsCreator = (error: string) => ({
  type: 'GET_USERS_FAILURE',
  error
});

/* Get Singular User */
export const getUser: UsersActionsCreator = (userID: UserIDData) => ({
  type: 'GET_USER',
  userID
});
export const getUserSuccess: UsersActionsCreator = (user: UserData) => ({
  type: 'GET_USER_SUCCESS',
  user
});
export const getUserFailure: UsersActionsCreator = (error: string) => ({
  type: 'GET_USER_FAILURE',
  error
});

/* Delete User */
export const deleteUser: UsersActionsCreator = (userID: UserIDData) => ({
  type: 'DELETE_USER',
  userID
});
export const deleteUserSuccess: UsersActionsCreator = (userID: UserIDData) => ({
  type: 'DELETE_USER_SUCCESS',
  userID
});
export const deleteUserFailure: UsersActionsCreator = (error: string) => ({
  type: 'DELETE_USER_FAILURE',
  error
});

/* Update User */
export const updateAddUser: UsersActionsCreator = (user: UserData) => ({
  type: 'UPDATE_ADD_USER',
  user
});
export const updateUserSuccess: UsersActionsCreator = (user: UserData) => ({
  type: 'UPDATE_ADD_USER_SUCCESS',
  user
});
export const updateUserFailure: UsersActionsCreator = (error: string) => ({
  type: 'UPDATE_ADD_USER_FAILURE',
  error
});

/* User Authentication Actions */
export const userLogin: UsersActionsCreator = () => ({
  type: 'USER_LOGIN'
});
export const userLogout: UsersActionsCreator = () => ({
  type: 'USER_LOGOUT'
});
export const userLoginSuccess: UsersActionsCreator = (authenticatedUser: AuthenticatedUser) => ({
  type: 'USER_LOGIN_SUCCESS',
  authenticatedUser
});
export const userLoginFailure: UsersActionsCreator = (error: string) => ({
  type: 'USER_LOGIN_FAILED',
  error
});
export const authenticateUserToken: UsersActionsCreator = (
  authenticatedUser: AuthenticatedUser
) => ({
  type: 'DECODE_USER_TOKEN',
  authenticatedUser
});

export const submitLogin = (dispatch: Dispatch) => (values: {
  username: string;
  password: string;
}) => {
  const { username, password } = values;
  dispatch(userLogin());
  fetchToken(username, password).then((r: LoginResponse) => {
    if (r.kind === 'Success') {
      const decoded = decodeJwt(r.token);
      Cookies.set('userAuthToken', r.token, {
        expires: moment(decoded.expirationDate).toDate()
      });
      dispatch(userLoginSuccess(decoded));
      appHistory.push('/');
    } else if (r.kind === 'Error') {
      dispatch(userLoginFailure(r.error));
      toast.error("Invalid Credentials");
    }
  });
};

export const submitLogout = (dispatch: Dispatch) => () => {
  Cookies.remove('userAuthToken');
  dispatch(userLogout()); // submit action to redux.
  window.scroll(0,0);
  appHistory.push('/login');
  /* window reload ain't the best but it'll do for now. */
  window.location.reload();
};

export const decodeUserToken = (dispatch: Dispatch) => (token: string) => {
  const authUser = decodeJwt(token) as AuthenticatedUser;
  dispatch(authenticateUserToken(authUser));
};
