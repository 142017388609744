import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Badge, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import AirportsSearchForm from '../components/search/airports/AirportsSearchForm';
import { getAllAirportsForLookup, useAirportTenantTypes } from '../api/Airports';
import Spinner from '../components/shared/spinner/Spinner';
import { cssClass } from '../components/wizard/cssSharedClasses';
import { AirportInstance, AxnInstanceStatus, SpecialQuestion } from '../types';
import { ContactsList, InitGetContacts, initGetContacts } from '../actions/contactsActions';
import { AppState } from '../reducers';
import { GetAirport, getAirport } from '../actions/airportActions';
import { GetTerminals, getTerminals, TerminalsList } from '../actions/terminalsActions';
import { AllowedWizTypes } from '../components/wizard/types';
import StepHeader from '../components/wizard/StepHeader';
import { ReviewAirportwideInfo } from '../components/wizard/airport/steps/review/ReviewAirportwideInfo';
import ExistingTerminals from '../components/wizard/airport/steps/info/ExistingTerminals';
import TerminalList from '../components/wizard/airport/steps/terminals/TerminalList';
import { ReviewSpecifics } from '../components/wizard/airport/steps/review/ReviewSpecifics';
import { ReviewGrossSales } from '../components/wizard/airport/steps/review/ReviewGrossSales';
import ReviewRevenue from '../components/wizard/airport/steps/review/ReviewRevenue';
import ExistingTenants from '../components/wizard/airport/steps/tenants/ExistingTenants';
import ReviewContacts from '../components/wizard/airport/steps/review/ReviewContacts';
import AccordionItem from '../components/accordion/AccordionItem';
import { pinStyle } from '../components/wizard/airport/steps/location/LocationStep';
import ReviewExpansions from '../components/wizard/airport/steps/review/ReviewExpansions';
import CovidResponseView from '../components/wizard/airport/steps/covid/CovidResponseView';
import { getSpecialQuestionFromAPI } from '../api/SpecialQuestions';
import { get } from 'lodash';
import { SpecialQuestionTypes } from '../constants';
import { useAllInstances } from '../api/instances';

export interface AllAirportsGeography {
  city: string;
  iataCode: string;
  airportId: string; // can convert to int
  airportName: string;
  airportStateProvince: string;
  airportState: string;
}

export interface AirportViewProps {
  setAirport: GetAirport;
  setTerminals: GetTerminals;
  setContacts: InitGetContacts;
  airportInstance: AirportInstance;
  terminals: TerminalsList;
  contacts: ContactsList;
  airportLoading: boolean;
  contactsLoading: boolean;
  terminalsLoading: boolean;
  axnInstanceId: number;
  axnPendingInstanceId: number;
  axnInstanceName: string;
  axnPendingInstanceName: string;
  activeInstanceStatus: string;
}

interface AirportsViewUrlParams {
  contentID: string;
}

const AirportsView = (props: AirportViewProps & RouteComponentProps<AirportsViewUrlParams>) => {
  const {
    airportInstance,
    contacts,
    terminals,
    setAirport,
    setContacts,
    setTerminals,
    match,
    airportLoading,
    contactsLoading,
    terminalsLoading,
    axnInstanceId,
    activeInstanceStatus,
  } = props;
  const { contentID } = match.params;

  const { axnInstanceStatus } = airportInstance;

  const { instances } = useAllInstances();

  //const instanceIdToUse = activeInstanceStatus === 'PENDING' && !Number.isNaN(axnPendingInstance) ? axnPendingInstance : axnActiveInstance;
  //const instanceYearToUse = activeInstanceStatus === 'PENDING' ? axnPendingInstanceName : axnInstanceName;

  const getAlertColor = (instanceStatus: AxnInstanceStatus) => {
    switch (instanceStatus) {
      case 'APPROVED':
      case 'PUBLISHED':
        return 'success';
      case 'INPROGRESS':
      case 'PENDING':
        return 'warning';
      default:
        return 'error'; // this will handle rejected as well
    }
  };

  const {
    airportName,
    city,
    county,
    isoCountryCode,
    stateProvinceCode,
    longitudeText,
    latitudeText
  } = airportInstance.airport;
  const contentType = 'airports';

  /* This could all be moved to a costume hook or redux? */
  const [airports, setAirports] = useState<AllAirportsGeography[]>([]);
  const [loading, setLoading] = useState(false);
  const { tenantTypes, tenantTypesLoading } = useAirportTenantTypes();
  const [lastUpdated, setLastUpdated] = useState(moment().unix());
  const [covidQuestion, setCovidQuestion] = useState<SpecialQuestion>();
  const [covidQuestionLoading, setCovidQuestionLoading] = useState(false);
  const [instanceIdToUse, setInstanceIdToUse] = useState(0);

  useEffect(() => {
    const axnActiveInstance = instances.find( i => i.axnInstanceMasterStatus === 'ACTIVE');
    const axnPendingInstance = instances.find( i => i.axnInstanceMasterStatus === 'PENDING');
  
    setInstanceIdToUse(activeInstanceStatus === 'PENDING' && axnPendingInstance ? axnPendingInstance?.axnInstanceId : axnActiveInstance?.axnInstanceId ?? 0);
  }, [instances]);

  useEffect(() => {
    setCovidQuestionLoading(true);
    if( instanceIdToUse && +contentID > 0 && +instanceIdToUse > 0 ) {
      getSpecialQuestionFromAPI(1, +contentID, +instanceIdToUse ).then(
        response => {
          setCovidQuestion(response.data)
          setCovidQuestionLoading(false);
        },
        error => {
          if (error.message.indexOf("404") < 0) {
            const errorMsg = get(
              error.responsone,
              'data.message',
              'Failed to Retrieve Covid Question'
            );
            toast.error(errorMsg);
          }
          setCovidQuestion(undefined)
          setCovidQuestionLoading(false);
        }
      ).catch( _ => {
        setCovidQuestion(undefined)
      })
    }
  }, [contentID, instanceIdToUse]);

  /* Get List of all airports to select/search through */
  useEffect(() => {
    /* TODO: change instance id to reflect a variable set according to user or set by admin. */
    setLoading(true);
    getAllAirportsForLookup()
      .then(response => {
        setLoading(false);
        return setAirports(response.data);
      })
      .catch(error => {
        setLoading(true);
        toast.error(`Error fetching data: ${error}`);
      });
  }, [airports.length]);

  /* is an airport ID exist use it to get the contacts, terminals and other necessary data. */
  useEffect(() => {
    if (contentID) {
      setAirport(+contentID, axnInstanceId);
      setTerminals(+contentID, axnInstanceId);
      setContacts(contentID, 'airports' as AllowedWizTypes, axnInstanceId);
    }
  }, [contentID, terminals.length, contacts.length, setAirport, axnInstanceId, setTerminals, setContacts]);

  const statusColor = getAlertColor(axnInstanceStatus as AxnInstanceStatus);

  return (
    <Container>
      <AccordionItem name="Airports List" expandedDefault className={cssClass}>
        {loading ? (
          <Spinner />
        ) : (
          <AirportsSearchForm airports={airports} setAirportInstance={setAirport} />
        )}
      </AccordionItem>
      {contentID && (
        <Container>
          <Row>
            <Col className={cssClass}>
              <div className="text-center">
                <Badge color={`${statusColor} py-2 px-4`} className="mb-3">
                  {axnInstanceStatus}
                </Badge>
                <h2>{airportName} </h2>
                <h4 className="my-3">
                  {city} ({county}), {stateProvinceCode}, {isoCountryCode}
                </h4>
              </div>
              <div style={{ height: '75vh', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY_PRIMARY!
                  }} // get api from env variables
                  center={{ lat: +latitudeText, lng: +longitudeText }}
                  defaultZoom={13}
                />
                <FontAwesomeIcon
                  className="text-primary"
                  icon={faMapMarkerAlt}
                  size="4x"
                  style={pinStyle}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <ReviewContacts
              contactsLoading={contactsLoading}
              contacts={contacts}
              contentID={contentID}
              contentType={contentType}
              selectedContact={() => false}
              axnInstanceId={axnInstanceId}
            />
          </Row>
          <Row>
            <Col className={cssClass}>
              <Row>
                <Col xl={5}>
                  <StepHeader Tag="h3" stepTitle="Airport Wide Information" />
                  <ReviewAirportwideInfo airport={airportInstance} />
                  <ReviewExpansions
                    expansions={airportInstance.airportExpansionList || []}
                  />
                </Col>
                <Col xl={7}>
                  <ExistingTerminals
                    airportName={airportName}
                    terminalsLoading={terminalsLoading}
                    terminals={terminals}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className={cssClass}>
              <StepHeader Tag="h3" stepTitle="Passenger Traffic" />
              <TerminalList
                terminals={terminals}
                loading={terminalsLoading}
                url={match.url}
                readonly
              />
            </Col>
          </Row>
          <Row>
            <ReviewSpecifics airportLoading={airportLoading} airport={airportInstance} />
          </Row>
          <Row>
            <ReviewGrossSales
              contentID={+contentID}
              terminals={terminals}
              axnInstanceId={axnInstanceId}
            />
          </Row>
          <Row>
            <ReviewRevenue
              airport={airportInstance}
              airportLoading={airportLoading}
              contentID={contentID}
              axnInstanceId={instanceIdToUse}
            />
          </Row>
          <Row>
            <Col className={cssClass}>
              <ExistingTenants
                tenantTypes={tenantTypes}
                contentType={contentType}
                contentID={contentID}
                lastUpdated={lastUpdated}
                setLastUpdated={setLastUpdated}
                title="Concession Tenant Details"
                loading={tenantTypesLoading}
                axnInstanceId={axnInstanceId}
                readonly
              />
            </Col>
          </Row>
          <Row>
            <Col className={cssClass}>
              <CovidResponseView 
                loading={covidQuestionLoading}
                questionText={'How was your business impacted by the pandemic (eg: location closings, indoor dining limited, alcohol sales restricted or TO GO added, etc.)?'}
                questionId={SpecialQuestionTypes.COVID}
                answer={covidQuestion?.answer ?? ""}
              />
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  airportInstance: state.airports.airportInstance,
  terminals: state.terminals.terminals,
  contacts: state.contacts.contacts,
  airportLoading: state.airports.loading,
  contactsLoading: state.contacts.loading,
  terminalsLoading: state.terminals.loading,
  axnInstanceId: state.users.authenticatedUser.axnInstanceId,
  axnPendingInstanceId: state.users.authenticatedUser.axnPendingInstanceId,
  axnInstanceName: state.users.authenticatedUser.axnInstanceName,
  axnPendingInstanceName: state.users.authenticatedUser.axnPendingInstanceName
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setAirport: getAirport(dispatch),
  setTerminals: getTerminals(dispatch),
  setContacts: initGetContacts(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AirportsView));
