/* Company Airport Instance */
import { axnAPI } from './config';

export const companiesAirportInstanceAPI = `/companyairportinstance`;

export const addCompanyAirportLocationFromAPI = (
  companyID: number,
  instanceID: number,
  companyType: string,
  airportId: number
) =>
  axnAPI.post(
    `${companiesAirportInstanceAPI}/${companyID}/instance/${instanceID}/type/${companyType}/airport/${airportId}`
  );

/* DELETE /{cid}/instance/{iid}/type/{tid}/airport/{aid} */
export const deleteCompanyAirportLocationFromAPI = (
  companyID: number,
  instanceID: number,
  companyType: string,
  airportId: number
) =>
  axnAPI.delete(
    `${companiesAirportInstanceAPI}/${companyID}/instance/${instanceID}/type/${companyType}/airport/${airportId}`
  );

/* Implement later */
/*
  export const getCompanyAirportLocationFromAPI = (
    companyID: number,
    instanceID: number,
    page = 1,
    size = 10
  ) =>
    axnAPI.get(`${companiesAirportInstanceAPI}/${companyID}/instance/${instanceID}`, {
      params: { page, size }
    });
*/

export const getCompanyAirportLocationByTypeFromAPI = (
  companyID: number,
  instanceID: number,
  companyType: string,
  page = 1,
  size = 10
) =>
  axnAPI.get(
    `${companiesAirportInstanceAPI}/${companyID}/instance/${instanceID}/type/${companyType}`,
    {
      params: { page, size }
    }
  );


export const getCompanyAirportLocationsFromAPI = (
  companyID: number,
  instanceID: number,
  page = 1,
  size = 10
) =>
  axnAPI.get(
    `${companiesAirportInstanceAPI}/${companyID}/instance/${instanceID}`,
    {
      params: { page, size }
    }
  );

