import React from 'react';
import { TableColumn } from '../masterlist/MasterList';
import { FilteredMasterList } from '../masterlist/filter/FilteredMasterList';
import { CompanyContact, useCompanyContactSearch } from '../../api/contacts';
import { IndexableMasterListItem } from '../masterlist/types';
import { workPhone, faxPhone, Address, Email, Url } from '../../pages/ContactsView';

const columns: TableColumn[] = [
  { id: 'fullName', title: 'Contact' },
  { id: 'title', title: 'Title' },
  { id: 'companyName', title: 'Company Name' },
  { id: 'address', title: 'Address', width: '200px' },
  { id: 'phone', title: 'Phone', width: '130px' },
  { id: 'fax', title: 'Fax', width: '130px' },
  { id: 'email', title: 'Email' },
  { id: 'url', title: 'Web site' }
];

export interface CompanyContactsSearchResult {
  fullName: string;
  title: string;
  companyName: string;
  phone: string;
  fax: string;
  address: JSX.Element;
  email: JSX.Element;
  url: JSX.Element;
}

const convert = ({contact, company}: CompanyContact): CompanyContactsSearchResult => ({
  fullName: `${contact.firstName} ${contact.lastName}`,
  title: contact.title,
  companyName: company.companyName,
  phone: workPhone(contact),
  fax: faxPhone(contact),
  address: <Address contact={contact} />,
  email: <Email contact={contact} />,
  url: <Url contact={contact} />
})

const convertAsIndexable = (data: CompanyContact): IndexableMasterListItem =>
  convert(data) as IndexableMasterListItem

interface Props {
  axnInstanceId: number;
}

const CompanyContacts = ({ axnInstanceId }: Props) =>
  <FilteredMasterList
    args={""}
    axnInstanceId={axnInstanceId}
    columns={columns}
    convert={convertAsIndexable}
    listType="Airport Contacts"
    useData={useCompanyContactSearch}
  />

export default CompanyContacts
