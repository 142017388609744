import { object, number } from 'yup';
import msgs from '../../../../../core/yupSchemas/msgs';

export const terminalsTotalSchema = object().shape({
    annualPercentageChangeAmount: 
        number()
            .nullable(true)
            .max(999.99)
            .min(-999.99),
});
