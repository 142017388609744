import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FrontendFooter } from '../frontend/Footer';
import { AdminFooter } from '../admin/Footer';
import { AuthoritiesList } from '../../actions/usersActions';
import './footer.scss';

interface FooterProps {
  isLoggedIn: boolean;
  authorities: AuthoritiesList;
}

const Footer = (props: FooterProps & RouteComponentProps) => {
  const { location, isLoggedIn, authorities } = props;
  const isAdmin = location.pathname.indexOf('/admin') === 0;
  return isAdmin ? (
    <AdminFooter isLoggedIn={isLoggedIn} authorities={authorities} />
  ) : (
    <FrontendFooter isLoggedIn={isLoggedIn} authorities={authorities} />
  );
};

export default withRouter(Footer);
