import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField from './FormField';
import { useManagementResponsibilities } from '../../api/ManagementResponsibilites';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

/* TODO: Fix the any */
const MgmtResponsibilitySelectField = (props: {
  values: any;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: any;
  handleBlur: any;
}) => {
  const { values, match, handleChange, handleBlur, setFieldValue } = props;
  const {
    managementResponsibilities,
    managementResponsibilitiesLoading
  } = useManagementResponsibilities();
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();

  useEffect(() => {
    const aclist = managementResponsibilities.map(mgmtResponsibility => ({
      ...mgmtResponsibility,
      label: mgmtResponsibility.managementResponsibilityType,
      key: `${mgmtResponsibility.managementResponsibilityTypeId}`,
      value: `${mgmtResponsibility.managementResponsibilityTypeId}`
    }));
    setAutoCompleteList(aclist);
  }, [managementResponsibilities]);

  useEffect(() => {
    const managementResponsibilityType = managementResponsibilities.find(
      managementResponsibility =>
        managementResponsibility.managementResponsibilityTypeId ===
        values.managementResponsibilityTypeId
    );
    if (managementResponsibilityType) {
      setFieldValue(
        'managementResponsibilityType',
        managementResponsibilityType.managementResponsibilityType,
        false
      );
    }
  }, [
    setFieldValue,
    managementResponsibilities,
    values.managementResponsibilityType,
    values.managementResponsibilityTypeId
  ]);

  return (
    <>
      {managementResponsibilitiesLoading ? (
        <SelectFieldSpinner />
      ) : (
        <FormField
          name="managementResponsibilityTypeId"
          labelName="managementResponsibilityType"
          label="Select Management Responsibility"
          type="autocomplete"
          match={match}
          value={values.managementResponsibilityTypeId || +''}
          labelValue={values.managementResponsibilityType || 'Please Select'}
          handleChange={handleChange}
          handleBlur={handleBlur}
          autoCompleteList={autoCompleteList}
          col={{ lg: 12 }}
          setFieldValue={setFieldValue}
        />
      )}
    </>
  );
};

export default MgmtResponsibilitySelectField;
