import { Col, Row } from 'reactstrap';
import React from 'react';

const StepHeader = (props: {
  stepTitle: string;
  Tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  className?: string;
}) => {
  const { Tag, className, stepTitle } = props;
  return (
    <Row>
      <Col>
        <Tag className={`step-header ${className}`}>{stepTitle}</Tag>
      </Col>
    </Row>
  );
};

StepHeader.defaultProps = {
  Tag: 'h1'
};

export default StepHeader;
