import React from 'react';
import { Col, Row } from 'reactstrap';
import { FaqsTypes } from './types';
import Faq from './Faq';
import AccordionItem from '../accordion/AccordionItem';

interface FaqsProps {
  faqs: FaqsTypes;
}

const Faqs = (props: FaqsProps) => {
  const { faqs } = props;
  return (
    <div>
      {faqs.map(faqType => (
        <Row>
          <Col>
            <AccordionItem name={faqType.type} expandedDefault>
              {faqType.qaList.map(faq => {
                const { question, answer } = faq;
                return <Faq question={question} answer={answer} />;
              })}
            </AccordionItem>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default Faqs;
