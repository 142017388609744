import React from 'react';
import { connect } from 'react-redux';
import MasterList, { TableColumn } from '../masterlist/MasterList';
import { MasterListPagesize, MasterListPagination } from '../masterlist';
import { MasterListActionButton, MasterListType } from '../masterlist/types';
import AccordionItem from '../accordion/AccordionItem';
import { TenantProductType } from '../../types';
import { useExpiringTenants } from '../../api/reports';
import { AppState } from '../../reducers';

const columns: TableColumn[] = [
  { id: 'brandName', title: 'Tenant Name', width: '30%' },
  { id: 'companyName', title: 'Company Name', width: '30%'  },
  { id: 'city', title: 'Airport Location' },
  { id: 'locationNumber', title: '# Locations' },
  { id: 'areaTotal', title: 'Square Footage' },
  { id: 'leaseExpireDate', title: 'Lease Expires' }
];

const actions: MasterListActionButton[] = [];

interface LeaseExpirationProps {
  tenantProductType: TenantProductType;
  axnInstanceId: number;
}

const LeaseExpiration = (props: LeaseExpirationProps) => {
  const { tenantProductType, axnInstanceId } = props;
  const { tenantType, tenantTypeDes } = tenantProductType;
  const model = useExpiringTenants(axnInstanceId, tenantType);
  const { data, fetchData, loading, setCurrentPage, setPageSize, busy } = model;
  const { content, pageSize, totalRecords, currentPage } = data;
  return (
    <AccordionItem name={tenantTypeDes} callbackFn={fetchData}>
      <div className="masterlist-controls">
        <MasterListPagesize size={pageSize} setPagesize={setPageSize} />
        <MasterListPagination
          totalItems={totalRecords}
          currentPage={currentPage}
          handlePaginationSelection={setCurrentPage}
          pageSize={pageSize}
        />
      </div>
      <MasterList
        columns={columns}
        list={(content as MasterListType) || []}
        actions={() => actions}
        currentPage={currentPage}
        itemCount={totalRecords}
        loading={busy}
        listType={tenantTypeDes}
        readonly
      />
      <div className="masterlist-controls my-3">
        <MasterListPagesize size={pageSize} setPagesize={setPageSize} />
        <MasterListPagination
          totalItems={totalRecords}
          currentPage={currentPage}
          handlePaginationSelection={setCurrentPage}
          pageSize={pageSize}
        />
      </div>
    </AccordionItem>    
  );
};

const mapStateToProps = (state: AppState) => ({
  axnInstanceId: state.users.authenticatedUser.axnInstanceId
});

export default connect(mapStateToProps, null)(LeaseExpiration);
