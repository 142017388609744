import React from 'react';
import { Container } from 'reactstrap';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import { useCompanyTypes, UseLocations } from '../../../../../api/Companies';
import Spinner from '../../../../shared/spinner/Spinner';
import CompanyLocationsList from './CompanyLocationsList';

interface CompanyAirportLocationListProps {
  companyId: number;
  axnInstanceId: number;
  useLocations: UseLocations;
}

const CompanyAirportLocationList = (props: CompanyAirportLocationListProps) => {
  const { companyId, axnInstanceId, useLocations } = props;
  const { companyTypes, companyTypesLoading } = useCompanyTypes(axnInstanceId, companyId, false);
  return (
    <Container className={cssClass}>
      <StepHeader stepTitle="Company Location List" />
      {companyTypesLoading ? (
        <Spinner />
      ) : (
        <div>
          {companyTypes.map(companyTypeObj => {
            const { companyType, companyTypeDes } = companyTypeObj;
            return (
              <div key={`${companyType}-${companyId}-list`}>
                <StepHeader stepTitle={companyTypeDes} Tag={'h3'} />
                <CompanyLocationsList
                  contentID={companyId}
                  axnInstanceId={axnInstanceId}
                  companyType={companyType}
                  useLocations={useLocations}
                />
              </div>
            );
          })}
        </div>
      )}
    </Container>
  );
};

export default CompanyAirportLocationList;
