import { number, object, string } from 'yup';
import msgs from '../../../core/yupSchemas/msgs';
import { phoneRegExpOrWhiteSpace } from "../../../core/yupSchemas/validators";

const AddEditUserSchema = object().shape({
  firstName: string().required(msgs.firstName),
  lastName: string().required(msgs.lastName),
  displayName: string().required(msgs.displayName),
  email: string().required(msgs.email),
  mobileNumber: object().shape({
    phone: string().matches(phoneRegExpOrWhiteSpace, msgs.mobilePhone)
  }),
  homeNumber: object().shape({
    phone: string().matches(phoneRegExpOrWhiteSpace, msgs.homePhone)
  }),
  workNumber: object().shape({
    phone: string().matches(phoneRegExpOrWhiteSpace, msgs.workPhone)
  }),
  faxNumber: object().shape({
    phone: string().matches(phoneRegExpOrWhiteSpace, msgs.fax)
  }),
  companyName: string(),
  title: string(),
  category: string(),
  address1: string(),
  address2: string(),
  city: string() /* .required(msgs.city) */,
  stateProvinceCode: string(),
  isoCountryCode: string(),
  countryName: string().required(msgs.country),
  postalCode: string(),
  securityRoleId: number().required(msgs.securityRole),
  userStatus: string().required(msgs.status),
  createdDate: string(),
  modifiedDate: string(),
  modifiedUserId: number(),
  publishedEmail: string(),
  userId: number(),
  userPassword: string(),
  jobCategory: string().required(msgs.jobCategory),
  jobCategoryName: string().required(msgs.jobCategory)
});

export default AddEditUserSchema;
