import { Button, ButtonGroup } from 'reactstrap';
import React from 'react';
import { StepConfig, StepperTopNavigatorProps } from './types';
import { appHistory } from '../../reducers';

const StepperTopNavigator = (props: StepperTopNavigatorProps) => {
  const { stepsToMap } = props;
  return (
    <ButtonGroup className={'pb-3 stepperButton'}>
      {stepsToMap.map((step: StepConfig) => {
        const { key, title, url } = step;
        return (
          <Button
            key={key}
            color={'primary'}
            active={appHistory.location.pathname.replace(/\/[0-9]*$/, '') === url}
            onClick={() => appHistory.push(url)}>
            <span className={'step-index'}>STEP {key + 1}</span>
            <br />
            <span className={'step-label'}>{title}</span>
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default StepperTopNavigator;
