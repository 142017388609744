import { AvailableInstanceMasterStatus, WizardActions } from '../actions/wizardActions';
import { IndexableMasterListItem } from '../components/masterlist/types';
import { AllowedWizTypes } from '../components/wizard/types';

/* Set Initial User State - Helps to avoid failure and creates good mental image of shape for other developers */
export const initialWizardState = {
  wizType: 'airports' as AllowedWizTypes,
  contentList: [],
  currentPage: 1,
  loading: false,
  totalListItems: 0,
  totalPages: 0,
  error: '',
  activeInstance: {
    id: 0,
    status: 'PENDING' as AvailableInstanceMasterStatus,
    name: ''
  },
  stepper: {
    airports: {
      contacts: {},
      airportInfo: {},
      terminals: {},
      rentalParking: {},
      tenants: {},
      covid: {},
      location: {},
      review: {}
    },
    companies: { contacts: {}, companyInfo: {}, location: {}, review: {} }
  }
};

export type ActiveInstance = typeof initialWizardState.activeInstance;

/* This Reducer setup is kind of wordy but it does offer type safe actions with a ton of IDE auto completion. */
/* This could probably be inferred by the initial state of the application. */
export interface WizardState {
  wizType: AllowedWizTypes;
  contentList: IndexableMasterListItem[];
  currentPage: number;
  loading: boolean;
  totalListItems: number;
  totalPages: number;
  error: string;
  activeInstance: ActiveInstance;
  stepper: {
    airports: {
      contacts: {};
      airportInfo: {};
      terminals: {};
      rentalParking: {};
      tenants: {};
      covid: {};
      location: {}; // No form here.
      review: {}; // No form here, may not even need this step at all.
    };
    companies: {
      contacts: {};
      companyInfo: {};
      location: {};
      review: {}; // No form here, may not even need this step at all.
    };
  };
}

export const wizardReducer = (state: WizardState = initialWizardState, action: WizardActions) => {
  switch (action.type) {
    case 'SWITCH_WIZARD':
      return {
        ...state,
        wizType: action.wizType,
        currentPage: 1
      };
    case 'SWITCH_WIZARD_INSTANCE':
      return {
        ...state,
        activeInstance: {
          id: action.instanceId,
          status: action.instanceStatus,
          name: action.instanceName
        },
        contentList: new Array<IndexableMasterListItem>()
      };
    case 'GET_WIZARD_LIST':
      return { ...state, loading: true };
    case 'GET_WIZARD_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        contentList: action.contentList,
        currentPage: action.currentPage,
        totalListItems: action.totalListItems,
        totalPages: action.totalPages
      };
    case 'GET_WIZARD_LIST_FAILURE':
      return { ...state, loading: false, error: action.error };
    case 'STORE_WIZARD_FORM_VALUES':
      return { ...state, ...action.form };
    default:
      return state;
  }
};
