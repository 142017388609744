import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { UseLocations } from '../../../../../api/Companies';
import MasterList, { TableColumn } from '../../../../masterlist/MasterList';
import { MasterListActions, MasterListType } from '../../../../masterlist/types';
import MasterListPagesize from '../../../../masterlist/MasterListPagesize';
import MasterListPagination from '../../../../masterlist/MasterListPagination';
import { deleteCompanyAirportLocationFromAPI } from '../../../../../api/CompanyAirportLocation';
import StepHeader from '../../../StepHeader';
import { cssClass } from '../../../cssSharedClasses';

interface CompanyLocationsListProps {
  contentID: number;
  axnInstanceId: number;
  useLocations: UseLocations;
  companyType: string;
}
const CompanyLocationsList = (props: CompanyLocationsListProps & RouteComponentProps) => {
  const { axnInstanceId, companyType, contentID, useLocations } = props;
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1); // 0 index
  const { locations, loading, pageObj } = useLocations(
    +contentID,
    axnInstanceId,
    setCurrentPage,
    setPageSize,
    currentPage,
    pageSize,
    companyType || 'ALL'
  );

  const locationColumnsMap: TableColumn[] = [
    { id: 'companyName', title: 'Company Name', width: ' 20%' },
    { id: 'airportName', title: 'Airport Name', width: ' 25%'  },
    { id: 'city', title: 'City' },
    { id: 'stateProvinceCode', title: 'State/Province' },
    { id: 'isoCountryCode', title: 'Country' },
    { id: 'iataCode', title: 'IATA' },
    { id: 'companyTypeDes', title: 'Type', width: ' 25%'  }
  ];

  const actionsButtons: MasterListActions = (companyName, airportId) => {
    return [
      {
        to: `/wizard/companies/${contentID}/steps/location`,
        icon: faTrash,
        text: 'Delete',
        actionCallback: () => {
          deleteCompanyAirportLocationFromAPI(
            contentID,
            axnInstanceId,
            companyType,
            airportId
          ).then(
            () => {
              toast.success('Successfully deleted!');
              setTimeout(() => window.location.reload(), 3000); // TODO: reload specific state of app instead of reloading entire app
            },
            () => toast.error('Something went wrong!')
          );
        }
      }
    ];
  };

  const slt = 'py-3 d-flex search-list-tools';
  const betweenSLT = `${slt} justify-content-between`;

  return (
      <Row>
        {pageObj.totalRecords === 0 ? (
          <Col xs={12}>
            <StepHeader Tag={'h4'} stepTitle={'No Airport Company Locations Available!'} />
          </Col>
        ) : (
          <Col xs={12}>
            <div className={betweenSLT}>
              <MasterListPagesize size={pageSize} setPagesize={setPageSize} />
              <MasterListPagination
                pageSize={pageSize}
                totalItems={pageObj.totalRecords}
                handlePaginationSelection={setCurrentPage}
                currentPage={pageObj.currentPage}
                className=""
              />
            </div>
            <Row>
              <Col>
                <MasterList
                  columns={locationColumnsMap}
                  list={locations as MasterListType}
                  actions={actionsButtons}
                  additionalValues={['airportId']}
                  currentPage={pageObj.currentPage}
                  itemCount={pageObj.totalRecords}
                  listType="Company Locations"
                  loading={loading}
                />
              </Col>
            </Row>
            <div className={betweenSLT}>
              <MasterListPagesize size={pageSize} setPagesize={setPageSize} />
              <MasterListPagination
                pageSize={pageSize}
                totalItems={pageObj.totalRecords}
                handlePaginationSelection={setCurrentPage}
                currentPage={pageObj.currentPage}
                className=""
              />
            </div>
          </Col>
        )}
      </Row>
  );
};

export default withRouter(CompanyLocationsList);
