import React from 'react';
import { Row } from 'reactstrap';
import { AllowedWizTypes } from './types';
import AxnInstanceFilter from '../forms/AxnInstanceFilter';
import { AvailableInstanceMasterStatus, WizardActionsCreator } from '../../actions/wizardActions';

const StepDetails = (props: {
  contentID: number;
  contentName: string;
  contentType: AllowedWizTypes;
  instanceID: number;
  instanceName: string;
  switchWizardInstance: WizardActionsCreator;
  status: AvailableInstanceMasterStatus;
}) => {
  const {
    contentID,
    contentType,
    contentName,
    instanceID,
    switchWizardInstance,
    status,
    instanceName
  } = props;
  const type = `${contentType.charAt(0).toUpperCase()}${contentType.slice(1)}`;
  const handleSwitchAxnMasterInstance = (instanceId: number, instanceName: string) => (instanceStatus: string) =>
    switchWizardInstance(instanceId, instanceStatus, instanceName);

  return (
    <div className={'pb-4'}>
      <h4 className={'py-2'}>{type} Details</h4>
      <div className={'py-1'}>
        <span className={'font-weight-bold'}>{type} ID: </span>
        {contentID}
      </div>
      <div className={'py-1'}>
        <span className={'font-weight-bold'}>{type} Name: </span>
        {contentName}
      </div>
      <div className={'py-1'}>
        <span className={'font-weight-bold'}>AXN Instance: </span>
        {instanceName}
      </div>
      {/*
      <div>
        <Row>
          <span className={'font-weight-bold py-1 col-auto'}>AXN Instance Status: </span>
          <AxnInstanceFilter
            auto
            axnStatusFilterColSize={{ xl: 10, lg: 9, md: 9, sm: 12, xs: 12 }}
            setStatus={handleSwitchAxnMasterInstance(instanceID, instanceName)}
            status={status}
            noPadding
          />
        </Row>
      </div>
      */}
    </div>
  );
};

export default StepDetails;
