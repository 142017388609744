import { string, object, number } from 'yup';
import {
  defaultStringValidation,
  phoneNumberValidation,
  selectCheck,
  zipValidation
} from '../../../../core/yupSchemas/validators';
import msgs from '../../../../core/yupSchemas/msgs';

const sharedValidators = {
  firstName: defaultStringValidation.required(msgs.firstName),
  lastName: defaultStringValidation.required(msgs.lastName),
  title: defaultStringValidation,
  company: defaultStringValidation,
  address1: string(),
  address2: string(),
  city: string().required(msgs.city),
  state: string(),
  zip: zipValidation(),
  isoCountryCode: string().required(msgs.country),
  countryName: selectCheck.string(msgs.country, ['']),
  workPhone: phoneNumberValidation(msgs.phone).required(msgs.phone),
  faxPhone: phoneNumberValidation('Please provide a valid fax number.'),
  email: string()
    .email(msgs.email)
    .required(msgs.email),
  website: string().url(msgs.website),
  orderSequenceNumber: number().typeError(msgs.nan)
};

const contactValidationSchema = object().shape({
  managementResponsibilityTypeId: selectCheck.string(msgs.mgmtRes, [0]),
  managementResponsibilityType: selectCheck.string(msgs.mgmtRes, ['']),
  firstName: defaultStringValidation.required(msgs.firstName),
  lastName: defaultStringValidation.required(msgs.lastName),
  title: defaultStringValidation,
  company: defaultStringValidation,
  address1: string(),
  address2: string(),
  city: string().required(msgs.city),
  state: string(),
  zip: zipValidation(),
  isoCountryCode: string().required(msgs.country),
  countryName: selectCheck.string(msgs.country, ['']),
  workPhone: phoneNumberValidation(msgs.phone).required(msgs.phone),
  faxPhone: phoneNumberValidation('Please provide a valid fax number.'),
  email: string()
    .email(msgs.email)
    .required(msgs.email),
  website: string().url(msgs.website),
  orderSequenceNumber: number().typeError(msgs.nan)
});

export const airportContactSchema = object().shape({
  ...sharedValidators,
  managementResponsibilityTypeId: selectCheck.string(msgs.mgmtRes, [0]),
  managementResponsibilityType: selectCheck.string(msgs.mgmtRes, ['']),
});

export const companyContactSchema = object().shape({
  ...sharedValidators,
  companyType: selectCheck.string(msgs.companyType, [0]),
  companyTypeDes: selectCheck.string(msgs.companyType, [''])
});

export default contactValidationSchema;
