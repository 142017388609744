import { MouseEvent } from 'react';
import { Dispatch } from 'redux';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import moment from 'moment';
import { IndexableMasterListItem } from '../components/masterlist/types';
import { initialWizardState } from '../reducers/wizardReducer';
import { AllowedWizTypes } from '../components/wizard/types';
import { appHistory as history } from '../reducers';
import { getSearchList } from '../api/WizardSearchList';
import { AirportInstance, ManageableContentTypes } from '../types';
import { CompanyInstance } from './companiesActions';

export type AvailableInstanceMasterStatus = 'PENDING' | 'ACTIVE';

export type WizardActions =
  | {
      type: 'SWITCH_WIZARD';
      wizType: ManageableContentTypes;
      pageNumber: number;
    }
  | { type: 'GET_WIZARD_LIST' }
  | {
      type: 'GET_WIZARD_LIST_SUCCESS';
      contentList: IndexableMasterListItem[];
      currentPage: number;
      totalPages: number;
      totalListItems: number;
    }
  | { type: 'GET_WIZARD_LIST_FAILURE'; error: string }
  | {
      type: 'STORE_WIZARD_FORM_VALUES';
      form: any; // since forms are so flexible in shape this is probably ok for now.
    }
  | {
      type: 'SWITCH_WIZARD_INSTANCE';
      instanceId: number;
      instanceStatus: AvailableInstanceMasterStatus;
      instanceName: string;
    };

export type WizardActionsCreator = (...props: any) => WizardActions;

/* WIZARD ACTION CREATORS */
/* Switch the wizard type */
export const switchWizType: WizardActionsCreator = (
  wizType: ManageableContentTypes,
  pageNumber: number
) => ({
  type: 'SWITCH_WIZARD',
  wizType,
  pageNumber
});

export const switchWizardInstance: WizardActionsCreator = (
  instanceId: number,
  instanceStatus: AvailableInstanceMasterStatus, // currently supports "PENDING" & "ACTIVE"
  instanceName: string
) => ({
  type: 'SWITCH_WIZARD_INSTANCE',
  instanceId,
  instanceStatus,
  instanceName
});

export const getWizardList: WizardActionsCreator = () => ({
  type: 'GET_WIZARD_LIST',
  loading: true
});

export const getWizardListSuccess: WizardActionsCreator = (
  contentList: IndexableMasterListItem[],
  currentPage: number = initialWizardState.currentPage,
  totalPages: number = initialWizardState.totalPages,
  totalListItems: number = initialWizardState.totalListItems
) => ({
  type: 'GET_WIZARD_LIST_SUCCESS',
  contentList,
  currentPage,
  totalPages,
  totalListItems
});

export const getWizardListFailure: WizardActionsCreator = (error: string) => ({
  type: 'GET_WIZARD_LIST_FAILURE',
  loading: false,
  error
});

export const storeWizardFormValues: WizardActionsCreator = (
  wizType: AllowedWizTypes,
  formName: string,
  formValues: {}
) => {
  const form = {
    stepper: {
      [wizType]: {
        [formName]: {
          ...formValues
        }
      }
    }
  };

  return {
    type: 'STORE_WIZARD_FORM_VALUES',
    form
  };
};

/* functions that utilize action creators - you can connect these to container props using mapDispatchToProps */
export const contentList = (dispatch: Dispatch) => (
  instanceId: number,
  contentType: AllowedWizTypes,
  userId: number,
  currentPage: number,
  pageSize: number,
  keyword: string
) => {
  dispatch(getWizardList());
  getSearchList(
    instanceId,
    keyword,
    contentType
  )(pageSize, currentPage).then(
    response => {
      const { content, pageable, totalPages, totalElements } = response.data;

      const manipulatedContent = {
        airports: content.map((item: AirportInstance) => ({
          ...item.airport,
          modifiedUserName: `${get(item.airport, 'modifiedUser.firstName', '')} ${get(
            item.airport,
            'modifiedUser.lastName',
            ''
          )}`
        })),
        companies: content.map((item: CompanyInstance) => ({
          ...item.company,
          modifiedUserName: `${get(item.company, 'modifiedUser.firstName', '')} ${get(
            item.company,
            'modifiedUser.lastName',
            ''
          )}`
        }))
      };
      dispatch(
        getWizardListSuccess(
          manipulatedContent[contentType],
          pageable.pageNumber,
          totalPages,
          totalElements,
          currentPage
        )
      );
    },
    error => {
      const errorMsg = get(
        error.response,
        'data.message',
        'There is currently no content available for this year of the AXN Fact Book.'
      );
      dispatch(getWizardListFailure(errorMsg));
      console.log(errorMsg);
      // toast.warn(errorMsg);
    }
  );
};

export const onSwitchWizard = (dispatch: Dispatch) => (e: MouseEvent<HTMLInputElement>) => {
  const wizType = e.currentTarget.value;
  dispatch(switchWizType(wizType, 0));
  history.push(`/wizard/${wizType}`);
};

export const setWizardFormState = (dispatch: Dispatch) => (
  wizType: AllowedWizTypes,
  formName: string,
  formValues: any
) => {
  dispatch(storeWizardFormValues(wizType, formName, formValues));
};

export type SetWizardFormState = (
  wizType: AllowedWizTypes,
  formName: string,
  formValues: any
) => void;

export const dispatchSwitchWizardInstance = (dispatch: Dispatch) => (
  instanceId: number,
  instanceStatus: AvailableInstanceMasterStatus, // currently supports "PENDING" & "ACTIVE"
  instanceName: string
) => {
  Cookies.set(
    'wizardInstance',
    {
      instanceId,
      instanceStatus,
      instanceName
    },
    {
      expires: moment()
        .add(1, 'day')
        .date()
    }
  );
  return dispatch(switchWizardInstance(instanceId, instanceStatus, instanceName));
};
