import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Alert, Col, Container, Row } from 'reactstrap';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import { AppState } from '../../../../../reducers';
import { SetWizardFormState } from '../../../../../actions/wizardActions';
import { WizardStepperMatch } from '../../../types';
import {
  ContactData,
  InitGetContacts,
  initGetContacts
} from '../../../../../actions/contactsActions';
import { CompanyInstance } from '../../../../../actions/companiesActions';
import CompanyLocationsList from '../location/CompanyLocationsList';
import { useCompanyLocations } from '../../../../../api/Companies';
import CompanyContactsList from '../contacts/CompanyContactList';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';

interface ReviewStepProps {
  dispatch?: Dispatch;
  setFormState: SetWizardFormState;
  match: WizardStepperMatch;
  contacts: ContactData[];
  setContacts: InitGetContacts;
  companyInstance: CompanyInstance;
  activeInstance: ActiveInstance;
}

const ReviewStep = (props: ReviewStepProps) => {
  const { contacts, match, setContacts, companyInstance, activeInstance } = props;
  const { id } = activeInstance;
  const { companyInfoText } = companyInstance.company;
  const { contentID, contentType } = match.params;
  /* get all the contacts associated with the current company  */
  useEffect(() => {
    setContacts(contentID, 'companies', id);
  }, [contacts.length, contentID, setContacts, id]);

  return (
    <Row>
      <Col>
        <Container>
          <StepHeader stepTitle="Company Review" />
          <Row>
            {contacts.length > 0 && (
              <Col className={cssClass}>
                <StepHeader Tag="h3" stepTitle="Existing Contacts" />
                <CompanyContactsList
                  contacts={contacts}
                  contentID={contentID}
                  contentType={contentType}
                  instanceId={id}
                  readonly
                />
              </Col>
            )}
            <Col className={cssClass}>
              <StepHeader Tag="h3" stepTitle="Company Information" />
              <Alert color={companyInfoText ? 'success' : 'danger'}>
                {companyInfoText || 'No Company Information Text Provided.'}
              </Alert>
            </Col>
          </Row>
        </Container>
      </Col>
      <Col>
        <div className={cssClass}>
          <StepHeader Tag="h3" stepTitle="Company Locations" />
          <CompanyLocationsList
            contentID={+contentID}
            axnInstanceId={id}
            useLocations={useCompanyLocations}
            companyType={'ALL'}
          />
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: AppState) => ({
  contacts: state.contacts.contacts,
  companyInstance: state.companies.companyInstance,
  activeInstance: state.wizard.activeInstance
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setContacts: initGetContacts(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewStep);
