import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AddEditUserForm from '../../components/users/AddEditUserForm';
// import ResetPasswordForm from '../../components/shared/login/ResetPasswordForm';
import { AppState } from '../../reducers';
import { AuthenticatedUser } from '../../actions/usersActions';

interface AddEditUserViewProps {
  authenticatedUser: AuthenticatedUser;
  isLoggedIn: boolean;
}

/* TODO: implement user ability to change password from user settings panel. */
const AddEditUserView = (props: AddEditUserViewProps & RouteComponentProps) => {
  // const { authenticatedUser, match, isLoggedIn } = props;
  // const { path, } = match;
  // const isAdminFacing = path.includes('admin');
  return (
    <Container>
      <Row>
        <Col>
          <AddEditUserForm />
          {/* // Reset password functionality not yet implemented on the backend for users.
            {!isAdminFacing && <ResetPasswordForm authenticatedUser={authenticatedUser} isLoggedIn={isLoggedIn}/>}
          */}
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  authenticatedUser: state.users.authenticatedUser,
  isLoggedIn: state.users.isLoggedIn
});

export default connect(
  mapStateToProps,
  null
)(withRouter(AddEditUserView));
