import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, Redirect, withRouter } from 'react-router-dom';
/* Import Page/Wrappers */
import AdminRouter from './AdminRouter';
import RouteWithAuthority from './components/RouteWithAuthority';
import LoginForm from './components/shared/login/LoginForm';
import ResetPasswordForm from './components/shared/login/ResetPasswordForm';
import ForgotPasswordForm from './components/shared/login/ForgotPasswordForm';
import AboutRouter from './AboutRouter';
import WizardSearchList from './components/wizard/WizardSearchList';
import Stepper from './components/wizard/Stepper';
import AirportsView from './pages/AirportsView';
import LeaseExpirationView from './pages/LeaseExpirationView';
import ReportsView from './pages/ReportsView';
import AddEditUserView from './pages/users/AddEditUserView';
import ReportsDBEView from './pages/ReportsDBEView';
import CompanyView from './components/company/CompanyView';
import TenantsView from './pages/TenantsView';
import ContactsView from './pages/ContactsView';
import CompaniesSearch from './pages/CompaniesView';
import IndexView from './pages/IndexView';
import ErrorView from './pages/ErrorView';
import { AppState } from './reducers';
import { AdSpeedLeaderboard } from './components/advertisement/adspeed/leaderboard';

interface AppRouter extends RouteComponentProps {
  pageTitle: string;
  isLoggedIn: boolean;
}

const AppRouter = (props: AppRouter) => {
  const { pageTitle, location, isLoggedIn } = props;

  /* Send GA pageView event on location change */
  useEffect(() => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'pageView',
      payload: { pagePath: location.pathname, pageTitle }
    });
  }, [location.pathname, pageTitle]);

  return (
    /* NOTE: if code splitting is necessary a splat (*) on the parents path prop. */
    <Switch>
      <Route
        exact
        component={() => (
          <>
            <AdSpeedLeaderboard />
            <IndexView isLoggedIn={isLoggedIn} />
          </>
        )}
        path="/"
      />

      <Route component={AboutRouter} path="/about" />
      {isLoggedIn ? (
        <Redirect exact to={'/'} path="/login" />
      ) : (
        <Route exact component={LoginForm} path="/login" />
      )}

      {/* Authenticated Reset */}
      <Route exact component={ForgotPasswordForm} path="/forgotpassword" />
      <Route exact component={ResetPasswordForm} path="/resetpassword/:resetPasswordToken" />

      {/* Authenticated Access */}
      <RouteWithAuthority
        exact
        component={ResetPasswordForm}
        path="/changepassword"
        requiredAuthorities={[
          'ROLE_SUBSCRIBER',
          'ROLE_AIRPORT_SUB',
          'ROLE_COMPANY_SUB',
          'ROLE_ADMIN'
        ]}
      />
      <RouteWithAuthority
        exact
        component={AddEditUserView}
        path="/settings"
        requiredAuthorities={[
          'ROLE_USER',
          'ROLE_SUBSCRIBER',
          'ROLE_AIRPORT',
          'ROLE_AIRPORT_SUB',
          'ROLE_COMPANY',
          'ROLE_COMPANY_SUB',
          'ROLE_ADMIN'
        ]}
      />
      <RouteWithAuthority
        exact
        component={AirportsView}
        path="/airports/:contentID?"
        requiredAuthorities={[
          'ROLE_SUBSCRIBER',
          'ROLE_AIRPORT_SUB',
          'ROLE_COMPANY_SUB',
          'ROLE_ADMIN'
        ]}
      />
      <RouteWithAuthority
        exact
        component={CompaniesSearch}
        path="/companies/:searchTerm?"
        requiredAuthorities={[
          'ROLE_SUBSCRIBER',
          'ROLE_AIRPORT_SUB',
          'ROLE_COMPANY_SUB',
          'ROLE_ADMIN'
        ]}
      />
      <RouteWithAuthority
        component={CompanyView}
        path="/company/:contentID"
        requiredAuthorities={[
          'ROLE_SUBSCRIBER',
          'ROLE_AIRPORT_SUB',
          'ROLE_COMPANY_SUB',
          'ROLE_ADMIN'
        ]}
      />
      <RouteWithAuthority
        exact
        component={ContactsView}
        path="/contacts/:contactType?"
        requiredAuthorities={[
          'ROLE_SUBSCRIBER',
          'ROLE_AIRPORT_SUB',
          'ROLE_COMPANY_SUB',
          'ROLE_ADMIN'
        ]}
      />

      <RouteWithAuthority
        exact
        component={LeaseExpirationView}
        path="/leaseexpiration"
        requiredAuthorities={[
          'ROLE_SUBSCRIBER',
          'ROLE_AIRPORT_SUB',
          'ROLE_COMPANY_SUB',
          'ROLE_ADMIN'
        ]}
      />
      <RouteWithAuthority
        exact
        component={TenantsView}
        path="/tenants"
        requiredAuthorities={[
          'ROLE_SUBSCRIBER',
          'ROLE_AIRPORT_SUB',
          'ROLE_COMPANY_SUB',
          'ROLE_ADMIN'
        ]}
      />
      <RouteWithAuthority
        exact
        component={ReportsView}
        path="/reports"
        requiredAuthorities={[
          'ROLE_SUBSCRIBER',
          'ROLE_AIRPORT_SUB',
          'ROLE_COMPANY_SUB',
          'ROLE_ADMIN'
        ]}
      />
      <RouteWithAuthority
        exact
        component={ReportsDBEView}
        path="/reports/dbe"
        requiredAuthorities={[
          'ROLE_SUBSCRIBER',
          'ROLE_AIRPORT_SUB',
          'ROLE_COMPANY_SUB',
          'ROLE_ADMIN'
        ]}
      />
      {/* Universal Wizard Routes */}
      <RouteWithAuthority
        exact
        component={WizardSearchList}
        path="/wizard/:contentType(companies|airports)?"
        requiredAuthorities={[
          'ROLE_AIRPORT',
          'ROLE_AIRPORT_SUB',
          'ROLE_COMPANY',
          'ROLE_COMPANY_SUB',
          'ROLE_ADMIN'
        ]}
      />
      <RouteWithAuthority
        component={Stepper}
        path="/wizard/:contentType(airports|companies)/:contentID/steps"
        requiredAuthorities={[
          'ROLE_AIRPORT',
          'ROLE_AIRPORT_SUB',
          'ROLE_COMPANY',
          'ROLE_COMPANY_SUB',
          'ROLE_ADMIN'
        ]}
      />
      {/* Admin Routes */}
      <RouteWithAuthority
        component={AdminRouter}
        requiredAuthorities={['ROLE_ADMIN']}
        path="/admin"
      />
      <Route component={ErrorView} />
    </Switch>
  );
};

const mapStateToProps = (state: AppState) => ({
  pageTitle: state.page.title,
  isLoggedIn: state.users.isLoggedIn
});

export default connect(mapStateToProps)(withRouter(AppRouter));
