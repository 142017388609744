import { ManageableContentTypes } from '../types';
import { axnAPI } from './config';
import { emptyPage, PageableData, useDataModel } from './index';
import { convertSearchCompanies, searchCompanies } from './Companies';
import { convertSearchAirports, searchAirports } from './Airports';

/* Request Used to Get Affiliated Content for wizard  */
/* DEPRECATED */
export const getList = (type: ManageableContentTypes) => (
  userId: number,
  size: number = 10,
  currentPage: number = 0,
  instanceID: number
) => {
  const controller = `/affiliated${type}`;
  const sort = type === 'airports' ? 'iataCode' : '';
  return axnAPI.get(`${controller}/user/${userId}/instance/${instanceID}`, {
    params: { page: currentPage, size }
  });
};

export const getSearchList = (
  instanceId: number,
  keyword: string,
  type: ManageableContentTypes
) => (size: number = 10, currentPage: number = 0) => {
  const endPoint = type === 'companies' ? searchCompanies : searchAirports;
  const sort = type === 'companies' ? 'company.companyName' : 'airport.airportName';

  return endPoint(instanceId, keyword)(size, currentPage, sort);
};

const getSearchEndpoint = <T>(instanceId: number, type: ManageableContentTypes, keyword: string) =>
  type === 'companies'
    ? convertSearchCompanies<T>(instanceId, keyword)
    : convertSearchAirports<T>(instanceId, keyword);

export const useWizardSearchList = <T>(
  instanceId: number,
  type: ManageableContentTypes,
  keyword: string,
  sort: string
): PageableData<T> =>
  useDataModel(
    (currentPage, pageSize) =>
      getSearchEndpoint<T>(instanceId, type, keyword)(currentPage, pageSize, sort),
    emptyPage<T>(),
    keyword
  );
