import React from 'react';
import { Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFax, faPhone } from '@fortawesome/free-solid-svg-icons';
import { AxnTitle } from '../../components/shared/title';

const ContactUs = () => (
  <Col xs={12} sm={6} md={4} lg={4} xl={4} className={'mb-4'}>
    <AxnTitle>Contact Us</AxnTitle>
    <ul className="nav-list text-center">
      <li>
        <FontAwesomeIcon icon={faEnvelope} className={'mr-3'} />
        <a href="mailto:info@airportxnews.com">AXN Information</a>
      </li>
      <li>
        <FontAwesomeIcon icon={faPhone} className={'mr-3'} />
        561-257-1024
      </li>
      {/*
        <li>
          <FontAwesomeIcon icon={faFax} className={'mr-3'} />
          561-228-0882
        </li>
      */}
      <li className={'mt-4'}>
        <div>110 S Hartford Ave Ste 200</div>
        <div>Tulsa, Ok 74120</div>
      </li>
    </ul>
  </Col>
);

export default ContactUs;
