export enum KeyEventEnum {
    NUMPAD0 = 48,
    NUMPAD9 = 57,
    DASH = 189,
    ENTER = 13,
    BACKSPACE = 8,
    DELETE = 46,
    OPTION_ALT = 18,
    CMD = 91,
    ARROW_LEFT=37,
    ARROW_UP=38,
    ARROW_RIGHT=39,
    ARROW_DOWN=40,
    PERIOD=190
}
export const restrictInputToAnyInteger = (e:React.KeyboardEvent<HTMLInputElement>) => {
    // [0-9] or - 
    let isSupportingKey = e.ctrlKey || e.metaKey || e.altKey;
  let isKeyAllowed = (e.keyCode >= KeyEventEnum.NUMPAD0 && e.keyCode <= KeyEventEnum.NUMPAD9) 
    || e.keyCode == KeyEventEnum.BACKSPACE
    || e.keyCode == KeyEventEnum.DELETE
    || e.keyCode == KeyEventEnum.DASH 
    || e.keyCode == KeyEventEnum.ENTER
    || e.keyCode == KeyEventEnum.OPTION_ALT
    || e.keyCode == KeyEventEnum.CMD
    || e.keyCode == KeyEventEnum.ARROW_LEFT
    || e.keyCode == KeyEventEnum.ARROW_UP
    || e.keyCode == KeyEventEnum.ARROW_RIGHT
    || e.keyCode == KeyEventEnum.ARROW_DOWN
    || e.keyCode == KeyEventEnum.PERIOD;
  
  if ( !isSupportingKey && !isKeyAllowed )
  {
    e.preventDefault();
    return;
  }
}
