import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'reactstrap';
import { deleteAirportTerminalFromAPI, getTerminalsPaged } from '../../../api/Airports';
import MasterList, { TableColumn } from '../../masterlist/MasterList';
import { AppState } from '../../../reducers';
import { paginationClasses } from '../adminUtils';
import { MasterListActionButton } from '../../masterlist/types';
import { MasterListPagesize, MasterListPagination } from '../../masterlist';
import { TerminalData } from '../../../actions/terminalsActions';
import {
  AvailableInstanceMasterStatus,
  dispatchSwitchWizardInstance,
  WizardActionsCreator
} from '../../../actions/wizardActions';
import { AuthenticatedUser } from '../../../actions/usersActions';
import AxnInstanceFilter from '../../forms/AxnInstanceFilter';
import GoBack from '../../buttons/GoBack';
import AddEntityButton from '../AddEntityButton';
import { initTerminalsVals } from './AddEditTerminalView';
import Confirm from '../users/Confirm';

type TerminalManagerURLParam = {
  aid: string;
};

interface TerminalManagerProps extends RouteComponentProps<TerminalManagerURLParam> {
  authenticatedUser: AuthenticatedUser;
  activeInstance: number;
  activeInstanceStatus: string;
  switchWizardInstance: WizardActionsCreator;
}

const TerminalManager = (props: TerminalManagerProps) => {
  const {
    authenticatedUser,
    activeInstance,
    activeInstanceStatus,
    switchWizardInstance,
    match
  } = props;
  const {
    axnInstanceId,
    axnPendingInstanceId,
    axnInstanceName,
    axnPendingInstanceName
  } = authenticatedUser;
  const [axnStatus, setAxnStatus] = useState(activeInstanceStatus as AvailableInstanceMasterStatus);

  useEffect(() => {
    const instanceIdToUse = axnStatus === 'PENDING' ? axnPendingInstanceId : axnInstanceId;
    const instanceNameToUse = axnStatus === 'PENDING' ? axnPendingInstanceName : axnInstanceName;
    switchWizardInstance(
      instanceIdToUse,
      axnStatus as AvailableInstanceMasterStatus,
      instanceNameToUse
    );
  }, [axnStatus]);

  const { aid } = match.params;
  const [terminals, setTerminals] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState<number>();
  const [currentPage, setCurrentPage] = useState(0);
  const [terminalsCount, setTerminalsCount] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    let hasContent = false;
    setLoading(true);
    getTerminalsPaged(+aid, activeInstance, currentPage, paginationSize)
      .then(res => {
        if (mounted) {
          if (res.data.content) hasContent = true;
          const { content, pageable, totalElements } = res.data;
          setTerminals(
            content.map((item: TerminalData) => {
              return {
                ...item,
                terminalId: item.terminal.terminalId,
                terminalName: item.terminal.terminalName,
                terminalShortName: item.terminal.terminalShortName
              };
            })
          );
          setTerminalsCount(totalElements);
          setPaginationSize(pageable.pageSize);
          setLoading(false);
        }
      })
      .catch(error => {
        if (mounted) {
          setLoading(false);
          const errorMsg = hasContent
            ? get(error.response, 'data.message', 'Failed to Retrieve Terminals!')
            : 'No Terminals Available!';
          if (hasContent) toast.error(errorMsg);
          else toast.warn(errorMsg);
        }
      });
    return () => {
      mounted = true;
    };
  }, [aid, activeInstance, paginationSize, currentPage, getTerminalsPaged]);

  const columnsMap: TableColumn[] = [
    { id: 'terminalId', title: 'ID' },
    { id: 'terminalName', title: 'Name', width: '60%' },
    { id: 'terminalShortName', title: 'Short Name' }
  ];

  const rowActions = (airportId: number) => (terminalId: number): MasterListActionButton[] => {
    return [
      {
        to: `/admin/manage/airports/${airportId}/terminals/edit/${terminalId}`,
        icon: faEdit,
        text: 'Edit'
      },
      {
        to: `/admin/manage/airports/${airportId}/terminals`,
        icon: faTrash,
        text: 'Delete',
        actionCallback: () => setSelectedTerminal(terminalId)
      }
    ];
  };

  const deleteTerminal = () => {
    setLoading(true);
    if (selectedTerminal)
      deleteAirportTerminalFromAPI(+aid, activeInstance, selectedTerminal).then(
        () => {
          toast.success('Successfully Deleted Airport Terminal');
          const newTerminalList = terminals.filter(
            terminal => get(terminal, 'terminalId', 0) !== selectedTerminal
          );
          setTerminals(newTerminalList);
          setTerminalsCount(terminalsCount - 1);
          setLoading(false);
          setSelectedTerminal(undefined);
        },
        () => {
          toast.error('Failed to Delete Airport Terminal');
          setLoading(false);
          setSelectedTerminal(undefined);
        }
      );
  };

  const usePaginationSelection = (pageNum: number) => {
    setCurrentPage(pageNum - 1);
  };

  const useSetPaginationSize = (pageSize: number) => {
    setCurrentPage(0);
    setPaginationSize(pageSize);
  };

  const [initTerminalValues, setInitTerminalValues] = useState(initTerminalsVals);

  return (
    <div>
      <AddEntityButton
        addIcon={faPlus}
        emptyEntityState={initTerminalValues}
        path={`/admin/manage/airports/${aid}/terminals/add`}
        setEntity={setInitTerminalValues}
      />
      <Row>
        <Col className={'my-auto'} xl={6} lg={6} md={6} sm={6} xs={12}>
          <GoBack className={'w-100'} />
        </Col>
        <AxnInstanceFilter
          axnStatusFilterColSize={{ xl: 6, lg: 6, md: 6, sm: 6, xs: 12 }}
          setStatus={setAxnStatus}
          status={axnStatus}
          authenticatedUser={authenticatedUser}
        />
      </Row>
      <div className={paginationClasses}>
        <MasterListPagesize size={paginationSize} setPagesize={useSetPaginationSize} />
        <MasterListPagination
          pageSize={paginationSize}
          totalItems={terminalsCount}
          handlePaginationSelection={usePaginationSelection}
          currentPage={currentPage + 1}
        />
      </div>
      <MasterList
        loading={loading}
        actions={rowActions(+aid)}
        columns={columnsMap}
        currentPage={currentPage}
        itemCount={terminalsCount}
        list={terminals}
        listType="Terminals"
        readonly={false}
        additionalValues={[]}
      />
      <div className={paginationClasses}>
        <MasterListPagesize size={paginationSize} setPagesize={useSetPaginationSize} />
        <MasterListPagination
          pageSize={paginationSize}
          totalItems={terminalsCount}
          handlePaginationSelection={usePaginationSelection}
          currentPage={currentPage + 1}
        />
      </div>
      <Confirm
        isOpen={selectedTerminal !== undefined}
        cancel={_event => setSelectedTerminal(undefined)}
        success={deleteTerminal}
        successTitle="Delete">
        <p>Are you sure you wish to delete this terminal?</p>
      </Confirm>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  authenticatedUser: state.users.authenticatedUser,
  activeInstance: state.wizard.activeInstance.id,
  activeInstanceStatus: state.wizard.activeInstance.status
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  switchWizardInstance: dispatchSwitchWizardInstance(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TerminalManager);
