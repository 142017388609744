import React from 'react';
import { Alert, Container } from 'reactstrap';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { FaqsView } from './pages/FaqsView';
import GlossaryView from './pages/GlossaryView';
import ContactUsView from './pages/ContactUsView';

const AboutRouter = (props: RouteComponentProps<{}>) => {
  const { match } = props;
  const { path } = match;
  return (
    <Container>
      <Switch>
        <Route exact component={GlossaryView} path={`${path}/glossary`} />
        <Route exact component={ContactUsView} path={`${path}/contact-us`} />
        <Route exact component={FaqsView} path={`${path}/faqs`} />
        <Route component={Alert} />
      </Switch>
    </Container>
  );
};

export default withRouter(AboutRouter);
