import React from 'react';
import { Form, Formik } from 'formik';
import { get } from 'lodash';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import './_listManager.scss';
import { ListManagerMatch } from '../types';
import CompanyTypeSelectField from '../../forms/CompanyTypeSelectField';
import { appHistory } from '../../../reducers';

interface ListManagerProps {
  selectedItems: any[]; // TODO: make this more strict on type.
  setSelectedItems: (...props: any) => void;
  deleteListItem: (...props: any) => void;
  addListItem: (...props: any) => void;
  headerText: string;
  fieldName: string;
  itemType: string;
  match: ListManagerMatch;
  initValues: any;
  addIcon: IconDefinition;
  deleteIcon: IconDefinition;
  axnInstanceId: number;
}

const ListManager = (props: ListManagerProps) => {
  const {
    match,
    selectedItems,
    setSelectedItems,
    headerText,
    fieldName,
    itemType,
    addListItem,
    initValues,
    addIcon,
    deleteIcon,
    deleteListItem,
    axnInstanceId
  } = props;
  const { contentID } = match.params;
  const handleDelete = (id: number, name: string) => () => {
    deleteListItem(id, name, axnInstanceId);
  };

  return (
    <div className={'listManagerWrapper'}>
      <h3 className={'wizard-instruction'}>{headerText}</h3>
      <div className={'listManagerFields'}>
        <div className={'existing-list-items container-fluid'}>
          <Row className={'list-item-col-names'}>
            <Col sm>Name</Col>
            <Col sm>Actions</Col>
          </Row>
          {selectedItems.map(item => {
            const { id, name, description } = item;
            return (
              <Row key={`${name}${id}`}>
                <Col sm>{description}</Col>
                <Col sm>
                  <ButtonGroup>
                    <Button color={'primary'} onClick={handleDelete(+contentID, name)}>
                      Delete <FontAwesomeIcon icon={deleteIcon} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            );
          })}
        </div>
        <div className={'my-3'}>
          <Formik
            onSubmit={values => {
              /* TODO: write logic to determine if item already exist in array, use it to either addListItem or toaster message. */
              const companyType = values[fieldName];
              const companyTypeDes = values[`${fieldName}Des`];
              addListItem(+contentID, axnInstanceId, companyType, companyTypeDes, {
                companyInstanceTypePK: {
                  companyId: +contentID,
                  axnInstanceId,
                  companyType
                }
              });
            }}
            initialValues={{ ...initValues }}
            render={({ handleBlur, handleChange, values, setFieldValue }) => {
              return (
                <Form>
                  <Row>
                    <CompanyTypeSelectField
                      setFieldValue={setFieldValue}
                      match={match}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      companyId={+contentID}
                      axnInstanceId={axnInstanceId}
                      values={values}
                      col={{ xl: 12 }}
                      browserAutoComplete={'off'}
                    />
                  </Row>
                  <ButtonGroup>
                    <Button color={'primary'} type={'submit'}>
                      Add {itemType} <FontAwesomeIcon icon={addIcon} />
                    </Button>
                  </ButtonGroup>
                </Form>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ListManager;
