import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Formik, FormikProps } from 'formik';
import { Col, Row } from 'reactstrap';
import { AllowedWizTypes, StepDefaultProps } from '../../../types';
import { setWizardFormState, SetWizardFormState } from '../../../../../actions/wizardActions';
import { appHistory, AppState } from '../../../../../reducers';
import ContactsForm from './ContactsForm';
import { CompanyInstance } from '../../../../../actions/companiesActions';
import {
  ContactData,
  deleteCompanyContact,
  DeleteContact,
  dispatchResetContact,
  DispatchResetContact,
  initAddContact,
  InitAddContact,
  InitGetContact,
  initGetContact,
  InitGetContacts,
  initGetContacts,
  initialContactFormValues,
  initUpdateContact,
  InitUpdateContact
} from '../../../../../actions/contactsActions';
import { ContactsState } from '../../../../../reducers/contactsReducer';
import { convertContactPhoneList } from '../../../../../api/contacts';
import { companyContactSchema } from '../../../shared/yupSchemas/yupSchema';
import { YesOrNo } from '../../../../../types';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import { AuthenticatedUser } from '../../../../../actions/usersActions';
import Spinner from '../../../../shared/spinner/Spinner';
import CompanyContactsList from './CompanyContactList';
import { CompanyType, useCompanyTypes } from '../../../../../api/Companies';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';

export interface ContactsStepProps extends StepDefaultProps {
  dispatch?: Dispatch;
  setFormState: SetWizardFormState;
  companyInstance: CompanyInstance;
  contactsManager: ContactsState;
  setContact: InitGetContact;
  setContacts: InitGetContacts;
  dispatchResetContact: DispatchResetContact;
  deleteContact: DeleteContact;
  updateContact: InitUpdateContact;
  addContact: InitAddContact;
  contact: ContactData;
  contacts: ContactData[];
  authenticatedUser: AuthenticatedUser;
  contactsLoading: boolean;
  activeInstance: ActiveInstance;
}

interface ContactStepRouteProps {
  contentID: string;
  contactID: string;
  contentType: AllowedWizTypes;
  companyType: string;
}

const ContactsStep = (
  props: RouteComponentProps<ContactStepRouteProps> & ContactsStepProps & FormikProps<ContactData>
) => {
  const {
    match,
    location,
    setContact,
    setContacts,
    dispatchResetContact,
    deleteContact,
    updateContact,
    addContact,
    companyInstance,
    contact,
    contacts,
    contactsLoading,
    authenticatedUser,
    activeInstance
  } = props;
  const { id } = activeInstance;

  const [contactToUse, setContactToUse] = useState(contact);
  useEffect(() => {
    setContactToUse(contact);
  }, [
    contact.managementResponsibilityType,
    contact.companyName,
    contact.stateProvince,
    contact.countryName
  ]);

  const { userId } = authenticatedUser;
  const { contentID, contactID, contentType, companyType } = match.params;

  /* This is here to for the reload from the child, used as dep of use effect */
  const [selectedContact] = useState(contactID);
  const [initVals, setInitVals] = useState(initialContactFormValues);
  const { companyTypes } = useCompanyTypes(id, +contentID, true);

  /* Set Form Initial Value and Track with Functional Component State */
  useEffect(() => {
    if (contact)
      setInitVals({
        ...initialContactFormValues,
        ...contact,
        workExt: contact.workExt || '',
        workPhone: contact.workPhone || '',
        faxPhone: contact.faxPhone || '',
        faxExt: contact.faxExt || '',
        modifiedUserId: userId,
        managementResponsibilityType: contactToUse.managementResponsibilityType,
        stateProvince: contactToUse.stateProvince,
        countryName: contactToUse.countryName,
        companyName: contactToUse.companyName
      });
  }, [initVals.contactId, contact, setInitVals]);

  /* get the current contact based on contact id url param if existent */
  useEffect(() => {
    const companyTypeObj = companyTypes.find(
      (companyTypeObj: CompanyType) => companyTypeObj.companyType === companyType
    );
    const typeDescirption = companyTypeObj ? companyTypeObj.companyTypeDes : '';

    if (contactID)
      setContact(contentID, contactID, 'companies', companyType || '', typeDescirption, id);
  }, [contact.firstName, selectedContact, contentID, contactID, setContact, id]);

  /* get all the contacts associated with the current company  */
  useEffect(() => {
    setContacts(contentID, 'companies', id);
  }, [contacts.length, contentID, setContacts, id]);

  /* change the location to default form location and reset contact so form resets. */
  const resetContact = () => {
    const urlRegex = /(?<pathWithoutContact>\/wizard\/(?<contentType>companies|airports)\/(?<contentId>[0-9]*)\/steps\/contacts)/;

    let groups: any = {
      pathWithoutContact: '',
      contentType: '',
      contentId: ''
    };

    if (location.pathname.match(urlRegex))
      groups = get(location.pathname.match(urlRegex), 'groups', {});

    appHistory.push(groups.pathWithoutContact);
    dispatchResetContact();
  };

  return (
    <>
      <Row>
        <Col>
          <Formik
            validationSchema={companyContactSchema}
            enableReinitialize
            onSubmit={(values: ContactData, formikActions) => {
              const sendVals = {
                ...values,
                factbookIncludeFlag: (values.factbookIncludeFlag ? 'Y' : 'N') as YesOrNo, // API requires it this way :(
                companyName: companyInstance.company.companyName,
                contactPhoneList: convertContactPhoneList(values)
              };

              delete sendVals.workPhone;
              delete sendVals.workExt;
              delete sendVals.faxPhone;
              delete sendVals.faxExt;

              if (contactID)
                updateContact(
                  contentID,
                  contactID,
                  values.managementResponsibilityTypeId,
                  values.companyType,
                  companyType || '',
                  sendVals,
                  'companies',
                  id
                );
              else addContact(contentID, sendVals, 'companies', id);

              resetContact();
              formikActions.resetForm(initialContactFormValues);
            }}
            onReset={() => resetContact()}
            initialValues={initVals}
            render={({
              handleBlur,
              handleChange,
              handleReset,
              handleSubmit,
              values,
              setFieldValue
            }) => (
              <ContactsForm
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleReset={handleReset}
                handleSubmit={handleSubmit}
                isUpdatingContact={!!contactID}
                match={match}
                values={values}
                setFieldValue={setFieldValue}
                companyId={+contentID}
                axnInstanceId={id}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={cssClass}>
            <StepHeader stepTitle={'Existing Contacts'} />
            {contactsLoading && <Spinner />}
            {!contactsLoading && contacts.length > 0 ? (
              <CompanyContactsList
                contacts={contacts}
                contentID={contentID}
                contentType={contentType}
                handleDelete={deleteContact}
                instanceId={id}
              />
            ) : (
              <StepHeader Tag={'h3'} stepTitle={`No Company Contacts Available!`} />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  contactFormStore: state.wizard.stepper.companies.contacts,
  companyInstance: state.companies.companyInstance,
  contact: state.contacts.contact,
  contacts: state.contacts.contacts,
  contactsLoading: state.contacts.loading,
  authenticatedUser: state.users.authenticatedUser,
  activeInstance: state.wizard.activeInstance
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setFormState: setWizardFormState(dispatch),
  setContact: initGetContact(dispatch),
  setContacts: initGetContacts(dispatch),
  dispatchResetContact: dispatchResetContact(dispatch),
  deleteContact: deleteCompanyContact(dispatch),
  updateContact: initUpdateContact(dispatch),
  addContact: initAddContact(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactsStep));
