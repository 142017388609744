import { number, object, string } from 'yup';
import msgs from '../../../core/yupSchemas/msgs';
import { phoneNumberValidation } from '../../../core/yupSchemas/validators';

const AddEditMainCompanySchema = object().shape({
  companyId: number(),
  companyName: string()
    .required(msgs.companyName)
    .notOneOf([''], msgs.companyName),
  address1: string(), //.required(msgs.address),
  address2: string(),
  city: string(), //.required(msgs.city),
  postalCode: string(), //.required(msgs.postalCode),
  /* State Province AutoComplete */
  stateProvinceCode: string(), // value, sent to server in request.
  stateProvince: string(),
  //  .required(msgs.stateProvince)
  //  .notOneOf([''], msgs.stateProvince), // label, validate label since this is what user sees.
  /* Country AutoComplete */
  isoCountryCode: string(), // value, sent to server in request.
  countryName: string(),
  //  .required(msgs.country)
  //  .notOneOf([''], msgs.country), // label, validate since this is what user sees.
  modifiedUserId: number(),
  modifiedDate: string(),
  axnStatus: string(), //.required(msgs.status),
  // phone: phoneNumberValidation(msgs.phone), //.required(msgs.phone),
  // fax: phoneNumberValidation(msgs.fax),
  url: string().url(msgs.website),
  companyInfoText: string()
});

export default AddEditMainCompanySchema;
