import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import StepHeader from '../../../StepHeader';
import FormField from '../../../../forms/FormField';
import { infoInitialValues } from './InfoStep';
import { AirportInfoChildForm } from './types';

const AirportRevenueForm = (props: AirportInfoChildForm & RouteComponentProps) => {
  const { match, values, handleBlur, handleChange } = props;
  return (
    <Col xl={4} lg={4} md={12} className="mb-3">
      <div>
        <StepHeader stepTitle="Airport Revenue" />
        <Container>
          <Row>
            <Col tag="fieldset" className="my-3">
              <legend>Passenger Services</legend>
              <Row className="py-3">
                <FormField
                  name="passengerSvcRevenueTotal"
                  label="Revenue Total"
                  type="number"
                  placeholder={infoInitialValues.passengerSvcRevenueTotal.toString()}
                  match={match}
                  value={values.passengerSvcRevenueTotal}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                />
                <FormField
                  name="passengerSvcRevenueAirportTotal"
                  label="Revenue to Airport"
                  type="number"
                  placeholder={infoInitialValues.passengerSvcRevenueAirportTotal.toString()}
                  match={match}
                  value={values.passengerSvcRevenueAirportTotal}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                  tooltipText={
                    'Refers to all non-retail or non-food services provided by the airport such as massage, ATMs, banking, business centers, barber shops, etc.'
                  }
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col tag="fieldset" className="my-3">
              <legend>Advertising</legend>
              <Row className="py-3">
                <FormField
                  name="advertisingRevenueTotal"
                  label="Revenue Total"
                  type="number"
                  placeholder={infoInitialValues.advertisingRevenueTotal.toString()}
                  match={match}
                  value={values.advertisingRevenueTotal}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                />
                <FormField
                  name="advertisingRevenueAirportTotal"
                  label="Revenue to Airport"
                  type="number"
                  placeholder={infoInitialValues.advertisingRevenueAirportTotal.toString()}
                  match={match}
                  value={values.advertisingRevenueAirportTotal}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                  tooltipText={
                    'Gross revenue and rent to the airport, where available, are provided. This category includes all revenue generated from advertising space at the airport that has been bought by companies paying for said space for the purpose of marketing their brand, including dioramas, wall space, banners, etc.'
                  }
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col tag="fieldset" className="my-3">
              <legend>Currency Exchange</legend>
              <Row className="py-3">
                <FormField
                  name="currencyExchangeRevenueTotal"
                  label="Revenue Total"
                  type="number"
                  placeholder={infoInitialValues.currencyExchangeRevenueTotal.toString()}
                  match={match}
                  value={values.currencyExchangeRevenueTotal}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                />
                <FormField
                  name="currencyExchangeRevenueAirportTotal"
                  label="Revenue to Airport"
                  type="number"
                  placeholder={infoInitialValues.currencyExchangeRevenueAirportTotal.toString()}
                  match={match}
                  value={values.currencyExchangeRevenueAirportTotal}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  col={{ lg: 12 }}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Col>
  );
};

export default withRouter(AirportRevenueForm);
