import { axnUnauthAPI } from './config';

export type LoginResponse = LoginSuccess | LoginError;

interface LoginSuccess {
  kind: 'Success';
  token: string;
}

interface LoginError {
  kind: 'Error';
  error: object;
  message: string;
}

const authApiUrl = `/authenticate`;

const fetchToken = async (username: string, password: string): Promise<LoginResponse> => {
  return axnUnauthAPI.post(authApiUrl, { username, password }).then(
    r => ({
      kind: 'Success',
      token: r.data.token
    }),
    error => ({
      error,
      kind: 'Error',
      message: `${error}`
    })
  );
};

export default fetchToken;

export const requestPasswordReset = (email: string) =>
  axnUnauthAPI.post(`${authApiUrl}/sendpasswordreset`, { email });

export const validateRestPasswordTokenFromAPI = (token: string) =>
  axnUnauthAPI.post(`${authApiUrl}/validateaccesstoken`, {
    accessToken: token
  });

export const resetPasswordFromAPI = (accessToken: string, userId: number, password: string) =>
  axnUnauthAPI.post(`${authApiUrl}/resetpassword`, {
    accessToken,
    userId,
    password
  });
