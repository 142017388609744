import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

type Direction = 'r' | 'l';

/* Rewrite of `react-reacstrap-pagination` as a functional component with typescript */
interface PaginationComponent {
  className: string;
  totalItems: number;
  pageSize: number;
  onSelect: (selectedPage: number) => void;
  maxPaginationNumbers: number;
  initActivePage: number;
}

const PaginationComponent = (props: PaginationComponent) => {
  const {
    initActivePage,
    totalItems,
    pageSize,
    maxPaginationNumbers,
    onSelect,
    className
  } = props;

  const pages = pageSize === 0 ? 0 : Math.ceil(totalItems / pageSize);

  const getFirstPageNum = (activePage: number) => {
    const distance = Math.floor(maxPaginationNumbers / 2);
    const newFPNumber = activePage - distance;
    const newLPNumber = activePage + distance;
    if (newFPNumber <= distance) {
      return 1;
    } else if (newLPNumber <= pages) {
      return newFPNumber;
    } else if (newLPNumber >= pages) {
      return pages - maxPaginationNumbers + 1;
    }
    return 1;
  };

  const firstPaginationNumber = getFirstPageNum(initActivePage);
  const lastPaginationNumber =
    firstPaginationNumber + Math.min(pages, maxPaginationNumbers) - 1;

  const numberedPagItem = (i: number) => (
    <PaginationItem
      key={i}
      id={`pagebutton${i}`}
      active={initActivePage === i}
      onClick={_ => onSelect(i)}>
      <PaginationLink style={{ minWidth: '43.5px' }}>{i}</PaginationLink>
    </PaginationItem>
  );

  const handleSelectNextOrPrevious = (direction: Direction) => {
    if (
      (direction === 'r' && initActivePage === pages) ||
      (direction === 'l' && initActivePage === 1)
    )
      return;

    const newActivePage =
      direction === 'r' ? initActivePage + 1 : initActivePage - 1;
    onSelect(newActivePage);
  };

  const firstOrLastPagItem = (name: string, page: number) => (
    <PaginationItem
      key={name}
      disabled={initActivePage === page}
      onClick={_ => onSelect(page)}>
      <PaginationLink>{name}</PaginationLink>
    </PaginationItem>
  );

  const nextOrPreviousPagItem = (
    name: string,
    page: number,
    direction: Direction
  ) => (
    <PaginationItem
      key={name}
      disabled={initActivePage === page}
      onClick={_ => handleSelectNextOrPrevious(direction)}>
      <PaginationLink>{name}</PaginationLink>
    </PaginationItem>
  );

  return (
    <Pagination className={className}>
      {firstOrLastPagItem('<<', 1)}
      {nextOrPreviousPagItem('<', 1, 'l')}
      {intRange(firstPaginationNumber, lastPaginationNumber).map(
        numberedPagItem
      )}
      {nextOrPreviousPagItem('>', pages, 'r')}
      {firstOrLastPagItem('>>', pages)}
    </Pagination>
  );
};

export default PaginationComponent;

// generates a range of integers from first to last inclusive.
// e.g. intRange(1, 5) === [1,2,3,4,5]
export const intRange = (first: number, last: number): number[] =>
  new Array<number>(last - first + 1).fill(first).map((n, index) => n + index);
