import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField from './FormField';
import { useAirportTenantTypes } from '../../api/Airports';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

/* TODO: Fix the any */
const CategorySelectField = (props: {
  values: any;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: any;
  handleBlur: any;
  col?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}) => {
  const { values, match, handleChange, handleBlur, setFieldValue, col } = props;
  const { tenantTypes, tenantTypesLoading } = useAirportTenantTypes();

  const separator = ` | `;
  const tenantTypesSelect = tenantTypes.flatMap(tenantType =>
    tenantType.productTypes.map(
      productType => `${tenantType.tenantType}${separator}${productType.productType}`
    )
  );
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();

  const [labelValue, setLabelValue] = useState(values.categoryName);
  const [value, setValue] = useState(values.category);

  useEffect(() => {
    const aclist: AutoCompleteOption[] = tenantTypesSelect
      .map(category => {
        return {
          label: category,
          key: category,
          value: category
        };
      })
      .sort();
    setAutoCompleteList(aclist);
  }, [tenantTypes]);

  useEffect(() => {
    const tenantType = tenantTypesSelect.find(tt => tt === values.category);
    if (tenantType) {
      setFieldValue('category', tenantType, false);
      setLabelValue(tenantType);
      setValue(tenantType);
    }
  }, [tenantTypes, values.category]);
  if (tenantTypesLoading) return <SelectFieldSpinner {...col} />;
  return (
    <FormField
      name="category"
      labelName="categoryName"
      label="Category"
      type="autocomplete"
      match={match}
      value={value}
      labelValue={labelValue}
      handleChange={handleChange}
      handleBlur={handleBlur}
      autoCompleteList={autoCompleteList}
      col={col || {}}
      setFieldValue={setFieldValue}
      browserAutoComplete={'off'}
    />
  );
};

CategorySelectField.defaultValues = {
  col: { xl: 12 }
};

export default CategorySelectField;
