import {
  faBuilding,
  faCalendarPlus,
  faCity,
  faFileAlt,
  faLink,
  faList,
  faPlane,
  faStoreAlt,
  faUsers,
  IconDefinition,
  faUniversity
} from '@fortawesome/free-solid-svg-icons';

export interface Feature {
  path: string;
  title: string;
  icon: IconDefinition;
  bullets: string[];
  children: {
    path: string;
    title: string;
    icon: IconDefinition;
    bullets: string[];
  }[];
}

export const manageFeature: Feature = {
  path: '/manage',
  title: 'Manage',
  icon: faList,
  bullets: ['Airports', 'Brands', 'Companies', 'Product Types', 'Users'],
  children: [
    {
      path: '/users',
      title: 'Users',
      icon: faUsers,
      bullets: ['Manage Users Information', 'Preview User Information', 'Send Password Resets']
    },
    {
      icon: faFileAlt,
      path: '/reports',
      title: 'Reports',
      bullets: ['Generate Report for All Published Airport/Company Instances']
    },
    {
      icon: faPlane,
      path: '/airports',
      title: 'Airports',
      bullets: ['Edit Airports Information', 'Add Airport Information']
    },
    {
      icon: faBuilding,
      path: '/companies',
      title: 'Companies',
      bullets: ['Edit Company Information', 'Add Company Information']
    },
    {
        icon: faUniversity,
        path: '/brands',
        title: 'Brands',
        bullets: [
            'Edit Brand Information', 
            'Add Brand Information'
        ]
    },
    {
      icon: faCalendarPlus,
      path: '/instances',
      title: 'Year Instances',
      bullets: [
        'Add AXN Instances',
        'Activate AXN Instances',
        'Publish All Airport/Company Instances'
      ]
    }
    /* TODO: Complete implementing Add/Edd & Output constraint errors for deletes. */
    // {
    //   path: '/product-types',
    //   title: 'Product Types',
    //   icon: faStoreAlt,
    //   bullets: ['View Product Types', 'Add Product Types', 'Delete Product Types']
    // }
  ]
};

export const affiliateFeature: Feature = {
  path: '/affiliate',
  title: 'Affiliate',
  icon: faLink,
  bullets: ['Affiliate Users with Airports', 'Affiliate Users with Companies'],
  children: [
    {
      icon: faPlane,
      path: '/airport',
      title: 'Affiliate Airports',
      bullets: ['Affiliate Users with Airports']
    },
    {
      icon: faCity,
      path: '/company',
      title: 'Affiliate Companies',
      bullets: ['Affiliate Users with Companies']
    }
  ]
};

export const panelFeatures: Feature[] = [manageFeature, affiliateFeature];
