import { find } from 'lodash';
import { AirportCommentType, AirportData } from '../../types';

export const extractAirportComment = (
  airport: AirportData,
  type: AirportCommentType
) => {
  const comment = find(
      airport.airportCommentList, 
      commentList => commentList.airportCommentPK.commentType.toUpperCase() === type.toUpperCase());
  return (comment && comment.airportComment) || '';
};
