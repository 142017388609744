import * as React from 'react';
import PaginationComponent from '../pagination/Pagination';

interface WizardPagination {
  totalItems: number;
  currentPage: number;
  handlePaginationSelection: (selectedPage: number) => void;
  pageSize: number;
  className?: string;
}

export const MasterListPagination = (props: WizardPagination) => {
  const {
    totalItems,
    currentPage,
    handlePaginationSelection,
    className,
    pageSize
  } = props;
  return (
    <PaginationComponent
      totalItems={totalItems}
      pageSize={pageSize}
      onSelect={handlePaginationSelection}
      maxPaginationNumbers={3}
      initActivePage={currentPage}
      className={`pagination ${className}`}
    />
  );
};

export default MasterListPagination;
