import { Dropdown, DropdownMenu, DropdownToggle, Navbar, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import LogoutItem from './LogoutItem';
import LoginItem from './LoginItem';
import { AuthoritiesList, AuthorityRoles, AuthorityRolesObject } from '../../actions/usersActions';

/* Be careful of recursion here */
interface NavLinkItem {
  path: string;
  text: string;
  roles: string[];
  children?: NavLinkItem[];
}

export type ToggleNavbar = (state: boolean) => void;

/* TODO: tighten down roles. */
const navItems = [
  {
    path: '/',
    text: 'Home',
    roles: ['*']
  },
  {
    path: '/about',
    text: 'About Us',
    roles: ['*'],
    children: [
      { path: '/glossary', text: 'Glossary', roles: ['*'] },
      { path: '/contact-us', text: 'Contact Us', roles: ['*'] },
      { path: '/faqs', text: 'FAQS', roles: ['*'] }
    ]
  },
  {
    path: '/leaseexpiration',
    text: 'Lease Exp',
    roles: ['ROLE_SUBSCRIBER', 'ROLE_AIRPORT_SUB', 'ROLE_COMPANY_SUB', 'ROLE_ADMIN']
  },
  {
    path: '/contacts',
    text: 'Contacts',
    roles: ['ROLE_SUBSCRIBER', 'ROLE_AIRPORT_SUB', 'ROLE_COMPANY_SUB', 'ROLE_ADMIN']
  },
  {
    path: '/airports',
    text: 'Airports',
    roles: ['ROLE_SUBSCRIBER', 'ROLE_AIRPORT_SUB', 'ROLE_COMPANY_SUB', 'ROLE_ADMIN']
  },
  {
    path: '/companies',
    text: 'Companies',
    roles: ['ROLE_SUBSCRIBER', 'ROLE_AIRPORT_SUB', 'ROLE_COMPANY_SUB', 'ROLE_ADMIN']
  },
  {
    path: '/tenants',
    text: 'Tenants',
    roles: ['ROLE_SUBSCRIBER', 'ROLE_AIRPORT_SUB', 'ROLE_COMPANY_SUB', 'ROLE_ADMIN']
  },
  {
    path: '/wizard',
    text: 'Modify Data',
    description: 'Show Affiliated Airports & Companies',
    roles: ['ROLE_ADMIN']
    /* Can temorarily allow all users to see this page, but remember to remove once the book is live */
    /*, 'ROLE_COMPANY', 'ROLE_COMPANY_SUB', 'ROLE_AIRPORT', 'ROLE_AIRPORT_SUB']*/
  },
  {
    path: '/reports',
    text: 'Reports',
    roles: ['ROLE_SUBSCRIBER', 'ROLE_AIRPORT_SUB', 'ROLE_COMPANY_SUB', 'ROLE_ADMIN']
  }
];

const assessRoles = (item: NavLinkItem, authorities: AuthoritiesList) => {
  return item.roles.some(
    role =>
      (authorities.length > 0 &&
        authorities
          .flatMap((a: AuthorityRolesObject) => a.authority)
          .includes(role as AuthorityRoles)) ||
      role === '*'
  );
};

const SingleNavItem = (props: {
  item: NavLinkItem;
  className?: string;
  toggleNavbar?: ToggleNavbar;
}) => {
  const { item, className, toggleNavbar } = props;
  return (
    <NavItem className={className}>
      <Link
        to={item.path}
        onClick={() => {
          if (toggleNavbar) toggleNavbar(false);
        }}>
        {item.text}
      </Link>
    </NavItem>
  );
};

const ParentNavItem = (props: {
  item: NavLinkItem;
  authorities: AuthoritiesList;
  toggleNavbar?: ToggleNavbar;
}) => {
  const { item, authorities, toggleNavbar } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown tag="li" isOpen={dropdownOpen} toggle={toggle} className="nav-item">
      <DropdownToggle tag="div" caret className="btn-dropdown">
        {item.text}
      </DropdownToggle>
      <DropdownMenu>
        <Navbar>
          {item.children &&
            item.children.length > 0 &&
            item.children
              .filter(items => assessRoles(items, authorities))
              .map(navItem => {
                const itemWithChildPaths = {
                  ...navItem,
                  path: `${item.path}${navItem.path}`
                };
                return (
                  <SingleNavItem
                    item={itemWithChildPaths}
                    key={window.btoa(navItem.text)}
                    className="dropdown-item"
                    toggleNavbar={toggleNavbar}
                  />
                );
              })}
        </Navbar>
      </DropdownMenu>
    </Dropdown>
  );
};

/* Entry point to contents of this file. */
const getNavLinks = (
  isLoggedIn: boolean,
  authorities: AuthoritiesList,
  toggleNavbar?: (state: boolean) => void
) => {
  const authLink = isLoggedIn ? (
    <LogoutItem key="logout" toggleNavbar={toggleNavbar} />
  ) : (
    <LoginItem key="login" toggleNavbar={toggleNavbar} />
  );
  const navItemComponents = navItems
    .filter(items => assessRoles(items, authorities))
    .map(item => {
      return item.children ? (
        <ParentNavItem
          item={item}
          key={window.btoa(item.text)}
          authorities={authorities}
          toggleNavbar={toggleNavbar}
        />
      ) : (
        <SingleNavItem item={item} key={window.btoa(item.text)} toggleNavbar={toggleNavbar} />
      );
    });
  return [...navItemComponents, authLink];
};

export { navItems, getNavLinks };
