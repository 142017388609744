import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

interface TitleButtonProps {
  children: JSX.Element[] | JSX.Element | string;
}

export const TitleButton = ({ children }: TitleButtonProps) => (
  <ButtonGroup>
    <Button
      color="primary"
      style={{
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px'
      }}>
      <h3 className="my-1 text-light">{children}</h3>
    </Button>
  </ButtonGroup>
);
