import _ from 'lodash';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { axnAPI } from './config';
import { UserData } from '../actions/usersActions';
import { AirportData } from '../types';
import useDataModel, { convertSpringPageable, emptyPage, Page, SpringPage } from '.';
import { CompanyData } from '../actions/companiesActions';
import { AffiliateObjectType } from '../components/admin/affiliations/AffiliateToObjectForm';

interface RawAPIResponse {
  message: string;
}

export type AffiliationResponse = Success | Failure;

interface Success {
  kind: 'Success';
  message: string;
}

interface Failure {
  kind: 'Failure';
  error: string;
}

const onSuccess = (response: AxiosResponse<RawAPIResponse>): Success => ({
  kind: 'Success',
  message: response.data.message
});

const onFailure = (reason: any): Failure => ({
  kind: 'Failure',
  error: `${reason}`
});

// Affiliates a user with an airport
const addAirportAffiliation = (
  user: UserData,
  airportId: number,
  instanceId: number
): Promise<AffiliationResponse> =>
  axnAPI
    .post('/affiliatedairports', {
      airportId,
      axnInstanceId: instanceId,
      userId: user.userId
    })
    .then(onSuccess, onFailure);

// Affiliates a user with a company
const addCompanyAffiliation = (
  user: UserData,
  companyId: number,
  instanceId: number
): Promise<AffiliationResponse> =>
  axnAPI
    .post('/affiliatedcompanies', {
      companyId,
      axnInstanceId: instanceId,
      userId: user.userId
    })
    .then(onSuccess, onFailure);

// returns the handler for adding an affiliation based off the object type
export const addAffliation = (objectType: AffiliateObjectType) => {
  if (objectType === 'Airport') return addAirportAffiliation;
  return addCompanyAffiliation;
};

// Removes the affiliation for a user and an airport
export const deleteAirportAffiliation = (
  userId: number,
  airportId: number,
  instanceId: number
): Promise<AffiliationResponse> =>
  axnAPI
    .delete(`/affiliatedairports/user/${userId}/instance/${instanceId}/airport/${airportId}`)
    .then(onSuccess, onFailure);

// Removes the affiliation for a user and a company
export const deleteCompanyAffiliation = (
  userId: number,
  companyId: number,
  instanceId: number
): Promise<AffiliationResponse> =>
  axnAPI
    .delete(`/affiliatedcompanies/user/${userId}/instance/${instanceId}/company/${companyId}`)
    .then(onSuccess, onFailure);

// fetches the url and handles any errors
export const safeGet = <T>(url: string, config: AxiosRequestConfig): Promise<Page<T>> =>
  axnAPI.get(url, config).then(
    response => convertSpringPageable(response.data),
    reason => {
      if (!axios.isCancel(reason)) {
        toast.error(`Unable to fetch results: ${reason}`);
        return emptyPage<T>();
      }
      throw reason;
    }
  );

// gets all the airports affiliated with a user
const getAffiliatedAirports = (user: UserData, instanceId: number) => (
  currentPage: number,
  pageSize: number
): Promise<Page<AirportData>> =>
  safeGet(`/affiliatedairports/user/${user.userId}/instance/${instanceId}`, {
    params: {
      size: pageSize - 1 /* zero-indexed */,
      page: currentPage
    }
  });

interface UserAffilitationWithAirportAndInstance {
  securityRoleId: number;
  airportId: number;
  instanceId: number;
}

// /user/{userId}/instance/{axnInstanceId}/airport/{airportId}
export const getAirportAffiliationWithAirportAndInstance = (
  userId: number,
  instanceId: number,
  airportId: number
): Promise<UserAffilitationWithAirportAndInstance> => {
  return axnAPI
    .get(`/affiliatedairports/user/${userId}/instance/${instanceId}/airport/${airportId}`)
    .then(response => response.data as UserAffilitationWithAirportAndInstance);
};

// gets all the companies affiliated with a user
const getAffiliatedCompanies = (user: UserData, instanceId: number) => (
  currentPage: number,
  pageSize: number
): Promise<Page<CompanyData>> =>
  safeGet(`/affiliatedcompanies/user/${user.userId}/instance/${instanceId}`, {
    params: {
      size: pageSize - 1 /* zero-indexed */,
      page: currentPage
    }
  });

const emptyWithPageSize = <T>(pageSize: number): Page<T> =>
  _.set(emptyPage<T>(), 'pageSize', pageSize);

// hook to use all airports affiliated with a user
export const useAffiliatedAirports = (user: UserData, instanceId: number) =>
  useDataModel(
    getAffiliatedAirports(user, instanceId),
    emptyWithPageSize(10000),
    user.userId,
    instanceId
  );

// hook to use all companies affiliated with a user
export const useAffiliatedCompanies = (user: UserData, instanceId: number) =>
  useDataModel(
    getAffiliatedCompanies(user, instanceId),
    emptyWithPageSize(10000),
    user.userId,
    instanceId
  );

export interface AffiliatedUserCompanyDTO {
  securityRole: {
    securityRoleName: string;
  };
  user: {
    userId: number;
    firstName: string;
    lastName: string;
  };
  company: {
    companyId: number;
    companyName: string;
  };
  axnInstanceId: number;
}

export interface AffiliatedUserCompany {
  companyId: number;
  displayName: string;
  companyName: string;
  securityRole: string;
  axnInstanceId: number;
  userId: number;
  [key: number]: string;
}

const getAffiliatedCompaniesForInstance = (keyword: string, instanceId: number) => (
  currentPage: number,
  pageSize: number
): Promise<Page<AffiliatedUserCompanyDTO>> =>
  axnAPI
    .post(`/affiliatedcompanies/instance/${instanceId}/paged/search`, null, {
      params: {
        size: pageSize,
        page: currentPage,
        keyword
      }
    })
    .then(resp => resp.data as SpringPage<AffiliatedUserCompanyDTO>)
    .then(convertSpringPageable);

export const useAffiliatedCompaniesForInstance = (
  status: string,
  keyword: string,
  instanceId: number
) =>
  useDataModel(
    getAffiliatedCompaniesForInstance(keyword, instanceId),
    emptyPage(),
    status,
    keyword,
    instanceId
  );

export interface AffiliatedUserAirportDTO {
  securityRole: {
    securityRoleName: string;
  };
  user: {
    userId: number;
    firstName: string;
    lastName: string;
  };
  airport: {
    airportId: number;
    iataCode: string;
    airportName: string;
  };
  axnInstanceId: number;
}

export interface AffiliatedUserAirport {
  airportId: number;
  displayName: string;
  iataCode: string;
  airportName: string;
  securityRole: string;
  axnInstanceId: number;
  userId: number;
  [key: number]: string;
}

const getAffiliatedAirportsForInstance = (keyword: string, instanceId: number) => (
  currentPage: number,
  pageSize: number
): Promise<Page<AffiliatedUserAirportDTO>> =>
  axnAPI
    .post(`/affiliatedairports/instance/${instanceId}/paged/search`, null, {
      params: {
        size: pageSize,
        page: currentPage,
        keyword
      }
    })
    .then(resp => resp.data as SpringPage<AffiliatedUserAirportDTO>)
    .then(convertSpringPageable);

export const useAffiliatedAirportsForInstance = (
  status: string,
  keyword: string,
  instanceId: number
) =>
  useDataModel(
    getAffiliatedAirportsForInstance(keyword, instanceId),
    emptyPage(),
    status,
    keyword,
    instanceId
  );
