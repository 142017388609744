import { object, ref, string } from 'yup';

export const LoginFormValidation = object().shape({});
export const ForgotPasswordValidation = object().shape({});

const ResetPasswordSchema = object().shape({
  password: string().required('Password is required'),
  retypePassword: string()
    .oneOf([ref('password'), null], "Password does not match")
    .required('Password confirm is required')
});

export default ResetPasswordSchema;
