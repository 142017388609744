import { lazy, object, string } from 'yup';
import msgs from '../../../../../core/yupSchemas/msgs';

//const mapRules = (map: any, rule: any) =>
//  Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {});

export const companyInfoValidationSchema = object().shape({
  companyInfoText: string()
    .required(msgs.companyInfoText)
    .typeError(msgs.companyInfoText),
    /*
  companyTypeInfoText: lazy(map =>
    object(
      mapRules(
        map,
        string()
          .required(msgs.companyInfoText)
          .typeError(msgs.companyInfoText)
      )
    )
  )*/
});
