import { number, object, string } from 'yup';
import msgs from '../../../../../core/yupSchemas/msgs';

export const CompanyAirportLocationSchema = object().shape({
  companyTypeDes: string()
    .required(msgs.companyType)
    .notOneOf([''], msgs.companyType),
  locationLabel: string()
    .required(msgs.airportName)
    .notOneOf([''], msgs.airportName)
});
