declare global {
  interface Window {
    dataLayer: {
      push: any;
    };
  }
}

/* This implements the Middleware pattern for Redux  */
export const analyticActions = () => (next: any) => (action: {
  type: string;
  payload: any;
}) => {
  const dataLayer = (window.dataLayer = window.dataLayer || []);

  dataLayer.push({
    event: action.type,
    payload: action.payload
  });
  return next(action);
};
