import {
  ProductTypeData,
  ProductTypeIDData,
  ProductTypesAction,
  ProductTypesList
} from '../actions/productTypeActions';

/* This Reducer setup is kind of wordy but it does offer type safe actions with a ton of IDE auto complete capabilities. */
export interface ProductTypeState {
  productTypes: ProductTypesList;
  productType: ProductTypeData;
  loading: boolean;
  error: string;
}

/* Set Initial ProductType State */
export const initialProductTypeState = {
  productTypes: Array<ProductTypeData>(),
  productType: {
    productType: '',
    productTypeDescription: ''
  },
  loading: false,
  error: ''
};

export type ProductTypesState = typeof initialProductTypeState;

export const productTypesReducer = (
  state: ProductTypeState = initialProductTypeState,
  action: ProductTypesAction
) => {
  switch (action.type) {
    case 'GET_PRODUCT_TYPE':
    case 'GET_PRODUCT_TYPES':
    case 'UPDATE_ADD_PRODUCT_TYPE':
    case 'DELETE_PRODUCT_TYPE':
      return { ...state, isLoading: true };
    case 'GET_PRODUCT_TYPE_SUCCESS':
    case 'UPDATE_ADD_PRODUCT_TYPE_SUCCESS':
      return { ...state, isLoading: false, productType: action.productType };
    case 'GET_PRODUCT_TYPES_SUCCESS':
      return { ...state, isLoading: false, productTypes: action.productTypes };
    case 'DELETE_PRODUCT_TYPE_SUCCESS':
      return {
        ...state,
        productTypes: state.productTypes.filter(
          data => (data as ProductTypeIDData) !== action.productTypeID
        ),
        isLoading: false
      };
    case 'GET_PRODUCT_TYPE_FAILURE':
    case 'GET_PRODUCT_TYPES_FAILURE':
    case 'DELETE_PRODUCT_TYPE_FAILURE':
    case 'UPDATE_ADD_PRODUCT_TYPE_FAILURE':
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
