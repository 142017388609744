import React, { Fragment } from 'react';
import { find } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup, Col, Form, Row } from 'reactstrap';
import { ContactData, contactPhoneList } from '../../../../../actions/contactsActions';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import FormField from '../../../../forms/FormField';
import { PhoneNumber } from '../../../../../types';
import IsoCountrySelectField from '../../../../forms/IsoCountrySelectField';
import StateProvinceSelectField from '../../../../forms/StateProvincesSelectField';
import CompanyTypeSelectField from '../../../../forms/CompanyTypeSelectField';

interface ContactsFormProps {
  handleBlur: (...props: any) => void;
  handleChange: (...props: any) => void;
  handleReset: (...props: any) => void;
  handleSubmit: (...props: any) => void;
  isUpdatingContact: boolean;
  match: any;
  values: ContactData;
  companyId: number;
  axnInstanceId: number;
  setFieldValue: (...props: any) => void;
  contact?: ContactData;
}

const ContactsForm = (props: ContactsFormProps) => {
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    isUpdatingContact,
    match,
    values,
    setFieldValue,
    companyId,
    axnInstanceId
  } = props;

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset}>
      <div className={cssClass}>
        <StepHeader stepTitle={'Company Contacts'} />
        <Row>
          <Col>
            <Row>
              <Col xl lg>
                <Row>
                  <FormField
                    name={'modifiedUserId'}
                    label={''}
                    type={'text'}
                    match={match}
                    value={values.modifiedUserId}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ lg: 12 }}
                    hidden
                    disabled
                    readOnly
                  />
                  <FormField
                    name={'axnStatus'}
                    label={''}
                    type={'text'}
                    match={match}
                    value={values.axnStatus}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ lg: 12 }}
                    hidden
                    disabled
                    readOnly
                  />
                  <CompanyTypeSelectField
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    match={match}
                    setFieldValue={setFieldValue}
                    label={'Associated Company Type'}
                    companyId={companyId}
                    axnInstanceId={axnInstanceId}
                    all={false}
                  />
                  <FormField
                    name={'firstName'}
                    label={'First Name'}
                    type={'text'}
                    match={match}
                    value={values.firstName || ""}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ lg: 12 }}
                  />
                  <FormField
                    name={'lastName'}
                    label={'Last Name'}
                    type={'text'}
                    match={match}
                    value={values.lastName || ""}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ lg: 12 }}
                  />
                  <FormField
                    name={'title'}
                    label={'Title'}
                    type={'text'}
                    match={match}
                    value={values.title || ""}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ lg: 12 }}
                  />
                  <FormField
                    name={'factbookIncludeFlag'}
                    label={'To Be Printed'}
                    type={'checkbox'}
                    match={match}
                    value={values.factbookIncludeFlag}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ lg: 12 }}
                  />
                </Row>
              </Col>
              <Col xl lg>
                <Row>
                  <FormField
                    name={'address1'}
                    label={'Address 1'}
                    type={'text'}
                    match={match}
                    value={values.address1 || ""}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ lg: 12 }}
                  />
                  <FormField
                    name={'address2'}
                    label={'Address 2'}
                    type={'text'}
                    match={match}
                    value={values.address2 || ""}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ lg: 12 }}
                  />
                  <FormField
                    name={'city'}
                    label={'City'}
                    type={'text'}
                    match={match}
                    value={values.city || ""}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ lg: 12 }}
                  />
                  <StateProvinceSelectField
                    match={match}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    values={values}
                  />
                  <FormField
                    name={'postalCode'}
                    label={'Zip Code'}
                    type={'text'}
                    match={match}
                    value={values.postalCode || ""}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ lg: 12 }}
                  />
                  <IsoCountrySelectField
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    match={match}
                    displayRequired={false}
                  />
                </Row>
              </Col>
              <Col xl lg>
                <Row>
                  {/* Work, Fax */}
                  {contactPhoneList.map((initPhoneNumber, key) => {
                    const currentNumber = find(
                      contactPhoneList,
                      (phoneNumber: PhoneNumber) =>
                        phoneNumber.phoneType === initPhoneNumber.phoneType
                    ) as PhoneNumber;

                    const { phoneType } = currentNumber;

                    return (
                      <Fragment key={key}>
                        <FormField
                          name={`${phoneType.toLowerCase()}Phone`}
                          label={`${phoneType} Number`}
                          type={'text'}
                          match={match}
                          value={(phoneType === 'Work' ? values.workPhone : values.faxPhone) || ''}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          col={{ lg: 8 }}
                        />
                        <FormField
                          name={`${phoneType.toLowerCase()}Ext`}
                          label={`${phoneType} Ext`}
                          type={'text'}
                          match={match}
                          value={(phoneType === 'Work' ? values.workExt : values.faxExt) || ''}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          col={{ lg: 4 }}
                        />
                      </Fragment>
                    );
                  })}
                  <FormField
                    name={'email'}
                    label={'Email'}
                    type={'email'}
                    match={match}
                    value={values.email || ""}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ lg: 12 }}
                  />
                  <FormField
                    name={'url'}
                    label={'Website'}
                    type={'text'}
                    match={match}
                    value={values.url || ""}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ lg: 12 }}
                  />
                  <FormField
                    name={'orderSequenceNumber'}
                    label={'Display Order'}
                    type={'number'}
                    match={match}
                    value={values.orderSequenceNumber || 0}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={{ xl: 12 }}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={'pt-5'}>
          <Col>
            <ButtonGroup>
              <Button color={'primary'} type={'submit'} onClick={handleSubmit}>
                {isUpdatingContact ? 'Update Contact' : 'Add Contact'}{' '}
                <FontAwesomeIcon icon={faUserAlt} />
              </Button>
              <Button color={'secondary'} type={'reset'}>
                Reset <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default ContactsForm;
