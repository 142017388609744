import { Dispatch } from 'redux';
import { find } from 'lodash';
import { toast } from 'react-toastify';
import { PhoneNumber, YesOrNo } from '../types';
import { AllowedWizTypes } from '../components/wizard/types';
import {
  addAirportContactFromAPI,
  addCompanyContactFromAPI,
  deleteAirportContactFromAPI,
  deleteCompanyContactFromAPI,
  getAirportContactFromAPI,
  getAirportContactsFromAPI,
  getCompanyContactFromAPI,
  getCompanyContactsFromAPI,
  updateAirportContactFromAPI,
  updateCompanyContactFromAPI
} from '../api/contacts';
import { getAuthUserID } from '../core/authentication/authenticationHelper';

/* Shape of phone number for forms, since there will only ever been one fax and phone number according to Barb */
export const phoneNumbersForForms = {
  workPhone: '',
  workExt: '',
  faxPhone: '',
  faxExt: ''
};

export const initialContactFormValues = {
  contactId: 0,
  managementResponsibilityTypeId: 0, // Airport Only
  managementResponsibilityType: '', // Airport Only
  companyType: '', // Company Only
  companyTypeDes: '', // Company Only
  firstName: '',
  lastName: '',
  title: '',
  companyId: 0,
  companyName: '',
  address1: '',
  address2: '',
  city: '',
  stateProvinceCode: '',
  stateProvince: '',
  postalCode: '',
  isoCountryCode: '',
  countryName: '',
  ...phoneNumbersForForms,
  email: '',
  url: '',
  modifiedUserId: getAuthUserID(),
  axnStatus: 'ACTIVE',
  orderSequenceNumber: 0
};

/* but is stored as boolean in the formik form to check the checkbox */
export const initialContactFormValuesWithFlag = {
  ...initialContactFormValues,
  factbookIncludeFlag: true
};

/* For API */
export const contactPhoneList = [
  {
    phone: '',
    extPhone: '',
    phoneType: 'Work'
  },
  {
    phone: '',
    extPhone: '',
    phoneType: 'Fax'
  }
];

export type ContactsContactData = {
  airportId: number;
  axnInstanceId: number;
  contact: ContactData;
  contactId: number;
  managementResponsibilityTypeId: number;
  orderSequenceNumber: number;
  factbookIncludeFlag: YesOrNo;
};

export type ContactData = typeof initialContactFormValues & {
  [key: string]: any; // not sure how to lock this down to a more strict type
};

export type ContactsList = ContactData[];

export type ContactsActions =
  | { type: 'RESET_CONTACT' }
  | { type: 'GET_CONTACTS' }
  | { type: 'GET_CONTACTS_SUCCESS'; contacts: ContactsList }
  | { type: 'GET_CONTACTS_FAILURE'; error: string }
  | { type: 'GET_CONTACT' }
  | { type: 'GET_CONTACT_SUCCESS'; contact: ContactData }
  | { type: 'GET_CONTACT_FAILURE'; error: string }
  | { type: 'DELETE_CONTACT' }
  | { type: 'DELETE_CONTACT_SUCCESS'; contactID: number }
  | { type: 'DELETE_CONTACT_FAILURE'; error: string }
  | { type: 'ADD_CONTACT' }
  | {
      type: 'ADD_CONTACT_SUCCESS';
      contactsContact: ContactsContactData;
    }
  | { type: 'ADD_CONTACT_FAILURE'; error: string }
  | { type: 'UPDATE_CONTACT' }
  | {
      type: 'UPDATE_CONTACT_SUCCESS';
      airportID: number;
      instanceID: number;
      contact: ContactData;
    }
  | { type: 'UPDATE_CONTACT_FAILURE'; error: string };

export type contactsActionsCreator = (...props: any) => ContactsActions;

/* Contacts Action Creators */

/* Reset Contact Form */
export const resetContact: contactsActionsCreator = () => ({
  type: 'RESET_CONTACT'
});

/* Get Contacts - Plural */
export const getContacts: contactsActionsCreator = () => ({
  type: 'GET_CONTACTS'
});
export const getContactsSuccess: contactsActionsCreator = (contacts: ContactsList) => ({
  type: 'GET_CONTACTS_SUCCESS',
  contacts
});
export const getContactsFailure: contactsActionsCreator = (error: string) => ({
  type: 'GET_CONTACTS_FAILURE',
  error
});

/* Get Contact - Singular */
export const getContact: contactsActionsCreator = () => ({
  type: 'GET_CONTACT'
});
export const getContactSuccess: contactsActionsCreator = (contact: ContactData) => ({
  type: 'GET_CONTACT_SUCCESS',
  contact
});
export const getContactFailure: contactsActionsCreator = (error: string) => ({
  type: 'GET_CONTACT_FAILURE',
  error
});

/* Add Contact */
export const addContact: contactsActionsCreator = (contact: ContactData) => ({
  type: 'ADD_CONTACT'
});
export const addContactSuccess: contactsActionsCreator = (
  contactsContact: ContactsContactData
) => ({
  type: 'ADD_CONTACT_SUCCESS',
  contactsContact
});
export const addContactFailure: contactsActionsCreator = (error: string) => ({
  type: 'ADD_CONTACT_FAILURE',
  error
});

/* Update Contact */
export const updateContact: contactsActionsCreator = () => ({
  type: 'UPDATE_CONTACT'
});
export const updateContactSuccess: contactsActionsCreator = (
  airportID: number,
  contact: ContactData,
  instanceID: number
) => ({
  type: 'UPDATE_CONTACT_SUCCESS',
  contact,
  airportID,
  instanceID
});
export const updateContactFailure: contactsActionsCreator = (error: string) => ({
  type: 'UPDATE_CONTACT_FAILURE',
  error
});

/* Delete Contact */
export const deleteContact: contactsActionsCreator = () => ({
  type: 'DELETE_CONTACT'
});
export const deleteContactSuccess: contactsActionsCreator = (contactID: number) => ({
  type: 'DELETE_CONTACT_SUCCESS',
  contactID
});
export const deleteContactFailure: contactsActionsCreator = (error: string) => ({
  type: 'DELETE_CONTACT_FAILURE',
  error
});

/* functions that utilize action creators - you can connect these to container props using mapDispatchToProps */

/* Used to reset the Contact Form */
export type DispatchResetContact = () => void;
export const dispatchResetContact = (dispatch: Dispatch) => () => {
  dispatch(resetContact());
};

/* Used to get the airports contacts (plural) based on airport id. */
export type InitGetContacts = (id: string, type: AllowedWizTypes, axnInstanceId: number) => void;
export const initGetContacts = (dispatch: Dispatch) => (
  id: string,
  type: AllowedWizTypes,
  axnInstanceId: number
) => {
  const contactsAPIs = {
    airports: getAirportContactsFromAPI,
    companies: getCompanyContactsFromAPI
  };
  dispatch(getContacts());
  contactsAPIs[type](id, axnInstanceId)
    .then(response => {
      dispatch(getContactsSuccess(response.data));
    })
    .catch(e => {
      dispatch(getContactFailure('something is wrong with the request of company contacts'));
    });
};

/* Used to get a specific airport contact (singular) based on contact id and airport id. */
export type InitGetContact = (
  contentID: string,
  contactID: string,
  contentType: AllowedWizTypes,
  companyType: string,
  companyTypeDes: string,
  axnInstanceId: number
) => void;
export const initGetContact = (dispatch: Dispatch) => (
  contentID: string,
  contactID: string,
  contentType: AllowedWizTypes,
  companyType: string,
  companyTypeDes: string,
  axnInstanceId: number
) => {
  dispatch(getContact());
  const contactAPIs = {
    airports: getAirportContactFromAPI,
    companies: getCompanyContactFromAPI
  };

  contactAPIs[contentType](contentID, contactID, companyType, axnInstanceId)
    .then(response => {
      /* Airport returns an array of 1 single contact */
      /* Company returns a contacts object. :( */
      const contactMap = {
        airports: (response.data.content && response.data.content[0]) || {},
        companies: response.data
      };

      const props = contactMap[contentType];
      const { contact, orderSequenceNumber } = props;

      const managementResponsibilityTypeId = props.managementResponsibilityTypeId || 0;

      const extractWorkNumber = find(
        contact.contactPhoneList,
        (ph: PhoneNumber) => ph.phoneType === 'Work'
      ) as PhoneNumber;

      const extractFaxNumber = find(
        contact.contactPhoneList,
        (ph: PhoneNumber) => ph.phoneType === 'Fax'
      ) as PhoneNumber;

      const sendContact = { ...contact };
      delete sendContact.contactPhoneList;

      const contactFormValues = {
        ...sendContact,
        managementResponsibilityTypeId,
        factbookIncludeFlag: props.factbookIncludeFlag,
        workPhone: extractWorkNumber ? extractWorkNumber.phone : null,
        workExt: extractWorkNumber ? extractWorkNumber.extPhone : null,
        faxPhone: extractFaxNumber ? extractFaxNumber.phone : null,
        faxExt: extractFaxNumber ? extractFaxNumber.extPhone : null,
        orderSequenceNumber,
        companyType,
        companyTypeDes
      };

      dispatch(getContactSuccess(contactFormValues));
    })
    .catch(e => {
      dispatch(
        getContactFailure(`Failed to get the Airport Contact with the following error: ${e}`)
      );
    });
};

/* Add Airport and Company Contact Method */
export type InitAddContact = (
  aid: string,
  contact: ContactData,
  type: AllowedWizTypes,
  instanceID: number
) => void;
export const initAddContact = (dispatch: Dispatch) => (
  aid: string,
  contact: ContactData,
  type: AllowedWizTypes,
  instanceID: number
) => {
  dispatch(addContact());

  const apiCalls = {
    airports: addAirportContactFromAPI,
    companies: addCompanyContactFromAPI
  };

  const apiCall = apiCalls[type];

  const sendContact = { ...contact };
  delete sendContact.contactId;

  apiCall(aid, sendContact, instanceID)
    .then(() => {
      dispatch(addContactSuccess(aid, instanceID, contact));
      dispatch(resetContact());
      toast.success(
        `Successfully added contact information for ${contact.firstName} ${contact.lastName}!`
      );
    })
    .catch(e => {
      dispatch(addContactFailure(e));
      toast.error('Failed to add contact');
    });
};

/* Update Airport and Company Contacts Methods */
export type InitUpdateContact = (
  aid: string,
  cid: string,
  mrt: number,
  companyType: string,
  prevCompanyType: string,
  contact: ContactData,
  type: AllowedWizTypes,
  instanceId: number
) => void;
export const initUpdateContact = (dispatch: Dispatch) => (
  contentID: string,
  contactID: string,
  mrt: number,
  companyType: string,
  prevCompanyType: string,
  contact: ContactData,
  type: AllowedWizTypes,
  instanceId: number
) => {
  /* Assign API endpoint to use based on type */
  const contactAPIs = {
    airports: updateAirportContactFromAPI,
    companies: updateCompanyContactFromAPI
  };
  dispatch(updateContact());

  contactAPIs[type](contact, contentID, contactID, mrt, companyType, prevCompanyType, instanceId)
    .then(() => {
      dispatch(updateContactSuccess(contentID, contact, instanceId));
      toast.success(
        `Successfully updated contact information for ${contact.firstName} ${contact.lastName}!`
      );
    })
    .catch(e => {
      dispatch(updateContactFailure(e));
      toast.error(`Error updating contact: ${e}`);
    });
};

/* Delete Airport and Company Contacts Methods - TODO: refactors to follow DRY */
export type DeleteContact = (
  cid: string,
  uid: string,
  mrt: number,
  contactName: string,
  companyType: string,
  instanceId: number
) => void;
export const deleteAirportContact = (dispatch: Dispatch) => (
  contentID: string,
  contactID: string,
  managementResponsibilityType: number,
  contactName: string,
  companyType: string,
  instanceId: number
) => {
  dispatch(deleteContact());
  deleteAirportContactFromAPI(contentID, contactID, managementResponsibilityType, instanceId)
    .then(() => {
      dispatch(deleteContactSuccess(contactID));
      toast.success(`Successfully deleted contact information for ${contactName}!`);
      dispatch(resetContact());
    })
    .catch(e => {
      dispatch(deleteContactFailure(e));
      toast.error(`Failed to delete contact information for ${contactName}!`);
      dispatch(resetContact());
    });
};
export const deleteCompanyContact = (dispatch: Dispatch) => (
  contentID: string,
  contactID: string,
  managementResponsibilityType: number,
  contactName: string,
  companyType: string,
  instanceId: number
) => {
  dispatch(deleteContact());
  deleteCompanyContactFromAPI(contentID, contactID, companyType, instanceId)
    .then(() => {
      dispatch(deleteContactSuccess(contactID));
      toast.success(`Successfully deleted contact information for ${contactName}!`);
      dispatch(resetContact());
    })
    .catch(e => {
      dispatch(deleteContactFailure(e));
      toast.error(`Failed to delete contact information for ${contactName}!`);
      dispatch(resetContact());
    });
};
