import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Form, Formik, FormikErrors, FormikValues } from 'formik';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserEdit, faUserMinus, faUserPlus, faKey } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
    createUserFromAPI,
    deleteUserByIDFromAPI,
    getCurrentUser,
    getUser,
    updateCurrentUser,
    updateUserByIDFromAPI
} from '../../api/Users';
import AddEditUserSchema from '../admin/users/AddEditUserSchema';
import Spinner from '../shared/spinner/Spinner';
import FormField from '../forms/FormField';
import StateProvinceSelectField from '../forms/StateProvincesSelectField';
import CompanySelectField from '../forms/CompanySelectField';
import IsoCountrySelectField from '../forms/IsoCountrySelectField';
import SecurityRoleSelect from '../forms/SecurityRoleSelect';
import { PhoneNumberFromAPI } from '../../types';
import { cssClass } from '../wizard/cssSharedClasses';
import { appHistory, AppState } from '../../reducers';
import emptyUser from '../admin/users/emptyUser';
import {
    AuthenticatedUser,
    submitLogout,
    UserData,
    UserDataWithPhones
} from '../../actions/usersActions';
import JobCategorySelectField from '../forms/JobCategorySelectField';
import AddEntityButton from '../admin/AddEntityButton';
import GoBack from '../buttons/GoBack';
import Confirm from '../admin/users/Confirm';
import { string } from 'yup';
import { requestPasswordReset } from '../../api/authentication';

interface AddEditUserFormProps {
    authenticatedUser: AuthenticatedUser;
    isAdmin: boolean;
    logout: () => void;
    isLoading?: boolean;
}

export type ModifyOp = 'add' | 'edit';

interface AddEditUserFormURLParams {
    userID: string;
    addEdit?: ModifyOp;
}

const AddEditUserForm = (
    props: AddEditUserFormProps & RouteComponentProps<AddEditUserFormURLParams>
) => {
    const {
        authenticatedUser,
        isAdmin,
        isLoading,
        match,
        logout
    } = props;

    const { userID, addEdit } = match.params;
    const [user, setUser] = useState<UserDataWithPhones>(emptyUser);
    const authenticatedID = authenticatedUser.userId;
    const idToUse = addEdit ? userID : authenticatedID;

    useEffect(() => {
        if (addEdit) {
            if (idToUse) {
                getUser(+idToUse)
                    .then(u => {
                        u.userPassword = '';
                        return u;
                    })
                    .then(setUser); // admins
            }
        } else {
            getCurrentUser().then(setUser); // normal users
        }
    }, [addEdit, idToUse]);

    const findTypeOrEmptyNumber = (type: string) => {
        const emptyPhone = {
            userPhonePK: {
                userId: +idToUse,
                phoneType: type
            },
            modifiedDate: moment().format(),
            phone: '',
            extPhone: ''
        };

        if (user.userPhoneList)
            return { ...emptyPhone, ...user.userPhoneList.find(pl => pl.userPhonePK.phoneType === type) };

        return emptyPhone;
    };

    const numbers: {
        mobile: PhoneNumberFromAPI;
        home: PhoneNumberFromAPI;
        work: PhoneNumberFromAPI;
        fax: PhoneNumberFromAPI;
        [key: string]: PhoneNumberFromAPI;
    } = {
        mobile: findTypeOrEmptyNumber('Mobile'),
        home: findTypeOrEmptyNumber('Home'),
        work: findTypeOrEmptyNumber('Work'),
        fax: findTypeOrEmptyNumber('Fax')
    };

    const createNumberType = (type: string, phone: string, ext: string): PhoneNumberFromAPI => {
        const current = numbers[type.toLowerCase()];
        return {
            userPhonePK: {
                userId: +idToUse,
                phoneType: type
            },
            modifiedDate:
                current.phone === phone || current.extPhone === ext
                    ? current.modifiedDate
                    : moment().format(), // is the user changed either the ext or phone set new modified date.
            extPhone: ext,
            phone
        };
    };

    const updateOrAddUser = (user: UserData) => {
        switch (addEdit) {
            case 'add':
                return createUserFromAPI(user);
            case 'edit':
                return updateUserByIDFromAPI(+idToUse)(user);
            default:
                return updateCurrentUser(user);
        }
    };

    const [updatingEmail, setUpdatingEmail] = useState<boolean>();
    const initialSubmitVals = {
        ...user,
        countryName: '',        // empty string, only used for display
        jobCategory: user.jobCategory || '',
        jobCategoryName: '',    // empty string, only used for display
        mobileNumber: numbers.mobile,
        homeNumber: numbers.home,
        workNumber: numbers.work,
        faxNumber: numbers.fax,
        category: user.jobCategory,
        publishedEmail: '' // not sure what this is
    };

    const [submitVals, setSubmitVals] = useState<UserData>(initialSubmitVals);

    const handleSubmission = (submitVals: UserData, logout?: () => void) =>
        updateOrAddUser(submitVals).then(
            () => {
                toast.success(`Successfully ${addEdit === 'add' ? 'added' : 'updated'} user`);
                setUpdatingEmail(undefined);
                if (logout) {
                    setTimeout(() => logout(), 2000);
                }
                else {
                    setTimeout(() => appHistory.push( isAdmin ? '/admin/manage/users' : '/'), 2000);
                }
            },
            error => {
                toast.error(get(error.response, 'data.message', `Failed to ${addEdit} user`));
                setUpdatingEmail(undefined);
            }
        );

    return (
        <Formik
            initialValues={initialSubmitVals}
            validationSchema={AddEditUserSchema}
            enableReinitialize
            onSubmit={values => {
                const submitVals: UserDataWithPhones = {
                    ...values,
                    modifiedUserId: authenticatedID,
                    modifiedDate: moment().format(),
                    userPhoneList: [
                        createNumberType('Mobile', values.mobileNumber.phone, values.mobileNumber.extPhone),
                        createNumberType('Home', values.homeNumber.phone, values.homeNumber.extPhone),
                        createNumberType('Work', values.workNumber.phone, values.workNumber.extPhone),
                        createNumberType('Fax', values.faxNumber.phone, values.faxNumber.extPhone)
                    ]
                };

                if (addEdit === 'add') delete submitVals.userId; // we don't want to send this to the endpoint when creating new users

                const isUpdatingSelf: Boolean = authenticatedUser.userId === submitVals.userId;

                if (values.email === authenticatedUser.email || !isUpdatingSelf) {
                    handleSubmission(submitVals);
                } else {
                    setSubmitVals(submitVals);
                    setUpdatingEmail(true);
                }
            }}

            render={({ handleChange, handleBlur, setFieldValue, values, errors }) => {
                return (
                    <Form className={cssClass}>
                        {isLoading ? (
                            <Spinner />
                        ) : (
                                <div>
                                    {addEdit === 'edit' && (
                                        <>
                                            <AddEntityButton
                                                addIcon={faUserPlus}
                                                emptyEntityState={emptyUser}
                                                path="/admin/manage/users/add"
                                                setEntity={setUser}
                                                bottom="80px"
                                            />
                                            <Button
                                                className="d-inline rounded-circle fixed-bottom m-5 p-3 bg-primary text-center text-light"
                                                style={{
                                                    left: 'auto',
                                                    right: 0,
                                                    width: '70px',
                                                    height: '70px'
                                                }}
                                                onClick={() => {
                                                    deleteUserByIDFromAPI(+idToUse)
                                                        .then(response => {
                                                            toast.success(response.msg);
                                                            appHistory.push('/admin/manage/users');
                                                        })
                                                        .catch(() => {
                                                            toast.error('failed to delete!');
                                                        });
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={faUserMinus}
                                                    size="2x"
                                                    style={{ position: 'relative' }}
                                                />
                                            </Button>
                                        </>
                                    )}
                                    <Row>
                                        <Col>
                                            <h1>
                                                <FontAwesomeIcon icon={faUser} size="1x" /> Update User Details
                                            </h1>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>
                                            <Col tag="fieldset" className="my-3 border-light">
                                                <legend>User Name</legend>
                                                <Row>
                                                    <FormField
                                                        name="firstName"
                                                        label={<span>First Name <i>(Required)</i></span> as JSX.Element}
                                                        type="text"
                                                        value={values.firstName}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 12, lg: 12 }}
                                                    />
                                                    <FormField
                                                        name="lastName"
                                                        label={<span>Last Name <i>(Required)</i></span> as JSX.Element}
                                                        type="text"
                                                        value={values.lastName}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 12, lg: 12 }}
                                                    />
                                                    {isAdmin && addEdit && (
                                                        <FormField
                                                            name="displayName"
                                                            label={<span>Display Name <i>(Required)</i></span> as JSX.Element}
                                                            type="text"
                                                            value={values.displayName}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            match={match}
                                                            col={{ xl: 12, lg: 12 }}
                                                            disabled={addEdit !== 'add'}
                                                        />
                                                    )}
                                                    {isAdmin && (
                                                        <FormField
                                                            name="userPassword"
                                                            label="Password"
                                                            type="password"
                                                            value={values.userPassword}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            match={match}
                                                            col={{ xl: 12, lg: 12 }}
                                                            browserAutoComplete={"new-password"}
                                                        />
                                                    )}
                                                    {!isAdmin && (
                                                        <Button
                                                            color="danger"
                                                            name="resetPassword"
                                                            label="Reset Password"
                                                            className="ml-3 mb-3"
                                                            onClick={ (event)=> {
                                                                event.preventDefault();
                                                                if ( values.email )
                                                                {
                                                                    requestPasswordReset(values.email)
                                                                    .then(() => {
                                                                        toast.success(
                                                                            `Password reset has been submitted for ${values.email}.  Please check your email for steps to proceed with the reset password process.`,
                                                                            { autoClose: 10000 }
                                                                        );
                                                                    });
                                                                }                                                                
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faKey}/> Reset Password
                                                        </Button>
                                                    )}

                                                </Row>
                                            </Col>
                                            <Col tag="fieldset" className="my-3 border-light">
                                                <legend>User Contact Details</legend>
                                                <Row>
                                                    <FormField
                                                        name="email"
                                                        label={<span>Email <i>(Required)</i></span> as JSX.Element}
                                                        type="text"
                                                        value={values.email}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 12, lg: 12 }}
                                                    />
                                                    <FormField
                                                        name="mobileNumber.phone"
                                                        label="Mobile Number"
                                                        type="text"
                                                        value={values.mobileNumber.phone}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 8, lg: 8 }}
                                                    />
                                                    <FormField
                                                        name="mobileNumber.extPhone"
                                                        label="Mobile Extension"
                                                        type="text"
                                                        value={values.mobileNumber.extPhone ?? ''}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 4, lg: 4 }}
                                                    />
                                                    <FormField
                                                        name="homeNumber.phone"
                                                        label="Home Number"
                                                        type="text"
                                                        value={values.homeNumber.phone}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 8, lg: 8 }}
                                                    />
                                                    <FormField
                                                        name="homeNumber.extPhone"
                                                        label="Home Extension"
                                                        type="text"
                                                        value={values.homeNumber.extPhone ?? ''}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 4, lg: 4 }}
                                                    />
                                                    <FormField
                                                        name="workNumber.phone"
                                                        label="Work Number"
                                                        type="text"
                                                        value={values.workNumber.phone ?? ''}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 8, lg: 8 }}
                                                    />
                                                    <FormField
                                                        name="workNumber.extPhone"
                                                        label="Work Extension"
                                                        type="text"
                                                        value={values.workNumber.extPhone ?? ''}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 4, lg: 4 }}
                                                    />
                                                    <FormField
                                                        name="faxNumber.phone"
                                                        label="Fax Number"
                                                        type="text"
                                                        value={values.faxNumber.phone ?? ''}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 8, lg: 8 }}
                                                    />
                                                    <FormField
                                                        name="faxNumber.extPhone"
                                                        label="Fax Extension"
                                                        type="text"
                                                        value={values.faxNumber.extPhone ?? ''}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 4, lg: 4 }}
                                                    />
                                                </Row>
                                            </Col>
                                            {isAdmin && addEdit && (
                                                <Col tag="fieldset" className="my-3 border-light">
                                                    <legend>Other User Details</legend>
                                                    <Row>
                                                        <FormField
                                                            name="userId"
                                                            label="ID"
                                                            type="text"
                                                            value={values.userId}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            match={match}
                                                            col={{ xl: 12, lg: 12 }}
                                                            disabled
                                                            readOnly
                                                        />
                                                        <FormField
                                                            name="createdDate"
                                                            label="Created"
                                                            type="text"
                                                            value={values.createdDate}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            match={match}
                                                            col={{ xl: 12, lg: 12 }}
                                                            disabled
                                                            readOnly
                                                        />
                                                        <FormField
                                                            name="modifiedDate"
                                                            label="Modified"
                                                            type="text"
                                                            value={values.modifiedDate}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            match={match}
                                                            col={{ xl: 12, lg: 12 }}
                                                            disabled
                                                            readOnly
                                                        />
                                                        <FormField
                                                            name="modifiedUserId"
                                                            label="Modified User ID"
                                                            type="text"
                                                            value={values.modifiedUserId}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            match={match}
                                                            col={{ xl: 12, lg: 12 }}
                                                            disabled
                                                            readOnly
                                                        />
                                                        <FormField
                                                            name="publishedEmail"
                                                            label="Published Email"
                                                            type="text"
                                                            value={values.publishedEmail}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            match={match}
                                                            col={{ xl: 12, lg: 12 }}
                                                            disabled
                                                        />
                                                    </Row>
                                                </Col>
                                            )}
                                        </Col>
                                        <Col xl={6}>
                                            <Col tag="fieldset" className="my-3 border-light">
                                                <legend>User Company Details</legend>
                                                <Row>
                                                    <CompanySelectField
                                                        match={match}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                    <FormField
                                                        name="title"
                                                        label="Title"
                                                        type="text"
                                                        value={values.title}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 12, lg: 12 }}
                                                    />
                                                    <JobCategorySelectField
                                                        values={values}
                                                        match={match}
                                                        setFieldValue={setFieldValue}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        displayRequired={true}
                                                    />
                                                </Row>
                                            </Col>
                                            <Col tag="fieldset" className="my-3 border-light">
                                                <legend>User Address</legend>
                                                <Row>
                                                    <FormField
                                                        name="address1"
                                                        label="Address 1"
                                                        type="text"
                                                        value={values.address1}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 12, lg: 12 }}
                                                    />
                                                    <FormField
                                                        name="address2"
                                                        label="Address 2"
                                                        type="text"
                                                        value={values.address2}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 12, lg: 12 }}
                                                    />
                                                    <FormField
                                                        name="city"
                                                        label="City"
                                                        type="text"
                                                        value={values.city}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 12, lg: 12 }}
                                                    />
                                                    <StateProvinceSelectField
                                                        match={match}
                                                        handleBlur={handleBlur}
                                                        setFieldValue={setFieldValue}
                                                        handleChange={handleChange}
                                                        values={values}
                                                    />
                                                    <IsoCountrySelectField
                                                        values={values}
                                                        match={match}
                                                        setFieldValue={setFieldValue}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        displayRequired={true}
                                                    />
                                                    <FormField
                                                        name="postalCode"
                                                        label="Postal Code"
                                                        type="text"
                                                        value={values.postalCode}
                                                        handleChange={handleChange}
                                                        handleBlur={handleBlur}
                                                        match={match}
                                                        col={{ xl: 12, lg: 12 }}
                                                    />
                                                </Row>
                                            </Col>
                                            {isAdmin && addEdit && (
                                                <Col tag="fieldset" className="my-3 border-light">
                                                    <legend>User App Details</legend>
                                                    <Row>
                                                        <SecurityRoleSelect
                                                            securityRoleId={values.securityRoleId}
                                                            match={match}
                                                            setFieldValue={setFieldValue}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                        />
                                                        <FormField
                                                            name="userStatus"
                                                            label={<span>Status <i>(Required)</i></span> as JSX.Element}
                                                            type="select"
                                                            value={values.userStatus}
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            match={match}
                                                            col={{ xl: 12, lg: 12 }}>
                                                            <option value="ACTIVE">Active</option>
                                                            <option value="NOT ACTIVE">Not Active</option>
                                                        </FormField>
                                                    </Row>
                                                </Col>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            
                        {errors && Object.keys(errors as FormikErrors<FormikValues>).length > 0 && 
                            <div className="alert p-3 mt-5 alert-danger text-right">
                                Form requirements not satisfied for submission.
                            </div>}

                        <ButtonGroup className="mt-5">
                            <GoBack color={'secondary'} />
                            <Button color="primary" type="submit">
                                {addEdit !== 'add' ? (
                                    <>
                                        <FontAwesomeIcon icon={faUserEdit} /> Save
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faUserPlus} /> Add
                                    </>
                                    )}
                            </Button>
                        </ButtonGroup>

                        <Confirm
                            isOpen={updatingEmail || false}
                            cancel={_event => setUpdatingEmail(undefined)}
                            success={() => {
                                handleSubmission(submitVals, logout);
                            }}
                            successTitle="Approve">
                            <div>
                                <p>
                                    Are you sure you want to change your email to{' '}
                                    <span className={'text-danger'}>{submitVals.email}</span>, which will be used to
                                    log into your account? You will no longer be able to access any functionality with
                                    your old email address once it has changed and will be required to use{' '}
                                    <span className={'text-danger'}>{submitVals.email}</span>.
                                </p>
                                <p>
                                    Upon approving this change you will be redirected to the login page to log back in
                                    using: <span className={'text-danger'}>{submitVals.email}</span>.
                                </p>
                            </div>
                        </Confirm>
                    </Form>
                );
            }}
        />
    );
};

const mapStateToProps = (state: AppState) => ({
    authenticatedUser: state.users.authenticatedUser,
    isAdmin: state.users.isAdmin
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: submitLogout(dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddEditUserForm));
