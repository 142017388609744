import * as React from 'react';
import { Col } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  ContactData,
  deleteContact
} from '../../../../../actions/contactsActions';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import Spinner from '../../../../shared/spinner/Spinner';
import AirportContactsList from "../contacts/AirportContactList";

interface ReviewContactsProps {
  axnInstanceId: number;
  contactsLoading: boolean;
  contacts: ContactData[];
  contentID: string;
  contentType: string;
  selectedContact: () => boolean;
}

interface ReviewContactsUrlParams {
  terminalID: string;
  contentID: string;
}

const ReviewContacts = (
  props: ReviewContactsProps & RouteComponentProps<ReviewContactsUrlParams>
) => {
  const {
    contacts,
    contentID,
    contentType,
    contactsLoading,
    selectedContact,
    axnInstanceId
  } = props;
  return (
    <Col className={cssClass}>
      <StepHeader Tag={'h3'} stepTitle={'Existing Contacts'} />
      {contactsLoading ? (
        <Spinner />
      ) : (
        <AirportContactsList
          contacts={contacts}
          contentID={contentID}
          contentType={contentType}
          handleDelete={deleteContact}
          instanceId={axnInstanceId}
          readonly
        />
      )}
    </Col>
  );
};

export default withRouter(ReviewContacts);
