import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Container } from 'reactstrap';
import { cssClass } from '../../wizard/cssSharedClasses';
import StepHeader from '../../wizard/StepHeader';

const AddEditAirportView = (props: RouteComponentProps<{}>) => {
  return (
    <Container className={cssClass}>
      <StepHeader stepTitle={'COMING SOON!'} />
    </Container>
  );
};

export default withRouter(AddEditAirportView);
