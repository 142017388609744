import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Formik } from 'formik';
import { get } from 'lodash';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { AllowedWizTypes, StepDefaultProps } from '../../../types';
import { AppState } from '../../../../../reducers';
import { setWizardFormState } from '../../../../../actions/wizardActions';
import airportInfoValidationSchema from './yupSchema';
import { cssClass } from '../../../cssSharedClasses';
import AirportRevenueForm from './AirportRevenueForm';
import AirportInfoForm from './AirportInfoForm';
import AirportRatiosForm from './AirportRatiosForm';
import { TerminalsList } from '../../../../../actions/terminalsActions';
import {
  InitUpdateAirportInfo,
  initUpdateAirportInfo
} from '../../../../../actions/airportActions';
import ExistingTerminals from './ExistingTerminals';
import {
  AirportInstance,
  AirportInstanceInfo,
  ConcessionManagementType,
  AirportExpansion,
  YOrN
} from '../../../../../types';
import { AuthenticatedUser } from '../../../../../actions/usersActions';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';
import { useAirportConfigType } from '../../../../../api/Airports';

export const initialInfoValues = {
  airportConfigurationId: 0,
  airportConfiguration: '',
  concessionManagementTypeId: 0,
  concessionManagementType: '',
  configurationAdditionalInfoText: '',
  additionalInfoFlag: 'N' as YOrN,
  dwellTimeAmount: 0,
  completeDate: '',
  airportExpansionList: new Array<AirportExpansion>()
};

const initialRevenueValues = {
  passengerSvcRevenueTotal: 0,
  passengerSvcRevenueAirportTotal: 0,
  advertisingRevenueTotal: 0,
  advertisingRevenueAirportTotal: 0,
  currencyExchangeRevenueTotal: 0,
  currencyExchangeRevenueAirportTotal: 0
};
const initialRatiosValues = {
  postSecurityPercentAmount: 0,
  preSecurityPercentAmount: 0,
  businessPercentAmount: 0,
  leisurePercentAmount: 0,
  odPercentAmount: 0,
  transferPercentAmount: 0
};

export interface InfoValues extends AirportInstanceInfo, ConcessionManagementType {
  airportConfiguration: string;
  airportExpansionList: AirportExpansion[];
}

export const infoInitialValues: InfoValues = {
  ...initialInfoValues,
  ...initialRevenueValues,
  ...initialRatiosValues
};

interface InfoStepProps extends StepDefaultProps, InfoValues {
  authenticatedUser: AuthenticatedUser;
  dispatch?: Dispatch;
  terminalsLoading: boolean;
  terminals: TerminalsList;
  airportInstance: AirportInstance;
  updateAirportInfo: InitUpdateAirportInfo;
  setFormState: (wizType: AllowedWizTypes, formName: string, formValues: any) => void;
  activeInstance: ActiveInstance;
}

const InfoStep = (props: InfoStepProps & RouteComponentProps<{ contentID: string }>) => {
  const {
    authenticatedUser,
    terminals,
    terminalsLoading,
    airportInstance,
    updateAirportInfo,
    activeInstance,
    match
  } = props;
  const { id } = activeInstance;
  const { contentID } = match.params;
  const { airportConfigTypes } = useAirportConfigType();

  let expansionList = new Array<AirportExpansion>();

  if (airportInstance) {
    if (airportInstance.airportExpansionList && airportInstance.airportExpansionList.length > 0) {
      expansionList = airportInstance.airportExpansionList;
    }
  }

  const airportConfigType = airportConfigTypes.find(value => {
    return value.airportConfigurationId === airportInstance.airportConfigurationId;
  });

  const initVals: InfoValues = {
    ...infoInitialValues,
    ...airportInstance,
    concessionManagementType: get(
      airportInstance.concessionManagementType,
      'concessionManagementType',
      ''
    ),
    airportExpansionList: expansionList,
    airportConfigurationId: +airportInstance.airportConfigurationId,
    airportConfiguration: airportConfigType ? airportConfigType.airportConfigurationName : '',
    additionalInfoFlag: airportInstance.airportConfiguration
      ? airportInstance.airportConfiguration.additionalInfoFlag
      : ('N' as YOrN)
  };

  const { airportName } = airportInstance.airport;
  const { userId } = authenticatedUser;
  return (
    <>
      <Formik
        initialValues={initVals}
        validationSchema={airportInfoValidationSchema}
        enableReinitialize
        onSubmit={values => {
          /* Grab the values necessary and only send them off. */
          const putValues: AirportInstance = {
            modifiedUserId: userId,
            airport: airportInstance.airport,
            airportConfigurationId: values.airportConfigurationId,
            configurationAdditionalInfoText: values.configurationAdditionalInfoText,
            additionalInfoFlag: values.additionalInfoFlag,
            concessionManagementTypeId: values.concessionManagementTypeId,
            dwellTimeAmount: values.dwellTimeAmount,
            // airportExpansionList: values.airportExpansionList,
            /* Passenger Services */
            passengerSvcRevenueTotal: values.passengerSvcRevenueTotal,
            passengerSvcRevenueAirportTotal: values.passengerSvcRevenueAirportTotal,
            /* Advertising */
            advertisingRevenueTotal: values.advertisingRevenueTotal,
            advertisingRevenueAirportTotal: values.advertisingRevenueAirportTotal,
            /* Currency Exchange */
            currencyExchangeRevenueTotal: values.currencyExchangeRevenueTotal,
            currencyExchangeRevenueAirportTotal: values.currencyExchangeRevenueAirportTotal,
            /* Pre/Post Security Percentages */
            preSecurityPercentAmount: values.preSecurityPercentAmount,
            postSecurityPercentAmount: values.postSecurityPercentAmount,
            /* Business/Leisure Percentages */
            businessPercentAmount: values.businessPercentAmount,
            leisurePercentAmount: values.leisurePercentAmount,
            /* Origination & Destination/Transfer Percentages */
            odPercentAmount: values.odPercentAmount,
            transferPercentAmount: values.transferPercentAmount,

            /* Terminal Data Related to Instance, get values from state since they aren't part of this form?  */
            passengerTotal: airportInstance.passengerTotal,
            passengerEpDomesticTotal: airportInstance.passengerEpDomesticTotal,
            passengerEpIntlTotal: airportInstance.passengerEpIntlTotal,
            enplaneTotal: airportInstance.enplaneTotal,
            deplaneTotal: airportInstance.deplaneTotal,
            acdbePercentageAmount: airportInstance.acdbePercentageAmount,

            /* Car Rental and Parking Data, get values from state since they aren't part of this form? */
            parkingRevenueTotal: airportInstance.parkingRevenueTotal,
            carRentalTotal: airportInstance.carRentalTotal,
            carRentalAreaTotal: airportInstance.carRentalAreaTotal,
            carRentalOnsiteAgenciesTotal: airportInstance.carRentalOnsiteAgenciesTotal,
            carRentalOnsiteRevenueTotal: airportInstance.carRentalOnsiteRevenueTotal,
            carRentalOnsiteRevenueAirportTotal: airportInstance.carRentalOnsiteRevenueAirportTotal,
            carRentalOffsiteAgenciesTotal: airportInstance.carRentalOffsiteAgenciesTotal,
            carRentalOffsiteRevenueTotal: airportInstance.carRentalOffsiteRevenueTotal,
            carRentalOffsiteRevenueAirportTotal:
              airportInstance.carRentalOffsiteRevenueAirportTotal,

            axnInstanceStatus: airportInstance.axnInstanceStatus,
            annualPercentageChangeAmount: airportInstance.annualPercentageChangeAmount,
            airportConfiguration: {
              airportConfigurationName: values.airportConfiguration,
              airportConfigurationId: +values.airportConfigurationId,
              additionalInfoFlag: values.additionalInfoFlag
            },
            concessionManagementType: {
              concessionManagementType: values.concessionManagementType,
              concessionManagementTypeId: +values.concessionManagementTypeId
            }
          };

          /* Call the redux based method that dispatches the async functionality to handle the update of the airport
           info step.  */
          updateAirportInfo(+contentID, id, putValues, true);
        }}
        render={({
          handleBlur,
          handleChange,
          handleReset,
          handleSubmit,
          values,
          setFieldValue
        }) => {
          return (
            <form
              className={cssClass}
              onReset={handleReset}
              onSubmit={handleSubmit}
              onChange={handleChange}>
              <Row>
                <AirportInfoForm
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  axnInstanceId={id}
                />
                <AirportRevenueForm
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
                <AirportRatiosForm
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Row>
              <Row className="pt-5">
                <Col>
                  <ButtonGroup>
                    <Button color="primary" type="submit" onClick={() => handleSubmit}>
                      Save Change <FontAwesomeIcon icon={faSave} />
                    </Button>
                    <Button color="secondary" type="reset" onClick={() => handleReset}>
                      Reset <FontAwesomeIcon icon={faRedoAlt} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </form>
          );
        }}
      />
      <ExistingTerminals
        airportName={airportName}
        terminalsLoading={terminalsLoading}
        terminals={terminals}
        bordered
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  infoFormStore: state.wizard.stepper.airports.airportInfo,
  terminals: state.terminals.terminals,
  terminalsLoading: state.terminals.loading,
  airportInstance: state.airports.airportInstance,
  authenticatedUser: state.users.authenticatedUser,
  activeInstance: state.wizard.activeInstance
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setFormState: setWizardFormState(dispatch),
  updateAirportInfo: initUpdateAirportInfo(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InfoStep));
