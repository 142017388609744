import React from 'react';
import './toolTip.scss';

interface ToolTipProps {
  text: string;
  visible: boolean;
}

const ToolTip = (props: ToolTipProps) => {
  const { text, visible } = props;
  return visible ? <div className={'axnToolTip'}>{text}</div> : <></>;
};

ToolTip.defaultProps = {
  text: '',
  visible: false
};

export default ToolTip;
