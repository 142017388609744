import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  faEdit,
  faFileExcel,
  faFilePdf,
  faPlaneDeparture
  // faPlus
} from '@fortawesome/free-solid-svg-icons';
import { TableColumn } from '../../masterlist/MasterList';
import { MasterListActionButton, IndexableMasterListItem } from '../../masterlist/types';
import { useFilteredAirports } from '../../../api/Airports';
import { AirportInstance, AxnInstanceStatus } from '../../../types';
import { FilteredMasterList } from '../../masterlist/filter/FilteredMasterList';
import { StatusFilter } from '../../masterlist/filter';
// import AddEntityButton from '../AddEntityButton';
// import emptyAirportState from './emptyAirport';
import { AppState } from '../../../reducers';
import {
  AvailableInstanceMasterStatus,
  dispatchSwitchWizardInstance,
  WizardActionsCreator
} from '../../../actions/wizardActions';
import { AuthenticatedUser } from '../../../actions/usersActions';
import AxnInstanceFilter from '../../forms/AxnInstanceFilter';
import { getBookReport } from '../../../api/reports';

const columns: TableColumn[] = [
  { id: 'airportId', title: 'ID' },
  { id: 'iataCode', title: 'IATA' },
  { id: 'airportName', title: 'Name', width: '40%' },
  { id: 'isoCountryCode', title: 'Country' },
  { id: 'modifiedDate', title: 'Last Modified', width: '10%' },
  { id: 'axnInstanceStatus', title: 'Status' }
];

export interface AirportsSearchResult {
  airportId: number;
  iataCode: string;
  airportName: string;
  isoCountryCode: string;
  modifiedDate: string;
  axnInstanceStatus: string;
}

const convert = (data: AirportInstance): AirportsSearchResult => ({
  airportId: data.airport.airportId,
  iataCode: data.airport.iataCode,
  airportName: data.airport.airportName,
  isoCountryCode: data.airport.isoCountryCode,
  modifiedDate: data.modifiedDate || '',
  axnInstanceStatus: data.axnInstanceStatus as AxnInstanceStatus
});

const convertAs = (data: AirportInstance): IndexableMasterListItem =>
  convert(data) as IndexableMasterListItem;

const AirportManager = (props: {
  dispatchSwitchWizardInstance: WizardActionsCreator;
  authenticatedUser: AuthenticatedUser;
  activeInstance: number;
  activeInstanceStatus: string;
}) => {
  const {
    dispatchSwitchWizardInstance,
    authenticatedUser,
    activeInstance,
    activeInstanceStatus
  } = props;
  const {
    axnPendingInstanceId,
    axnInstanceId,
    axnPendingInstanceName,
    axnInstanceName
  } = authenticatedUser;
  const [status, setStatus] = useState('ALL');
  const [axnStatus, setAxnStatus] = useState(activeInstanceStatus as AvailableInstanceMasterStatus);

  const actions = (id: number, name: string): MasterListActionButton[] => [
    {
      to: `/wizard/airports/${id}/steps/info`,
      icon: faEdit,
      text: 'Edit'
    },
    {
      to: `/admin/manage/airports/${id}/terminals`,
      icon: faPlaneDeparture,
      text: 'Terminals'
    },
    {
      to: '/admin/manage/airports',
      icon: faFilePdf,
      text: 'PDF',
      actionCallback: () => getBookReport(activeInstance, 'airports', 'pdf', name, [id])
    },
    {
      to: '/admin/manage/airports',
      icon: faFileExcel,
      text: 'Excel',
      actionCallback: () => getBookReport(activeInstance, 'airports', 'xlsx', name, [id])
    }
  ];

  useEffect(() => {
    const instanceIdToUse = axnStatus === 'PENDING' ? axnPendingInstanceId : axnInstanceId;
    const instanceNameToUse = axnStatus === 'PENDING' ? axnPendingInstanceName : axnInstanceName;
    dispatchSwitchWizardInstance(
      instanceIdToUse,
      axnStatus as AvailableInstanceMasterStatus,
      instanceNameToUse
    );
  }, [axnStatus]);

  return (
    <div>
      <div className={'d-flex align-items-center justify-content-between'}>
        <div>
          <StatusFilter handleChange={setStatus} selected={status} type={'instance'} />
        </div>
        <div>
          <AxnInstanceFilter
            axnStatusFilterColSize={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}
            setStatus={setAxnStatus}
            status={axnStatus}
            authenticatedUser={authenticatedUser}
          />
        </div>
        {/* TODO: add ability to Create New Main Airport & Airport Instances, similar to companies implementation.  */}
        {/*
          <AddEntityButton
            addIcon={faPlus}
            emptyEntityState={emptyAirportState}
            path={'/admin/manage/airports/add'}
          />
        */}
      </div>
      <FilteredMasterList
        actions={actions}
        args={status}
        columns={columns}
        convert={convertAs}
        listType="Airports"
        useData={useFilteredAirports}
        axnInstanceId={activeInstance}
        additionalValues={['airportName']}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  authenticatedUser: state.users.authenticatedUser,
  activeInstance: state.wizard.activeInstance.id,
  activeInstanceStatus: state.wizard.activeInstance.status
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchSwitchWizardInstance: dispatchSwitchWizardInstance(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AirportManager);
