import * as React from 'react';
import { toast } from 'react-toastify';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { UserData } from '../../../actions/usersActions';
import { deleteCompanyAffiliation, useAffiliatedCompanies } from '../../../api/affiliations';
import { TableColumn } from '../../masterlist/MasterList';
import { MasterListActionButton } from '../../masterlist/types';
import { AffiliationData } from './CurrentAffiliations';
import { CompanyData } from '../../../actions/companiesActions';

const actions = (user: UserData, instanceId: number, refresh: () => void) => (
  companyId: number
): MasterListActionButton[] => [
  {
    to: `/admin/affiliate/companies/${user.userId}`,
    icon: faTrash,
    text: 'Delete',
    actionCallback: (event: React.MouseEvent<any, MouseEvent>) => {
      event.preventDefault();
      deleteCompanyAffiliation(user.userId, companyId, instanceId)
        .then(response => {
          if (response.kind === 'Success') {
            toast.success('Successfully deleted company affiliation');
          } else if (response.kind === 'Failure') {
            toast.error(`Unable to delete affiliation: ${response.error}`);
          }
        })
        .then(refresh);
    }
  }
];

const columns: TableColumn[] = [
  { id: 'companyId', title: 'ID' },
  { id: 'companyName', title: 'Name' },
  { id: 'stateProvinceCode', title: 'State/Province' },
  { id: 'isoCountryCode', title: 'Country' }
];

const companyModel: AffiliationData<CompanyData> = {
  fetchData: useAffiliatedCompanies,
  actions,
  columns
};

export default companyModel;
