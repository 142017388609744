import moment from 'moment';
import {
  AuthenticatedUser,
  AuthorityRolesObject,
  UserData,
  UserIDData,
  UsersAction,
  UsersList
} from '../actions/usersActions';
import { decodeJwt, getAuthTokenFromKeyValue } from '../core/authentication/authenticationHelper';

/* This Reducer setup is kind of wordy but it does offer type safe actions with a ton of IDE auto complete
 capabilities. */
export interface UserState {
  authenticatedUser: AuthenticatedUser;
  users: UsersList;
  user: UserData;
  loading: boolean;
  isLoggedIn: boolean;
  isAdmin: boolean;
  error: string;
}

/* this will be used to set that initial state of the app */
const { userAuthToken, hasUserAuthToken } = getAuthTokenFromKeyValue();

export const unauthenticatedUser: AuthenticatedUser = {
  firstName: '',
  lastName: '',
  sub: '',
  userName: '',
  exp: moment().unix(),
  userId: 0,
  iat: moment().unix(),
  email: '',
  authorities: new Array<AuthorityRolesObject>(),
  expirationDate: moment().unix(),
  axnInstanceId: +'',
  axnPendingInstanceId: +'',
  axnInstanceName: '',
  axnPendingInstanceName: ''
};

/* Set Initial User State */
const initialUserState = {
  users: Array<UserData>(),
  user: { userId: 0 },
  loading: false,
  isAdmin: false,
  isLoggedIn: hasUserAuthToken,
  error: '',
  authenticatedUser: hasUserAuthToken ? decodeJwt(userAuthToken) : unauthenticatedUser
};

export type UsersState = typeof initialUserState;

export const usersReducer = (state: UserState = initialUserState, action: UsersAction) => {
  switch (action.type) {
    case 'GET_USER':
    case 'GET_USERS':
    case 'UPDATE_ADD_USER':
    case 'DELETE_USER':
    case 'USER_LOGIN':
      return { ...state, isLoading: true };
    case 'GET_USER_SUCCESS':
    case 'UPDATE_ADD_USER_SUCCESS':
      return { ...state, isLoading: false, user: action.user };
    case 'GET_USERS_SUCCESS':
      return { ...state, isLoading: false, users: action.users };
    case 'DELETE_USER_SUCCESS':
      return {
        ...state,
        users: state.users.filter(data => (data as UserIDData) !== action.userID),
        isLoading: false
      };
    case 'GET_USER_FAILURE':
    case 'GET_USERS_FAILURE':
    case 'DELETE_USER_FAILURE':
    case 'UPDATE_ADD_USER_FAILURE':
      return { ...state, isLoading: false, error: action.error };
    case 'USER_LOGIN_SUCCESS':
    case 'DECODE_USER_TOKEN':
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        isAdmin: !!action.authenticatedUser.authorities.find(a => a.authority === 'ROLE_ADMIN'),
        authenticatedUser: action.authenticatedUser
      };
    case 'USER_LOGIN_FAILED':
      return { ...state, isLoading: false, isLoggedIn: false };
    case 'USER_LOGOUT':
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        isAdmin: false,
        authenticatedUser: unauthenticatedUser
      };
    default:
      return state;
  }
};
