import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import { get } from 'lodash';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, Container, Row } from 'reactstrap';
import { faPlus, faTrash, faUserEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddEntityButton from '../AddEntityButton';
import { cssClass } from '../../wizard/cssSharedClasses';
import FormField from '../../forms/FormField';
import AxnStatusSelectField from '../../forms/AxnStatusSelectField';
import GoBack from '../../buttons/GoBack';
import {
  createAirportTerminalFromAPI,
  deleteAirportTerminalFromAPI,
  getMainTerminal,
  getTerminal,
  updateAirportTerminalFromAPI
} from '../../../api/Airports';
import { ActiveInstance } from '../../../reducers/wizardReducer';
import { appHistory, AppState } from '../../../reducers';
import { AxnStatus } from '../../../types';
import { TerminalData } from '../../../actions/terminalsActions';
import Confirm from '../users/Confirm';

export const initTerminalsVals = {
  terminalName: '',
  terminalShortName: '',
  axnStatus: 'ACTIVE' as AxnStatus
};

type AddEditTerminalFormUrlParams = {
  addEdit: string;
  aid: string;
  terminalID: string;
};

type AddEditTerminalFormProps = {
  activeInstance: ActiveInstance;
  authenticatedUserId: number;
};

const AddEditTerminalView = (
  props: AddEditTerminalFormProps & RouteComponentProps<AddEditTerminalFormUrlParams>
) => {
  const { match, activeInstance, authenticatedUserId } = props;
  const { id } = activeInstance;
  const { addEdit, aid, terminalID } = match.params;
  const [selectedTerminal, setSelectedTerminal] = useState<number>();

  const [initTerminalFormValues, setInitTerminalFormValues] = useState(initTerminalsVals);
  const [terminalValues, setTerminalValues] = useState<TerminalData>();
  useEffect(() => {
    if (terminalID && addEdit.toLowerCase() === 'edit') {
      getMainTerminal(+aid, +terminalID, id).then(
        response => {
          setInitTerminalFormValues({ ...response.data });
        },
        () => toast.warn('Failed to get Main Terminal data!')
      );
      getTerminal(+aid, +terminalID, id).then(
        response => {
          setTerminalValues({ ...response.data });
        },
        () => toast.warn('Failed to get Terminal data!')
      );
    }
  }, [+aid, +terminalID, id, addEdit]);

  const ucFirstAddEdit = addEdit[0].toUpperCase() + addEdit.slice(1);

  const deleteTerminal = () => {
    deleteAirportTerminalFromAPI(+aid, id, +terminalID).then(
      () => {
        toast.success('Successfully Deleted Airport Terminal');
        setSelectedTerminal(undefined);
        appHistory.push(`/admin/manage/airports/${aid}/terminals`);
      },
      () => {
        toast.error('Failed to Delete Airport Terminal');
        setSelectedTerminal(undefined);
      }
    );
  };
  return (
    <Container>
      <Row>
        <Col>
          <AddEntityButton
            addIcon={faPlus}
            emptyEntityState={initTerminalsVals}
            path={`/admin/manage/airports/${aid}/terminals/add`}
            setEntity={setInitTerminalFormValues}
          />
          <Formik
            enableReinitialize
            onSubmit={values => {
              const isAdd = addEdit.toLowerCase() === 'add';
              const { terminalName, terminalShortName, axnStatus } = values;
              const endpoint = isAdd ? createAirportTerminalFromAPI : updateAirportTerminalFromAPI;

              const sendVals = {
                airportTerminalPK: {
                  airportId: +aid,
                  terminalId: +terminalID,
                  axnInstanceId: id
                },
                enplaneTotal: get(terminalValues, 'enplaneTotal', 0),
                deplaneTotal: get(terminalValues, 'deplaneTotal', 0),
                modifiedUserId: authenticatedUserId,
                modifiedDate: moment().format('YYYY-MM-DD'), // should be date type '2018-12-31T06:00:00.000+0000' of sorts maybe moment?
                terminal: { terminalId: +terminalID, terminalName, terminalShortName, axnStatus },
                passengerTotal: get(terminalValues, 'passengerTotal', 0),
                epDomesticTotal: get(terminalValues, 'epDomesticTotal', 0),
                epIntlTotal: get(terminalValues, 'epIntlTotal', 0),
                annualPercentChangeAmount: get(terminalValues, 'annualPercentChangeAmount', 0)
              };

              endpoint(+aid, id, terminalID || '', sendVals).then(
                () => {
                  toast.success(`Successfully ${ucFirstAddEdit}ed Airport Terminal!`);
                  appHistory.push(`/admin/manage/airports/${aid}/terminals`);
                },
                error => {
                  const errorMsg = get(
                    error.response,
                    'data.message',
                    `Failed to ${ucFirstAddEdit} Airport Terminal!`
                  );
                  toast.error(errorMsg);
                }
              );
            }}
            initialValues={initTerminalFormValues}
            render={({ handleBlur, handleChange, values }) => {
              return (
                <Form className={cssClass}>
                  <h1 className={'mb-3'}>{ucFirstAddEdit} Airport Terminal</h1>
                  <Row>
                    <FormField
                      name="terminalName"
                      label="Terminal Name"
                      type="text"
                      value={values.terminalName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      match={match}
                      col={{ xl: 12, lg: 12 }}
                    />
                    <FormField
                      name="terminalShortName"
                      label="Terminal Short Name"
                      type="text"
                      value={values.terminalShortName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      match={match}
                      col={{ xl: 12, lg: 12 }}
                    />
                    <AxnStatusSelectField
                      axnStatusValue={values.axnStatus || 'ACTIVE'}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                    />
                  </Row>
                  <ButtonGroup className="mt-5">
                    <GoBack color={'secondary'} />
                    {addEdit.toLowerCase() === 'edit' && (
                      <Button
                        color={'dark'}
                        onClick={() => {
                          setSelectedTerminal(+terminalID);
                        }}>
                        <FontAwesomeIcon icon={faTrash} /> Delete
                      </Button>
                    )}
                    <Button color="primary" type="submit">
                      {addEdit === 'edit' ? (
                        <>
                          <FontAwesomeIcon icon={faUserEdit} /> Save
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faUserPlus} /> Add
                        </>
                      )}
                    </Button>
                  </ButtonGroup>
                </Form>
              );
            }}
          />
        </Col>
      </Row>
      <Confirm
        isOpen={selectedTerminal !== undefined}
        cancel={_event => setSelectedTerminal(undefined)}
        success={deleteTerminal}
        successTitle="Delete">
        <p>Are you sure you wish to delete this terminal?</p>
      </Confirm>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  activeInstance: state.wizard.activeInstance,
  authenticatedUserId: state.users.authenticatedUser.userId
});

export default connect(mapStateToProps)(withRouter(AddEditTerminalView));
