import React, { CSSProperties, useEffect, useRef } from 'react';

interface ListItemButtonProps {
  className: string;
  handleClick: (e: React.MouseEvent) => void;
  label: string;
  value: string;
  isActive: boolean;
}

const ListItemButton = (props: ListItemButtonProps) => {
  const { className, handleClick, label, value, isActive } = props;
  const style: CSSProperties = isActive
    ? {
        textAlign: 'center',
        width: '100%',
        zIndex: 1000000,
        position: 'relative'
      }
    : {
        textAlign: 'center',
        width: '100%'
      };
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isActive && buttonRef && buttonRef.current) {
      const { current } = buttonRef;
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      current.scrollIntoView({
        behavior: 'auto',
        block: 'nearest'
      });
    }
  }, [isActive]);

  return (
    <button
      style={style}
      className={`${className} btn btn-link`}
      onMouseDown={handleClick}
      value={value}
      ref={buttonRef}
      type="button">
      {label}
    </button>
  );
};

export default ListItemButton;
