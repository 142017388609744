import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField from './FormField';
import useJobCategories from '../../api/jobCategory';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

/* TODO: Fix the any */
const JobCategorySelectField = (props: {
  values: any;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: any;
  handleBlur: any;
  displayRequired: boolean;
}) => {
  const { values, match, handleChange, handleBlur, setFieldValue } = props;
  const { jobCategories, jobCategoriesLoading } = useJobCategories();
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();
  useEffect(() => {
    const aclist = jobCategories.map(jobCategory => ({
      ...jobCategory,
      label: jobCategory.jobCategory,
      key: jobCategory.jobCategory,
      value: jobCategory.jobCategory
    }));
    setAutoCompleteList(aclist);
  }, [jobCategories]);

  useEffect(() => {
    const jobCategory = jobCategories.find(c => c.jobCategory === values.jobCategory);
    if (jobCategory) setFieldValue('jobCategoryName', jobCategory.jobCategory, false);
  }, [jobCategories, setFieldValue, values.jobCategory, values.jobCategoryName]);

  if (jobCategoriesLoading) return <SelectFieldSpinner />;
  return (
    <FormField
      name="jobCategory"
      labelName="jobCategoryName"
      label={
        (<span>Job Category {props.displayRequired ? <i>(Required)</i> : ''}</span>) as JSX.Element
      }
      type="autocomplete"
      match={match}
      value={values.jobCategory}
      labelValue={values.jobCategoryName}
      handleChange={handleChange}
      handleBlur={handleBlur}
      autoCompleteList={autoCompleteList}
      col={{ lg: 12 }}
      setFieldValue={setFieldValue}
    />
  );
};

export default JobCategorySelectField;
