import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Spinner } from 'reactstrap';
import { MasterListPagesize, MasterListPagination } from '../masterlist';
import MasterList, { TableColumn } from '../masterlist/MasterList';
import { CompanyData } from '../../actions/companiesActions';
import { IndexableMasterListItem } from '../masterlist/types';
import { TitleButton } from '../shared/title';
import { useCompanySearch } from '../../api/Companies';
import { cssClass } from '../wizard/cssSharedClasses';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';

export interface SearchResultData {
  companyName: JSX.Element;
  url: string;
  location: string;
  axnStatus: string;
}

const link = (data: CompanyData) => (
  <Link target="_blank" to={`/company/${data.companyId}`}>
    {data.companyName}
  </Link>
);

const location = (data: CompanyData) =>
  `${data.city !== undefined ? `${data.city}, ` : ''}
   ${data.stateProvinceCode || ''}
   ${data.isoCountryCode || ''}`;

const convert = (data: CompanyData): SearchResultData => ({
  companyName: link(data),
  url: data.url,
  location: location(data),
  axnStatus: data.axnStatus
});

const columns: TableColumn[] = [
  { id: 'companyName', title: 'Name' },
  { id: 'location', title: 'Location' },
  { id: 'url', title: 'Web site' },
  { id: 'axnStatus', title: 'Status' }
];

interface SearchResultProps {
  searchTerm: string;
  axnInstanceId: number;
}

export const SearchResult = ({ searchTerm, axnInstanceId }: SearchResultProps) => {
  const model = useCompanySearch(searchTerm, axnInstanceId);
  const { setPageSize, setCurrentPage } = model;
  const { content, pageSize, numberOfElements, totalRecords, currentPage } = model.data;

  useEffect(() => {
    model.setCurrentPage(0);
  }, [searchTerm])

  const getContentComponent = () => {
    if (model.busy) return <Spinner id="search-results-loader" xl={6} color="secondary" className="d-flex justify-content-center mr-auto ml-auto" />;
    else
        return <MasterList
            columns={columns}
            list={content.map(convert) as IndexableMasterListItem[]}
            actions={() => []}
            currentPage={currentPage}
            itemCount={numberOfElements}
            listType={'Companies'}
            loading={false}
            readonly
        />
  }

  return (
    <Container className={cssClass}>
      <TitleButton>Search Results</TitleButton>
      <div className="d-flex justify-content-between pt-3">
        <MasterListPagesize size={pageSize} setPagesize={setPageSize} />
        <MasterListPagination
          totalItems={totalRecords}
          currentPage={currentPage}
          handlePaginationSelection={setCurrentPage}
          pageSize={pageSize}
        />
      </div>
      { getContentComponent() }
      <div className="py-3 d-flex justify-content-between">
        <MasterListPagesize size={pageSize} setPagesize={setPageSize} />
        <MasterListPagination
          totalItems={totalRecords}
          currentPage={currentPage}
          handlePaginationSelection={setCurrentPage}
          pageSize={pageSize}
        />
      </div>
    </Container>
  );
};
