import React, { useEffect, useState } from 'react';
import { getCompanyFromAPI } from '../../api/Companies';
import { CompanyData } from '../../actions/companiesActions';

interface CompanyInfoProps {
  contactID: string;
}

const empty: CompanyData = {
  companyId: 0,
  companyName: '',
  address1: '',
  address2: '',
  city: '',
  postalCode: '',
  stateProvinceCode: '',
  isoCountryCode: '',
  modifiedUserId: 0,
  modifiedDate: '',
  axnStatus: '',
  phone: '',
  fax: '',
  url: '',
  companyInfoText: ''
};

interface CompanyDataProps {
  company: CompanyData;
}

const Address = ({ company }: CompanyDataProps) => {
  const address2 =
    company.address2 !== undefined && company.address2.length > 0
      ? [<br key={1} />, company.address2]
      : [];
  return (
    <p>
      {company.address1}
      {address2} <br />
      {company.city}, {company.stateProvinceCode} {company.postalCode} <br />
      {company.isoCountryCode}
    </p>
  );
};

interface LinkProps {
  url: string;
}

const Website = ({ url }: LinkProps) => (
  <NotEmpty value={url}>
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={
        url !== undefined && url.startsWith('http') ? url : `http://${url}`
      }>
      {url}
    </a>
  </NotEmpty>
);

interface NotEmptyProps {
  value: string;
  children: JSX.Element[] | JSX.Element | string;
}

// Only displays the children if `value` is not empty
const NotEmpty = ({ children, value }: NotEmptyProps) =>
  value === undefined || value.length < 1 ? <div></div> : <div>{children}</div>;

const Phone = ({ company }: CompanyDataProps) => (
  <NotEmpty value={company.phone}>
    <div>Phone: {company.phone}</div>
  </NotEmpty>
);

const Fax = ({ company }: CompanyDataProps) => (
  <NotEmpty value={company.fax}>
    <div>Fax: {company.fax}</div>
  </NotEmpty>
);

export const CompanyInfo = ({ contactID }: CompanyInfoProps) => {
  const [info, setInfo] = useState(empty);
  useEffect(() => {
    getCompanyFromAPI(contactID).then(setInfo);
  }, [contactID]);

  return (
    <div>
      <h1 className="step-header">{info.companyName}</h1>
      <p>{info.companyInfoText}</p>
      <Address company={info} />
      <Phone company={info} />
      <Fax company={info} />
      <Website url={info.url} />
    </div>
  );
};
