import { ContactList } from './types';

export default [
  {
    name: 'Information',
    title: '',
    phone: null,
    mobile: null,
    email: 'info@airportxnews.com'
  },
  {
    name: 'Melissa Montes',
    title: 'Vice President & Publisher Clarion NA DEIB Business Lead',
    phone: null,
    mobile: null,
    email: 'mel@airportxnews.com'
  },
  {
    name: 'Barb McCarter',
    title: 'Portfolio Manager',
    phone: null,
    mobile: null,
    email: 'barb@airportxnews.com'
  },
  {
    name: 'Carol Ward',
    title: 'Editor in Chief',
    phone: null,
    mobile: null,
    email: 'carol@airportxnews.com'
  },
  {
    name: 'Andrew Tellijohn',
    title: 'Senior Reporter',
    phone: null,
    mobile: null,
    email: 'andrew@airportxnews.com'
  },
  {
    name: 'Jean Claude Chaouloff',
    title: 'Business Development Manager',
    phone: null,
    mobile: null,
    email: 'jean@airportxnews.com'
  }
] as ContactList;
