import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Input } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import OptionsList from './OptionsList';

export interface AutoCompleteOption {
  label: string;
  value: string;
  key: string;
}

interface AutoCompleteProps {
  value: string;
  labelValue: string;
  name: string;
  labelName: string;
  options: AutoCompleteOption[];
  onChange: (e: React.ChangeEvent<any>, label: string, value: string) => void;
  onFocus?: (e: React.FocusEvent, setInput: (...props: any) => void) => void;
  onBlur: (e: React.FocusEvent) => void;
  onSelect: (e: React.ChangeEvent<any>, label: string, value: string) => void;
  setFieldValue?: (...props: any) => void;
  browserAutoComplete?: string;
}

const AutoComplete = (props: AutoCompleteProps) => {
  const {
    name,
    options,
    onBlur,
    onChange,
    onFocus,
    value,
    labelValue,
    labelName,
    setFieldValue,
    onSelect,
    browserAutoComplete
  } = props;
  const [activeOption, setActiveOption] = useState(0);
  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const [inputLabel, setInputLabel] = useState(labelValue);
  useEffect(() => {
    setInputLabel(labelValue);
  }, [labelValue]);

  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const getFilteredOpts = (userInput: string) =>
    options.filter(option => option.label.toLowerCase().includes(userInput.toLowerCase()));

  /* handles change of user typing in input */
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    const userInput = event.currentTarget.value;
    const currentOptions = getFilteredOpts(userInput || '');
    setFilteredOptions(currentOptions);
    setActiveOption(0);
    setShowOptions(true);
    setInputLabel(userInput);
    if (setFieldValue) {
      setFieldValue(name, userInput, false);
      if (currentOptions[0]) {
        setFieldValue(labelName, currentOptions[0].value, false);
        setInputValue(currentOptions[0].value);
      } else {
        setFieldValue(labelName, '', false);
        setInputValue('');
      }
    }

    // handle formik change
    onChange(event, inputLabel, inputValue);
  };

  /* Handle enter (13), up (38), down (40) key presses */
  const onKeyDown = (e: React.KeyboardEvent) => {
    const nextOption = activeOption + 1;
    const prevOption = activeOption - 1;
    const resetOption = 0;
    if (e.keyCode === 13) {
      e.preventDefault(); // don't submit form.

      // if enter key
      setActiveOption(resetOption);
      setShowOptions(false);

      // set label in value to empty in the case that the user enters a value that does not exist in the auto complete list.
      const optionLabel = get(filteredOptions[activeOption], 'label', '');
      const optionValue = get(filteredOptions[activeOption], 'value', '');

      /* set the input for the managed field */
      if (filteredOptions) {
        setInputLabel(optionLabel);
        setInputValue(optionValue);

        if (setFieldValue) {
          setFieldValue(name, optionValue, false);
          setFieldValue(labelName, optionLabel, false);
        }
      }

      onChange(e, optionLabel, optionValue);
    } else if (e.keyCode === 38) {
      // if up key
      if (activeOption !== 0) setActiveOption(prevOption);
    } else if (e.keyCode === 40) {
      // if down key
      if (nextOption !== filteredOptions.length) setActiveOption(nextOption);
    }
  };

  const handleFocus = (event: React.FocusEvent<any>) => {
    setShowOptions(true);
    if (!inputValue) setFilteredOptions(options);
    if (onFocus) onFocus(event, setInputLabel);
  };

  const handleBlur = (event: React.FocusEvent<any>) => {
    setShowOptions(false);
  };

  const handleSelectClick = (
    event: React.ChangeEvent,
    selectedLabel: string,
    selectedValue: string
  ) => {
    setInputValue(selectedValue);
    setInputLabel(selectedLabel);
    if (setFieldValue) {
      setFieldValue(name, selectedValue, false);
      setFieldValue(labelName, selectedLabel, false);
    }
    onSelect(event, selectedLabel, selectedValue);
  };
  const hasOptions = filteredOptions.length > 0;

  const icon = showOptions ? faChevronUp : faChevronDown;
  return (
    <div id={name} className="position-relative" onBlur={handleBlur}>
      <Input
        name={name}
        type="text"
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={handleFocus}
        onKeyDown={onKeyDown}
        value={inputValue}
        autoComplete={browserAutoComplete || name}
        hidden
        disabled
      />
      <div className={'position-relative'}>
        <Input
          name={labelName}
          type="text"
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={handleFocus}
          onKeyDown={onKeyDown}
          value={inputLabel}
          autoComplete={browserAutoComplete || labelName}
          placeholder="Please Select"
          style={{
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            borderBottomLeftRadius: showOptions && hasOptions ? '0' : '5px',
            borderBottomRightRadius: showOptions && hasOptions ? '0' : '5px'
          }}
        />
        <FontAwesomeIcon
          icon={icon}
          className={'position-absolute'}
          style={{
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            pointerEvents: 'none'
          }}
        />
      </div>
      {showOptions && (
        <OptionsList
          activeOption={activeOption}
          filteredOptions={filteredOptions}
          showOptions={showOptions}
          setShowOptions={setShowOptions}
          setFilteredOptions={setFilteredOptions}
          getFilteredOpts={getFilteredOpts}
          setInput={setInputLabel}
          userInput={inputLabel}
          onClick={handleSelectClick}
        />
      )}
    </div>
  );
};

export default AutoComplete;
