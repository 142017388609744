import React from 'react';
import { FaqProps } from './types';
import ResponsiveTable from "../table/ResponsiveTable";

const Faq = (props: FaqProps) => {
  const { question, answer } = props;
  return (
    <ResponsiveTable>
      <tbody>
        <tr>
          <td>{question}</td>
        </tr>
        <tr>
          <td>{answer}</td>
        </tr>
      </tbody>
    </ResponsiveTable>
  );
};

export default Faq;
