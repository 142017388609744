import React, { useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { AffiliateObjectType } from './AffiliateToObjectForm';
import { UserData } from '../../../actions/usersActions';
import MasterList, { TableColumn } from '../../masterlist/MasterList';
import { PageableData } from '../../../api';
import { MasterListActionButton } from '../../masterlist/types';
import airportModel from './AirportAffiliations';
import companyModel from './CompanyAffiliations';

type AffiliationActions = (user: UserData, instanceId: number, refresh: () => void) =>
  (companyId: number) => MasterListActionButton[]

export interface AffiliationData<T> {
  fetchData: (user: UserData, instanceId: number) => PageableData<T>,
  actions: AffiliationActions,
  columns: TableColumn[]
}

const modelFor = (objectType: AffiliateObjectType) => {
  switch (objectType) {
    case 'Airport':
      return airportModel;
    default:
      return companyModel;
  }
}

export interface CurrentAffiliationsProps {
  handleDelete: () => void;
  instanceId: number;
  objectType: AffiliateObjectType;
  user: UserData;
}

const CurrentAffiliations = ({handleDelete, instanceId, objectType, user}: CurrentAffiliationsProps) => {
  const {fetchData, actions, columns} = modelFor(objectType)
  const model = fetchData(user, instanceId)
  const {content} = model.data;

  const refresh = useCallback(() => {
    handleDelete()
    model.fetchData()
  }, [handleDelete, model])

  return (
    <div className="axn-component">
      <Row>
        <Col>
          <h3 className={'card-title'}>
            {`Current ${objectType} Affiliations for ${user.firstName} ${user.lastName}`}
          </h3>
        </Col>
      </Row>
      <MasterList
        actions={actions(user, instanceId, refresh)}
        additionalValues={['airportName']}
        columns={columns}
        currentPage={1}
        itemCount={content.length}
        listType="Affiliations"
        list={content}
        loading={false}
      />
    </div>
  );
}

export default CurrentAffiliations;
