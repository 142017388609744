import React, { useState, useEffect } from 'react';
import { AppState, appHistory } from '../../../reducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Button, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStoreAlt,
  faEdit,
  faFilePdf,
  faFileExcel,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FilteredMasterList } from '../../masterlist/filter/FilteredMasterList';
import { IndexableMasterListItem, MasterListActionButton } from '../../masterlist/types';
import { useFilteredCompanies } from '../../../api/Companies';
import { getBookReport } from '../../../api/reports';
import MasterList, { TableColumn } from '../../masterlist/MasterList';
import AddEntityButton from '../AddEntityButton';
import { AxnStatus } from '../../../types';
import { paginationClasses } from '../adminUtils';
import { MasterListPagesize, MasterListPagination } from '../../masterlist';
import GoBack from '../../buttons/GoBack';
import { RouteComponentProps } from 'react-router-dom';
import { AuthenticatedUser } from '../../../actions/usersActions';
import {
  WizardActionsCreator,
  AvailableInstanceMasterStatus
} from '../../../actions/wizardActions';
import {
  getAllBrandsFromAPI,
  getBrandsFromAPIPaged,
  BrandData,
  deleteBrandFromAPI,
  useFilteredBrands
} from '../../../api/Brands';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { initBrandVals } from './AddEditBrandView';
import Confirm from '../users/Confirm';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';

interface BrandManagerProps extends RouteComponentProps<any> {
  authenticatedUser: AuthenticatedUser;
}

const brandColumnsMap: TableColumn[] = [
  { id: 'brandId', title: 'ID' },
  { id: 'brandName', title: 'Name', width: '40%' },
  { id: 'axnStatus', title: 'Status' }
];

const BrandManager = (props: BrandManagerProps) => {
  const { authenticatedUser, match } = props;

  const [brands, setBrands] = useState([]);
  const [initBrandValues, setInitBrandValues] = useState(initBrandVals);
  const [brandsCount, setBrandsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationSize, setPaginationSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedBrandId, setSelectedBrandId] = useState<number | undefined>();
  const [dirty, setDirty] = useState<Boolean>(true);

  const convertAs = (data: BrandData): IndexableMasterListItem => data as IndexableMasterListItem;

  const rowActions = () => (brandId: number): MasterListActionButton[] => {
    return [
      {
        to: `/admin/manage/brands/edit/${brandId}`,
        icon: faEdit,
        text: 'Edit'
      },
      {
        to: `/admin/manage/brands`,
        icon: faTrash,
        text: 'Delete',
        actionCallback: () => setSelectedBrandId(brandId)
      }
    ];
  };

  const useSetPaginationSize = (pageSize: number) => {
    setCurrentPage(0);
    setPaginationSize(pageSize);
    setDirty(true);
  };

  const usePaginationSelection = (pageNum: number) => {
    setCurrentPage(pageNum - 1);
    setDirty(true);
  };

  const deleteBrand = () => {
    deleteBrandFromAPI(selectedBrandId || -1).then(
      () => {
        toast.success('Successfully Deleted Brand');
        setSelectedBrandId(undefined);
        setDirty(true);
        appHistory.push(`/admin/manage/brands`);
      },
      () => {
        toast.error(`Failed to Delete Brand ${selectedBrandId}`);
        setSelectedBrandId(undefined);
      }
    );
  };

  useEffect(() => {
    let mounted = true;
    let hasContent = false;
    if (dirty) {
      setLoading(true);
      getBrandsFromAPIPaged(currentPage, paginationSize)
        .then(res => {
          if (mounted) {
            hasContent = res.data.content != null;
            const { content, pageable, totalElements } = res.data;

            setBrands(content);

            setBrandsCount(totalElements);
            setPaginationSize(pageable.pageSize);
            setLoading(false);
          }
        })
        .catch(error => {
          if (mounted) {
            setLoading(false);
            const errorMsg = hasContent
              ? get(error.response, 'data.message', 'Failed to Retrieve Terminals!')
              : 'No Terminals Available!';
            if (hasContent) toast.error(errorMsg);
            else toast.warn(errorMsg);
          }
        });

      setDirty(false);
    }

    return () => {
      mounted = true;
    };
  }, [paginationSize, currentPage, dirty]);

  return (
    <>
      <h4>Manage Brands</h4>
      <AddEntityButton
        addIcon={faPlus}
        emptyEntityState={initBrandValues}
        path={`/admin/manage/brands/add`}
        setEntity={setInitBrandValues}
      />

      <FilteredMasterList
        actions={rowActions()}
        args={''}
        columns={brandColumnsMap}
        convert={convertAs}
        listType="brands"
        readonly={false}
        additionalValues={[]}
        useData={useFilteredBrands}
        axnInstanceId={-1}
      />
      <Confirm
        isOpen={selectedBrandId !== undefined}
        cancel={_event => setSelectedBrandId(undefined)}
        success={deleteBrand}
        successTitle="Delete">
        <p>Are you sure you wish to delete this brand?</p>
      </Confirm>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  authenticatedUser: state.users.authenticatedUser,
  activeInstance: state.wizard.activeInstance.id,
  activeInstanceStatus: state.wizard.activeInstance.status
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BrandManager);
