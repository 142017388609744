import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import { AllowedWizTypes, StepDefaultProps, WizardStepperMatch } from '../../../types';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import { AppState } from '../../../../../reducers';
import {
  ContactData,
  InitGetContacts,
  initGetContacts
} from '../../../../../actions/contactsActions';
import TerminalList from '../terminals/TerminalList';
import { TerminalsList } from '../../../../../actions/terminalsActions';
import { useAirportTenantTypes } from '../../../../../api/Airports';
import ExistingTerminals from '../info/ExistingTerminals';
import ExistingTenants from '../tenants/ExistingTenants';
import { ReviewAirportwideInfo } from './ReviewAirportwideInfo';
import { ReviewSpecifics } from './ReviewSpecifics';
import { ReviewGrossSales } from './ReviewGrossSales';
import ReviewRevenue from './ReviewRevenue';
import { AirportInstance } from '../../../../../types';
import ReviewContacts from './ReviewContacts';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';
import ReviewExpansions from './ReviewExpansions';
import { AuthenticatedUser } from '../../../../../actions/usersActions';

interface ReviewStepProps extends StepDefaultProps {
  authenticatedUser: AuthenticatedUser;
  contentType: AllowedWizTypes;
  contacts: ContactData[];
  contactsLoading: boolean;
  match: WizardStepperMatch;
  setContacts: InitGetContacts;
  terminals: TerminalsList;
  terminalsLoading: boolean;
  airportInstance: AirportInstance;
  airportLoading: boolean;
  activeInstance: ActiveInstance;
  activeInstanceStatus: string;
}

/* TODO: move this to a more sensible location. */
export const vnfMsg = 'Value Not Found';

export const ReviewStep = (props: ReviewStepProps & StepDefaultProps) => {
  const {
    authenticatedUser,
    airportInstance,
    airportLoading,
    contacts,
    contactsLoading,
    match,
    setContacts,
    terminals,
    terminalsLoading,
    activeInstance,
    activeInstanceStatus
  } = props;
  const { id } = activeInstance;
  const { contentID, contentType } = match.params;
  const { airportName } = airportInstance.airport;

  const {
    axnInstanceId,
    axnPendingInstanceId,
    axnInstanceName,
    axnPendingInstanceName
  } = authenticatedUser;

  const instanceIdToUse = activeInstanceStatus === 'PENDING' ? axnPendingInstanceId : axnInstanceId;
  const instanceYearToUse = activeInstanceStatus === 'PENDING' ? axnPendingInstanceName : axnInstanceName;

  /* get all the contacts associated with the current airport */
  useEffect(() => {
    setContacts(contentID, 'airports', id);
  }, [contacts.length, contentID, id, setContacts]);

  /* Tenants Review */
  const { tenantTypes, tenantTypesLoading } = useAirportTenantTypes();
  const [lastUpdated, setLastUpdated] = useState(moment().unix());
  return (
    <Row>
      <Col>
        <Container>
          <StepHeader stepTitle="Airport Review" />
          <Row>
            <ReviewContacts
              contactsLoading={contactsLoading}
              contacts={contacts}
              contentID={contentID}
              contentType={contentType}
              selectedContact={() => false}
              axnInstanceId={id}
            />
          </Row>
          <Row>
            <Col className={cssClass}>
              <Row>
                <Col xl={5}>
                  <StepHeader Tag="h3" stepTitle="Airport Wide Information" />
                  <ReviewAirportwideInfo airport={airportInstance} />
                  <ReviewExpansions expansions={airportInstance.airportExpansionList || []} />
                </Col>
                <Col xl={7}>
                  <ExistingTerminals
                    airportName={airportName}
                    terminalsLoading={terminalsLoading}
                    terminals={terminals}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className={cssClass}>
              <StepHeader Tag="h3" stepTitle="Passenger Traffic" />
              <TerminalList
                terminals={terminals}
                loading={terminalsLoading}
                url={match.url}
                readonly
              />
            </Col>
          </Row>
          <Row>
            <ReviewSpecifics airportLoading={airportLoading} airport={airportInstance} />
          </Row>
          <Row>
            <ReviewGrossSales contentID={+contentID} terminals={terminals} axnInstanceId={id} />
          </Row>
          <Row>
            <ReviewRevenue
              airport={airportInstance}
              airportLoading={airportLoading}
              contentID={contentID}
              axnInstanceId={instanceIdToUse}
            />
          </Row>
          <Row>
            <Col className={cssClass}>
              <ExistingTenants
                tenantTypes={tenantTypes}
                contentType={contentType}
                contentID={contentID}
                lastUpdated={lastUpdated}
                setLastUpdated={setLastUpdated}
                title="Concession Tenant Details"
                loading={tenantTypesLoading}
                axnInstanceId={id}
                readonly
              />
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};
const mapStateToProps = (state: AppState) => ({
  airportInstance: state.airports.airportInstance,
  airportLoading: state.airports.loading,
  contacts: state.contacts.contacts,
  contactsLoading: state.contacts.loading,
  terminals: state.terminals.terminals,
  terminalsLoading: state.terminals.loading,
  activeInstance: state.wizard.activeInstance,
  authenticatedUser: state.users.authenticatedUser,
  activeInstanceStatus: state.wizard.activeInstance.status
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setContacts: initGetContacts(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewStep);
