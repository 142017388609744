import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { SingleDatePicker } from 'react-dates';

interface SingleDatePickerProps {
  name: string;
  initDate: Moment | null;
  displayPastDates?: boolean;
  setFieldValue: (...props: any) => void;
}

const SingleDatePickerWrapper = (props: SingleDatePickerProps) => {
  const {setFieldValue, name, initDate, displayPastDates} = props;
  const autoFocus = false;

  const [focus, setFocus] = useState(autoFocus);
  const [date, setDate] = useState<Moment | null>(initDate); // default to the current moment
  
  const onDateChange = (date: Moment | null) => {

    // There should always be a date expect on first render when useState does it's stuff.
    setFieldValue(name, (date) 
        ? date.format('YYYY-MM-DD')
        : null);

    return setDate(date)
  };
  
  const onFocusChange = (args: any) => setFocus(args.focused);

  return (
    <SingleDatePicker
      id={`${name}`}
      date={ date }
      displayFormat={'YYYY-MM-DD'}
      onDateChange={onDateChange}
      focused={focus}
      onFocusChange={onFocusChange}
      numberOfMonths={1}
      showClearDate
      isOutsideRange={(day) => !displayPastDates && !(day.isAfter(moment())) }
    />
  );
};

export default SingleDatePickerWrapper;
