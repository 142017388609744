import React, { Dispatch } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faEye,
  faEyeSlash,
  faNewspaper,
  faStamp,
  faUnlock,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { Button, ButtonGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { appHistory, AppState } from '../../reducers';
import { StepperBottomNavigatorProps } from './types';
import { updateAirportInstanceStatusFromAPI } from '../../api/Airports';
import { AxnInstanceStatus } from '../../types';
import { updateCompanyInstanceStatusFromAPI } from '../../api/Companies';
import { connect } from 'react-redux';

interface StepperBottomNavigatorUrlParams {
  contentID: string;
  contentType: string;
}

const StepperBottomNavigator = (
  props: StepperBottomNavigatorProps & RouteComponentProps<StepperBottomNavigatorUrlParams>
) => {
  const {
    authenticatedUser,
    currentAxnInstanceStatus,
    currentStepKey,
    match,
    nextStep,
    prevStep,
    stepsToMap,
    setContentInstance,
    activeInstanceStatus
  } = props;
  const { url, params } = match;
  const { contentID, contentType } = params;
  const {
    authorities,
    axnInstanceId,
    axnPendingInstanceId,
    axnInstanceName,
    axnPendingInstanceName
  } = authenticatedUser;

  const isAdmin = authorities.some(role => role.authority === 'ROLE_ADMIN');

  const instanceIdToUse = activeInstanceStatus === 'PENDING' ? axnPendingInstanceId : axnInstanceId;
  const instanceYearToUse = activeInstanceStatus === 'PENDING' ? axnPendingInstanceName : axnInstanceName;

  const handleStatusChange = (axnInstanceStatus: AxnInstanceStatus) => () => {
    const contentBasedStatusChange =
      contentType === 'airports'
        ? updateAirportInstanceStatusFromAPI
        : updateCompanyInstanceStatusFromAPI;

    return contentBasedStatusChange(+contentID, instanceIdToUse, axnInstanceStatus).then(
      () => {
        toast.success('Instance Status Updated Successfully!');
        setContentInstance(+contentID, instanceIdToUse);
      },
      error => {
        const message = get(
          error.response,
          'data.message',
          'Instance Status Failed to Successfully Update!'
        );
        toast.error(message);
      }
    );
  };

  return (
    <>
      <ButtonGroup>
        <Button
          color="primary"
          disabled={!stepsToMap[prevStep]}
          onClick={() => {
            return stepsToMap[prevStep]
              ? appHistory.push(stepsToMap[prevStep].url)
              : appHistory.push(url);
          }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Previous
        </Button>
        <Button
          color="primary"
          disabled={!stepsToMap[nextStep]}
          onClick={() => {
            return stepsToMap[nextStep] && appHistory.push(stepsToMap[nextStep].url);
          }}>
          Next <FontAwesomeIcon icon={faArrowRight} />
        </Button>
        {(currentAxnInstanceStatus === 'INPROGRESS' || currentAxnInstanceStatus === 'REJECTED') && (
          <Button
            color="primary"
            disabled={stepsToMap.length !== currentStepKey + 1}
            onClick={handleStatusChange('PENDING')}>
            Submit for Review <FontAwesomeIcon icon={faUpload} />
          </Button>
        )}
      </ButtonGroup>
      {isAdmin && (
        <ButtonGroup className="pt-5">
          {currentAxnInstanceStatus === 'PENDING' && (
            <>
              <Button color="primary" onClick={handleStatusChange('REJECTED')}>
                Unlock for Editing <FontAwesomeIcon icon={faUnlock} />
              </Button>
              <Button color="success" onClick={handleStatusChange('APPROVED')}>
                Approve for Publish <FontAwesomeIcon icon={faStamp} />
              </Button>
            </>
          )}
          {currentAxnInstanceStatus === 'APPROVED' && (
            <>
              <Button color="primary" onClick={handleStatusChange('PENDING')}>
                Send Back to Review <FontAwesomeIcon icon={faEye} />
              </Button>
              <Button color="success" onClick={handleStatusChange('PUBLISHED')}>
                Publish <FontAwesomeIcon icon={faNewspaper} />
              </Button>
            </>
          )}
          {currentAxnInstanceStatus === 'PUBLISHED' && (
            <Button color="primary" onClick={handleStatusChange('APPROVED')}>
              Unpublish <FontAwesomeIcon icon={faEyeSlash} />
            </Button>
          )}
        </ButtonGroup>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  activeInstanceStatus: state.wizard.activeInstance.status
});

export default connect(mapStateToProps, null)(withRouter(StepperBottomNavigator));
