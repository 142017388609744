import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { Form, Formik, FormikActions } from 'formik';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import { SelectFieldSpinner } from '../../../../shared/spinner/Spinner';
import FormField from '../../../../forms/FormField';
import CompanyTypeSelectField from '../../../../forms/CompanyTypeSelectField';
import { AllAirportsGeography } from '../../../../../pages/AirportsView';
import { getAllAirports } from '../../../../../api/Airports';
import { addCompanyAirportLocationFromAPI } from '../../../../../api/CompanyAirportLocation';
import { CompanyAirportLocationSchema } from './CompanyAirportLocationSchema';

const addCompanyAirportLocationInitValues = {
  locationLabel: '',
  locationId: '',
  airportId: '',
  companyType: '',
  companyTypeDes: ''
};
// type AddCompanyAirportLocationInitValues = typeof addCompanyAirportLocationInitValues;

const handleAddCompanyAirportLocationSubmit = (companyID: number, instanceID: number) => (
  values: any
  // formikActions: FormikActions<AddCompanyAirportLocationInitValues>
) => {
  const { airportId, companyType } = values;
  addCompanyAirportLocationFromAPI(companyID, instanceID, companyType, airportId).then(
    () => {
      toast.success('Success');
      setTimeout(() => window.location.reload(), 3000); // TODO: reload specific state of app instead of reloading entire app
    },
    () => {
      toast.error('Error');
    }
  );
};

interface AddCompanyAirportLocationProps {
  axnInstanceId: number;
}

const AddCompanyAirportLocation = (
  props: AddCompanyAirportLocationProps & RouteComponentProps<{ contentID: string }>
) => {
  const { match, axnInstanceId } = props;
  const { params } = match;
  const { contentID } = params;

  const [airports, setAirports] = useState<AllAirportsGeography[]>([]);
  const [airportsLoading, setAirportsLoading] = useState(false);

  useEffect(() => {
    setAirportsLoading(true);
    getAllAirports()
      .then(response => {
        setAirportsLoading(false);
        return setAirports(response.data);
      })
      .catch(error => {
        setAirportsLoading(false);
        toast.warn(error);
      });
  }, [airports.length]);

  const autoCompleteListLocation = airports.map(airport => ({
    ...airport,
    label: `${airport.city}, ${airport.airportState} (${airport.airportStateProvince}) (${airport.iataCode})`,
    key: airport.airportId,
    value: airport.airportId
  }));
  return (
    <Container className={cssClass}>
      <StepHeader stepTitle="Add Company Locations" />
      <Row>
        <Col xl={12} lg={12}>
          <Formik
            onSubmit={handleAddCompanyAirportLocationSubmit(+contentID, axnInstanceId)}
            initialValues={addCompanyAirportLocationInitValues}
            validationSchema={CompanyAirportLocationSchema}
            render={({ values, setFieldValue, handleBlur, handleChange }) => {
              return (
                <Form>
                  <Row>
                    {airportsLoading ? (
                      <SelectFieldSpinner />
                    ) : (
                      <FormField
                        name="locationId"
                        labelName="locationLabel"
                        labelValue={values.locationLabel || ''}
                        value={values.locationId || ''}
                        label="Airport"
                        type="autocomplete"
                        helpText="This field autocompletes, start typing a City, State or IATA code to pick an airport."
                        handleChange={(e, label, value) => {
                          const autoSelectAirport = autoCompleteListLocation.filter(location =>
                            location.label.toLowerCase().includes(value!.toLowerCase() || '')
                          );

                          const airportId = get(autoSelectAirport[0], 'airportId', value || 0);

                          setFieldValue('airportId', airportId, false);
                          handleChange(e);
                        }}
                        handleBlur={handleBlur}
                        match={match}
                        col={{ lg: 12 }}
                        autoCompleteList={autoCompleteListLocation}
                        setFieldValue={setFieldValue}
                        placeholder="City, State (IATA Code)"
                        browserAutoComplete={'off'}
                      />
                    )}
                    <CompanyTypeSelectField
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      match={match}
                      setFieldValue={setFieldValue}
                      label={'Company Type'}
                      companyId={+contentID}
                      axnInstanceId={axnInstanceId}
                      all={false}
                      browserAutoComplete={'off'}
                    />
                  </Row>
                  <Row className="pt-3">
                    <Col>
                      <ButtonGroup>
                        <Button color="primary" type="submit">
                          <FontAwesomeIcon icon={faPlus} /> Add Company Location
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(AddCompanyAirportLocation);
