import React, { useEffect } from 'react';
import { Button, Container, Row } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import FormField from '../../forms/FormField';
import { LoginFormValidation } from './yupSchema';
import { AppState } from '../../../reducers';
import { submitLogin } from '../../../actions/usersActions';
import { cssClass } from '../../wizard/cssSharedClasses';

interface LoginFormProps {
  isLoggedIn: boolean;
  submitLogin: (values: { username: string; password: string }) => void;
}

interface Params {
  contentID?: string;
}

const LoginFormUnconnected = (props: LoginFormProps & RouteComponentProps<Params>) => {
  const { match, submitLogin } = props;
  const col = {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Container
      tag={'section'}
      className={`${cssClass}`}
      style={{ width: '600px', maxWidth: '90%' }}>
      <div className="axn-component">
        <Formik
          initialValues={{
            username: '',
            password: ''
          }}
          onSubmit={submitLogin}
          validationSchema={LoginFormValidation}
          enableReinitialize
          render={({ handleSubmit, handleChange, handleBlur, values }) => {
            return (
              <form className="axn-form" id="loginForm" onSubmit={handleSubmit}>
                <h3 className="card-title">Login</h3>
                <Row>
                  <FormField
                    name={'username'}
                    label={'Email:'}
                    type={'text'}
                    match={match}
                    value={values.username}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={col}
                  />
                  <FormField
                    name={'password'}
                    label={'Password:'}
                    type={'password'}
                    match={match}
                    value={values.password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    col={col}
                  />
                </Row>
                <Button type="submit" color="dark">
                  Submit
                </Button>
              </form>
            );
          }}
        />

        <br />
        <Link to="/forgotpassword">Forgot Password?</Link>
      </div>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.users.isLoggedIn
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  submitLogin: submitLogin(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginFormUnconnected);
