import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import './pagination.scss';

interface WizardPagesizeProps {
  size: number;
  setPagesize: (...props: any) => void;
}

export const MasterListPagesize = (props: WizardPagesizeProps) => {
  const { setPagesize, size } = props;
  const handleChange = (e: React.FormEvent<HTMLInputElement>) =>
    setPagesize(e.currentTarget.value);
  return (
    <FormGroup className={'wizard-pagesize-select-wrapper'}>
      <Input
        className={'d-inline-block wizardPagesizeSelect'}
        type="select"
        name="wizardPagesizeSelect"
        onChange={handleChange}
        value={size}>
        <option>10</option>
        <option>20</option>
        <option>30</option>
        <option>40</option>
        <option>50</option>
        <option>75</option>
        <option>100</option>
      </Input>
    </FormGroup>
  );
};

export default MasterListPagesize;
