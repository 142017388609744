import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { AppState } from '../reducers';
import { AuthoritiesList, AuthorityRoles } from '../actions/usersActions';

interface RouteWithAuthorityProps extends RouteProps {
  isLoggedIn: boolean;
  authorities: AuthoritiesList;
  requiredAuthorities: AuthorityRoles[];
}

// checks that the user has the correct authority to view the child component
const RouteWithAuthority = (routeProps: RouteWithAuthorityProps) => {
  const {
    component,
    isLoggedIn,
    requiredAuthorities,
    authorities,
    ...rest
  } = routeProps;
  const Component: any = component;
  const validRequest =
    isLoggedIn &&
    requiredAuthorities.some(requiredAuthority =>
      authorities.find(r => r.authority === requiredAuthority)
    );

  return (
    <Route
      {...rest}
      render={moreProps => {
        if (!routeProps.isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: moreProps.location }
              }}
            />
          );
        }
        if (!validRequest) {
          return (
            <Redirect
              to={{
                pathname: '/unauthorized',
                state: { from: moreProps.location }
              }}
            />
          );
        }
        return <Component {...moreProps} />;
      }}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.users.isLoggedIn,
  authorities: state.users.authenticatedUser.authorities
});

export default connect(mapStateToProps)(RouteWithAuthority as FunctionComponent<
  RouteWithAuthorityProps
>);
