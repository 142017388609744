import * as React from 'react';

interface HeaderTag {
  level: number;
  classes: string;
}

const HeaderTag: React.FC<HeaderTag> = props => {
  const { level, classes } = props;
  switch (level) {
    case 2:
      return <h2 className={classes}>{props.children}</h2>;
    case 3:
      return <h3 className={classes}>{props.children}</h3>;
    case 4:
      return <h4 className={classes}>{props.children}</h4>;
    case 5:
      return <h5 className={classes}>{props.children}</h5>;
    default:
      return <h1 className={classes}>{props.children}</h1>;
  }
};

const AxnTitle = (props: { children: React.ReactElement | string }) => {
  return (
    <HeaderTag level={4} classes={'m-4 axn-title axn-subtitle'}>
      {props.children}
    </HeaderTag>
  );
};

export { AxnTitle };
