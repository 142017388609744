import React from 'react';
import { AirportExpansion } from '../../../../../types';
import ResponsiveTable from '../../../../table/ResponsiveTable';

interface RowProps {
  expansion: AirportExpansion;
}

const ExpansionRow = ({ expansion }: RowProps) => (
  <tr>
    <td>{expansion.expansionPlannedText}</td>
    <td>{expansion.areaTotal}</td>
    <td>{expansion.completionDateText}</td>
  </tr>
);

interface Props {
  expansions: AirportExpansion[];
}

const ReviewExpansions = ({ expansions }: Props) => (
  <ResponsiveTable>
    <thead>
      <tr>
        <th colSpan={3}>Planned Expansions</th>
      </tr>
      <tr>
        <th>Expansion Planned</th>
        <th>Additional Space</th>
        <th>Completion Date</th>
      </tr>
    </thead>
    <tbody>
      {expansions.map(e => (
        <ExpansionRow expansion={e} key={e.airportExpansionPK.expansionId} />
      ))}
    </tbody>
  </ResponsiveTable>
);

export default ReviewExpansions;
