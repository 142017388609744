import React from 'react';
import { Card, CardBody, CardTitle, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { panelFeatures } from '../../../resources/main/panel/config';
import './home.scss';

export const AdminIndexView = () => {
  return (
    <Row>
      <Col>
        <div className={'admin-panel-grid'}>
          {panelFeatures.map((item, index) => (
            <div key={index} className={'home-panel-card'}>
              <Link to={`/admin${item.path}`} className={'home-card-link'}>
                <Card
                  color={'light'}
                  className={'d-flex justify-content-center align-items-center py-5'}>
                  <FontAwesomeIcon icon={item.icon} className={'card-img-top'} size={'6x'} />
                  <CardBody
                    className={'w-75 text-center d-flex justify-content-center flex-column'}>
                    <CardTitle className={'text-center'}>{item.title}</CardTitle>
                    <ListGroup color={'secondary'}>
                      {item.bullets.map((val, key) => (
                        <ListGroupItem key={key}>{val}</ListGroupItem>
                      ))}
                    </ListGroup>
                  </CardBody>
                </Card>
              </Link>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};
