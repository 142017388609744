import { object, string, number } from 'yup';
import msgs from '../../../../../core/yupSchemas/msgs';

const expansionPlanSchema = object().shape({
  completionDateText: string().required(msgs.completeDate),
  areaTotal: number()
    .max(2147483647, 'Please provide an area size smaller than 2,147,483,647.')
    .min(0, 'Please provide an area size greater than 0.')
    .typeError(msgs.nan),
  expansionPlannedText: string().required(msgs.expansionPlannedText)
});

export default expansionPlanSchema;