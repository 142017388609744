import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField, { ChangeHandler } from './FormField';
import { useAirportConfigType } from '../../api/Airports';
import { AirportConfigType } from '../../types';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

const AirportConfigurationSelectField = (props: {
  values: AirportConfigType;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: ChangeHandler;
  handleBlur: (e: React.FocusEvent<Element>) => void;
}) => {
  const { values, match, handleChange, handleBlur, setFieldValue } = props;
  const { airportConfigTypes, airportConfigTypesLoading } = useAirportConfigType();
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();

  const [labelValue, setLabelValue] = useState(values.airportConfigurationName);
  const [value] = useState(values.airportConfigurationId);

  useEffect(() => {
    const aclist: AutoCompleteOption[] = airportConfigTypes
      .map(configType => ({
        ...configType,
        label: configType.airportConfigurationName,
        key: configType.airportConfigurationId + '',
        value: configType.airportConfigurationId + ''
      }))
      .sort((a, b) => {
        return a.label.toLowerCase() === 'none' ? 1 : a.label.localeCompare(b.label);
      })
      .filter(item => item.label !== '');
    setAutoCompleteList(aclist);
  }, [airportConfigTypes]);

  useEffect(() => {
    const airportConfig = airportConfigTypes.find(
      configType => +configType.airportConfigurationId === +values.airportConfigurationId
    );

    if (airportConfig) {
      setLabelValue(airportConfig.airportConfigurationName);
      setFieldValue('airportConfiguration', airportConfig.airportConfigurationName, false);
      setFieldValue('additionalInfoFlag', airportConfig.additionalInfoFlag, false);
    }
  }, [airportConfigTypes, values.airportConfigurationId]);

  if (airportConfigTypesLoading) return <SelectFieldSpinner />;

  return (
    <FormField
      name="airportConfigurationId"
      labelName="airportConfiguration"
      label="Airport Configuration"
      type="autocomplete"
      match={match}
      value={value}
      labelValue={labelValue}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      handleBlur={handleBlur}
      autoCompleteList={autoCompleteList}
      col={{ lg: 12 }}
      tooltipText={
        'Describes the shape of your airport. For example, T-shaped, U-shaped, Fingers, etc.'
      }
    />
  );
};

export default AirportConfigurationSelectField;
