import React, { useCallback } from 'react';
import { Button, FormGroup } from 'reactstrap';
import { PageableData } from '../../api';
import { AffiliateObjectType } from './affiliations/AffiliateToObjectForm';
import { AutoCompleteOption } from '../forms/autocomplete/AutoComplete';
import FormField from '../forms/FormField';

interface SelectProps<T> {
  handleSubmit: (key: string) => void;
  model: PageableData<T>;
  mkOption: (t: T) => AutoCompleteOption;
  objectType: AffiliateObjectType;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = () => {};

const SelectModelData = <T extends unknown>({
  handleSubmit,
  model,
  mkOption,
  objectType
}: SelectProps<T>) => {
  const [selected, setSelected] = React.useState<string>();
  const lowerCaseType = objectType.toLowerCase();
  const { content } = model.data;
  const isBusy = model.busy;

  const handleChange = useCallback(
    (_e: React.ChangeEvent<any>, _label?: string, value?: string) => {
      if (value !== undefined) {
        setSelected(value);
      }
    },
    [setSelected]
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<any, MouseEvent>) => {
      e.preventDefault();
      if (selected) handleSubmit(selected);
    },
    [selected, handleSubmit]
  );

  const label = () => isBusy ? `${objectType} (Loading):` : `${objectType}:`;

  return (
    <div className={'axn-form'} id={`affiliate${objectType}Form`}>
      <FormField
        autoCompleteList={content.map(mkOption)}
        name={lowerCaseType}
        label={label()}
        type="autocomplete"
        value=""
        handleChange={handleChange}
        handleBlur={noOp}
        match={{ params: {} }}
        col={{}}
        className="px-0"
      />
      <FormGroup>
        <Button color="danger" type="submit" onClick={handleClick}>
          Add
        </Button>
      </FormGroup>
    </div>
  );
};

export { SelectModelData };
