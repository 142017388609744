import update from 'immutability-helper';

import { initialInfoValues } from '../components/wizard/airport/steps/info/InfoStep';
import {
  AirportExpansion,
  AirportExpansionList,
  AirportInstance,
  AirportsList,
  YOrN
} from '../types';
import { AirportsAction } from '../actions/airportActions';
import { getAuthUserID } from '../core/authentication/authenticationHelper';

/* This Reducer setup is kind of wordy but it does offer type safe actions with a ton of IDE auto complete capabilities. */
export interface AirportState {
  airports: AirportsList;
  airportInstance: AirportInstance;
  loading: boolean;
  error: string;
}

/* Set Initial Airport State */
const initialAirportState = {
  airports: Array<AirportInstance>(),
  airportInstance: {
    airport: {
      airportId: -1,
      iataCode: '',
      airportName: 'Loading...',
      city: '',
      websiteUrl: '',
      latitudeText: 0,
      longitudeText: 0,
      stateProvinceCode: '',
      isoCountryCode: '',
      county: '',
      modifiedDate: '2019-02-01',
      modifiedUserId: getAuthUserID(),
      axnStatus: 'ACTIVE'
    } /* {
      airportId: 1,
      iataCode: 'YYC',
      airportName: 'Calgary International Airport',
      city: 'Calgary',
      websiteUrl: '',
      latitudeText: 51.1250641,
      longitudeText: -114.0190782,
      stateProvinceCode: 'AB',
      isoCountryCode: 'USA',
      county: 'CANADA',
      modifiedDate: '2019-02-01',
      modifiedUserId: getAuthUserID(),
      axnStatus: 'ACTIVE'
    } */,
    modifiedUserId: 0,
    airportConfigurationId: 1,
    configurationAdditionalInfoText: '',
    additionalInfoFlag: 'N' as YOrN,
    concessionManagementTypeId: 0,
    dwellTimeAmount: 0,
    passengerSvcRevenueTotal: 0,
    passengerSvcRevenueAirportTotal: 0,
    advertisingRevenueTotal: 0,
    advertisingRevenueAirportTotal: 0,
    currencyExchangeRevenueTotal: 0,
    currencyExchangeRevenueAirportTotal: 0,
    preSecurityPercentAmount: 0,
    postSecurityPercentAmount: 0,
    businessPercentAmount: 0,
    leisurePercentAmount: 0,
    odPercentAmount: 0,
    transferPercentAmount: 0,
    passengerTotal: 0,
    deplaneTotal: 0,
    enplaneTotal: 0,
    passengerEpDomesticTotal: 0,
    passengerEpIntlTotal: 0,
    acdbePercentageAmount: 0,
    carRentalOnsiteAgenciesTotal: 0,
    carRentalOnsiteRevenueTotal: 0,
    carRentalOnsiteRevenueAirportTotal: 0,
    carRentalOffsiteAgenciesTotal: 0,
    carRentalOffsiteRevenueTotal: 0,
    carRentalOffsiteRevenueAirportTotal: 0,
    carRentalTotal: 0,
    carRentalAreaTotal: 0,
    parkingRevenueTotal: 0,
    airportExpansionList: initialInfoValues.airportExpansionList,
    annualPercentageChangeAmount: 0
  },
  loading: false,
  error: ''
};

export type AirportsState = {
  airports: AirportInstance[];
  airportInstance: AirportInstance;
  loading: false;
  error: '';
};

export const airportsReducer = (
  state: AirportState = initialAirportState,
  action: AirportsAction
) => {
  switch (action.type) {
    /* Handle Async Start Actions. */
    case 'GET_AIRPORT':
    case 'GET_AIRPORTS':
    case 'UPDATE_ADD_AIRPORT':
    case 'DELETE_AIRPORT':
    case 'ADD_AIRPORT_EXPANSION':
    case 'UPDATE_AIRPORT_EXPANSION':
    case 'UPDATE_AIRPORT_INFO':
      return { ...state, isLoading: true };
    /* Handle Async Success */
    case 'GET_AIRPORT_SUCCESS':
    case 'UPDATE_ADD_AIRPORT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        airportInstance: action.airportInstance
      };
    case 'GET_AIRPORTS_SUCCESS':
      return { ...state, isLoading: false, airports: action.airports };
    case 'DELETE_AIRPORT_SUCCESS':
      return {
        ...state,
        airports: state.airports.filter(data => +data !== action.airportID),
        isLoading: false
      };
    case 'ADD_AIRPORT_EXPANSION_SUCCESS':
      return {
        ...state,
        airportInstance: {
          ...state.airportInstance,
          airportExpansionList: update(
            state.airportInstance.airportExpansionList,
            // @ts-ignore
            {
              $push: [action.expansionPlan] as AirportExpansionList
            }
          )
        }
      };
    case 'UPDATE_AIRPORT_EXPANSION_SUCCESS':
      return {
        ...state,
        airportInstance: {
          ...state.airportInstance,
          airportExpansionList: update(
            state.airportInstance.airportExpansionList,
            // @ts-ignore
            {
              [state.airportInstance.airportExpansionList!.length - 1 || 0]: {
                $set: action.expansionPlan
              }
            }
          )
        }
      };
    case 'UPDATE_AIRPORT_INFO_SUCCESS':
      return {
        ...state,
        airportInstance: {
          ...state.airportInstance,
          ...action.airportInfo,
          airportConfig: {
            airportConfigurationName:
              action.airportInfo.airportConfiguration?.airportConfigurationName,
            airportConfigurationId: action.airportInfo.airportConfiguration?.airportConfigurationId
          }
        }
      };
    /* Handle Async Failures */
    case 'GET_AIRPORT_FAILURE':
    case 'GET_AIRPORTS_FAILURE':
    case 'DELETE_AIRPORT_FAILURE':
    case 'UPDATE_ADD_AIRPORT_FAILURE':
    case 'ADD_AIRPORT_EXPANSION_FAILURE':
    case 'UPDATE_AIRPORT_EXPANSION_FAILURE':
    case 'UPDATE_AIRPORT_INFO_FAILURE':
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
