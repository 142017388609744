import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormField from './FormField';
import { AxnStatus } from '../../api';
import { getAxnStatusTypes } from '../../api/Companies';

type AxnStatusSelectFieldProps = {
  axnStatusValue: string;
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
};

const AxnStatusSelectField = (props: AxnStatusSelectFieldProps & RouteComponentProps) => {
  const { axnStatusValue, handleBlur, handleChange, match } = props;

  const [statuses, setStatuses] = useState(new Array<AxnStatus>());
  useEffect(() => {
    getAxnStatusTypes().then(
      response => setStatuses(response),
      () => toast.warn(`Failed to get AXN Statuses!`)
    );
  }, []);
  return (
    <FormField
      name={'axnStatus'}
      label={'Status'}
      type={'select'}
      match={match}
      value={axnStatusValue}
      handleChange={handleChange}
      handleBlur={handleBlur}
      col={{ xl: 12, lg: 12 }}>
      {statuses &&
        statuses.map(status => (
          <option key={window.btoa(status)} value={status}>
            {status}
          </option>
        ))}
    </FormField>
  );
};

export default withRouter(AxnStatusSelectField);
