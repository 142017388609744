import React, { useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ReactToPrint from 'react-to-print';
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row
} from 'reactstrap';
import { faEdit, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MasterList, { TableColumn } from '../masterlist/MasterList';
import { IndexableMasterListItem, MasterListActionButton } from '../masterlist/types';
import {
  AvailableInstanceMasterStatus,
  contentList,
  dispatchSwitchWizardInstance,
  onSwitchWizard
} from '../../actions/wizardActions';
import { AppState } from '../../reducers';
import './buttonGroup.scss';
import MasterListPagesize from '../masterlist/MasterListPagesize';
import MasterListPagination from '../masterlist/MasterListPagination';
import { AllowedWizTypes } from './types';
import { AuthenticatedUser } from '../../actions/usersActions';
import SearchField from '../search/SearchField';
import { searchUsers } from '../../api/Users';
import AxnInstanceFilter from '../forms/AxnInstanceFilter';

export interface WizardProps extends RouteComponentProps {
  authenticatedUser: AuthenticatedUser;
  loading: boolean;
  error: string | undefined;
  contentList: IndexableMasterListItem[];
  currentPage: number;
  totalPages: number;
  totalListItems: number;
  onSwitchWizard: any;
  switchWizardInstance: any;
  getContentList: any;
  wizType: AllowedWizTypes;
  activeInstanceStatus: string;
}

interface MatchContentType {
  contentType: string;
}

const WizardSearchList = (props: WizardProps) => {
  const {
    authenticatedUser,
    wizType,
    getContentList,
    contentList,
    onSwitchWizard,
    currentPage,
    totalListItems,
    loading,
    match,
    switchWizardInstance,
    activeInstanceStatus
  } = props;
  const {
    userId,
    axnInstanceId,
    axnPendingInstanceId,
    axnPendingInstanceName,
    axnInstanceName
  } = authenticatedUser;
  const isAdminOrRep = !!authenticatedUser.authorities.find(
    a =>
      a.authority === 'ROLE_ADMIN' ||
      a.authority === 'ROLE_AIRPORT' ||
      a.authority === 'ROLE_AIRPORT_SUB' ||
      a.authority === 'ROLE_COMPANY' ||
      a.authority === 'ROLE_COMPANY_SUB'
  );
  const defaultStatus = (isAdminOrRep
    ? activeInstanceStatus
    : 'ACTIVE') as AvailableInstanceMasterStatus;
  const [status, setStatus] = useState(defaultStatus);
  const [pageSize, setPagesize] = useState(10);
  const urlBasedWizType = (match.params as MatchContentType).contentType as AllowedWizTypes;
  const wizTypeToUse = urlBasedWizType || wizType;

  const setSearching = useState(false)[1];
  const [keyword, setKeyword] = useState('');
  useEffect(() => {
    const instanceIdToUse = status === 'PENDING' ? axnPendingInstanceId : axnInstanceId;
    const instanceNameToUse = status === 'PENDING' ? axnPendingInstanceName : axnInstanceName;

    switchWizardInstance(
      instanceIdToUse,
      status as AvailableInstanceMasterStatus,
      instanceNameToUse
    );

    getContentList(instanceIdToUse, wizTypeToUse, userId, currentPage - 1, pageSize, keyword);
  }, [wizTypeToUse, pageSize, getContentList, keyword, status]);

  /* Determine property names and table column titles for different content types */
  const companiesColumnsMap: TableColumn[] = [
    { id: 'companyId', title: 'ID' },
    { id: 'companyName', title: 'Company Name' },
    { id: 'modifiedDate', title: 'Last Modified' },
    { id: 'modifiedUserName', title: 'Changed By' },
    { id: 'axnStatus', title: 'Status' }
  ];

  const airportsColumnsMap: TableColumn[] = [
    { id: 'airportId', title: 'ID' },
    { id: 'iataCode', title: 'IATA' },
    { id: 'airportName', title: 'Airport Name', width: '600px' },
    { id: 'modifiedDate', title: 'Last Modified', width: '180px' },
    { id: 'modifiedUserName', title: 'Changed By', width: '220px' },
    { id: 'axnStatus', title: 'Status' }
  ];

  const currentWizardColumns =
    wizTypeToUse === 'airports' ? airportsColumnsMap : companiesColumnsMap;

  /* Determine the actions the should be shown based on content type. */
  const rowActions = (id: number): MasterListActionButton[] => {
    return [
      {
        to: `/wizard/${wizTypeToUse}/${id}/steps/info`, // start at contacts
        icon: faEdit,
        text: 'Edit'
      }
    ];
  };

  const handlePaginationSelection = (selectedPage: number) =>
    getContentList(axnInstanceId, wizTypeToUse, userId, selectedPage - 1, pageSize, keyword);

  const colSizes = {
    header: {
      adminOrRep: { xl: 2, lg: 3, md: 12, sm: 12, xs: 12 },
      subscriber: { xl: 3, lg: 4, md: 5, sm: 6, xs: 12 }
    },
    entityToggler: {
      adminOrRep: { xl: 8, lg: 7, md: 8, sm: 12, xs: 12 },
      subscriber: { xl: 9, lg: 8, md: 7, sm: 6, xs: 12 }
    },
    axnStatusFilter: {
      adminOrRep: { xl: 2, lg: 2, md: 4, sm: 12, xs: 12 },
      subscriber: { xl: 0, lg: 0, md: 0, sm: 0, xs: 0 }
    }
  };

  const headerColSize = isAdminOrRep ? colSizes.header.adminOrRep : colSizes.header.subscriber;

  const entityTogglerColSize = isAdminOrRep
    ? colSizes.entityToggler.adminOrRep
    : colSizes.entityToggler.subscriber;

  const axnStatusFilterColSize = isAdminOrRep
    ? colSizes.axnStatusFilter.adminOrRep
    : colSizes.axnStatusFilter.subscriber;

  const componentRef = useRef<any>();

  return (
    <Container>
      <ReactToPrint
        trigger={() => (
          <Button
            type={'button'}
            color={'primary'}
            className={'print-masterlist position-relative mb-3'}
            style={{
              left: '100%',
              transform: 'translatex(-100%)'
            }}>
            <FontAwesomeIcon icon={faPrint} /> Print
          </Button>
        )}
        content={() => componentRef.current}
      />
      <Row className={'py-0'}>
        <Col
          xl={headerColSize.xl}
          lg={headerColSize.lg}
          md={headerColSize.md}
          sm={headerColSize.sm}
          xs={headerColSize.xs}>
          <h3 className={'text-center py-4'}>{`Your Affiliations`}</h3>
        </Col>
        <Col
          className={'py-3'}
          xl={entityTogglerColSize.xl}
          lg={entityTogglerColSize.lg}
          md={entityTogglerColSize.md}
          sm={entityTogglerColSize.sm}
          xs={entityTogglerColSize.xs}>
          <ButtonGroup>
            <Button
              color={'primary'}
              onClick={onSwitchWizard}
              active={wizTypeToUse === 'airports'}
              value={'airports'}>
              Airports
            </Button>
            <Button
              color={'primary'}
              value={'companies'}
              onClick={onSwitchWizard}
              active={wizTypeToUse === 'companies'}>
              Companies
            </Button>
          </ButtonGroup>
        </Col>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {isAdminOrRep && (
          <AxnInstanceFilter
            axnStatusFilterColSize={axnStatusFilterColSize}
            setStatus={setStatus}
            status={status}
            authenticatedUser={authenticatedUser}
          />
        )}
      </Row>
      <div className={'py-3 d-flex search-list-tools justify-content-between'}>
        <MasterListPagesize size={pageSize} setPagesize={setPagesize} />
        <SearchField
          searchRequest={searchUsers}
          setKeyword={setKeyword}
          setSearching={setSearching}
          size={pageSize}
          page={currentPage}
        />
        <MasterListPagination
          pageSize={pageSize}
          totalItems={totalListItems}
          handlePaginationSelection={handlePaginationSelection}
          currentPage={currentPage + 1}
        />
      </div>
      <Row>
        <Col>
          <MasterList
            loading={loading}
            listType={wizTypeToUse}
            columns={currentWizardColumns}
            list={contentList}
            actions={rowActions}
            currentPage={currentPage}
            itemCount={totalListItems}
            ref={componentRef}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert color={'info'} className={'border my-3 d-block'}>
            <ListGroup>
              <ListGroupItem>
                Based on Bank of Canada average exchange rate for 2023, the rate of conversion is
                $1CAD to $0.7410 USD.
              </ListGroupItem>
              <ListGroupItem>
                To add additional Airports/Companies to your list, please contact us at{' '}
                <Link to={'mailto:info@airportxnews.com'}>info@airportxnews.com.</Link>
              </ListGroupItem>
              <ListGroupItem>
                Please read our{' '}
                <Link to={'https://clarionevents.com/clarion-events-group-privacy-policy-2/'}>
                  Privacy Policy.
                </Link>
              </ListGroupItem>
            </ListGroup>
          </Alert>
        </Col>
      </Row>

      <div className={'py-3 d-flex search-list-tools justify-content-between'}>
        <MasterListPagesize size={pageSize} setPagesize={setPagesize} />
        <MasterListPagination
          pageSize={pageSize}
          totalItems={totalListItems}
          handlePaginationSelection={handlePaginationSelection}
          currentPage={currentPage + 1}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  authenticatedUser: state.users.authenticatedUser,
  contentList: state.wizard.contentList,
  error: state.wizard.error,
  currentPage: state.wizard.currentPage,
  totalPages: state.wizard.totalPages,
  totalListItems: state.wizard.totalListItems,
  loading: state.wizard.loading,
  wizType: state.wizard.wizType,
  activeInstanceStatus: state.wizard.activeInstance.status
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSwitchWizard: onSwitchWizard(dispatch),
  getContentList: contentList(dispatch),
  switchWizardInstance: dispatchSwitchWizardInstance(dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WizardSearchList));
