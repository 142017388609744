import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faStoreAlt } from '@fortawesome/free-solid-svg-icons';
import AxnInstanceFilter from '../../forms/AxnInstanceFilter';
import { AvailableInstanceMasterStatus } from '../../../actions/wizardActions';
import { AllowedWizTypes } from '../../wizard/types';
import { getBookReport } from '../../../api/reports';
import { ChildReportComponent } from './ReportsManager';
import { cssClass } from '../../wizard/cssSharedClasses';

const AllReports = (props: ChildReportComponent) => {
  const {
    activeInstance,
    activeInstanceStatus,
    axnInstanceId,
    axnPendingInstanceId,
    axnInstanceName,
    axnPendingInstanceName,
    switchWizardInstance,
    authenticatedUser
  } = props;
  const [axnStatus, setAxnStatus] = useState(activeInstanceStatus);
  const handleGenerateAll = (type: AllowedWizTypes) =>
    getBookReport(activeInstance, type, 'pdf', 'All');

  useEffect(() => {
    const instanceIdToUse = axnStatus === 'PENDING' ? axnPendingInstanceId : axnInstanceId;
    const instanceNameToUse = axnStatus === 'PENDING' ? axnPendingInstanceName : axnInstanceName;
    switchWizardInstance(
      instanceIdToUse,
      axnStatus as AvailableInstanceMasterStatus,
      instanceNameToUse
    );
  }, [axnStatus]);
  return (
    <div className={cssClass}>
      <Row>
        <Col>
          <h1 className={'mb-3'}>Full Reports for Airports/Companies</h1>
        </Col>
      </Row>
      <Row>
        <AxnInstanceFilter
          axnStatusFilterColSize={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}
          setStatus={setAxnStatus}
          status={axnStatus}
          authenticatedUser={authenticatedUser}
        />
      </Row>
      <ButtonGroup className={'py-3'}>
        <Button color={'primary'} onClick={() => handleGenerateAll('airports')} type={'button'}>
          <FontAwesomeIcon icon={faPlane} /> Generate Report for All Airports
        </Button>
        <Button color={'secondary'} onClick={() => handleGenerateAll('companies')} type={'button'}>
          <FontAwesomeIcon icon={faStoreAlt} /> Generate Report for All Companies
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default AllReports;
