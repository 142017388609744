import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import Contacts from '../components/contacts/Contacts';
import { cssClass } from '../components/wizard/cssSharedClasses';
import AccordionItem from '../components/accordion/AccordionItem';
import { pinStyle } from '../components/wizard/airport/steps/location/LocationStep';
import { AdSpeedLeaderboard } from '../components/advertisement/adspeed/leaderboard';

const ContactUsView = () => {
  return (
    <>
      <AdSpeedLeaderboard />
      <Container className={cssClass}>
        <h1 className="mb-3">Contact Information</h1>
        <Row>
          <Col>
            <AccordionItem name="Mailing Address" expandedDefault>
              <h2>AIRPORT EXPERIENCE NEWS</h2>
              <div>110 S Hartford Ave Ste 200</div>
              <div>Tulsa, Ok 74120</div>
              <div style={{ height: '75vh', width: '100%' }} className="my-3">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    key: process.env.REACT_APP_GOOGLE_MAPS_KEY_PRIMARY!
                  }} // get api from env variables
                  center={{ lat: 36.15780658593757, lng: -95.98386227116383 }}
                  defaultZoom={17}
                />
                <FontAwesomeIcon
                  className="text-primary"
                  icon={faMapMarkerAlt}
                  size="4x"
                  style={pinStyle}
                />
              </div>
            </AccordionItem>
          </Col>
        </Row>
        <Row>
          <Col>
            <AccordionItem name="Contact List" expandedDefault>
              <div className="w-100 border rounded px-4 my-3 text-center">
                <div className="my-3">
                  <FontAwesomeIcon icon={faPhone} /> Phone: 561-257-1024
                </div>
              </div>
              <Contacts />
            </AccordionItem>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactUsView;
