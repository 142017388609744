import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { chunk } from 'lodash';
import { Col, Row } from 'reactstrap';
import { ContactData, DeleteContact } from '../../../../../actions/contactsActions';
import ContactItem from '../../../shared/ContactItem';

interface ContactsListProps {
  instanceId: number;
  contentID: string;
  contentType: string;
  contacts: ContactData[];
  handleDelete?: DeleteContact;
  readonly?: boolean;
}

export interface ContactsListUrlParams {
  terminalID: string;
  contentID: string;
  contentType: string;
}
const AirportContactsList = (
  props: ContactsListProps & RouteComponentProps<ContactsListUrlParams>
) => {
  const { contentID, match, contentType, contacts, handleDelete, readonly, instanceId } = props;
  const { url } = match;

  const contactRows = (contacts: ContactData[]) =>
    chunk(
      contacts.sort((a, b) => a.orderSequenceNumber - b.orderSequenceNumber),
      2
    );

  return (
    <div>
      {contactRows(contacts).map(items => (
        <Row key={window.btoa(items.map(item => item.contactId).toString())}>
          {items.map(contact => {
            return (
              <Col lg={6} key={window.btoa(`${contact.contactId}`)}>
                {contact.contact && (
                  <ContactItem
                    contacts={contacts}
                    contact={contact.contact}
                    contentID={contentID}
                    editUrl={url.replace(/\/[0-9]*$/, '')} // remove  the current contact number if existent
                    contentType={contentType}
                    managementResponsibilityTypeId={contact.managementResponsibilityTypeId || 0}
                    handleDelete={handleDelete}
                    readonly={readonly}
                    axnInstanceId={instanceId}
                  />
                )}
              </Col>
            );
          })}
        </Row>
      ))}
    </div>
  );
};
export default withRouter(AirportContactsList);
