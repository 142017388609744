import { string, number, TestContext } from 'yup';
import msgs from './msgs';

export const defaultStringValidation = string()
  .min(2)
  .max(255);

export const zipRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const zipValidation = (msg: string = msgs.zip) => string().matches(zipRegExp, msg);

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const phoneRegExpOrWhiteSpace = /^(\s*|((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?)$/;
export const phoneNumberValidation = (msg = msgs.phone) => string().matches(phoneRegExp, msg);

export const reuseableSelectCheck = (type: () => any) => (msg: string, notOneOfVals: any[]) =>
  type()
    .required(msg)
    .notOneOf(notOneOfVals, msg)
    .typeError(msg);

export const selectCheck = {
  string: reuseableSelectCheck(string),
  number: reuseableSelectCheck(number)
};

/* Currently this only takes into consideration two fields and throws an error if the total of the two fields does not equal 100% */
export const validatePercentages = (referenceField: string) =>
  function(this: TestContext, value: any) {
    const currentValue = value;
    const relativeValue = this.parent[`${referenceField}`];
    const total = currentValue + relativeValue;
    return total === 100 || total === 0;
  };
