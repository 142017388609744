import * as React from 'react';
import { get } from 'lodash';
import { AirportInstance } from '../../../../../types';
import ResponsiveTable from '../../../../table/ResponsiveTable';

interface Props {
  airport: AirportInstance;
}

export const ReviewAirportwideInfo = ({ airport }: Props) => (
  <ResponsiveTable>
    <thead>
      <tr>
        <th colSpan={2}>Airport Info</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Airport Configuration</td>
        <td>{get(airport, 'airportConfiguration.airportConfigurationName', '')}</td>
      </tr>
      <tr>
        <td>Concessions Management Type</td>
        <td>{get(airport, 'concessionManagementType.concessionManagementType', '')}</td>
      </tr>
      {get(airport, 'airportConfiguration.additionalInfoFlag', 'N') === 'Y' && (
        <tr>
          <td>Additional Info</td>
          <td>{get(airport, 'configurationAdditionalInfoText', '')}</td>
        </tr>
      )}
    </tbody>
  </ResponsiveTable>
);
