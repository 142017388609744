import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { appHistory } from '../../reducers';

interface AddUserButtonProps {
  addIcon: IconDefinition;
  emptyEntityState: object;
  path: string;
  bottom?: string;
  setEntity?: (...props: any) => void;
}

const AddEntityButton = (props: AddUserButtonProps) => {
  const { addIcon, bottom, emptyEntityState, path, setEntity } = props;
  return (
    <Button
      className="d-inline rounded-circle fixed-bottom m-5 p-3 bg-primary text-center text-light"
      style={{
        left: 'auto',
        right: 0,
        bottom,
        width: '70px',
        height: '70px'
      }}
      onClick={() => {
        appHistory.push(path);
        if (setEntity) setEntity(emptyEntityState);
      }}>
      <FontAwesomeIcon icon={addIcon} size="2x" style={{ position: 'relative' }} />
    </Button>
  );
};

export default AddEntityButton;
