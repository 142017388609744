import React, { useEffect, useState } from 'react';
import { Button, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faFileExcel,
  faFilePdf,
  faPlus,
  faStoreAlt
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useFilteredCompanies } from '../../../api/Companies';
import { TableColumn } from '../../masterlist/MasterList';
import { MasterListActionButton, IndexableMasterListItem } from '../../masterlist/types';
import { CompanyInstance } from '../../../actions/companiesActions';
import { FilteredMasterList } from '../../masterlist/filter/FilteredMasterList';
import { StatusFilter } from '../../masterlist/filter';
import { appHistory, AppState } from '../../../reducers';
import AddEntityButton from '../AddEntityButton';
import emptyCompanyInstanceState from './emptyCompanyInstance';
import {
  AvailableInstanceMasterStatus,
  dispatchSwitchWizardInstance,
  WizardActionsCreator
} from '../../../actions/wizardActions';
import { AuthenticatedUser } from '../../../actions/usersActions';
import AxnInstanceFilter from '../../forms/AxnInstanceFilter';
import { getBookReport } from '../../../api/reports';

export interface CompanySearchResult {
  companyId?: number;
  companyName: string;
  isoCountryCode: string;
  modifiedDate: string;
  axnInstanceStatus: string;
}

const companyColumnsMap: TableColumn[] = [
  { id: 'companyId', title: 'ID' },
  { id: 'companyName', title: 'Name', width: '40%' },
  { id: 'isoCountryCode', title: 'Country' },
  { id: 'modifiedDate', title: 'Last Modified' },
  { id: 'axnInstanceStatus', title: 'Status' }
];
const convert = (data: CompanyInstance): CompanySearchResult => ({
  companyId: data.company.companyId,
  companyName: data.company.companyName,
  isoCountryCode: data.company.isoCountryCode,
  modifiedDate: data.modifiedDate || '',
  axnInstanceStatus: data.axnInstanceStatus
});

export const CompanyManager = (props: {
  switchWizardInstance: WizardActionsCreator;
  authenticatedUser: AuthenticatedUser;
  activeInstance: number;
  activeInstanceStatus: string;
}) => {
  const { switchWizardInstance, authenticatedUser, activeInstance, activeInstanceStatus } = props;
  const {
    axnPendingInstanceId,
    axnInstanceId,
    axnPendingInstanceName,
    axnInstanceName
  } = authenticatedUser;
  const [status, setStatus] = useState('ALL');
  const [axnStatus, setAxnStatus] = useState(activeInstanceStatus as AvailableInstanceMasterStatus);

  const rowActions = (id: number, name: string): MasterListActionButton[] => {
    return [
      {
        to: `/wizard/companies/${id}/steps/info`,
        icon: faEdit,
        text: 'Edit'
      },
      {
        to: '/admin/manage/companies',
        icon: faFilePdf,
        text: 'PDF',
        actionCallback: () => getBookReport(activeInstance, 'companies', 'pdf', name, [id])
      },
      {
        to: '/admin/manage/companies',
        icon: faFileExcel,
        text: 'Excel',
        actionCallback: () => getBookReport(activeInstance, 'companies', 'xlsx', name, [id])
      }
    ];
  };

  useEffect(() => {
    const instanceIdToUse = axnStatus === 'PENDING' ? axnPendingInstanceId : axnInstanceId;
    const instanceNameToUse = axnStatus === 'PENDING' ? axnPendingInstanceName : axnInstanceName;
    switchWizardInstance(
      instanceIdToUse,
      axnStatus as AvailableInstanceMasterStatus,
      instanceNameToUse
    );
  }, [
    axnStatus,
    axnInstanceId,
    axnInstanceName,
    axnPendingInstanceId,
    axnPendingInstanceName,
    switchWizardInstance
  ]);

  return (
    <>
      <Container className={'container text-right'}>
        <Button color={'primary'} onClick={() => appHistory.push(`/admin/manage/main/companies`)}>
          <FontAwesomeIcon icon={faStoreAlt} /> Manage Main Companies
        </Button>
      </Container>
      <div className={'d-flex align-items-center justify-content-between'}>
        <div>
          <StatusFilter handleChange={setStatus} selected={status} type={'instance'} />
        </div>
        <div>
          <AxnInstanceFilter
            axnStatusFilterColSize={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}
            setStatus={setAxnStatus}
            status={axnStatus}
            authenticatedUser={authenticatedUser}
          />
        </div>
      </div>
      <AddEntityButton
        addIcon={faPlus}
        emptyEntityState={emptyCompanyInstanceState}
        path={'/admin/manage/companies/add'}
      />
      <FilteredMasterList
        listType="Companies"
        actions={rowActions}
        columns={companyColumnsMap}
        convert={d => convert(d) as IndexableMasterListItem}
        useData={useFilteredCompanies}
        args={status}
        axnInstanceId={activeInstance}
        additionalValues={['companyName']}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  authenticatedUser: state.users.authenticatedUser,
  activeInstance: state.wizard.activeInstance.id,
  activeInstanceStatus: state.wizard.activeInstance.status
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  switchWizardInstance: dispatchSwitchWizardInstance(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyManager);
