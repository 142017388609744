import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface Props {
  isOpen: boolean;
  cancel: (event: React.MouseEvent<any, MouseEvent>) => void;
  success: (event: React.MouseEvent<any, MouseEvent>) => void;
  successTitle: string;
  children?: JSX.Element;
}

const Confirm = ({ children, cancel, isOpen, success, successTitle }: Props) => (
  <Modal centered isOpen={isOpen} toggle={cancel} >
    <ModalBody>
      {children}
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={success}>
        {successTitle}
      </Button>{' '}
      <Button color="secondary" onClick={cancel}>
        Cancel
      </Button>
    </ModalFooter>
  </Modal>
)

export default Confirm;
