import React, { useEffect, useState } from 'react';
import { SelectFieldSpinner } from '../shared/spinner/Spinner';
import FormField from './FormField';
import { useAllCompanies } from '../../api/Companies';
import { AutoCompleteOption } from './autocomplete/AutoComplete';

/* TODO: Fix the any */
const CompanySelectField = (props: {
  values: any;
  match: any;
  setFieldValue: (...props: any) => void;
  handleChange: any;
  handleBlur: any;
  col?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}) => {
  const { values, match, handleChange, handleBlur, setFieldValue, col } = props;
  const { companies, companiesLoading } = useAllCompanies();
  const [autoCompleteList, setAutoCompleteList] = useState<AutoCompleteOption[]>();
  const [value] = useState(values.companyName);

  useEffect(() => {
    const aclist = companies
      .map(company => ({
        ...company,
        label: company.companyName,
        key: `${company.companyId}`,
        value: `${company.companyId}`
      }))
      .sort((a, b) => (a.companyId ?? 0) - (b.companyId ?? 0));
    setAutoCompleteList(aclist);
  }, [companies]);

  useEffect(() => {
    const company = companies.find(c => c.companyName === values.companyName);
    if (company) setFieldValue('companyName', company.companyName, false);
  }, [setFieldValue, companies.length, values.companyName]);

  return (
    <>
      {companiesLoading ? (
        <SelectFieldSpinner {...col} />
      ) : (
        <FormField
          name="companyId"
          labelName="companyName"
          label="Company"
          type="autocomplete"
          match={match}
          value={value || +''}
          labelValue={values.companyName || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          autoCompleteList={autoCompleteList}
          col={col || {}}
          setFieldValue={setFieldValue}
          browserAutoComplete={'off'}
        />
      )}
    </>
  );
};

CompanySelectField.defaultValues = {
  col: { xl: 12 }
};

export default CompanySelectField;
